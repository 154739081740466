// React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Grid from '@mui/material/Grid'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import CheckoutForm from "./CheckoutForm";

// Audit Vault Utilities
import { encryptKey } from "../../utilities/common-encrypt-util";
import currencyFormatter from "../../utilities/common-currency-utils";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

// Stripe Components
import { Elements} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";



function PaymentForm2 (props) {

    const { user, company, companySize, product, price, promoCode, billingInterval, totalPrice, setOpenPaymentForm } = props;

    const [stripePromise, setStripePromise] = useState("");
    const [paymentIntentId, setPaytmentIntentId] = useState("");
    const [stripeCustomerId, setStripeCustomerId] = useState("");
    const [loading, setLoading] = useState(true);

    function getPaymentOptions(price)
    {
        const totalPrice = price.pricePerUnit
        const paymentOptions = {
            mode: 'subscription',
            amount: totalPrice,
            currency: 'usd',
            // Fully customizable with appearance API.
            appearance: {/*...*/},
        };

        return paymentOptions;
    }


    useEffect(() => {
        async function fetchData()
        {
            try
            {
                console.log("Payment Form: " + companySize);
                const publishableKey = process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY;
                setStripePromise(loadStripe(publishableKey));
                // get or create the customer
                if (!company.stripeCustomerId)
                {
                    // create the customer
                    var payload =`{
                        "companyId": ${company.id},
                        "description": "${company.companyName}",
                        "email": "${company.billingEmail}",
                        "phone": "${company.phone}",
                        "name": "${company.companyName}",
                        "currency": "usd",
                        "discount": "",
                        "city": "${company.city}",
                        "country": "${company.country}",
                        "addressLine1": "${company.streetAddress}",
                        "addressLine2": "${company.streetAddressLine2}",
                        "postalZipCode": "${company.postalZip}",
                        "provinceState": "${company.provinceState}",
                        "UserEmail": "${await encryptKey(user.userEmail)}",
                        "UserMicrosoftGraphId": "${await encryptKey(user.microsoftGraphId)}"
                    }`;

                    var stripeCustomerIdResponse = await axios.post(`${WEB_API_URL}Billing/create-customer-in-stripe`, null, {
                        params: {
                            key: payload,
                        }
                    });

                    if (stripeCustomerIdResponse)
                    {
                        setStripeCustomerId(stripeCustomerIdResponse.data.id);
                    }
                }
                else{
                    setStripeCustomerId(company.stripeCustomerId);
                }
                
            }
            catch (e)
            {
                console.log("ERROR: PaymentForm2.fetchdata");
                console.log(e);
            }
            finally{
                setLoading(false);
            }
        }

        fetchData();
    }, []);


    return(
    <>
    {!loading && stripePromise && stripeCustomerId &&
    (
        <>
        <Grid container spacing={2} sx={{width: '100%'}}>
            <Grid item xs={8} key="grdElement">
            <Elements stripe={stripePromise} options={getPaymentOptions(price)}>
                <CheckoutForm
                    user={user}
                    company={company}
                    companySize={companySize}
                    product={product}
                    price={price}
                    promoCode={promoCode}
                    paymentIntentId={paymentIntentId}
                    stripeCustomerId={stripeCustomerId}
                    setOpenPaymentForm={setOpenPaymentForm}></CheckoutForm>
            </Elements>
            </Grid>
            <Grid item xs={4} key="grdPriceTotal">
            <Card sx={{ width: '100%', height: '100%' }} variant="outlined">
                <CardContent>
                    <Typography variant="h6" component="div">Total Price</Typography>
                    <hr />
                    <Typography><b>Subscription Plan:</b></Typography>
                    <Typography>{product.name}</Typography>
                    <div style={{ fontSize: '0.8rem' }}>{product.description}</div>
                    
                    {promoCode &&
                        (
                            <>
                                <Typography sx={{ paddingTop: '5px', paddingBottom: '5px' }}>Discount Applied: <b>{promoCode.couponName}</b></Typography>
                            </>
                        )}
                    
                    <Typography component="div"><br></br></Typography>
                    <Typography component="div" sx={{ paddingBottom: 1 }}>
                        <Typography sx={{ paddingRight: 3 }}>
                            Billing Frequency: {billingInterval}
                        </Typography>
                    </Typography>
                    <Typography variant="h6" component="div"><br></br></Typography>
                    <Typography>Total Price: {currencyFormatter(totalPrice)}/{billingInterval} (USD)<br />Paid every {billingInterval}.
                        {billingInterval && billingInterval == 'month' && (<> Minimum 1 year committment.</>)}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        </Grid>
        </>
    
    )}
    
    </>);
}

export default PaymentForm2;
