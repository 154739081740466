// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { encryptKey } from '../utilities/common-encrypt-util';


// The SearchOrchestratorForm component is used for both editing an existing SearchOrchestrator and creating a new SearchOrchestrator.
export default function SearchOrchestratorForm(props) {

    // Component Constants.
    const { user, searchOrchestratorId } = props
    const navigate = useNavigate();    
    const [accessDenied, setAccessDenied] = useState(false);
    
    const [orchestratorName, setOrchestratorName] = useState('');
    const [serverName, setServerName] = useState('');
    const [numProcessors, setNumProcessors] = useState(1);
    const [maxNumConcurrentTasks, setMaxNumConcurrentTasks] = useState(1);    
    const [isActive, setIsActive] = useState(false);
    
    const [errOrchestratorName, setErrOrchestratorName] = useState(false);
    const [errServerName, setErrServerName] = useState(false);    

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const orchestratorNameValue = useRef("");
    const serverNameValue = useRef("");
    const numProcessorsValue = useRef(1);
    const maxNumConcurrentTasksValue = useRef(1);
    //const isActiveValue = useRef(false);
  
    // Generating an array of values from 1 to 25.
    const values = Array.from({ length: 25 }, (_, index) => index + 1);

    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


    // Component Functions
    /*
    // We double check the user's permissions - they have to be a System Admin to proceed.
    if (user && user.companyId && user.isSystemAdmin) 
    {
        setAccessDenied(false);
    }
    else {
        setAccessDenied(true);
    }
    */

    useEffect(() => {
        getData();
    }, []);


    async function getData() {
        setLoading(true);
        try {
            // We attempt to load the search orchestrator.
            if(searchOrchestratorId) 
            {
                // Editing an existing Search Orchestrator.  We load the SearchOrchestrator via the WebAPI.        
                var rsaKey = await encryptKey(`{id: ${searchOrchestratorId}}`);                
                if (rsaKey) {
                    var response = await axios.get(`${WEB_API_URL}SearchOrchestrator/GetSearchOrchestratorById`, {
                        params: {
                            key: `${rsaKey}`
                        }
                    });
                    // Now update the hooks.
                    // Convert isActive to boolean for state
                    const isActiveBoolean = Boolean(response.data.isActive);                
                    // Now update the hooks.                
                    setOrchestratorName(response.data.orchestratorName);
                    setServerName(response.data.serverName);
                    setNumProcessors(response.data.numOfProcessors);
                    setMaxNumConcurrentTasks(response.data.maxNumOfConcurrentTasks);
                    setIsActive(isActiveBoolean);
                }                
            }
            // Else creating a new search orchestrator - do nothing.
            setLoading(false);
        }
        catch (e) {
            catchAxiosErr2(e, "SearchOrchestratorForm.getData", setLoading, setServiceIsDownError);
        }
    }


    // Function to handle inserting / creation.
    const handleInsert = async () => {
        let isError = false;
        isError = validateInfoHandler();  // Call to validate the controls.

        if(!isError) {
            // There were no errors.  We can submit the payload.
            /*
                {
                "id": 0,
                "orchestratorName": "string",
                "serverName": "string",
                "numOfProcessors": 0,
                "maxNumOfConcurrentTasks": 0,
                "isActive": true,
                "lastRunDate": "2023-11-27T21:19:45.413Z"
                }
            */
            var payload = {
                id: 0,
                orchestratorName: await encryptKey(orchestratorName),
                serverName: serverName,
                numOfProcessors: numProcessors,
                maxNumOfConcurrentTasks: maxNumConcurrentTasks,
                isActive: isActive,                
            };            
            //console.log(`Handle Insert - Payload: ${JSON.stringify(payload)}`);
            var keyString = JSON.stringify(payload);

            axios.post(`${WEB_API_URL}SearchOrchestrator/CreateSearchOrchestrator`, null, 
                { 
                    params: {
                        key: keyString,
                    }
                }
            ).then((response) => 
            {
                navigate("/Admin/HeartbeatForSearch");
            }).catch(e => {
                console.log(e);                
            });             
        
        }    

    }


    // Function to handle performing updates.
    const handleUpdate = async () => {

        let isError = false;
        isError = validateInfoHandler();  // Call to validate the controls.

        if(!isError) {
            // There were no errors.  We can submit the payload.
            /*
                {
                "id": 0,
                "orchestratorName": "string",
                "serverName": "string",
                "numOfProcessors": 0,
                "maxNumOfConcurrentTasks": 0,
                "isActive": true,
                "lastRunDate": "2023-11-27T21:19:45.413Z"
                }
            */
            var payload = {
                id: searchOrchestratorId,
                orchestratorName: await encryptKey(orchestratorName),
                serverName: serverName,
                numOfProcessors: numProcessors,
                maxNumOfConcurrentTasks: maxNumConcurrentTasks,
                isActive: isActive,                
                lastRunDate: new Date(),
            };                
            //console.log(`Handle Edit - Payload: ${JSON.stringify(payload)}`);            
            var keyString = JSON.stringify(payload);

            axios.put(`${WEB_API_URL}SearchOrchestrator/UpdateSearchOrchestrator`, null, 
                { 
                    params: {
                        key: keyString,
                    }
                }
            ).then((response) => {
              navigate("/Admin/HeartbeatForSearch");
            });
        }

    }
     
    
    // Function to validate the form.
    // Returns true if there is an error, false otherwise.
    // Only 2 fields need to be checked for errors.
    function validateInfoHandler() 
    {
        let retVal = false;
        if (orchestratorName.trim() === '') {
            setErrOrchestratorName(true);
            retVal = true;
        } 
        else {
            setErrOrchestratorName(false);
        }

        if(serverName.trim() === '') {
            setErrServerName(true);
            retVal = true;
        }
        else {
            setErrServerName(false);
        }

        return retVal;        
    }


    // Component UI

    if(accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    if (loading) {
        console.log("Loading Data ...")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return(
        <>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>

                            <Typography key="orchestratorName" sx={{ marginBottom: 3}} component="div">
                                <b>Orchestrator Name:</b>
                                <br />
                                <TextField 
                                    required 
                                    name="Orchestrator Name" 
                                    id="orchestratorName" 
                                    placeholder="Give your Search Orchestrator a name (ex. Orchestrator1)."                        
                                    variant="outlined" 
                                    size="small"               
                                    style={{ width: 600}}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}                                
                                    onChange={event => setOrchestratorName(event.target.value) }
                                    inputRef={orchestratorNameValue}
                                    value={orchestratorName}
                                    error={errOrchestratorName}
                                    helperText={errOrchestratorName ? 'Orchestrator Name must be between 1 and 50 characters.' : ''} 
                                />
                            </Typography>
                            <Typography key="serverName" sx={{ marginBottom: 3}} component="div">
                                <b>Server Name:</b>
                                <br />
                                <TextField      
                                    required                                                     
                                    name="Server Name" 
                                    id="serverName" 
                                    placeholder="Provide app server name the Search Orchestrator will reside on (ex. VPS-1)."
                                    variant="outlined" 
                                    size="small"
                                    style={{ width: 600}}
                                    InputLabelProps={{ shrink: true }}                                
                                    inputProps={{ maxLength: 50 }}
                                    onChange={event => setServerName(event.target.value)} 
                                    inputRef={serverNameValue}
                                    value={serverName}
                                    error={errServerName}
                                    helperText={errServerName ? 'Server Name must be between 1 and 50 characters.' : ''} 
                                />     
                            </Typography>                                                   
                            <Typography key="numProcessors" sx={{ marginBottom: 3}} component="div">
                                <b>Number of Processors:</b>
                                <br />
                                <TextField      
                                    required    
                                    select                                                 
                                    name="Num Processors" 
                                    id="numProcessors"                                     
                                    variant="outlined" 
                                    size="small"
                                    style={{ width: 100}}
                                    InputLabelProps={{ shrink: true }}                                                                    
                                    onChange={event => setNumProcessors(event.target.value)} 
                                    inputRef={numProcessorsValue}                                             
                                    value={numProcessors}                                                              
                                >     
                                    {values.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                    {value}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Typography>   
                            <Typography key="maxTasks" sx={{ marginBottom: 3}} component="div">
                                <b>Maximum Tasks Per Run:</b>
                                <br />
                                <TextField      
                                    required    
                                    select                                                 
                                    name="Max Tasks" 
                                    id="maxTasks"                                     
                                    variant="outlined" 
                                    size="small"
                                    style={{ width: 100}}
                                    InputLabelProps={{ shrink: true }}                                                                    
                                    onChange={event => setMaxNumConcurrentTasks(event.target.value)} 
                                    inputRef={maxNumConcurrentTasksValue}          
                                    value={maxNumConcurrentTasks}                                                              
                                >     
                                    {values.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                    {value}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Typography>   
                            <Typography key="isActive" sx={{ marginBottom: 3 }} component="div">
                                <b>Is Active:</b>
                                <br />                                
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox required
                                        checked={isActive} 
                                        name="isActive"
                                        controlid="isActive" 
                                        onChange={event => setIsActive(event.target.checked) } 
                                    /> 
                                    <div>
                                        Search Orchestrator Is Active
                                    </div>
                                </div>


                            </Typography>


                        </CardContent>
                    </Card>
                </Grid>
            </Grid>                   

            <div>
                <br />
                {!searchOrchestratorId ? <Button variant="contained" className="ml-auto" onClick={() => handleInsert()}> Create </Button>
                    : <Button variant="contained" className="ml-auto" onClick={() => handleUpdate()}> Save Changes </Button>}
                &nbsp; <Button variant="contained" className="ml-auto" onClick={() => navigate("/Admin/HeartbeatForSearch")}> Discard </Button>
            </div>

        </>
        
    )

}


