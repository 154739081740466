import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export const AddTenantButton = () => {
   
  const navigate = useNavigate();
 
  return (
    <div>
        <Button
            variant="contained"
            type="button"
            size='small'
            onClick={() => navigate(`/tenant-create`)}
        >
            Add A New Tenant
        </Button>
    </div>
  );
}

export default AddTenantButton;