// React Components
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

// Audit Vault Utilities
import { getBillingStatus, getBillingStatusColour } from "../../utilities/common-getBillingInfo";
import { formatDate } from "../../utilities/common-date-utils";

export default function BillingInfo(props) 
{
    const navigate = useNavigate();
    const { companyId, subscription } = props;

    return(
        <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
            <b>Billing Status</b> 
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell key="billingTableHead" colSpan={2}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell key="BillingStatus" style={{ width: '40%' }}>Status:</TableCell>                                                
                    <TableCell key="BillingStatusDesc">
                        <Typography variant="h6" color={getBillingStatusColour(subscription.status)} >
                            <CircleRoundedIcon color={getBillingStatusColour(subscription.status)} sx={{paddingRight: 1, alignItems: "center"}} />
                            {getBillingStatus(subscription.status)}
                        </Typography>
                    </TableCell> 
                </TableRow>
                <TableRow>
                    <TableCell key="BillingProduct" style={{ width: '40%' }}>Subscription:</TableCell>                                                
                    <TableCell key="BillingProductDesc">
                        {subscription.description}
                    </TableCell> 
                </TableRow>
                <TableRow>
                    <TableCell key="BillingPeriod" style={{ width: '40%' }}>Next Bill Date:</TableCell>                                                
                    <TableCell key="BillingPeriodDesc">
                        {formatDate(subscription.currentPeriodEndDate)}
                    </TableCell> 
                </TableRow>
                </TableBody>
            </Table>
        </CardContent>
        <CardActions>
            <Button size="small" onClick={() => navigate(`/mybilling`)}>See More</Button>
        </CardActions>
        </Card>
    );
}