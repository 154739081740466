// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


// Function to insert our Team Name selector (textfield).
// Later enhance to auto-complete on Team Name.
export default function TeamNameSelector(props){

    const { tenantId, selectedTeam, setSelectedTeam, selectedTeamValue, errorSelectedTeam } = props;

    return(
        
        <Grid item xs={12}>
            {/* User input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Team Name:</b>
                </div>
                <div>
                    <TextField                        
                        required
                        name="Team Name Text Field"
                        id="teamNameTextfield"
                        placeholder="Enter all or a part of the name of a Team (ex. CorporateWide)."
                        variant="outlined"
                        size="small"
                        style={{ width: 600 }}
                        inputProps={{ maxLength: 300 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setSelectedTeam(event.target.value)}
                        inputRef={selectedTeamValue}
                        value={selectedTeam}
                        error={errorSelectedTeam}
                        helperText={errorSelectedTeam ? 'Team Name cannot be empty.' : ''}                     
                    />
                </div>
            </div>
        </Grid>
    );
};