// React Components
import * as React from 'react';
import { useState } from "react";
import { useParams } from "react-router-dom";

// Audit Vault Components
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';
import MessageForm from '../../components/MessageForm';

// PW: Add a check that user is an Admin User, otherwise don't allow access. 

function EditMessage() {
  
    // Component Constants.
    const { id } = useParams();
    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "Message Center - Edit Message";
    const theSectionDesc = "";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Message Center', link: '/Admin/MessageCenterAdmin' },
        { name: 'Edit Message', link: '/Admin/EditMessage' }
    ];


    // Component UI

    return (
    <>
        <DisplayBreadCrumb paths={theBreadcrumbPath} />
        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
        <MessageForm isAdminPage={isAdminPage} messageId={id}></MessageForm>
    </>
  );
}

export default EditMessage;