// React Components
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import UserSearchAssignRoleForm from "../components/role/UserSearchAssignRoleForm";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Common
import { verifyUserCanAccessCompanyData } from '../utilities/common-user-utils';

// Audit Vault Constants
const theSectionTitle = "Assign Roles";
const theSectionDesc = "Assign a Permission Role to Users who belong to your organization.  Users are M365 users whom you may grant access to manage your Company and Tenant settings + data.";
const isAdminPage = false;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Company', link: '/mycompany' },
    { name: 'Company Roles', link: '/mycompany-roles' },
    { name: 'Assign Roles', link: `/mycompany-assign-role` },
];

function MyCompanyAssignRole(props) {

    const { user } = props;
    const { tenantId, roleId } = useParams();

    // Component Constants
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [sourcePage, setSourcePage] = useState("MyCompany");

    async function fetchData() {
        if (loading && user) {

            if (verifyUserCanAccessCompanyData(user)) {
                if (roleId == 6)
                    setSourcePage("MyCompanyUsers");

                setLoading(false);
            }
            else {
                setAccessDenied(true)
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (accessDenied) {
        return <AccessDenied></AccessDenied>
    }

    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (!loading) {
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <UserSearchAssignRoleForm user={user} tenantId={tenantId} roleId={roleId} sourcePage={sourcePage} />
            </>
        )
    }

}

export default MyCompanyAssignRole;