// React Components
import * as React from 'react';
import { useState } from "react";
import { useParams } from "react-router-dom";

// Audit Vault Components
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';
import SearchOrchestratorForm from '../../components/SearchOrchestratorForm';


function EditSearchOrchestrator(props) {

    // Component Constants.
    const { user } = props;
    const { id } = useParams();

    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "Edit Search Orchestrator";
    const theSectionDesc = "Modify an existing Search Orchestrator for use with an application server. If you wish to disable a Search Orchestrator from running just mark it as in-active.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Search Orchestrators', link: '/Admin/HeartbeatForSearch' }
    ];    

    // Component UI    
    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <SearchOrchestratorForm user={user} searchOrchestratorId={id}></SearchOrchestratorForm>            
        </>
    );
}

export default EditSearchOrchestrator;