// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

// Audit Vault Components
import ProgressBar from "./common/ProgressBar";
import AccessDenied from "./common/AccessDenied";
import ServiceIsDown from "./common/ServiceIsDown";

// Audit Vault Utilities
import { getTenantStatus, getTenantStatusColour } from "../utilities/common-tenant-utils";
import { encryptKey } from '../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function AdminOrchestratorStats(props) 
{
    const navigate = useNavigate();
    const { user } = props;
    const [stats, setStats] = useState("");
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);

    async function fetchData() {
        try{
            if (user && user.isSystemAdmin)
            {
                var result;
                var data = {
                    sysAdminId: user.microsoftGraphId,                
                };                                                
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) 
                {                                                
                    result = await axios.get(`${WEB_API_URL}AdminUtilities/GetSystemOrchestratorStats`, {
                        params: {
                            key: keyString,
                        }
                    }); 
                    if(result) {
                        setStats(result.data);
                        return result.data;
                    }
                }
            }
            else
            {
                setAccessDenied(true);
            }
        }
        catch (e)
        {
            console.log("Error: AdminOrchestratorStats");
            console.log(e);
            setServiceIsDown(true);
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (accessDenied)
    {
        return(<AccessDenied></AccessDenied>)
    }

    if (serviceIsDown)
    {
        return(<ServiceIsDown />)
    }

    return (
        <Card variant="outlined">
        <CardContent>
            <b>System Orchestrator Status:</b>
            <ProgressBar message="Loading ..." loading={loading} />
            {!loading &&
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableBody>
                <TableRow>
                    <TableCell style={{ width: '40%' }}>Health:</TableCell>                                                
                    <TableCell>
                        <Typography variant="h6" color={getTenantStatusColour(stats.status)} >
                            <CircleRoundedIcon color={getTenantStatusColour(stats.status)} sx={{paddingRight: 1, alignItems: "center"}} />
                            {getTenantStatus(stats.status)}
                        </Typography> 
                    </TableCell> 
                </TableRow>
                
                <TableRow>
                    <TableCell style={{ width: '50%' }}>Active Orchestrators:</TableCell>
                    <TableCell>{stats.numberOfActiveOrchestrators}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ width: '50%' }}>Total Number of Orchestrators:</TableCell>
                    <TableCell>{stats.totalNumberOfOrchestrators}</TableCell>
                </TableRow>
                </TableBody>
            </Table>
            }
        </CardContent>
        <CardActions>
            <Button size="small" onClick={() => navigate(`/Admin/JobOrchestrators`)}>See More</Button>
        </CardActions>
        </Card>
    );
}