// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';


// Function to insert our Threshold Count selector (textfield).
export default function ThresholdCountSelector(props){

    const { selectedThresholdCount, setSelectedThresholdCount, selectedThresholdCountValue, errorSelectedThresholdCount } = props;

    return(
        
        <Grid item xs={12}>
            {/* User input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Threshold Count:</b>
                </div>
                <div>
                    <TextField                        
                        required
                        name="Threshold Count Field"
                        id="thresholdCountTextfield"
                        placeholder="Enter Threshold Count (ex. 100)."
                        variant="outlined"
                        size="small"
                        style={{ width: 248 }}
                        inputProps={{ maxLength: 100 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setSelectedThresholdCount(event.target.value)}
                        inputRef={selectedThresholdCountValue}
                        value={selectedThresholdCount}
                        error={errorSelectedThresholdCount}
                        helperText={errorSelectedThresholdCount ? 'Threshold Count cannot be empty.' : ''}                     
                    />
                </div>
            </div>
        </Grid>
    );
};