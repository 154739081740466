import React from "react";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import signInWithMsLogo from '../../imgs/buttonSignInWithMicrosoft.svg'

import { loginToMicrosoft } from '../../utilities/common-user-utils';

// MSAL Components
import { useMsal } from '@azure/msal-react';

const SessionTimeout = () => {
  
    const { instance } = useMsal();

    return (
    <>
    <Alert severity="error">
        <AlertTitle>Your session has timed out.</AlertTitle>
        Please login again.
    </Alert>
    <Typography component="div"><br></br></Typography>
    <Button onClick={() => loginToMicrosoft(instance)}>
        <img src={signInWithMsLogo} alt="Sign in with Microsoft" />
    </Button>
    </>);
      
}
export default SessionTimeout;