import * as React from 'react';
import Grid from '@mui/material/Grid';

export default function CompanyDetails(props) {

    const { company } = props

    const getColor = (isError) => {
        if (isError)
            return "red";
      };

    return (
    <>
    <Grid container spacing={1} sx={{overflow: 'hidden'}}>
        <Grid item xs={6}>Company Name:</Grid>
        <Grid item xs={6}>{company.companyName}</Grid>
        <Grid item xs={6}>Company Size:</Grid>
        <Grid item xs={6}>{company.companySize}</Grid>
        <Grid item xs={6}>Contact First Name:</Grid>
        <Grid item xs={6}>{company.primaryContactFirstName}</Grid>
        <Grid item xs={6}>Contact Last Name:</Grid>
        <Grid item xs={6}>{company.primaryContactLastName}</Grid>
        <Grid item xs={6}>Contact Email:</Grid>
        <Grid item xs={6}>{company.primaryContactEmail}</Grid>
        <Grid item xs={6}>Contact Phone Number:</Grid>
        <Grid item xs={6}>{company.primaryContactPhone}</Grid>
        <Grid item xs={6}>Country:</Grid>
        <Grid item xs={6}>{company.country}</Grid>
    </Grid>
    </>);

}