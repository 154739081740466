import React, { useState } from "react";

//Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

//  Audit Vault Components
import ProgressBar from "../common/ProgressBar";

// Audit Vault Common Utilities
import { createSubscription } from "../../utilities/common-getBillingInfo";

// Stripe Components
import { useStripe, useElements} from '@stripe/react-stripe-js';
import { PaymentElement } from "@stripe/react-stripe-js";

// Audit Vault Constants
const PAYMENT_COMPLETE_URL = process.env.REACT_APP_LINK_PAYMENT_COMPLETE;

function CheckoutForm (props) {

    const { user, company, companySize, product, price, promoCode, stripeCustomerId, setOpenPaymentForm} = props;

    const stripe = useStripe();
    const elements = useElements();
    
    const [myError, setMyError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleError = (error) => {
        setLoading(false);
        setIsProcessing(false);
        setErrorMessage(error.message);
    }
    
    const handleSubmit = async(e) =>
    {
        e.preventDefault();

        if (!stripe || !elements)
            return;

        setIsProcessing(true);
        setLoading(true);

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Create the subscription
        try{
            setLoading(true);
            const res = await createSubscription(user, company, companySize, product, price, promoCode, stripeCustomerId);
            if (res)
            {
                console.log(res);
                const type = res.data.type;
                const clientSecret = res.data.clientSecret;

                const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

                // Confirm the Intent using the details collected by the Payment Element
                const {error} = await confirmIntent({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: PAYMENT_COMPLETE_URL,
                    },
                });

                if (error) {
                    // This point is only reached if there's an immediate error when confirming the Intent.
                    // Show the error to your customer (for example, "payment details incomplete").
                    handleError(error);
                } else {
                    
                    // Your customer is redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer is redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    
                }
            }
            else
            {
                setMyError(true);
            }
        }
        catch (e)
        {
            console.log("ERROR:CheckoutForm.handleSubmit");
            console.log(e);
            setMyError(true);
        }
        finally
        {
            setLoading(false);
        }
        
    };

    if (myError)
    {
        return(<Alert severity="error"><AlertTitle>Error</AlertTitle>There was an issue processing this payment.  Please try again.</Alert>)
    }

    if (stripe)
    {
        return(
            <form onSubmit={handleSubmit}>
                {errorMessage && <Alert severity="warning"><AlertTitle>Error</AlertTitle>{errorMessage} To correct, please click the <Link onClick={() => location.reload()}>back button</Link> and try again.</Alert>}
                <br></br>
                {isProcessing &&
                    <><ProgressBar loading={isProcessing}></ProgressBar></>
                }
                <PaymentElement />
                <br></br>
                <Typography component="div">
                    <Typography component="span" sx={{ textAlign: "left", width: '30%'}}><Button type="submit" size="small" variant="contained" disabled={!loading}>{loading ? "Subscribe Now" : "Processing ..."}</Button></Typography>
                    <Typography component="span" sx={{ textAlign: "right", width: '70%'}}>
                        <Button sx={{ mr: 1 }} size="small" onClick={() => setOpenPaymentForm(false)}>Back</Button>
                    </Typography>
                </Typography>
            </form>
        );
    }
}




export default CheckoutForm;