// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';

import { green, grey } from '@mui/material/colors';
import GrantAccessButton from '../GrantAccessButton';

// Audit Vault Components
import AddTenantButton from '../AddTenantButton';
import { Typography } from '@mui/material';

export default function CompanyNag(props) {

    // Component Constants
    const { company, openCompanyNag, setOpenCompanyNag } = props;
    const navigate = useNavigate();

    // Component Functions
    const handleValidate = () => {
      navigate(`/mytenant-validate`);
    }

    if (!company)
      return "";
    
    // Component UI
    return (
        <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={2}>
        <Collapse in={openCompanyNag}>
        {company.setupStageComplete <= 4 ? (
        <Alert severity="warning">
          <AlertTitle>Audit Vault for M365 - Complete Your Setup to Continue</AlertTitle>
          <div><br></br></div>
          <Grid container spacing={3}>
          {company.setupStageComplete === 0 &&
            <>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Sign up completed - account created</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" onClick={() => navigate(`/mycompanysetupcreate`)}>Complete Initial Setup</Button></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Configure your M365 Tenant settings for this App</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Add A New Tenant</Button></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Grant this App access to your M365 Tenant (login as your M365 Global Admininistrator to consent)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Grant Consent</Button></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Validate and run first Audit Synch (login as M365 User that was designated as App Administrator)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Validate</Button></Grid>
            </>
            }

            {company.setupStageComplete === 1 &&
            <>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Sign up completed - account created</Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon /></Grid>
            <Grid item xs={7}>Configure your M365 Tenant settings for this App</Grid>
            <Grid item xs={4}><AddTenantButton /></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Grant this App access to your M365 Tenant (login as your M365 Global Administrator to consent)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Grant Consent</Button></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Validate and run first Audit Synch (login as M365 User that was designated as App Administrator)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Validate</Button></Grid>
            </>
            }

            {company.setupStageComplete === 2 &&
            <>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Sign up completed - account created</Grid>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Configure your M365 Tenant settings for this App</Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon /></Grid>
            <Grid item xs={7}>Grant this App access to your M365 Tenant (login as your M365 Global Administrator to consent)</Grid>
            <Grid item xs={4}><GrantAccessButton company={company} /></Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon sx={{ color: grey[500] }} /></Grid>
            <Grid item xs={7}>Validate and run first Audit Synch (login as M365 User that was designated as App Administrator)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" disabled>Validate</Button></Grid>
            </>
            }

            {(company.setupStageComplete === 3 || company.setupStageComplete === 4) &&
            <>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Sign up completed - account created</Grid>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Configure your M365 Tenant settings for this App</Grid>
            <Grid item xs={1}><CheckCircleOutlineIcon sx={{ color: green[500] }} /></Grid>
            <Grid item xs={11}>Grant this App access to your M365 Tenant (login as your M365 Global Administrator to consent)</Grid>
            <Grid item xs={1}><RadioButtonUncheckedIcon /></Grid>
            <Grid item xs={7}>Validate and run first Audit Synch (login as M365 User that was designated as App Administrator)</Grid>
            <Grid item xs={4}><Button variant="contained" size="small" onClick={handleValidate}>Validate</Button></Grid>
            </>
            }
          </Grid>
        </Alert>
        )
        : 
        (company.showSetUpNag &&
            <>
            <Alert severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenCompanyNag(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}>
            <AlertTitle>Setup Complete.</AlertTitle>
            <Typography sx={{paddingBottom: 2}}>
            Congratulations! Audit Vault for M365 is now securely preserving your audit logs from Microsoft 365!         
            </Typography>
            <Typography>
            Additional Setup Items We Recommend:
            <ul>
              <li>Deploy our "Audit History for SharePoint" App to your SharePoint Online Site Collection(s) from Microsoft AppSource. <Link onClick={() => navigate(`/Tenant/MyTenant?tab=2`)} component="button">Learn more</Link></li>                        
              <li>Import your Tenant's Historical Microsoft 365 Audit Log Records. <Link onClick={() => navigate(`/Tenant/MyTenant?tab=4`)} component="button">Learn More</Link></li>            
            </ul>
            </Typography>
            </Alert>
            </>
        )
        }
        </Collapse>
      </Stack>
    );
  }