/*
Note: 
The transaction log is responsible for recording all changes made to the database, providing durability and transactional integrity.
The transaction log serves several purposes:
    Recovery: The transaction log allows for database recovery in the event of a system failure or crash. It provides a record of all transactions and modifications made to the database, allowing SQL Server to restore the database to a consistent state.
    Rollback: The transaction log enables the ability to roll back transactions. If a transaction needs to be undone or rolled back, the log can be used to revert the changes made by the transaction.
    Concurrency: The transaction log facilitates concurrent processing and maintains the integrity of transactions occurring simultaneously. It ensures that multiple transactions can occur concurrently without interfering with each other.

    Without regular log backups, the transaction log can consume a significant amount of disk space, leading to issues such as disk space shortage, degraded performance, and potential transaction log-related errors.
To manage the transaction log size effectively, it is crucial to establish a proper backup strategy that includes regular transaction log backups. By performing log backups at appropriate intervals, you can truncate the log and reclaim space, keeping the log file size under control. Additionally, having a backup strategy ensures data recoverability and provides a point-in-time recovery option in case of system failures or disasters
To perform a transaction log backup for a database in SQL Server, you can use the BACKUP LOG statement. 
ex.
sql > BACKUP LOG [DatabaseName] TO [BackupDevice]
*/

// Control Database specific.
const AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB = 102400;  // Set control DB, soft limit to 100 GB.
const AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE = 75;
const AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB = 15360;  // Set control DB, transaction log soft limit to 15 GB.
const AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_WARNPERCENTAGE = 75;

// Tenant Database specific.
const AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_1_INFORM = 60;
const AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_2_STRONGWARN = 75;
const AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_3_CRITICAL = 90;

// We use this to specify how GB's are included in a DB UNIT of measure.
const AUDIT_VAULT_TENANT_DBUNITSPURCHASED_GB_PER_UNIT = 1;


export { AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_WARNPERCENTAGE, AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_1_INFORM, AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_2_STRONGWARN, AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_3_CRITICAL, AUDIT_VAULT_TENANT_DBUNITSPURCHASED_GB_PER_UNIT };