
// React Components
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Material UI Components

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import SharePointFileActivityNewAndEdit from "../components/reports/SharePointFileActivityNewAndEdit";
import ImageSharePoint from '../imgs/iconSharePoint.svg';

// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";

const theSectionTitle = "Report: SharePoint File Activity - New Files and Edits";
const theSectionDesc = "";
const isAdminPage = false;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'SharePoint Online Reports', link: '/Reports/SharePoint' },
];

/*
ReportsSharePointFileActivityNewAndEdit report.
*/

function ReportsSharePointFileActivityNewAndEdit(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, periodToUse, filterByColumn } = useParams();

    const [accessDenied, setAccessDenied] = useState(false);

    // Component Functions

    useEffect(() => {

        // Check if current user has Report Reader access to the specified tenant.
        var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTREPORTREADER);
        if (!user || !checkReportAccessForTenant) {
            setAccessDenied(true);
        }
        else {
            setAccessDenied(false);
        }

    }, []);

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageSharePoint} alt="SharePoint Reports" />
                </div>
            </div>
            <div>
                <SharePointFileActivityNewAndEdit
                    user={user}
                    companyId={user.companyId}
                    tenantId={parseInt(tenantId)}
                    periodToUse={parseInt(periodToUse)}
                    loadData={true}
                    filterByColumn={filterByColumn} />
            </div>
        </>
    );
}
export default ReportsSharePointFileActivityNewAndEdit;
