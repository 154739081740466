// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageTeams from '../imgs/iconTeams.svg';

// Audit Vault Utilities
import ReportTemplateActivityByUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Teams Reports', link: '/Reports/Teams' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'teamName', label: 'Team Name', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'channelName', label: 'Channel Name', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'members', label: 'Members', minWidth: 100, maxWidth: 115, align: 'left' },
    { id: 'attendees', label: 'Attendees', minWidth: 100, maxWidth: 115, align: 'left' }
];

/*
ReportsTeamsAllActivityByUser report - calls the reusable template ReportTemplateActivityByUserAndDateRange.
*/
function ReportsTeamsAllActivityByUser(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: Teams - All Activity by User";
    const theSectionDesc = "This report can be used in an audit investigation to return all Teams activity by a User over a date range.  To run the report you must provide the User keyword and a valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "TeamsAllActivityByUser";


    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByUserAndDateRangeWithBarChart
            user={user}
            reportType={"TeamsAllActivityByUser"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageTeams} />
    );
}
export default ReportsTeamsAllActivityByUser;