// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Audit Vault Components
import AccessDenied from '../../components/common/AccessDenied';
import TenantForm from "../../components/TenantForm";
import ProgressBar from '../../components/common/ProgressBar';
import ServiceIsDown from '../../components/common/ServiceIsDown';

// Audit Vault Utilities
import { verifyUserCanAccessTenantData } from '../../utilities/common-user-utils';
import { getTenantById } from '../../utilities/common-tenant';

function UpdateTenantAdmin(props) {

    const { user } = props;

    const { id } = useParams();

    const [tenant, setTenant] = useState(0);
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    let isAdminPage = true;

    // Component Functions
    async function fetchData() {
        try {
            if (loading && user) {
                if (verifyUserCanAccessTenantData(user, id)) {

                    var response = await getTenantById(id);
                    if (response) {
                        setTenant(response);
                    }
                }
                else {
                    setAccessDenied(true);
                }
            };
        }
        catch (e) {
            console.log("ERROR: UpdateTenantAdmin.fetchData");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Comnponent UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />)
    }

    if (tenant) {
        return (
            <>
                <TenantForm user={user} tenant={tenant} tenantId={id} isAdminPage={isAdminPage}></TenantForm>
            </>
        );
    }

}
export default UpdateTenantAdmin;