
// This list comes from Microsoft documentation on Oct 24, 2023.
// See url: https://learn.microsoft.com/en-us/purview/audit-log-activities?view=o365-worldwide
// I would assume Microsoft would not change these values but add on to the values.
// These arrays are used for Search Term building using Activities by Workload.
// As of writing - we only populate 3 Arrays - AUDIT_ACTIVITIES_ENTRA, AUDIT_ACTIVITIES_EXCHANGE, and AUDIT_ACTIVITES_SHAREPOINT.
// When we add additional workloads we would populate new Audit Activity Array constants for those.



// Teams Related Audit Log Activities.
const AUDIT_ACTIVITIES_TEAMS = [
  {
      Title: "Teams Activities",
      ChildArray: [
          {
              FriendlyName: "Added bot to team",
              Operation: "BotAddedToTeam",
              Description: "A user adds a bot to a team.",
          },
          {
              FriendlyName: "Added channel",
              Operation: "ChannelAdded",
              Description: "A user adds a channel to a team.",
          },
          {
              FriendlyName: "Added connector",
              Operation: "ConnectorAdded",
              Description: "A user adds a connector to a channel.",
          },
          {
              FriendlyName: "Added details about Teams meeting",
              Operation: "MeetingDetail",
              Description: "Teams added info about a meeting, including start time, end time, and join URL.",
          },
          {
              FriendlyName: "Added information about meeting participants",
              Operation: "MeetingParticipantDetail",
              Description: "Teams added info about meeting participants, including user IDs, join, and leave times.",
          },
          {
              FriendlyName: "Added members",
              Operation: "MemberAdded",
              Description: "A team owner adds members to a team, channel, or group chat.",
          },
          {
              FriendlyName: "Added tab",
              Operation: "TabAdded",
              Description: "A user adds a tab to a channel.",
          },
          {
              FriendlyName: "Applied sensitivity label",
              Operation: "SensitivityLabelApplied",
              Description: "A user or meeting organizer applied a sensitivity label to a Teams meeting.",
          },
          {
              FriendlyName: "Changed channel setting",
              Operation: "ChannelSettingChanged",
              Description: "A channel setting was changed by a team member.",
          },
          {
              FriendlyName: "Changed organization setting",
              Operation: "TeamsTenantSettingChanged",
              Description: "A global admin changed org-wide Teams settings.",
          },
          {
              FriendlyName: "Changed role of members in team",
              Operation: "MemberRoleChanged",
              Description: "A team owner changes the role of members in a team.  1 - Indicates the Member role, 2 - Indicates the Owner role, 3 - Indicates the Guest role.",
          },
          {
              FriendlyName: "Changed team setting",
              Operation: "TeamSettingChanged",
              Description: "A team owner changed team settings.",
          },
          {
              FriendlyName: "Changed sensitivity label",
              Operation: "SensitivityLabelChanged",
              Description: "A user changed a sensitivity label on a Teams meeting.",
          },
          {
              FriendlyName: "Created a chat",
              Operation: "ChatCreated",
              Description: "A Teams chat was created.",
          },
          {
              FriendlyName: "Created team",
              Operation: "TeamCreated",
              Description: "A user creates a team.",
          },
          {
              FriendlyName: "Deleted a message",
              Operation: "MessageDeleted",
              Description: "A message in a chat or channel was deleted.",
          },
          {
              FriendlyName: "Deleted all organization apps",
              Operation: "DeletedAllOrganizationApps",
              Description: "Deleted all organization apps from the catalog.",
          },
          {
              FriendlyName: "Deleted app",
              Operation: "AppDeletedFromCatalog",
              Description: "An app has been deleted from the catalog.",
          },
          {
              FriendlyName: "Deleted channel",
              Operation: "ChannelDeleted",
              Description: "A user deletes a channel from a team.",
          },
          {
              FriendlyName: "Deleted team",
              Operation: "TeamDeleted",
              Description: "A team owner deletes a team.",
          },
          {
              FriendlyName: "Edited a message with a URL link in Teams",
              Operation: "MessageEditedHasLink",
              Description: "A user edits a message and adds a URL link to it in Teams.",
          },
          {
              FriendlyName: "Exported messages",
              Operation: "MessagesExported",
              Description: "Chat or channel messages were exported.",
          },
          {
              FriendlyName: "Exported recordings",
              Operation: "RecordingExported",
              Description: "Chat recordings were exported.",
          },
          {
              FriendlyName: "Exported transcripts",
              Operation: "TranscriptsExported",
              Description: "Chat transcripts were exported.",
          },
          {
              FriendlyName: "Failed to validate invitation to shared channel",
              Operation: "FailedValidation",
              Description: "A user responds to an invitation to a shared channel but the invitation failed validation.",
          },
          {
              FriendlyName: "Fetched chat",
              Operation: "ChatRetrieved",
              Description: "A Microsoft Teams chat was retrieved.",
          },
          {
              FriendlyName: "Fetched all hosted content of a message",
              Operation: "MessageHostedContentsListed",
              Description: "All hosted content in a message, such as images or code snippets, was retrieved.",
          },
          {
              FriendlyName: "Installed app",
              Operation: "AppInstalled",
              Description: "An app was installed.",
          },
          {
              FriendlyName: "Performed action on card",
              Operation: "PerformedCardAction",
              Description: "A user took action on an adaptive card within a chat.",
          },
          {
              FriendlyName: "Posted a new message",
              Operation: "MessageSent",
              Description: "A new message was posted to a chat or channel.",
          },
          {
              FriendlyName: "Published app",
              Operation: "AppPublishedToCatalog",
              Description: "An app was added to the catalog.",
          },
          {
              FriendlyName: "Read a message",
              Operation: "MessageRead",
              Description: "A message of a chat or channel was retrieved.",
          },
          {
              FriendlyName: "Read hosted content of a message",
              Operation: "MessageHostedContentRead",
              Description: "Hosted content in a message, such as an image or a code snippet, was retrieved.",
          },
          {
              FriendlyName: "Removed bot from team",
              Operation: "BotRemovedFromTeam",
              Description: "A user removes a bot from a team.",
          },
          {
              FriendlyName: "Removed connector",
              Operation: "ConnectorRemoved",
              Description: "A user removes a connector from a channel.",
          },
          {
              FriendlyName: "Removed members",
              Operation: "MemberRemoved",
              Description: "A team owner removes members from a team, channel, or group chat.",
          },
          {
              FriendlyName: "Removed sensitivity label",
              Operation: "SensitivityLabelRemoved",
              Description: "A user removed a sensitivity label from a Teams meeting.",
          },
          {
              FriendlyName: "Removed sharing of team channel",
              Operation: "TerminatedSharing",
              Description: "A team or channel owner disabled sharing for a shared channel.",
          },
          {
              FriendlyName: "Restored sharing of team channel",
              Operation: "SharingRestored",
              Description: "A team or channel owner re-enabled sharing for a shared channel.",
          },
          {
              FriendlyName: "Removed tab",
              Operation: "TabRemoved",
              Description: "A user removes a tab from a channel.",
          },
          {
              FriendlyName: "Responded to invitation for shared channel",
              Operation: "InviteeResponded",
              Description: "A user responded to a shared channel invitation.",
          },
          {
              FriendlyName: "Responded to invitee response to shared channel",
              Operation: "ChannelOwnerResponded",
              Description: "A channel owner responded to a response from a user who responded to a shared channel invitation.",
          },
          {
              FriendlyName: "Retrieved messages",
              Operation: "MessagesListed",
              Description: "Messages from a chat or channel were retrieved.",
          },
          {
              FriendlyName: "Sent a message with a URL link in Teams",
              Operation: "MessageCreatedHasLink",
              Description: "A user sends a message containing a URL link in Teams.",
          },
          {
              FriendlyName: "Sent change notification for message creation",
              Operation: "MessageCreatedNotification",
              Description: "A change notification was sent to notify a subscribed listener application of a new message.",
          },
          {
              FriendlyName: "Sent change notification for message deletion",
              Operation: "MessageDeletedNotification",
              Description: "A change notification was sent to notify a subscribed listener application of a deleted message.",
          },
          {
              FriendlyName: "Sent change notification for message update",
              Operation: "MessageUpdatedNotification",
              Description: "A change notification was sent to notify a subscribed listener application of an updated message.",
          },
          {
              FriendlyName: "Sent invitation for shared channel",
              Operation: "InviteSent",
              Description: "A channel owner or member sends an invitation to a shared channel.",
          },
          {
              FriendlyName: "Subscribed to message change notifications",
              Operation: "SubscribedToMessages",
              Description: "A subscription was created by a listener application to receive change notifications for messages.",
          },
          {
              FriendlyName: "Uninstalled app",
              Operation: "AppUninstalled",
              Description: "An app was uninstalled.",
          },
          {
              FriendlyName: "Updated app",
              Operation: "AppUpdatedInCatalog",
              Description: "An app was updated in the catalog.",
          },
          {
              FriendlyName: "Updated a chat",
              Operation: "ChatUpdated",
              Description: "A Teams chat was updated.",
          },
          {
              FriendlyName: "Updated a message",
              Operation: "MessageUpdated",
              Description: "A message of a chat or channel was updated.",
          },
          {
              FriendlyName: "Updated connector",
              Operation: "ConnectorUpdated",
              Description: "A user modified a connector in a channel.",
          },
          {
              FriendlyName: "Updated tab",
              Operation: "TabUpdated",
              Description: "A user modified a tab in a channel.",
          },
          {
              FriendlyName: "Upgraded app",
              Operation: "AppUpgraded",
              Description: "An app was upgraded to its latest version in the catalog.",
          },
          {
              FriendlyName: "User signed in to Teams",
              Operation: "TeamsSessionStarted",
              Description: "A user signs in to a Microsoft Teams client.",
          },
      ],
  },
];


// Entra ID Related Audit Log Activities.
const AUDIT_ACTIVITIES_ENTRA = [
    {
      Title: "Application administration activities",
      ChildArray: [
        {
          FriendlyName: "Added delegation entry",
          Operation: "Add delegation entry",
          Description: "An authentication permission was created/granted to an application in Azure AD.",
        },
        {
          FriendlyName: "Added service principal",
          Operation: "Add service principal",
          Description: "An application was registered in Azure AD. An application is represented by a service principal in the directory.",
        },
        {
          FriendlyName: "Added credentials to a service principal",
          Operation: "Add service principal credentials",
          Description: "Credentials were added to a service principal in Azure AD. A service principal represents an application in the directory.",
        },
        {
          FriendlyName: "Removed delegation entry",
          Operation: "Remove delegation entry",
          Description: "An authentication permission was removed from an application in Azure AD.",
        },
        {
          FriendlyName: "Removed a service principal from the directory",
          Operation: "Remove service principal",
          Description: "An application was deleted/unregistered from Azure AD. An application is represented by a service principal in the directory.",
        },
        {
          FriendlyName: "Removed credentials from a service principal",
          Operation: "Remove service principal credentials",
          Description: "Credentials were removed from a service principal in Azure AD. A service principal represents an application in the directory.",
        },
        {
          FriendlyName: "Set delegation entry",
          Operation: "Set delegation entry",
          Description: "An authentication permission was updated for an application in Azure AD.",
        },
      ],
    },
    {
      Title: "Azure AD group administration activities",
      ChildArray: [
        {
          FriendlyName: "Added group",
          Operation: "Add group",
          Description: "A group was created.",
        },
        {
          FriendlyName: "Added member to group",
          Operation: "Add member to group",
          Description: "A member was added to a group.",
        },
        {
          FriendlyName: "Deleted group",
          Operation: "Delete group",
          Description: "A group was deleted.",
        },
        {
          FriendlyName: "Removed member from group",
          Operation: "Remove member from group",
          Description: "A member was removed from a group.",
        },
        {
          FriendlyName: "Updated group",
          Operation: "Update group",
          Description: "A property of a group was changed.",
        },
      ],
    },
    {
      Title: "Directory administration activities",
      ChildArray: [
        {
          FriendlyName: "Added domain to company",
          Operation: "Add domain to company",
          Description: "Added a domain to your organization.",
        },
        {
          FriendlyName: "Added a partner to the directory",
          Operation: "Add partner to company",
          Description: "Added a partner (delegated administrator) to your organization.",
        },
        {
          FriendlyName: "Removed domain from company",
          Operation: "Remove domain from company",
          Description: "Removed a domain from your organization.",
        },
        {
          FriendlyName: "Removed a partner from the directory",
          Operation: "Remove partner from company",
          Description: "Removed a partner (delegated administrator) from your organization.",
        },
        {
          FriendlyName: "Set company information",
          Operation: "Set company information",
          Description: "Updated the company information for your organization. Includes email addresses for subscription-related email sent by Microsoft 365, and technical notifications about Microsoft 365 services.",
        },
        // Repeat this structure for other child entries.
      ],
    },
    {
      Title: "Role administration activities",
      ChildArray: [
        {
          FriendlyName: "Add member to Role",
          Operation: "Add member to role",
          Description: "Added a user to an admin role in Microsoft 365.",
        },
        {
          FriendlyName: "Removed a user from a directory role",
          Operation: "Remove member from role",
          Description: "Removed a user from an admin role in Microsoft 365.",
        },
        {
          FriendlyName: "Set company contact information",
          Operation: "Set company contact information",
          Description: "Updated the company-level contact preferences for your organization. This includes email addresses for subscription-related email sent by Microsoft 365, and technical notifications about services.",
        },
      ],
    },
    {
      Title: "User administration activities",
      ChildArray: [
        {
          FriendlyName: "Added user",
          Operation: "Add user",
          Description: "A user account was created.",
        },
        {
          FriendlyName: "Changed user license",
          Operation: "Change user license",
          Description: "The license assigned to a user was changed. To see what licenses were changed, see the corresponding Updated user activity.",
        },
        {
          FriendlyName: "Changed user password",
          Operation: "Change user password",
          Description: "A user changes their password. Self-service password reset has to be enabled (for all or selected users) in your organization to allow users to reset their password. You can also track self-service password reset activity in Azure Active Directory. For more information, see Reporting options for Azure AD password management.",
        },
        {
          FriendlyName: "Deleted user",
          Operation: "Delete user",
          Description: "A user account was deleted.",
        },
        {
          FriendlyName: "Reset user password",
          Operation: "Reset user password",
          Description: "Administrator resets the password for a user.",
        },
        {
          FriendlyName: "Set property that forces user to change password",
          Operation: "Set force change user password",
          Description: "Administrator set the property that forces a user to change their password the next time the user signs in to Microsoft 365.",
        },
        {
          FriendlyName: "Set license properties",
          Operation: "Set license properties",
          Description: "Administrator modifies the properties of a license assigned to a user.",
        },
        {
          FriendlyName: "Updated user",
          Operation: "Update user",
          Description: "Administrator changes one or more properties of a user account. For a list of the user properties that can be updated, see the 'Update user attributes' section in Azure Active Directory Audit Report Events.",
        },
      ],
    },
    // Repeat this structure for additional titles if needed.
  ];
  


  // Exchange Related Audit Log Activities.
  const AUDIT_ACTIVITIES_EXCHANGE = [
    {
      Title: "Briefing email activities",
      ChildArray: [
        {
          FriendlyName: "Updated organization privacy settings",
          Operation: "UpdatedOrganizationBriefingSettings",
          Description: "Admin updates the organization privacy settings for Briefing email.",
        },
        {
          FriendlyName: "Updated user privacy settings",
          Operation: "UpdatedUserBriefingSettings",
          Description: "Admin updates the user privacy settings for Briefing email.",
        },
      ],
    },
    {
      Title: "Exchange mailbox activities",
      ChildArray: [
        {
          FriendlyName: "Accessed mailbox items",
          Operation: "MailItemsAccessed",
          Description: "Messages were read or accessed in mailbox. Audit records for this activity are triggered in one of two ways: when a mail client (such as Outlook) performs a bind operation on messages or when mail protocols (such as Exchange ActiveSync or IMAP) sync items in a mail folder. This activity is only logged for users with an Office 365 or Microsoft 365 E5 license. Analyzing audit records for this activity is useful when investigating compromised email account. For more information, see the 'Audit (Premium) events' section in Audit (Premium).",
        },
        {
          FriendlyName: "Added delegate mailbox permissions",
          Operation: "Add-MailboxPermission",
          Description: "An administrator assigned the FullAccess mailbox permission to a user (known as a delegate) to another person's mailbox. The FullAccess permission allows the delegate to open the other person's mailbox, and read and manage the contents of the mailbox. The audit record for this activity is also generated when a system account in the Microsoft 365 service periodically performs maintenance tasks in behalf of your organization. A common task performed by a system account is updating the permissions for system mailboxes. For more information, see System accounts in Exchange mailbox audit records.",
        },
        {
          FriendlyName: "Added or removed user with delegate access to calendar folder",
          Operation: "UpdateCalendarDelegation",
          Description: "A user was added or removed as a delegate to the calendar of another user's mailbox. Calendar delegation gives someone else in the same organization permissions to manage the mailbox owner's calendar.",
        },
        {
          FriendlyName: "Added permissions to folder",
          Operation: "AddFolderPermissions",
          Description: "A folder permission was added. Folder permissions control which users in your organization can access folders in a mailbox and the messages located in those folders.",
        },
        {
          FriendlyName: "Copied messages to another folder",
          Operation: "Copy",
          Description: "A message was copied to another folder.",
        },
        {
          FriendlyName: "Created mailbox item",
          Operation: "Create",
          Description: "An item is created in the Calendar, Contacts, Notes, or Tasks folder in the mailbox. For example, a new meeting request is created. Creating, sending, or receiving a message isn't audited. Also, creating a mailbox folder isn't audited.",
        },
        {
          FriendlyName: "Created new inbox rule in Outlook web app",
          Operation: "New-InboxRule",
          Description: "A mailbox owner or other user with access to the mailbox created an inbox rule in the Outlook web app.",
        },
        {
          FriendlyName: "Deleted messages from Deleted Items folder",
          Operation: "SoftDelete",
          Description: "A message was permanently deleted or deleted from the Deleted Items folder. These items are moved to the Recoverable Items folder. Messages are also moved to the Recoverable Items folder when a user selects it and presses Shift+Delete.",
        },
        {
          FriendlyName: "Labeled message as a record",
          Operation: "ApplyRecordLabel",
          Description: "A message was classified as a record. Occurs when a retention label that classifies content as a record is manually or automatically applied to a message.",
        },
        {
          FriendlyName: "Moved messages to another folder",
          Operation: "Move",
          Description: "A message was moved to another folder.",
        },
        {
          FriendlyName: "Moved messages to Deleted Items folder",
          Operation: "MoveToDeletedItems",
          Description: "A message was deleted and moved to the Deleted Items folder.",
        },
        {
          FriendlyName: "Modified folder permission",
          Operation: "UpdateFolderPermissions",
          Description: "A folder permission was changed. Folder permissions control which users in your organization can access mailbox folders and the messages in the folder.",
        },
        {
          FriendlyName: "Modified inbox rule from Outlook web app",
          Operation: "Set-InboxRule",
          Description: "A mailbox owner or other user with access to the mailbox modified an inbox rule using the Outlook web app.",
        },
        {
          FriendlyName: "Purged messages from the mailbox",
          Operation: "HardDelete",
          Description: "A message was purged from the Recoverable Items folder (permanently deleted from the mailbox).",
        },
        {
          FriendlyName: "Removed delegate mailbox permissions",
          Operation: "Remove-MailboxPermission",
          Description: "An administrator removed the FullAccess permission (that was assigned to a delegate) from a person's mailbox. After the FullAccess permission is removed, the delegate can't open the other person's mailbox or access any content in it.",
        },
        {
          FriendlyName: "Removed permissions from folder",
          Operation: "RemoveFolderPermissions",
          Description: "A folder permission was removed. Folder permissions control which users in your organization can access folders in a mailbox and the messages located in those folders.",
        },
        {
          FriendlyName: "Sent message",
          Operation: "Send",
          Description: "A message was sent, replied to, or forwarded. This activity is only logged for users with an Office 365 or Microsoft 365 E5 license. For more information, see the 'Audit (Premium) events' section in Audit (Premium).",
        },
        {
          FriendlyName: "Sent message using Send As permissions",
          Operation: "SendAs",
          Description: "A message was sent using the SendAs permission. This means that another user sent the message as though it came from the mailbox owner.",
        },
        {
          FriendlyName: "Sent message using Send On Behalf permissions",
          Operation: "SendOnBehalf",
          Description: "A message was sent using the SendOnBehalf permission. This means that another user sent the message on behalf of the mailbox owner. The message indicates to the recipient whom the message was sent on behalf of and who actually sent the message.",
        },
        {
          FriendlyName: "Updated inbox rules from Outlook client",
          Operation: "UpdateInboxRules",
          Description: "A mailbox owner or other user with access to the mailbox created, modified, or removed an inbox rule using the Outlook client.",
        },
        {
          FriendlyName: "Updated message",
          Operation: "Update",
          Description: "A message or its properties was changed.",
        },
        {
          FriendlyName: "User signed in to mailbox",
          Operation: "MailboxLogin",
          Description: "The user signed in to their mailbox.",
        },
        {
          FriendlyName: "Label message as a record",
          Operation: "ApplyRecordLabel",
          Description: "A user applied a retention label to an email message, and that label is configured to mark the item as a record.",
        },
      ],
    },
    // Repeat this structure for other titles if needed.
  ];
  



  // SharePoint Related Audit Log Activities.
  const AUDIT_ACTIVITIES_SHAREPOINT = [
    {
      Title: "File and page activities",
      ChildArray: [
        {
          FriendlyName: "Accessed file",
          Operation: "FileAccessed",
          Description: "User or system account accesses a file. Once a user accesses a file, the FileAccessed event isn't logged again for the same user for the same file for the next five minutes.",
        },
        {
          FriendlyName: "Accessed file (extended)",
          Operation: "FileAccessedExtended",
          Description: "This is related to the 'Accessed file' (FileAccessed) activity. A FileAccessedExtended event is logged when the same person continually accesses a file for an extended period (up to 3 hours).",
        },
        {
          FriendlyName: "Changed retention label for a file",
          Operation: "ComplianceSettingChanged",
          Description: "A retention label was applied to or removed from a document. This event is triggered when a retention label is manually or automatically applied to a message.",
        },
        {
          FriendlyName: "Changed record status to locked",
          Operation: "LockRecord",
          Description: "The record status of a retention label that classifies a document as a record was locked. This means the document can't be modified or deleted. Only users assigned at least the contributor permission for a site can change the record status of a document.",
        },
        {
          FriendlyName: "Changed record status to unlocked",
          Operation: "UnlockRecord",
          Description: "The record status of a retention label that classifies a document as a record was unlocked. This means that the document can be modified or deleted. Only users assigned at least the contributor permission for a site can change the record status of a document.",
        },
        {
          FriendlyName: "Checked in file",
          Operation: "FileCheckedIn",
          Description: "User checks in a document that they checked out from a document library.",
        },
        {
          FriendlyName: "Checked out file",
          Operation: "FileCheckedOut",
          Description: "User checks out a document located in a document library. Users can check out and make changes to documents that have been shared with them.",
        },
        {
          FriendlyName: "Copied file",
          Operation: "FileCopied",
          Description: "User copies a document from a site. The copied file can be saved to another folder on the site.",
        },
        {
          FriendlyName: "Deleted file",
          Operation: "FileDeleted",
          Description: "User deletes a document from a site.",
        },
        {
          FriendlyName: "Deleted file from recycle bin",
          Operation: "FileDeletedFirstStageRecycleBin",
          Description: "User deletes a file from the recycle bin of a site.",
        },
        {
          FriendlyName: "Deleted file from second-stage recycle bin",
          Operation: "FileDeletedSecondStageRecycleBin",
          Description: "User deletes a file from the second-stage recycle bin of a site.",
        },
        {
          FriendlyName: "Deleted file marked as a record",
          Operation: "RecordDelete",
          Description: "A document or email that was marked as a record was deleted. An item is considered a record when a retention label that marks items as a record is applied to content.",
        },
        {
          FriendlyName: "Detected document sensitivity mismatch",
          Operation: "DocumentSensitivityMismatchDetected",
          Description: "User uploads a document to a site that's protected with a sensitivity label and the document has a higher priority sensitivity label than the sensitivity label applied to the site. For example, a document labeled Confidential is uploaded to a site labeled General.",
        },
        {
          FriendlyName: "Detected malware in file",
          Operation: "FileMalwareDetected",
          Description: "SharePoint anti-virus engine detects malware in a file.",
        },
        {
          FriendlyName: "Discarded file checkout",
          Operation: "FileCheckOutDiscarded",
          Description: "User discards (or undoes) a checked out file. That means any changes they made to the file when it was checked out are discarded, and not saved to the version of the document in the document library.",
        },
        {
          FriendlyName: "Downloaded file",
          Operation: "FileDownloaded",
          Description: "User downloads a document from a site.",
        },
        {
          FriendlyName: "Modified file",
          Operation: "FileModified",
          Description: "User or system account modifies the content or the properties of a document on a site. The system waits five minutes before it logs another FileModified event when the same user modifies the content or properties of the same document.",
        },
        {
          FriendlyName: "Modified file (extended)",
          Operation: "FileModifiedExtended",
          Description: "This is related to the 'Modified file' (FileModified) activity. A FileModifiedExtended event is logged when the same person continually modifies a file for an extended period (up to 3 hours).",
        },
        {
          FriendlyName: "Moved file",
          Operation: "FileMoved",
          Description: "User moves a document from its current location on a site to a new location.",
        },
        {
          FriendlyName: "Previewed file",
          Operation: "FilePreviewed",
          Description: "User previews files on a SharePoint or OneDrive for Business site. These events typically occur in high volumes based on a single activity, such as viewing an image gallery.",
        },
        {
          FriendlyName: "Performed search query",
          Operation: "SearchQueryPerformed",
          Description: "User or system account performs a search in SharePoint or OneDrive for Business. Some common scenarios where a service account performs a search query include applying an eDiscovery holds and retention policy to sites and OneDrive accounts, and auto-applying retention or sensitivity labels to site content.",
        },
        {
          FriendlyName: "Recycled a file",
          Operation: "FileRecycled",
          Description: "User moves a file into the SharePoint Recycle Bin.",
        },
        {
          FriendlyName: "Recycled a folder",
          Operation: "FolderRecycled",
          Description: "User moves a folder into the SharePoint Recycle Bin.",
        },
        {
          FriendlyName: "Recycled all minor versions of file",
          Operation: "FileVersionsAllMinorsRecycled",
          Description: "User deletes all minor versions from the version history of a file. The deleted versions are moved to the site's recycle bin.",
        },
        {
          FriendlyName: "Recycled all versions of file",
          Operation: "FileVersionsAllRecycled",
          Description: "User deletes all versions from the version history of a file. The deleted versions are moved to the site's recycle bin.",
        },
        {
          FriendlyName: "Recycled version of file",
          Operation: "FileVersionRecycled",
          Description: "User deletes a version from the version history of a file. The deleted version is moved to the site's recycle bin.",
        },
        {
          FriendlyName: "Renamed file",
          Operation: "FileRenamed",
          Description: "User renames a document.",
        },
        {
          FriendlyName: "Restored file",
          Operation: "FileRestored",
          Description: "User restores a document from the recycle bin of a site.",
        },
        {
          FriendlyName: "Uploaded file",
          Operation: "FileUploaded",
          Description: "User uploads a document to a folder on a site.",
        },
        {
          FriendlyName: "Viewed page",
          Operation: "PageViewed",
          Description: "User views a page on a site. This doesn't include using a Web browser to view files located in a document library. Once a user views a page, the PageViewed event isn't logged again for the same user for the same page for the next five minutes.",
        },
        {
          FriendlyName: "Viewed page (extended)",
          Operation: "PageViewedExtended",
          Description: "This is related to the 'Viewed page' (PageViewed) activity. A PageViewedExtended event is logged when the same person continually views a web page for an extended period (up to 3 hours).",
        },
        {
          FriendlyName: "View signaled by client",
          Operation: "ClientViewSignaled",
          Description: "A user's client (such as a website or mobile app) has signaled that the indicated page has been viewed by the user. This activity is often logged following a PagePrefetched event for a page.",
        },
        {
          FriendlyName: "Page prefetched",
          Operation: "PagePrefetched",
          Description: "A user's client (such as a website or mobile app) has requested the indicated page to help improve performance if the user browses to it. This event is logged to indicate that the page content has been served to the user's client. This event isn't a definitive indication that the user navigated to the page.",
        },
      ],
    },
    {
      Title: "Folder activities",
      ChildArray: [
        {
        FriendlyName: "Copied folder",
        Operation: "FolderCopied",
        Description: "User copies a folder from a site to another location in SharePoint or OneDrive for Business.",
        },
        {
        FriendlyName: "Created folder",
        Operation: "FolderCreated",
        Description: "User creates a folder on a site.",
        },
        {
        FriendlyName: "Deleted folder",
        Operation: "FolderDeleted",
        Description: "User deletes a folder from a site.",
        },
        {
        FriendlyName: "Deleted folder from recycle bin",
        Operation: "FolderDeletedFirstStageRecycleBin",
        Description: "User deletes a folder from the recycle bin on a site.",
        },
        {
        FriendlyName: "Deleted folder from second-stage recycle bin",
        Operation: "FolderDeletedSecondStageRecycleBin",
        Description: "User deletes a folder from the second-stage recycle bin on a site.",
        },
        {
        FriendlyName: "Modified folder",
        Operation: "FolderModified",
        Description: "User modifies a folder on a site. This includes changing the folder metadata, such as changing tags and properties.",
        },
        {
        FriendlyName: "Moved folder",
        Operation: "FolderMoved",
        Description: "User moves a folder to a different location on a site.",
        },
        {
        FriendlyName: "Renamed folder",
        Operation: "FolderRenamed",
        Description: "User renames a folder on a site.",
        },
        {
        FriendlyName: "Restored folder",
        Operation: "FolderRestored",
        Description: "User restores a deleted folder from the recycle bin on a site.",
        },
      ],
    },    
    {
      Title: "SharePoint list activities",
      ChildArray: [
        {
        FriendlyName: "Created list",
        Operation: "ListCreated",
        Description: "A user created a SharePoint list.",
        },
        {
        FriendlyName: "Created list column",
        Operation: "ListColumnCreated",
        Description: "A user created a SharePoint list column. A list column is a column that's attached to one or more SharePoint lists.",
        },
        {
        FriendlyName: "Created list content type",
        Operation: "ListContentTypeCreated",
        Description: "A user created a list content type. A list content type is a content type that's attached to one or more SharePoint lists.",
        },
        {
        FriendlyName: "Created list item",
        Operation: "ListItemCreated",
        Description: "A user created an item in an existing SharePoint list.",
        },
        {
        FriendlyName: "Created site column",
        Operation: "SiteColumnCreated",
        Description: "A user created a SharePoint site column. A site column is a column that isn't attached to a list. A site column is also a metadata structure that can be used by any list in a given web.",
        },
        {
        FriendlyName: "Created site content type",
        Operation: "SiteContentTypeCreated",
        Description: "A user created a site content type. A site content type is a content type that's attached to the parent site.",
        },
        {
        FriendlyName: "Deleted list",
        Operation: "ListDeleted",
        Description: "A user deleted a SharePoint list.",
        },
        {
        FriendlyName: "Deleted list column",
        Operation: "ListColumnDeleted",
        Description: "A user deleted a SharePoint list column.",
        },
        {
        FriendlyName: "Deleted list content type",
        Operation: "ListContentTypeDeleted",
        Description: "A user deleted a list content type.",
        },
        {
        FriendlyName: "Deleted list item",
        Operation: "ListItemDeleted",
        Description: "A user deleted a SharePoint list item.",
        },
        {
        FriendlyName: "Deleted site column",
        Operation: "SiteColumnDeleted",
        Description: "A user deleted a SharePoint site column.",
        },
        {
        FriendlyName: "Deleted site content type",
        Operation: "SiteContentTypeDeleted",
        Description: "A user deleted a site content type.",
        },
        {
        FriendlyName: "Recycled list item",
        Operation: "ListItemRecycled",
        Description: "A user moved a SharePoint list item to the Recycle Bin.",
        },
        {
        FriendlyName: "Restored list",
        Operation: "ListRestored",
        Description: "A user restored a SharePoint list from the Recycle Bin.",
        },
        {
        FriendlyName: "Restored list item",
        Operation: "ListItemRestored",
        Description: "A user restored a SharePoint list item from the Recycle Bin.",
        },
        {
        FriendlyName: "Updated list",
        Operation: "ListUpdated",
        Description: "A user updated a SharePoint list by modifying one or more properties.",
        },
        {
        FriendlyName: "Updated list column",
        Operation: "ListColumnUpdated",
        Description: "A user updated a SharePoint list column by modifying one or more properties.",
        },
        {
        FriendlyName: "Updated list content type",
        Operation: "ListContentTypeUpdated",
        Description: "A user updated a list content type by modifying one or more properties.",
        },
        {
        FriendlyName: "Updated list item",
        Operation: "ListItemUpdated",
        Description: "A user updated a SharePoint list item by modifying one or more properties.",
        },
        {
        FriendlyName: "Updated site column",
        Operation: "SiteColumnUpdated",
        Description: "A user updated a SharePoint site column by modifying one or more properties.",
        },
        {
        FriendlyName: "Updated site content type",
        Operation: "SiteContentTypeUpdated",
        Description: "A user updated a site content type by modifying one or more properties.",
        },
      ],
    },
    {
      Title: "Sharing and access request activities",
      ChildArray: [
        {
        FriendlyName: "Added permission level to site collection",
        Operation: "PermissionLevelAdded",
        Description: "A permission level was added to a site collection.",
        },
        {
        FriendlyName: "Accepted access request",
        Operation: "AccessRequestAccepted",
        Description: "An access request to a site, folder, or document was accepted, and the requesting user has been granted access.",
        },
        {
        FriendlyName: "Accepted sharing invitation",
        Operation: "SharingInvitationAccepted",
        Description: "User (member or guest) accepted a sharing invitation and was granted access to a resource. This event includes information about the user who was invited and the email address that was used to accept the invitation (they could be different). This activity is often accompanied by a second event that describes how the user was granted access to the resource, for example, adding the user to a group that has access to the resource.",
        },
        {
        FriendlyName: "Blocked sharing invitation",
        Operation: "SharingInvitationBlocked",
        Description: "A sharing invitation sent by a user in your organization is blocked because of an external sharing policy that either allows or denies external sharing based on the domain of the target user. In this case, the sharing invitation was blocked because:",
        },
        {
        FriendlyName: "Created access request",
        Operation: "AccessRequestCreated",
        Description: "User requests access to a site, folder, or document they don't have permissions to access.",
        },
        {
        FriendlyName: "Created a company shareable link",
        Operation: "CompanyLinkCreated",
        Description: "User created a company-wide link to a resource. Company-wide links can only be used by members in your organization. They can't be used by guests.",
        },
        {
        FriendlyName: "Created an anonymous link",
        Operation: "AnonymousLinkCreated",
        Description: "User created an anonymous link to a resource. Anyone with this link can access the resource without having to be authenticated.",
        },
        {
        FriendlyName: "Created secure link",
        Operation: "SecureLinkCreated",
        Description: "A secure sharing link was created to this item.",
        },
        {
        FriendlyName: "Created sharing invitation",
        Operation: "SharingInvitationCreated",
        Description: "User shared a resource in SharePoint Online or OneDrive for Business with a user who isn't in your organization's directory.",
        },
        {
        FriendlyName: "Deleted secure link",
        Operation: "SecureLinkDeleted",
        Description: "A secure sharing link was deleted.",
        },
        {
        FriendlyName: "Denied access request",
        Operation: "AccessRequestDenied",
        Description: "An access request to a site, folder, or document was denied.",
        },
        {
        FriendlyName: "Removed a company shareable link",
        Operation: "CompanyLinkRemoved",
        Description: "User removed a company-wide link to a resource. The link can no longer be used to access the resource.",
        },
        {
        FriendlyName: "Removed an anonymous link",
        Operation: "AnonymousLinkRemoved",
        Description: "User removed an anonymous link to a resource. The link can no longer be used to access the resource.",
        },
        {
        FriendlyName: "Shared file, folder, or site",
        Operation: "SharingSet",
        Description: "User (member or guest) shared a file, folder, or site in SharePoint or OneDrive for Business with a user in your organization's directory. The value in the Detail column for this activity identifies the name of the user the resource was shared with and whether this user is a member or a guest.",
        },
        {
        FriendlyName: "Updated access request",
        Operation: "AccessRequestUpdated",
        Description: "An access request to an item was updated.",
        },
        {
        FriendlyName: "Updated an anonymous link",
        Operation: "AnonymousLinkUpdated",
        Description: "User updated an anonymous link to a resource. The updated field is included in the EventData property when you export the search results.",
        },
        {
        FriendlyName: "Updated sharing invitation",
        Operation: "SharingInvitationUpdated",
        Description: "An external sharing invitation was updated.",
        },
        {
        FriendlyName: "Used an anonymous link",
        Operation: "AnonymousLinkUsed",
        Description: "An anonymous user accessed a resource by using an anonymous link. The user's identity might be unknown, but you can get other details such as the user's IP address.",
        },
        {
        FriendlyName: "Unshared file, folder, or site",
        Operation: "SharingRevoked",
        Description: "User (member or guest) unshared a file, folder, or site that was previously shared with another user.",
        },
        {
        FriendlyName: "Used a company shareable link",
        Operation: "CompanyLinkUsed",
        Description: "User accessed a resource by using a company-wide link.",
        },
        {
        FriendlyName: "Used secure link",
        Operation: "SecureLinkUsed",
        Description: "A user used a secure link.",
        },
        {
        FriendlyName: "User added to secure link",
        Operation: "AddedToSecureLink",
        Description: "A user was added to the list of entities who can use a secure sharing link.",
        },
        {
        FriendlyName: "User removed from secure link",
        Operation: "RemovedFromSecureLink",
        Description: "A user was removed from the list of entities who can use a secure sharing link.",
        },
        {
        FriendlyName: "Withdrew sharing invitation",
        Operation: "SharingInvitationRevoked",
        Description: "User withdrew a sharing invitation to a resource.",
        },
      ],
    },
    {
      Title: "Site administration activities",
      ChildArray: [
        {
        FriendlyName: "Added allowed data location",
        Operation: "AllowedDataLocationAdded",
        Description: "A SharePoint or global administrator added an allowed data location in a multi-geo environment.",
        },
        {
        FriendlyName: "Added exempt user agent",
        Operation: "ExemptUserAgentSet",
        Description: "A SharePoint or global administrator added a user agent to the list of exempt user agents in the SharePoint admin center.",
        },
        {
        FriendlyName: "Added geo location admin",
        Operation: "GeoAdminAdded",
        Description: "A SharePoint or global administrator added a user as a geo admin of a location.",
        },
        {
        FriendlyName: "Allowed user to create groups",
        Operation: "AllowGroupCreationSet",
        Description: "Site administrator or owner adds a permission level to a site that allows a user assigned that permission to create a group for that site.",
        },
        {
        FriendlyName: "Canceled site geo move",
        Operation: "SiteGeoMoveCancelled",
        Description: "A SharePoint or global administrator successfully cancels a SharePoint or OneDrive site geo move. The Multi-Geo capability lets an organization span multiple Microsoft datacenter geographies, which are called geos.",
        },
        {
        FriendlyName: "Changed a sharing policy",
        Operation: "SharingPolicyChanged",
        Description: "A SharePoint or global administrator changed a SharePoint sharing policy by using the Microsoft 365 admin center, SharePoint admin center, or SharePoint Online Management Shell. Any change to the settings in the sharing policy in your organization will be logged.",
        },
        {
        FriendlyName: "Changed device access policy",
        Operation: "DeviceAccessPolicyChanged",
        Description: "A SharePoint or global administrator changed the unmanaged devices policy for your organization. This policy controls access to SharePoint, OneDrive, and Microsoft 365 from devices that aren't joined to your organization.",
        },
        {
        FriendlyName: "Changed exempt user agents",
        Operation: "CustomizeExemptUsers",
        Description: "A SharePoint or global administrator customized the list of exempt user agents in the SharePoint admin center.",
        },
        {
        FriendlyName: "Changed network access policy",
        Operation: "NetworkAccessPolicyChanged",
        Description: "A SharePoint or global administrator changed the location-based access policy (also called a trusted network boundary) in the SharePoint admin center or by using SharePoint Online PowerShell.",
        },
        {
        FriendlyName: "Completed migration job",
        Operation: "MigrationJobCompleted",
        Description: "A migration job was successfully completed.",
        },
        {
        FriendlyName: "Completed site geo move",
        Operation: "SiteGeoMoveCompleted",
        Description: "A site geo move that was scheduled by a global administrator in your organization was successfully completed.",
        },
        {
        FriendlyName: "Created Sent To connection",
        Operation: "SendToConnectionAdded",
        Description: "A SharePoint or global administrator creates a new Send To connection on the Records management page in the SharePoint admin center.",
        },
        {
        FriendlyName: "Created site collection",
        Operation: "SiteCollectionCreated",
        Description: "A SharePoint or global administrator creates a site collection in your SharePoint Online organization or a user provisions their OneDrive for Business site.",
        },
        {
        FriendlyName: "Deleted orphaned hub site",
        Operation: "HubSiteOrphanHubDeleted",
        Description: "A SharePoint or global administrator deleted an orphan hub site, which is a hub site that doesn't have any sites associated with it.",
        },
        {
        FriendlyName: "Deleted Sent To connection",
        Operation: "SendToConnectionRemoved",
        Description: "A SharePoint or global administrator deletes a Send To connection on the Records management page in the SharePoint admin center.",
        },
        {
        FriendlyName: "Deleted site",
        Operation: "SiteDeleted",
        Description: "Site administrator deletes a site.",
        },
        {
        FriendlyName: "Enabled document preview",
        Operation: "PreviewModeEnabledSet",
        Description: "Site administrator enables document preview for a site.",
        },
        {
        FriendlyName: "Enabled legacy workflow",
        Operation: "LegacyWorkflowEnabledSet",
        Description: "Site administrator or owner adds the SharePoint 2013 Workflow Task content type to the site.",
        },
        {
        FriendlyName: "Enabled Office on Demand",
        Operation: "OfficeOnDemandSet",
        Description: "Site administrator enables Office on Demand, which lets users access the latest version of Office desktop applications.",
        },
        {
        FriendlyName: "Enabled result source for People Searches",
        Operation: "PeopleResultsScopeSet",
        Description: "Site administrator creates the result source for People Searches for a site.",
        },
        {
        FriendlyName: "Enabled RSS feeds",
        Operation: "NewsFeedEnabledSet",
        Description: "Site administrator or owner enables RSS feeds for a site.",
        },
        {
        FriendlyName: "Joined site to hub site",
        Operation: "HubSiteJoined",
        Description: "A site owner associates their site with a hub site.",
        },
        {
        FriendlyName: "Modified site collection quota",
        Operation: "SiteCollectionQuotaModified",
        Description: "Site administrator modifies the quota for a site collection.",
        },
        {
        FriendlyName: "Registered hub site",
        Operation: "HubSiteRegistered",
        Description: "A SharePoint or global administrator creates a hub site.",
        },
        {
        FriendlyName: "Removed allowed data location",
        Operation: "AllowedDataLocationDeleted",
        Description: "A SharePoint or global administrator removed an allowed data location in a multi-geo environment.",
        },
        {
        FriendlyName: "Removed geo location admin",
        Operation: "GeoAdminDeleted",
        Description: "A SharePoint or global administrator removed a user as a geo admin of a location.",
        },
        {
        FriendlyName: "Renamed site",
        Operation: "SiteRenamed",
        Description: "Site administrator or owner renames a site.",
        },
        {
        FriendlyName: "Scheduled site geo move",
        Operation: "SiteGeoMoveScheduled",
        Description: "A SharePoint or global administrator successfully schedules a SharePoint or OneDrive site geo move.",
        },
        {
        FriendlyName: "Set host site",
        Operation: "HostSiteSet",
        Description: "A SharePoint or global administrator changes the designated site to host personal or OneDrive for Business sites.",
        },
        {
        FriendlyName: "Set storage quota for geo location",
        Operation: "GeoQuotaAllocated",
        Description: "A SharePoint or global administrator configured the storage quota for a geo location in a multi-geo environment.",
        },
        {
        FriendlyName: "Unjoined site from hub site",
        Operation: "HubSiteUnjoined",
        Description: "A site owner disassociates their site from a hub site.",
        },
        {
        FriendlyName: "Unregistered hub site",
        Operation: "HubSiteUnregistered",
        Description: "A SharePoint or global administrator unregisters a site as a hub site.",
        },
      ],
    },
    {
      Title: "Site permissions activities",
      ChildArray: [
        {
        FriendlyName: "Added site collection admin",
        Operation: "SiteCollectionAdminAdded",
        Description: "Site collection administrator or owner adds a person as a site collection administrator for a site.",
        },
        {
        FriendlyName: "Added user or group to SharePoint group",
        Operation: "AddedToGroup",
        Description: "User added a member or guest to a SharePoint group.",
        },
        {
        FriendlyName: "Broke permission level inheritance",
        Operation: "PermissionLevelsInheritanceBroken",
        Description: "An item was changed so that it no longer inherits permission levels from its parent.",
        },
        {
        FriendlyName: "Broke sharing inheritance",
        Operation: "SharingInheritanceBroken",
        Description: "An item was changed so that it no longer inherits sharing permissions from its parent.",
        },
        {
        FriendlyName: "Created group",
        Operation: "GroupAdded",
        Description: "Site administrator or owner creates a group for a site.",
        },
        {
        FriendlyName: "Deleted group",
        Operation: "GroupRemoved",
        Description: "User deletes a group from a site.",
        },
        {
        FriendlyName: "Modified access request setting",
        Operation: "WebRequestAccessModified",
        Description: "The access request settings were modified on a site.",
        },
        {
        FriendlyName: "Modified 'Members Can Share' setting",
        Operation: "WebMembersCanShareModified",
        Description: "The Members Can Share setting was modified on a site.",
        },
        {
        FriendlyName: "Modified permission level on a site collection",
        Operation: "PermissionLevelModified",
        Description: "A permission level was changed on a site collection.",
        },
        {
        FriendlyName: "Modified site permissions",
        Operation: "SitePermissionsModified",
        Description: "Site administrator or owner (or system account) changes the permission level that is assigned to a group on a site.",
        },
        {
        FriendlyName: "Removed permission level from site collection",
        Operation: "PermissionLevelRemoved",
        Description: "A permission level was removed from a site collection.",
        },
        {
        FriendlyName: "Removed site collection admin",
        Operation: "SiteCollectionAdminRemoved",
        Description: "Site collection administrator or owner removes a person as a site collection administrator for a site.",
        },
        {
        FriendlyName: "Removed user or group from SharePoint group",
        Operation: "RemovedFromGroup",
        Description: "User removed a member or guest from a SharePoint group.",
        },
        {
        FriendlyName: "Requested site admin permissions",
        Operation: "SiteAdminChangeRequest",
        Description: "User requests to be added as a site collection administrator for a site collection.",
        },
        {
        FriendlyName: "Restored sharing inheritance",
        Operation: "SharingInheritanceReset",
        Description: "A change was made so that an item inherits sharing permissions from its parent.",
        },
        {
        FriendlyName: "Updated group",
        Operation: "GroupUpdated",
        Description: "Site administrator or owner changes the settings of a group for a site.",
        },
      ],
    },
    {
      Title: "Synchronization activities",
      ChildArray: [
        {
        FriendlyName: "Allowed computer to sync files",
        Operation: "ManagedSyncClientAllowed",
        Description: "User successfully establishes a sync relationship with a site.",
        },
        {
        FriendlyName: "Blocked computer from syncing files",
        Operation: "UnmanagedSyncClientBlocked",
        Description: "User tries to establish a sync relationship with a site from a computer that isn't a member of your organization's domain or is a member of a domain that hasn't been added to the list of domains (called the safe recipients list) that can access document libraries in your organization.",
        },
        {
        FriendlyName: "Downloaded files to computer",
        Operation: "FileSyncDownloadedFull",
        Description: "User downloads a file to their computer from a SharePoint document library or OneDrive for Business using OneDrive sync app (OneDrive.exe).",
        },
        {
        FriendlyName: "Downloaded file changes to computer",
        Operation: "FileSyncDownloadedPartial",
        Description: "This event has been deprecated along with the old OneDrive for Business sync app (Groove.exe).",
        },
        {
        FriendlyName: "Uploaded files to document library",
        Operation: "FileSyncUploadedFull",
        Description: "User uploads a new file or changes to a file in SharePoint document library or OneDrive for Business using OneDrive sync app (OneDrive.exe).",
        },
        {
        FriendlyName: "Uploaded file changes to document library",
        Operation: "FileSyncUploadedPartial",
        Description: "This event has been deprecated along with the old OneDrive for Business sync app (Groove.exe).",
        },
      ],
    },
  ];
  

  
  export { AUDIT_ACTIVITIES_TEAMS, AUDIT_ACTIVITIES_ENTRA, AUDIT_ACTIVITIES_EXCHANGE, AUDIT_ACTIVITIES_SHAREPOINT};
  