//React Components
import axios from "axios";

// Audit Vault Utilities
import { encryptKey } from './common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function createSystemEvent(user, operation, additionalDetails) {

    if (user) {
        try {

            var payload = `{
                "userMicrosoftId": "${await encryptKey(user.microsoftGraphId)}",
                "userEmail": "${await encryptKey(user.userEmail)}",
                "companyId": ${user.companyId},
                "operation": "${operation}",
                "additionalDetails": "${additionalDetails}"
              }`;

            await axios.post(`${WEB_API_URL}UserActivityLogs/CreateUserActivityLog`, null, {
                params: {
                    key: payload,
                }
            });

        }
        catch (e) {
            console.log(`createSystemEvent Error:`, e.message);
        }
    }
    return "";
}