// React Components
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";
import '../Countdown.css';

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import CountdownTimer from "../components/common/CountdownTimer";
import FirstAuditImportStats from "../components/FirstAuditImportStats";

// Audit Vault Functions
import { getCompanyByCompanyId } from "../utilities/common-company";
import { LINK_TO_ENABLE_AUDITING_ON_TENANT } from "../constants/constants-links";
import { getTenantById, createFirstAuditImportJobs } from "../utilities/common-tenant";
import { getAuditVaultUser } from "../utilities/common-user-utils";

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Tenant', link: `/Tenant/MyTenant?tab=0` },
];


var theSectionTitle = "Validate Tenant";
var theSectionDesc = "You will need to ensure that the Microsoft Management API has been correctly set up for your tenant.  Please click on the Run First Audit Sync button to validate that Auditing is enabled on your Tenant; which will then automatically import the last 7 days of audit information into the system.  When the First Audit Sync is completed you may optionally pull in additional audit log history if you require (ex. 1 year).";
var isAdminPage = false;

var theSectionTitle2 = "View Import Progress";
var theSectionDesc2 = "The process to import your Tenant's M365 audit log history for the last 7 days has started.  This screen will periodically refresh with the latest progress.";

var theSectionTitle3 = "First Audit Import Complete";
var theSectionDesc3 = "The process to import your Tenant's M365 audit log for the last 7 days has finished.  See the details of the import below.";


function MyTenantValidate(props) {

    const { user, setUser } = props;

    // Component Constants
    const { id } = useParams();
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [tenant, setTenant] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [validationFailed, setValidationFailed] = useState(false);
    const [disableValidateButton, setDisableValidateButton] = useState(true);

    const [delay, setDelay] = useState(0);
    const [delayTime, setDelayTime] = useState('');

    // Component Functions
    async function fetchData() {

        if (loading) {
            let tenantId = id;
            if (user.permissions.length > 0)
                tenantId = user.permissions[0].tenantId;

            if (!tenantId) {
                var response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    var updatedUser = await getAuditVaultUser(isAuthenticated, accounts[0]);
                    if (updatedUser) {
                        console.log("Update User");
                        // just need to update the user since user probably just gave Admin Consent
                        setUser(updatedUser);
                    }
                    if (response.tenantList.length > 0) {
                        for (var x = 0; x < response.tenantList.length; x++) {
                            if (!response.tenantList[x].companySetUpStage < 2)
                                tenantId = response.tenantList[x].id;
                        }
                    }
                }
            }

            if (tenantId > 0) {
                try {
                    var tenantResponse = await getTenantById(tenantId);
                    if (tenantResponse) {
                        setTenant(tenantResponse);
                        if (tenantResponse.companyId != user.companyId) {
                            setAccessDenied(true);
                        }

                        var delpayResponse = await axios.get(`${WEB_API_URL}Tenant/CalculateTenantValidationDelayInMinutes/${tenantResponse.adminConsentGrantedDate}`);
                        if (delpayResponse != null) {
                            console.log(delpayResponse.data);
                            if (delpayResponse.data < 10) {
                                setDisableValidateButton(true);
                                var now = new Date();
                                now.setMinutes(now.getMinutes() + (10 - delpayResponse.data)); // timestamp
                                now = new Date(now); // Date object
                                setDelayTime(now);
                            }
                            else
                                setDisableValidateButton(false);

                            setDelay(delpayResponse.data);
                        }

                        if (tenantResponse.firstAuditImportStage == 1) {
                            theSectionTitle = theSectionTitle2;
                            theSectionDesc = theSectionDesc2;
                        }

                        if (tenantResponse.firstAuditImportStage == 2) {
                            theSectionTitle = theSectionTitle3;
                            theSectionDesc = theSectionDesc3;
                        }
                    }
                }
                catch (e) {
                    console.log("ERROR: MyTenantValidate.fetchData");
                    console.log(e);
                }
                finally {
                    setLoading(false);
                }

            };
        };
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleValidate = async () => {
        setLoading(true);
        setDisableValidateButton(true);
        try {
            var response = await createFirstAuditImportJobs(tenant.id, user);
            if (response != null) {
                console.log(response);
                navigate(0);
            }

        }
        catch (e) {
            console.log("ERROR: MyTenantValidate.handleValidate: ");
            console.log(e);
            setValidationFailed(true);
        }
        finally {
            setLoading(false);
        }

    };

    // Component UI
    if (accessDenied) {
        return (<AccessDenied></AccessDenied>)
    }

    if (validationFailed) {
        return (
            <Alert severity="error">
                <AlertTitle>Validation Failed!</AlertTitle>
                <p>It looks like Microsoft Auditing isn't currently enabled for your Microsoft 365 Tenant.</p>
                <p>To turn on Auditing on your Microsoft 365 Tenant please follow the instructions in this <Link href={LINK_TO_ENABLE_AUDITING_ON_TENANT} target="_blank">Link</Link>.
                    Once Auditing is enabled in your Microsoft 365 Tenant you can return to the Home Page and re-validate.</p>
            </Alert>
        );
    }

    if (tenant) {

        return (
            <>
                {tenant.firstAuditImportStage == 0 &&
                    <>
                        <DisplayBreadCrumb paths={theBreadcrumbPath} />
                        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                        <div><br></br></div>
                        <Card sx={{ maxWidth: '80%' }} variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div"></Typography>
                                <ProgressBar message="" loading={loading} />
                                {delay < 10 &&
                                    <>
                                        <CountdownTimer targetDate={delayTime} />
                                        <Typography><br></br></Typography>
                                    </>}

                                <Typography variant="h6" component="div">Your Tenant Settings are as follows:</Typography>
                                <Typography component="div">You will be preserving your Tenant's Audit History from the following sources. Please click on the Run First Audit Synch to start preserving your audit records.</Typography>
                                <Typography component="div"><br></br></Typography>
                                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Tenant:</TableCell>
                                            <TableCell>{tenant.tenantUrl}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Azure Tenant Id:</TableCell>
                                            <TableCell>{tenant.azureTenantId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>SharePoint Audit History:</TableCell>
                                            <TableCell>
                                                <Typography>{tenant.logSharePointAudit ? <TaskAltIcon fontSize="small" sx={{ color: 'green' }}></TaskAltIcon> :
                                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Exchange Audit History:</TableCell>
                                            <TableCell>
                                                <Typography>{tenant.logExchangeAudit ? <TaskAltIcon fontSize="small" sx={{ color: 'green' }}></TaskAltIcon> :
                                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Entra ID Audit History:</TableCell>
                                            <TableCell>
                                                <Typography>{tenant.logEntraIDAudit ? <TaskAltIcon fontSize="small" sx={{ color: 'green' }}></TaskAltIcon> :
                                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Teams Audit History:</TableCell>
                                            <TableCell>
                                                <Typography>{tenant.logTeamsAudit ? <TaskAltIcon fontSize="small" sx={{ color: 'green' }}></TaskAltIcon> :
                                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </CardContent>
                            <CardActions>
                                <Typography component="span" sx={{ width: '50%' }}>
                                    <Button type="submit" aria-label="search" onClick={() => navigate(`/tenant-update/${tenant.id}`)}>Edit Tenant</Button>
                                </Typography>
                                <Typography component="span" sx={{ width: '50%', textAlign: 'right' }}>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        disabled={disableValidateButton}
                                        onClick={(event) => handleValidate()}
                                    >
                                        Run First Audit Synch
                                    </Button>
                                </Typography>
                            </CardActions>
                            <br />
                        </Card></>
                }

                {tenant.firstAuditImportStage == 1 &&
                    <>
                        <DisplayBreadCrumb paths={theBreadcrumbPath} />
                        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle2} sectionDescription={theSectionDesc2} isAdminPage={isAdminPage} />
                        <div><br></br></div>
                        <FirstAuditImportStats user={user} tenantId={tenant.id} />
                    </>
                }

                {tenant.firstAuditImportStage == 2 &&
                    <><DisplayBreadCrumb paths={theBreadcrumbPath} /><DisplaySectionTitleAndDescription sectionTitle={theSectionTitle3} sectionDescription={theSectionDesc3} isAdminPage={isAdminPage} /><div><br></br></div><FirstAuditImportStats user={user} tenantId={tenant.id} /></>
                }
            </>
        );
    };
}
export default MyTenantValidate;