// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from 'dayjs';

// Material UI Components
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from '../components/common/ProgressBar';


// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { formatDate } from '../utilities/common-date-utils';
import getAuditSource from '../utilities/common-auditsource-util';
import { getHistoricalJobQueuesByTenantIdFromTenantDb, getHistoricalAuditHistoryByTenantIdAndJobId } from '../utilities/common-tenant';

// constants
const theSectionTitle = "Report: Historical Audit History Import";
const theSectionDesc = "The historical audit import operation pulled in your Tenant's available Audit History from Microsoft 365 to secure it in Audit Vault for M365. This represents the historical data that was chosen to be imported-in after the date of when you first started using Audit Vault for M365.  You may drill down to review the entries imported for a specific day as part of your proof of legal chain of custody and serves as proof of your historical audit log import compliance certificate.";
const isAdminPage = false;

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


function ReportsHistoricalAuditHistory(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState("loading ...");
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);

    const [history, setHistory] = useState([]);


    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Tenant', link: '/Tenant/MyTenant?tab=0' },
        { name: 'Tenant History', link: '/Tenant/MyTenant?tab=4' },
    ];

    // Need page variables for each audit type
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    // Component Functions
    async function fetchData() {
        if (loading && user) {
            try {
                // Check if current user has Report Reader access to the specified tenant.
                var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTREPORTREADER);
                if (checkReportAccessForTenant) {
                    var result = await getHistoricalJobQueuesByTenantIdFromTenantDb(tenantId);
                    if (result) {
                        setHistory(result);
                    }
                }
                else {
                    setAccessDenied(true);
                }
            }
            catch (e) {
                setServiceIsDown(true);
                console.log("ERROR: ResportsHistoricalAuditHistory:fetchData");
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }


    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [auditHistory, setAuditHistory] = React.useState([]);
        const [loadingRow, setLoadingRow] = useState(false);

        // Need page variables for each audit type
        const [page2, setPage2] = React.useState(0);
        const [rowsPerPage2, setRowsPerPage2] = React.useState(100);

        const handleChangePage2 = (event, newPage) => {
            setPage2(newPage);
        };

        const handleChangeRowsPerPage2 = (event) => {
            setRowsPerPage2(+event.target.value);
            setPage2(0);
        };


        const handleOpen = async (val, jobId) => {
            console.log(val);
            setOpen(val);
            if (val) {
                setLoadingRow(true);
                try {
                    var result = await getHistoricalAuditHistoryByTenantIdAndJobId(tenantId, jobId);
                    if (result) {
                        setAuditHistory(result);
                    }
                }
                catch (e) {
                    console.log("ERROR: handleOpen");
                    console.log(e);
                }
                finally {
                    setLoadingRow(false);
                }
            }

        };

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' }, minWidth: '100%', maxWidth: '100%' }}>

                    <TableCell component="th" scope="row">
                        {row.isComplete &&
                            <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                        }
                        {!row.isComplete &&
                            <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "blue" }}></RadioButtonUncheckedIcon>
                        }
                    </TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "-thJobId"} align="left">{row.controlDbHistoricalJobId}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thStartDayToProcessFor"} align="left">{formatDate(row.dayToProcessFor)}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thEndDayToProcessFor"} align="left">{formatDate(row.dayToProcessForEnd)}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thAuditEntries"} align="center">{row.auditEntriesProcessed}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thJobStart"} align="left">{formatDate(row.jobStartTime)}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thJobEnd"} align="left">{formatDate(row.jobEndTime)}</TableCell>
                    <TableCell key={row.controlDbHistoricalJobId + "thDeatils"}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(!open, row.controlDbHistoricalJobId)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell key={row.controlDbHistoricalJobId + "SeeDetails"} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography gutterBottom component="div">
                                    <b>Imported Items for this Job:</b>
                                </Typography>
                                <ProgressBar loading={loadingRow} ></ProgressBar>
                                <TableContainer component={Paper} sx={{ maxWidth: 950 }}>
                                    <Table aria-label="HistoricalAuditTableDetails"></Table>
                                    <Table size="small" aria-label="imported-items-details">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={row.id + "SeeDeatailsDate"} style={{ minWidth: 120, maxWidth: 150 }}>Date</TableCell>
                                                <TableCell key={row.id + "SeeDetailsSource"} style={{ minWidth: 60, maxWidth: 60 }}>Source</TableCell>
                                                <TableCell key={row.id + "SeeDetailsOperation"} style={{ minWidth: 120, maxWidth: 150 }}>Operation</TableCell>
                                                <TableCell key={row.id + "SeeDetailsUsrID"} style={{ minWidth: 120, maxWidth: 150 }}>User Id</TableCell>
                                                <TableCell key={row.id + "SeeDetailsObjId"}>Object Id</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {auditHistory.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((historyRow) => (
                                                <TableRow key={auditHistory.id + "-" + row.id + "-2"}>
                                                    <TableCell key={auditHistory.id + "SeeDeatailsDate2"} component="th" scope="row">
                                                        {formatDate(dayjs(historyRow.creationTime))}
                                                    </TableCell>
                                                    <TableCell key={auditHistory.id + "SeeDeatailsSource2"}>{getAuditSource(Number(historyRow.auditType))}</TableCell>
                                                    <TableCell key={auditHistory.id + "SeeDeatailsOperation2"}>{historyRow.operation}</TableCell>
                                                    <TableCell key={auditHistory.id + "SeeDeatailsUserId2"}>{historyRow.userId}</TableCell>
                                                    <TableCell key={auditHistory.id + "SeeDeatailsObjId2"}>{historyRow.objectId}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        component="span"
                                        count={auditHistory.length}
                                        rowsPerPage={rowsPerPage2}
                                        page={page2}
                                        onPageChange={handleChangePage2}
                                        onRowsPerPageChange={handleChangeRowsPerPage2} />
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    /*
    Row.propTypes = {
        row: PropTypes.shape({
            isComplete: PropTypes.bool.isRequired,
            auditEntriesProcessed: PropTypes.number.isRequired,
            dayToProcessFor: PropTypes.string.isRequired,
            dayToProcessForEnd: PropTypes.string.isRequired,
            history: PropTypes.arrayOf(
                PropTypes.shape({
                    amount: PropTypes.number.isRequired,
                    customerId: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    };
    */



    useEffect(() => {
        fetchData();
    }, []);

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>)
    }

    if (serviceIsDown) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    if (loading) {
        return (<ProgressBar loadingMsg='Loading ...'></ProgressBar>)
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
                <Table aria-label="HistoricalAuditTable">
                    <TableHead>
                        <TableRow>
                            <TableCell key="SPHeaderSucess">Complete</TableCell>
                            <TableCell key="SPJobId">Id</TableCell>
                            <TableCell key="SPHeaderM65StartDay">M365 Source Start Date</TableCell>
                            <TableCell key="SPHeaderM65EndDay">M365 Source End Date</TableCell>
                            <TableCell key="SPHeaderItems">Items Processed</TableCell>
                            <TableCell key="SPHeaderJobStart">Job Start Time</TableCell>
                            <TableCell key="SPHeaderJobEnd">Job End Time</TableCell>
                            <TableCell key="Details">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((his) => (
                            <Row key={his.name + "-" + his.id} row={his} />
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="span"
                    count={history.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </>
    );

}
export default ReportsHistoricalAuditHistory;