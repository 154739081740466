// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageExchange from '../imgs/iconExchange.svg';

// Audit Vault Utilities
import ReportTemplateActivityByUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Exchange Reports', link: '/Reports/Exchange' },
];


// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 125, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth: 65, align: 'left' },
    { id: 'recordType', label: 'Record Type', minWidth: 40, maxWidth: 40, align: 'left' },
    { id: 'affectedItems', label: 'Affected Items', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'parameters', label: 'Parameters', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'originatingServer', label: 'Originating Server', minWidth: 60, maxWidth: 50, align: 'left' }
];


/*
ReportsExchangeMailboxesCreatedAndDeletedByUser report - calls the reusable template ReportTemplateActivityByUserAndDateRange.
*/
function ReportsExchangeMailboxesCreatedAndDeletedByUser(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: Exchange Mailboxes Created or Deleted by Modifying User";
    const theSectionDesc = "This report can be used in an audit investigation to return all Exchange mailboxes created or deleted by the modifying User over a date range.  To run the report you must provide the User keyword and a valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "ExchangeMailboxesCreatedOrDeletedByModifyingUser";

    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByUserAndDateRangeWithBarChart
            user={user}
            reportType={"ExchangeMailboxesCreatedDeletedByModUser"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageExchange} />
    );
}
export default ReportsExchangeMailboxesCreatedAndDeletedByUser;