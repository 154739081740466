import React from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

// Temporary Bread Crumb trail component - to be replaced with Link enabled.
const DisplayBreadCrumb = (props) => {
    const { paths } = props;
    const navigate = useNavigate();
    
    const handleBreadcrumbClick = (path) => {
      navigate(path);
    }
    
    return (
      <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" separator="›">
          {paths.map((path, index) => (
              <Link key={index} underline="hover" color="inherit" onClick={() => handleBreadcrumbClick(path.link)}>{path.name}</Link>
          ))}
          </Breadcrumbs>
        </div>        
      );
 };

 export default DisplayBreadCrumb;