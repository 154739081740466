// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";

// Material UI Components
import StorageIcon from '@mui/icons-material/Storage';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import { green, red } from '@mui/material/colors';

// Audit Vault Components
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';
import AccessDenied from "../components/common/AccessDenied";
import { AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB, AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_WARNPERCENTAGE } from "../constants/constants-admindatabasewarnings";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { encryptKey } from '../utilities/common-encrypt-util';


// In this component we will display a nice simple summary of Database Health Summary - System Control.
// This component is self-enclosed, you just call it from the Admin Home Page and it loads what it needs.

function AdminControlDatabaseSummary(props) {
    
    // Component Constants        
    const { user } = props;              
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);    
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    
    const [accessDenied, setAccessDenied] = useState(false);

    /*
    Payload...
    {
        "controlDBCurrentSizeInMB": "string",
        "controlDBReportedFreeSpaceInMB": "string",
        "controlDBReportedMaxSizeInMB": "string",
        "controlLogCurrentSizeInMB": "string",
        "controlLogReportedFreeSpaceInMB": "string",
        "controlLogReportedMaxSizeInMB": "string"
    }
    */
    
    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
    
    const [controlDBCurrentSizeInMB, setControlDBCurrentSizeInMB] = useState(0);
    const [controlDBReportedFreeSpaceInMB, setControlDBReportedFreeSpaceInMB] = useState(0);
    const [controlDBReportedMaxSizeInMB, setControlDBReportedMaxSizeInMB] = useState(0);
    const [controlLogCurrentSizeInMB, setControlLogCurrentSizeInMB] = useState(0);
    const [controlLogReportedFreeSpaceInMB, setControlLogReportedFreeSpaceInMB] = useState(0);
    const [controlLogReportedMaxSizeInMB, setControlLogReportedMaxSizeInMB] = useState(0);    
    
    // Section titles.
    // There are no titles or breadcrumb for this preview sub component.

    // Component Functions
    useEffect(() => {
        async function fetchData() {            
            try {                                                      
            
                var data = {
                    sysAdminId: user.microsoftGraphId,                
                };                                                
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) 
                {                                                
                    var response = await axios.get(`${WEB_API_URL}AdminUtilities/GetControlDBStats`, {
                        params: {
                            key: keyString,
                        }
                    }); 
                    if(response) {
                        setControlDBCurrentSizeInMB(parseInt(response.data.controlDBCurrentSizeInMB, 10));  // Convert each string to a long.
                        setControlDBReportedFreeSpaceInMB(parseInt(response.data.controlDBReportedFreeSpaceInMB, 10));
                        setControlDBReportedMaxSizeInMB(parseInt(response.data.controlDBReportedMaxSizeInMB, 10));
                        setControlLogCurrentSizeInMB(parseInt(response.data.controlLogCurrentSizeInMB, 10));
                        setControlLogReportedFreeSpaceInMB(parseInt(response.data.controlLogReportedFreeSpaceInMB, 10));
                        setControlLogReportedMaxSizeInMB(parseInt(response.data.controlLogReportedMaxSizeInMB, 10));    
                        setAccessDenied(false);
                    }
                }
                setLoading(false);                        
            }   
            catch(e) {            
                // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
                catchAxiosErr2(e, "AdminControlDatabaseSummary.fetchData", setLoading, setServiceIsDownError)
                setAccessDenied(true);
            }                                 
        }
        fetchData();
    }, []);


    // Component UI
    
    // If we are still loading data, display that in the UI...
    if (loading) {        
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    // Perform analysis and process the results here.
    // Calculate current percentage of DB use.
    var currentDBUsagePercent = (controlDBCurrentSizeInMB / AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB) * 100;
    var currentLogUsagePercent = (controlLogCurrentSizeInMB / AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB) * 100;
    var dbWarn = false;
    var logWarn = false;
    if(currentDBUsagePercent > AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE) {
        dbWarn = true;
    }
    if(currentLogUsagePercent > AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_WARNPERCENTAGE) {
        logWarn = true;
    }

    // Once data has loaded now render the UI for the component.
    return (        
        
        <>                                
                <div>                     
                    The information below summarizes if there are any current or upcoming database or log issues to be proactively aware of.
                    <br /><br />
                    {/* Display the Database Stats. */}
                    <Tooltip title="The Audit Vault Control SQL Database: Data in the SQL Server database system, used for storing and retrieving data efficiently." arrow>
                        <StorageIcon color="action" />
                    </Tooltip>
                    &nbsp;                     
                    Database (sizes in MB)<br />                    
                    <div style={{ fontSize: '0.8rem' }}>                    
                        Current size: { controlDBCurrentSizeInMB } <br/>
                        Reported free space: { controlDBReportedFreeSpaceInMB } <br/>
                        Reported max size: {isNaN(controlDBReportedMaxSizeInMB) ? ( <>Unlimited</> ) : ( controlDBReportedMaxSizeInMB )} <br />                                                            
                        <div style={{ marginLeft: '25px', display: 'flex', alignItems: 'right', flexDirection: 'column'}}>
                            <div style={{ marginBottom: '5px' }}>Current Usage Stats:</div>
                                <LinearProgress
                                variant="determinate"
                                value={currentDBUsagePercent}
                                style={{ width: '50%', marginLeft: '0px', height: '10px', borderRadius: '5px' }}
                                />
                            <div>
                                <ul>
                                    <li>{currentDBUsagePercent.toFixed(1)}% of storage used, {controlDBCurrentSizeInMB} of {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB} MB</li>
                                    <li>Admin soft limit on database size: {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_MB}</li>
                                    <li>Admin warning occurs at: {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE}% usage</li>
                                    <li style={{ display: 'flex', alignItems: 'center' }}>                                                                            
                                        <b>Current Status:&nbsp; </b>
                                        { 
                                            dbWarn === true ? ( <><Avatar sx={{ bgcolor: red[500], height: 15, width: 15}}> </Avatar><span style={{fontWeight: 'bold', color: 'red'}}>&nbsp; WARNING PLEASE INCREASE DB SIZE ASAP</span></> ) : ( <><Avatar sx={{ bgcolor: green[500], height: 15, width: 15}}> </Avatar><span style={{fontWeight: 'bold', color: 'green'}}>&nbsp; Healthy</span></> ) 
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Display the Transaction Log Stats. */}                    
                    <Tooltip title="The Audit Vault Control SQL Transaction Log: A file that records all modifications made to a SQL Server database, ensuring transactional consistency, durability, and providing the ability to recover the database to a specific point in time." arrow>
                        <Inventory2Icon color="action" />
                    </Tooltip>                    
                    &nbsp; Transaction Log (sizes in MB) <br />
                    <div style={{ fontSize: '0.8rem' }}>                    
                        Current Size: { controlLogCurrentSizeInMB } <br/>
                        Reported Free Space: { controlLogReportedFreeSpaceInMB } <br/>
                        Reported Max Size: {isNaN(controlLogReportedMaxSizeInMB) ? ( <>Unlimited</> ) : ( controlLogReportedMaxSizeInMB )} <br/>
                        <div style={{ marginLeft: '25px', display: 'flex', alignItems: 'right', flexDirection: 'column'}}>
                            <div style={{ marginBottom: '5px' }}>Current Usage Stats:</div>
                                <LinearProgress
                                variant="determinate"
                                value={currentLogUsagePercent}
                                style={{ width: '50%', marginLeft: '0px', height: '10px', borderRadius: '5px' }}
                                />
                            <div>
                                <ul>
                                    <li>{currentLogUsagePercent.toFixed(1)}% of storage used, {controlLogCurrentSizeInMB} of {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB} MB</li>
                                    <li>Admin soft limit on log size: {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_MB}</li>
                                    <li>Admin warning occurs at: {AUDIT_VAULT_CONTROL_SOFTLIMIT_ON_TRANSACTIONLOGSIZE_WARNPERCENTAGE}% usage</li>                                    
                                    <li style={{ display: 'flex', alignItems: 'center' }}>                                                                            
                                        <b>Current Status:&nbsp; </b>
                                        { 
                                            logWarn === true ? ( <><Avatar sx={{ bgcolor: red[500], height: 15, width: 15}}> </Avatar><span style={{fontWeight: 'bold', color: 'red'}}>&nbsp; WARNING PLEASE TRUNCATE OR BACKUP LOGS ASAP</span></> ) : ( <><Avatar sx={{ bgcolor: green[500], height: 15, width: 15}}> </Avatar><span style={{fontWeight: 'bold', color: 'green'}}>&nbsp; Healthy</span></> ) 
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                                        
                </div>
        </>
    );
}
export default AdminControlDatabaseSummary;