// React Components
import { useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, LineElement, CategoryScale, BarElement} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 

// Audit Vault Components
import AccessDenied from "../common/AccessDenied";
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from "../common/ProgressBar";
import InvalidTenant from '../common/InvalidTenant';
import DisplayBreadCrumb from "../common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../common/DisplaySectionTitleAndDescription";
import UserSelector from "./UserSelector";
import StartAndEndDateSelector from "./StartAndEndDateSelector";
import DisplayExportableDataSource from "./DisplayExportableDataSource";

// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../../constants/constants-roles";
import { combineDateJsDateAndTimeToDateTimeOffset, presetDatasetColors } from "../../utilities/common-report-utils";
import { MAX_RESULTS_REGULAR_REPORT_QUERY } from "../../constants/constants-search";
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

// Note, below is the tree-shakable way to decrease the bundle size.
ChartJS.register(Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, BarElement, CategoryScale);
ChartJS.register(ChartDataLabels);

/*
TemplateActivityByUserAndDateRangeWithBarChart report.

This template is intended to be used with a Report page with UI user input and date range input.

Reusable report template used by various reports for any workload source with default input of User and Date Range.
Parameters:
    user
	tenantId
    theSectionTitle
	theSectionDesc
    isAdminPage
    theBreadcrumbPath
    columns
	csvFilenameTitle (prefix) -- the specific unique file name will be appended with the suffix (extension) automatically.
	axiosReportUri
    reportImageIcon 

Chart: Barchart that is displayed by default but can be hidden.
*/
function ReportTemplateActivityByUserAndDateRangeWithBarChart(props) {

    const { user, 
        reportType,
        tenantId, 
        theSectionTitle, 
        theSectionDesc, 
        isAdminPage, 
        theBreadcrumbPath, 
        columns, 
        csvFilenameTitle, 
        reportImageIcon } = props;

    // Component Constants

    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    
    const [invalidTenantError, setInvalidTenantError] = useState(false);    
    

    const reportInputData = [];  // This data structure stores the input data.
    const [reportResults, setReportResults] = useState([]);  // This data structure stores the results.
    // User selection related.
    const selectedUserIdValue = useRef("");
    const [errorSelectedUserId, setErrorSelectedUserId] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    // Date selection related.
    const previousDay = new Date();
    previousDay.setDate(previousDay.getDate());
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const [selectedStartDate, setSelectedStartDate] = useState(previousDay);
    const [selectedStartTime, setSelectedStartTime] = useState('00:00');
    const [selectedEndDate, setSelectedEndDate] = useState(nextDay);
    const [selectedEndTime, setSelectedEndTime] = useState('00:00');    
    // Form input related.
    const [formErrorText, setFormErrorText] = useState('');
    const [chartVisible, setChartVisible] = useState(true);  // Show the chart by default setting.
 
    const [csvFilenameSpecific, setCSVFilenameSpecific] = useState('');
    var csvFilenameExtension = ".csv";
    const chartWidth = 1000; // Set the desired width of the chart  

    // Component Functions.
    const toggleChartVisibility = () => {
        setChartVisible(!chartVisible);
    };

    async function fetchData() 
    {                        
        setLoading(true);
        try 
        {
            var usrEmail = "N/A";
            if (user.userEmail)
                usrEmail = user.userEmail;

            var payload = `{
                companyId: ${user.companyId},
                tenantId: ${tenantId},
                reportType: "${reportType}",
                startDt: "${reportInputData[0].periodStartDate}",                                        
                endDt: "${reportInputData[0].periodEndDate}",
                userId: "${selectedUserId}",
                userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
                userEmail: "${await encryptKey(usrEmail)}"               
            }`; 

            var response = await axios.get(`${WEB_API_URL}Reports/GetReportDataByUserDateRange`, {
                params: {
                    key: `${payload}`
                }
            });
            if (response)
            {                                                
                const results = []; // Temporary array to store the fetched result
                results.push(response.data); // Store the fetched data in the temporary array            
                                  
                setReportResults(results);  // The report results array is stored in memory.   
            }
            else {
                setInvalidTenantError(true);                
            }
        }   
        catch(e) {
            setAccessDenied(true);
            console.log("ERROR: ReportTemplateActivityByUserAndDateRangeWithBarChart.fetchData");
            console.log(e);
        }
        finally{
            setLoading(false);
        }                             
    }
    

    useEffect(() => {        

        setLoading(true);
        
        // Check if User has minimially Report Access role to the specified Tenant.
        if(!user || !verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTREPORTREADER)) {                    
            setAccessDenied(true);
        }
        setLoading(false);

    }, [formErrorText]);


    // HandleRun - check and validate input controls before running.
    async function handleRun()
    {
        let formattedSelectedStartDate = dayjs(selectedStartDate).format('YYYY-MM-DD'); 
        let formattedSelectedEndDate = dayjs(selectedEndDate).format('YYYY-MM-DD');        
        // Clear the errors at start.
        setErrorSelectedUserId(false);
        let myFormError = "";        

        // Check the userId field.
        if(selectedUserId == null || selectedUserId.length === 0) {
            myFormError = "User ID cannot be empty.";            
            setErrorSelectedUserId(true);  // Flag the error.
        }
        else {
            // Jan 12, 2024: Updated to make sure the % is not used in the userId field.
            if(selectedUserId.includes("%")) {            
                myFormError = myFormError + "User field cannot contain any % characters.";            
                //setErrorSelectedUserId(true);  // Flag the error.
            }
        }
                        
        // Check the start date and end date are valid.
        if(formattedSelectedStartDate > formattedSelectedEndDate) {            
            myFormError = myFormError + " The start date cannot be before the end date.";
        }
        else {
            if(formattedSelectedStartDate == formattedSelectedEndDate) {                
                if(selectedStartTime >= selectedEndTime) {
                    myFormError = myFormError + " The start time must be before the end time when the start date and end date are the same.";
                }
            }
            // Else the dates are not the same - in this case it's all valid at this point.                        
        }

        if (myFormError.length == 0) 
        {
            // There were no errors.
            setFormErrorText("");
            // Format the input data.  The dates assume 0 time offset, so they are in effect UTC comparison.
            reportInputData.push({
                period: 0,
                periodStartDate: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedStartDate, selectedStartTime),
                periodEndDate: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedEndDate, selectedEndTime),
            });            
            // Set specific CSV filename details to use.
            setCSVFilenameSpecific("_" + selectedUserId + "_" + reportInputData[0].periodStartDate + "_to_" + reportInputData[0].periodEndDate);
            //console.log("SUBMIT: " + reportInputData[0].periodStartDate + ", " + reportInputData[0].periodEndDate);
        
            // Fetch the result data.            
            fetchData();
        }
        else {
            setFormErrorText(myFormError);            
        }
    }

    // Component UI

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }
    
    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }    

    // Add check to see if invalid tenant error detected.
    if (invalidTenantError) {
        return (<><InvalidTenant></InvalidTenant></>);
    }
    

    // Charts Part 1: Prepare data for the chart 
    // Populate the chart with our retrieved data.  This code is only ever reached once the data loading is completed.      
    const totalOperationCounts = {}; // To store the global operation counts
    const datasetOperationCountsArray = []; // To store tally for 'operation' counts for each dataset (ex. FileRenamed, FileModified...)
    // Iterate through each dataset in reportResults and build up the legend for unique dataset 'operation' counts and total operation counts.
    reportResults.forEach((dataset, datasetIndex) => {
        const datasetOperationCounts = {}; // To store counts for this dataset

        // Iterate through each item in the dataset
        dataset.forEach(item => {
            const { operation } = item;
            // Increment the count for the operation in this dataset
            datasetOperationCounts[operation] = (datasetOperationCounts[operation] || 0) + 1;
            // Increment the count for the operation globally
            totalOperationCounts[operation] = (totalOperationCounts[operation] || 0) + 1;
        });
        // Store datasetOperationCounts in the datasetOperationCountsArray
        datasetOperationCountsArray.push(datasetOperationCounts);
    });

    // Display the total operation counts and dataset operation counts outside of the forEach loop
    //console.log('Total Operation Counts:', totalOperationCounts);
    //console.log('Dataset Operation Counts:', datasetOperationCountsArray);    

    // Collect a list of all unique operations from all datasets
    const uniqueOperations = new Set();
    datasetOperationCountsArray.forEach(counts => {
        Object.keys(counts).forEach(operation => {
            uniqueOperations.add(operation);
        });
    });


    // Charts Part 2: Create datasets and options for the chart
    // Extract labels and data from the totalOperationCounts object
    const labels = Object.keys(totalOperationCounts);
    const data = Object.values(totalOperationCounts);
    const chartData = {
        labels: labels,
        datasets: [
        {
            label: 'Report Results Summary',
            backgroundColor: data.map((_, index) => presetDatasetColors[index % presetDatasetColors.length]), 
            borderColor: 'rgb(0,0,0)',
            borderWidth: 3,
            data: data,
        },
        ],
    };
    // Configure the chart options.
    const chartOptions = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
    };

    
    
    // Charts Part 3: Summarize - calculate the total counts for each unique operation across all datasets
    const totalCountsSummary = {};
    var resultsTotalCountCheck = 0;
    datasetOperationCountsArray.forEach(counts => {
      Object.keys(counts).forEach(operation => {
        totalCountsSummary[operation] = (totalCountsSummary[operation] || 0) + counts[operation];
        resultsTotalCountCheck += counts[operation];  // Add up the total count of operations.
      });
    });  
    // Convert total counts summary to displayable text
    const totalSummaryText = Object.keys(totalCountsSummary).map(operation => {
      return `${operation}: ${totalCountsSummary[operation]}`;
    }).join(', ')


    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={reportImageIcon} alt="Reports" />
                </div>
            </div>
            <div>
                <Grid container spacing={2}>

                    { /* Render reusable UserID Textfield control. */ }
                    <UserSelector tenantId={tenantId} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} selectedUserIdValue={selectedUserIdValue} errorSelectedUserId={errorSelectedUserId} />
                    { /* Render reusable Start and End date picker control. */}
                    <StartAndEndDateSelector selectedStartDate={selectedStartDate} setSelectedStartDate={setSelectedStartDate} selectedStartTime={selectedStartTime} setSelectedStartTime={setSelectedStartTime} selectedEndTime={selectedEndTime} selectedEndDate={selectedEndDate} setSelectedEndDate={setSelectedEndDate} setSelectedEndTime={setSelectedEndTime} />

                    <Grid item xs={12}>
                        <Button variant="contained" type="button" onClick={() => handleRun()}>Run Report</Button>                
                    </Grid>
                </Grid>

                {
                    formErrorText.length > 0 && 
                    (                        
                        <Grid item xs={12}>     
                        <br />                                                   
                        <Alert severity="error">                            
                            <AlertTitle>Form Error: {formErrorText}</AlertTitle>
                        </Alert>
                        </Grid>
                    )                    
                }

                {/* Display informational note if the report results exceeded the total # of allowable report results set on the backend. */}        
                {
                    (resultsTotalCountCheck >= MAX_RESULTS_REGULAR_REPORT_QUERY) &&                                
                    (
                        <Grid item xs={12}>
                            <br />
                            <Alert severity="warning">
                                <AlertTitle>The Report Results have exceeded the total number of allowed results in a Report.</AlertTitle>
                                The maximum number of results in a Report is truncated at: {MAX_RESULTS_REGULAR_REPORT_QUERY}.
                                <br />Please refine your Report Criteria (above) to narrow down the results or alternatively run an Insights Search which allows for a larger result set.
                            </Alert>
                        </Grid>
                    )
                }                                                                                     

                <br />
                <ProgressBar message="Loading ..." loading={loading} />                
                {
                    !loading && totalSummaryText.length > 0 &&  
                    (
                        <>
                        <br />
                        <a href="#" onClick={toggleChartVisibility}>
                            {chartVisible ? 'Hide Chart & Summary' : 'Show Chart & Summary'}
                        </a>
                        <br />
                        </>
                    )                    
                }                
                {                    
                    !loading && totalSummaryText.length > 0 && chartVisible &&
                    (
                        <>
                        <br />
                        <div style={{ width: `${chartWidth}px` }}>
                        <h6>
                            Chart and Summary: {theSectionTitle}
                        </h6>                                                    
                        <Bar data={chartData} options={chartOptions} />
                        <div style={{ fontSize: '0.8rem' }}>
                            <br/><b>Event Summary for Period:</b> {totalSummaryText}
                            <br /><br />
                        </div>
                        </div>
                        </>
                    )                    
                }
                {                    
                    !loading &&
                    (                                
                        <DisplayExportableDataSource columns={columns} reportResults={reportResults} csvFilename={(csvFilenameTitle + csvFilenameSpecific + csvFilenameExtension)} tenantId={tenantId} reportType={reportType} user={user} />                                                
                    )                    
                }
            
            </div>
        </>
    );
}
export default ReportTemplateActivityByUserAndDateRangeWithBarChart;