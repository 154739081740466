// React Components
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

//Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ProgressBar from "../common/ProgressBar";
import ServiceIsDown from "../common/ServiceIsDown";
import PaymentForm2 from "../companysetup/PaymentForm2";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
import { LINK_TO_SAAS_CUSTOMER_AGREEMENT } from "../../constants/constants-links";
import { COMPANY_SIZES } from "../../constants/constants-companysizes";

// Audit Vault Utilities
import { getCountOfTenantUsers } from '../../utilities/common-user-utils';
import currencyFormatter from "../../utilities/common-currency-utils";
import { getCompanyByCompanyId } from "../../utilities/common-company";
import { CardActions } from "@mui/material";



function MakePaymentForm(props) {

  const { user } = props;

  const [loading, setLoading] = useState(true);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const [tenantUsersCount, setTenantUsersCount] = useState(true);
  const [company, setCompany] = useState("");
  

  // Product Info From Stripe
  const [billingInterval, setBillingInterval] = useState("year");
  const [stripeProducts, setStripeProducts] = useState("");
  const [product, setProduct] = useState("");
  const [price, setPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  // Upgrade Subscription 
  const [companySize, setCompanySize] = useState(0);
  const [upgradeSubscriptionError, setUpgradeSubscriptionError] = useState(false);

  const promo = useRef("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeMsg, setPromoCodeMsg] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoUsed, setPromoUsed] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [loadingPromo, setLoadingPromo] = useState(false);

  const [openPaymentForm, setOpenPaymentForm] = useState(false);

  const handleApplyPromoCode = async() =>
    {
        var found = false;
        var currentPrice = totalPrice;
        const response = await axios.get(`${WEB_API_URL}Billing/get-promos`);
        if (response) {
            for(var i = 0; i < response.data.length; i++) {
                
                if (response.data[i].code == discountCode)
                {
                    if (response.data[i].couponIsValidForBillingInterval == price.billingInterval)
                    {
                        setPromoCode(response.data[i]);
                        setPromoCodeMsg("Success!  Applied " + response.data[i].couponName);
                        setPromoUsed(true);

                        var total = Number(price.pricePerUnit);

                        if (response.data[i].couponPercentOff > 0)
                        {
                            total = (Number(price.pricePerUnit) - Number(price.pricePerUnit * response.data[i].couponPercentOff/100))/100;
                        }
                        else if (response.data[i].couponAmountOff > 0)
                        {
                            total = (Number(price.pricePerUnit) - Number(response.data[i].couponAmountOff))/100;
                        }

                        if (total > 0)
                        {
                            found = true;
                            // Apply Promo code discount to price
                            setTotalPrice(total);
                        }
                        else
                        {
                            found = false;
                        }

                        setLoadingPromo(false);
                        break;
                    }
                }
            }

            if (!found)
            {
                setPromoCode('');
                setPromoUsed(false);
                setPromoCodeMsg("Promo Code is not valid.");
                setLoadingPromo(false);

                // reset price back to original
                setTotalPrice(currentPrice);
            }
        }
    }

    const handleApplyPromo = async() =>
    {
        setLoadingPromo(true);
        let isError=false;
        if (discountCode.trim() === '' ? setPromoCodeError(true) : setPromoCodeError(false));
        if (discountCode.trim() === '') {isError = true;}
        if (!isError)
        {
            var applyPromoResponse = await handleApplyPromoCode();
            if (applyPromoResponse)
            {
                setLoadingPromo(false);
            }
        }
        else
        {
            // reset price back to original
            setTotalPrice(price.pricePerUnit/100)
            setLoadingPromo(false);
        }
        
    }
      
    const handleRemovePromo = () => 
    {
        // reset price back to original
        setTotalPrice(price.pricePerUnit/100)

        setPromoCode("");
        setPromoUsed(false);
        setPromoCodeMsg("");
        setDiscountCode('');
    }
  
    const handleBillingInterval = async(val) =>
    {
        setPromoCode("");
        setPromoUsed(false);
        setPromoCodeMsg("");
        setDiscountCode('');
        setPromoCodeError(false);

        setBillingInterval(val);
        var response = await getProductAndPriceIds(val);
        if (response)
        {
            setTotalPrice(response.pricePerUnit/100);
        }  
    }

    function handleUpgradeSubscription(val)
    {
        setPromoCode("");
        setPromoUsed(false);
        setPromoCodeMsg("");
        setDiscountCode('');
        setPromoCodeError(false);

        if (val < tenantUsersCount)
        {
            setCompanySize(company.companySize);
            setUpgradeSubscriptionError(true);
            return false;
        }
        setUpgradeSubscriptionError(false);
        console.log("Setting company size to: " + val);
        setCompanySize(val);


        for (let i = 0; i < stripeProducts.length; i++) {
            if (val > stripeProducts[i].minUserCount) {
                
                for (let x = 0; x < stripeProducts[i].productPrices.length; x++) {
                    if (stripeProducts[i].productPrices[x].billingInterval == billingInterval) {
                        console.log(stripeProducts[i]);
                        setProduct(stripeProducts[i]);
                        setPrice(stripeProducts[i].productPrices[x]);
                        setTotalPrice(stripeProducts[i].productPrices[x].pricePerUnit/100);
                        return stripeProducts[i].productPrices[x];
                    }
                }
                break;
            }
        }

    }

    async function getProductAndPriceIds(val) {

        for (let i = 0; i < stripeProducts.length; i++) {
            if (companySize > stripeProducts[i].minUserCount) {
                
                for (let x = 0; x < stripeProducts[i].productPrices.length; x++) {
                    if (stripeProducts[i].productPrices[x].billingInterval == val) {
                        console.log(stripeProducts[i]);
                        setProduct(stripeProducts[i]);
                        setPrice(stripeProducts[i].productPrices[x]);
                        return stripeProducts[i].productPrices[x];
                    }
                }
                break;
            }
        }
    }

async function fetchData() {
    try {
        if (loading) {
            var stripeProductsResponse = await axios.get(`${WEB_API_URL}Billing/get-products`);
            if (stripeProductsResponse) {
                setStripeProducts(stripeProductsResponse.data);
                setProduct(stripeProductsResponse.data[0]);

                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);
                    const response1 = await getCountOfTenantUsers(response.id, response.tenantList[0].id);
                    if (response1)
                    {
                        var userCount = parseInt(response1);
                        setTenantUsersCount(userCount);

                        // set the company size to the tier based off actual user count
                        for (let i = 0; i < COMPANY_SIZES.length; i++) 
                        {
                            if (COMPANY_SIZES[0].minValue < userCount);
                            {
                                console.log("Fetch Setting company size to: " + COMPANY_SIZES[0].value);
                                setCompanySize(COMPANY_SIZES[0].value);
                            }
                        }

                        for (let i = 0; i < stripeProductsResponse.data.length; i++) {
                        if (response1 > stripeProductsResponse.data[i].minUserCount) {
                            setProduct(stripeProductsResponse.data[i]);
                            console.log(stripeProductsResponse.data[i]);
                            for (let x = 0; x < stripeProductsResponse.data[i].productPrices.length; x++) {
                                if (stripeProductsResponse.data[i].productPrices[x].billingInterval == billingInterval) {
                                    setPrice(stripeProductsResponse.data[i].productPrices[x]);
                                    setTotalPrice(stripeProductsResponse.data[i].productPrices[x].pricePerUnit / 100);
                                }
                            }
                            break;
                        }
                    }
                }

                }
              }
          }
            
        }
        catch (e) {
            console.log("ERROR: MakePaymentForm.fetchData")
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

  if (loading) {        
    return (<ProgressBar message="Loading ..." loading={loading} />);
  }

  if (serviceIsDownError) {
      return (<><ServiceIsDown></ServiceIsDown></>);
  }

  if (!openPaymentForm)
  {
  return (
    <Grid container spacing={2}>
      <Grid item xs={7} key="grdPriceTotal" sx={{minWidth: '425px'}}>
          <Card sx={{ width: '100%', height: '100%' }} variant="outlined">
              <CardContent>
                <Typography variant="h6" component="div">Total Price</Typography>
                <hr />
                <Typography><b>Subscription Plan:</b></Typography>
                <Typography>{product.name}</Typography>
                <div style={{ fontSize: '0.8rem' }}>{product.description}</div>
                <Typography component="div"><br></br></Typography>
                <div style={{ fontSize: '0.8rem' }}>Total # of Users detected in your Tenant: {tenantUsersCount}.</div>
                
                {promoCode && 
                    (
                        <>
                        <Typography sx={{paddingTop: '5px', paddingBottom:'5px'}}>Discount Applied: <b>{promoCode.couponName}</b></Typography>
                        </>
                    )                                                       
                }
                
                <Typography component="div"><br></br></Typography>
                <Typography component="div" sx={{ paddingBottom: 1 }}>
                    <Typography sx={{ paddingRight: 3 }}>
                        Billing Frequency:
                    </Typography>
                    <Typography>
                        <TextField required
                            select
                            value={billingInterval}
                            onChange={event => handleBillingInterval(event.target.value)}
                            name="billingInterval"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            style={{ width: 125 }}
                            InputLabelProps={{ shrink: true }} >
                            <MenuItem key='year' value='year'>Yearly</MenuItem>
                            <MenuItem key='month' value='month'>Monthly</MenuItem>
                        </TextField>
                    </Typography>
                </Typography>                       
                <Typography variant="h6" component="div"><br></br></Typography>
                <Typography>Total Price: {currencyFormatter(totalPrice)}/{billingInterval} (USD)<br />Paid every {billingInterval}. 
                  {billingInterval && billingInterval == 'month' && ( <> Minimum 1 year committment.</>) }
                </Typography>  
            </CardContent>
            <CardActions>
                <Typography component="span" sx={{ textAlign: "left", width: '30%'}}><Button variant="contained" size="small" onClick={() => setOpenPaymentForm(true)}>Make Payment</Button></Typography>
                <Typography component="span" sx={{ textAlign: "right", width: '70%'}}><a href={LINK_TO_SAAS_CUSTOMER_AGREEMENT} target="_blank" rel="noopener noreferrer">View Customer Agreement</a></Typography>
            </CardActions>
        </Card>
      </Grid>
      <Grid item xs={5} key="grdOrderInfo" sx={{minWidth: '425px'}}>

      <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
        <Typography variant="h6" component="div">Upgrade Your Subscription</Typography>
        <hr />
        {upgradeSubscriptionError && 
            <><Alert severity="error"><AlertTitle>Error:</AlertTitle>You have more users than what this plan allows.</Alert><br></br></>
        }
        <Typography component="div">If you require more licenses than the default plan, please adjust the size of your company.</Typography>
        <Typography component="div"><br></br></Typography>
        <Typography component="div">
                <b>Company Size:</b><br></br>
                    <TextField required
                        select
                        name="companySize"
                        variant="outlined"
                        size="small"
                        style={{ maxWidth: '90%' }}
                        InputLabelProps={{ shrink: true }}
                        value={companySize}
                        helperText="Select the number of Users in your Microsoft 365 Tenant."
                        onChange={event => handleUpgradeSubscription(event.target.value)}
                    >
                        {COMPANY_SIZES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Typography>
        </CardContent>
      </Card>
      <Typography variant="h6" component="div"><br></br></Typography>
      <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
        <Typography variant="h6" component="div">Apply Promotional Discount</Typography>
        <hr />                    
        <div>
            {loadingPromo &&
            (<><ProgressBar loading={loadingPromo}></ProgressBar></>)
            }      
            {promoCodeMsg && 
                (
                    <>
                    <Alert severity="info">{promoCodeMsg}</Alert>
                    <br />
                    </>
                )                                                       
            }
            {!loadingPromo && !promoCode  &&
                (
                    <>
                    <b>Promo Code:</b>
                    <br></br>                   
                    <TextField 
                        name="promoCode" 
                        controlid="discountCode" 
                        variant="outlined" 
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setDiscountCode(event.target.value) }
                        error={promoCodeError}
                        disabled={promoUsed}
                        inputRef={promo}
                        helperText={promoCodeError ? 'Promo code cannot be empty.' : ''} />
                        <Button onClick={handleApplyPromo} sx={{ mr: 1 }}>Apply</Button>
                    </>
                )
            }
            {promoCode &&
                (
                    <>
                    <b>Promo Code:</b>
                    <br></br>
                    <Typography component="span" sx={{paddingRight: '20px'}}>{promoCode.couponName}</Typography>        
                    <Typography component="span"><Button onClick={handleRemovePromo} sx={{ mr: 1 }}>Remove</Button></Typography>
                    </>
                )
            }
        </div>
        <Typography variant="h6" component="div"><br></br></Typography>
        <Typography>Total Price: {currencyFormatter(totalPrice)}/{billingInterval}</Typography>
    </CardContent>
    </Card>
      </Grid>
    </Grid>
  )

}
else
{
return (
  <Grid container spacing={2}>
    <Grid item xs={12} key="grdPriceTotal">
    <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
        <Typography variant="h6" component="div">Enter Payment Information</Typography>
        <hr /> 
        <PaymentForm2 
          user={user} 
          company={company} 
          companySize={companySize}
          product={product} 
          price={price}
          promoCode={promoCode}
          billingInterval={billingInterval}
          totalPrice={totalPrice}
          setOpenPaymentForm={setOpenPaymentForm}>
          </PaymentForm2>
        </CardContent>
        <CardActions>
            
        </CardActions>
      </Card>
    </Grid>
  </Grid>
)
}
}

export default MakePaymentForm;
