// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

// Audit Vault Components
import ServiceIsDown from '../../components/common/ServiceIsDown';
import ProgressBar from '../../components/common/ProgressBar';
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';
import MessageList from "../../components/MessageList";

// Audit Vault Utilities
import catchAxiosErr2 from '../../utilities/common-axios-err-catch2';
import { encryptKey } from '../../utilities/common-encrypt-util';

// A simplified list of Messages and interface for whence the Administrator can purge/delete, edit, and or broadcast new messages.
function MessageCenterAdmin(props) {

    // Component Constants
    const { user } = props;
    const navigate = useNavigate();
    const companyId = -1;
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    // Store the MessageList array for companyID.
    const [myMessages, setMessages] = useState([]);
    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "Message Center - Administration";
    const theSectionDesc = "From the Message Center Admin page an Administrator is able to edit, purge, or broadcast new messages to all Companies in the System.  When deleting a message from here it purges the message from the system.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Message Center', link: '/Admin/MessageCenterAdmin' }
    ];

    // For snackbar notification UI element.  Message deleted notification.
    const [openNotification, setOpenNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    // For child component - lifted state to the parent (here) so that on child component reloads we remember the state of these items.
    // Whenever the child component does a MarkAsRead, MarkAsUnread, or Delete operation the Parent does not need to be refreshed, only the child component needs to be updated.  As such maintaining state in the parent is important for optimized Virtual DOM and DOM updating.
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRow, setExpandedRow] = useState(null);  // For Accordion control.  Will be a base 10 digit or NaN if not specified.
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.


    // Component Functions

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };


    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );



    // PW: We should replace the user and companyID loading with common library call.
    // Code below checks to see if the companyId is specified, if so loads the company.  If companyId is not specified, use current user to load the correct home company.
    // PW: Also looks like the catch portion is throwing an error.  This needs to be handled.

    async function getData() {
        console.log("Getting data start.");
        setLoading(true);  // Progress bar.                               
        try {
            console.log("Load messages for Administrator: " + companyId);

            // Now load the messages.                        
            var rsaKey = await encryptKey(`{companyId: ${companyId}, returnDeleted: true}`);
            if (rsaKey) {
                var response1 = await axios.get(`${WEB_API_URL}MessageCenter/GetAllMessagesForCompany`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });
                setMessages(response1.data);
            }
            // Set status.
            setLoading(false);
        }
        catch (e) {
            // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
            catchAxiosErr2(e, "MessageCenterAdmin.getData", setLoading, setServiceIsDownError);
        }
        console.log("Getting data end.");
    }


    useEffect(() => {
        getData();
    }, []);



    // Component UI

    // If we are still loading data, display that in the UI...
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <div>
                <MessageList myMessages={myMessages} theCompanyId={companyId} theUser={user} updateDataFunc={getData} snackbarNotification={setOpenNotification} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} expandedRow={expandedRow} setExpandedRow={setExpandedRow} sortConfig={sortConfig} setSortConfig={setSortConfig} setLoading={setLoading} setServiceIsDownError={setServiceIsDownError} isAdminPage={isAdminPage}></MessageList>                
            </div>
            <br /><Button variant="contained" type="button" onClick={() => navigate("/admin/NewMessage")}>New Message</Button>
            <div>
                <Snackbar
                    open={openNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message="Message Deleted"
                    action={openNotificationAction}
                />
            </div>
        </>
    );
}
export default MessageCenterAdmin;