// React Components
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';

// Audit Vault Utilities
import { deleteTenantById } from "../../utilities/common-tenant";
import {getCompanyByCompanyId} from "../../utilities/common-company";

// Confirm deletion before calling deletion.

export default function  TenantDeleteConfirmationDialog(props) {

  //const { isOpen, onClose, onDelete } = props;

  const { onClose, isOpen, companyId, tenantId, snackbarNotification, setCompany, user } = props;
  const [loading, setLoading] = useState(false);
  const [serviceIsDownError, setServiceIsDownError] =  useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  async function deleteHandler() {
    
    if (tenantId > 0) 
    {
      try
      {
        setLoading(true);
        
        const result = await deleteTenantById(companyId, tenantId, user);
        if (result)
        {        
          var response = await getCompanyByCompanyId(companyId, setServiceIsDownError);
          if (response != null) {
              setCompany(response);
              handleClose();
              snackbarNotification(true);
          }
          
        }
      }
      catch (e)
      {
        console.log("ERROR: DeleteCompanyConformation.deleteHandler");
        console.log(e);
      }
      finally{
        setLoading(false);
      }

    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
      <ProgressBar message="Deleting ..." loading={loading} />
        <DialogContentText>
        Deleting a Tenant entry cannot be undone. Are you sure you want to delete this Tenant?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={deleteHandler} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}