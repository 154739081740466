// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageSharePoint from '../imgs/iconSharePoint.svg';

// Audit Vault Utilities
import ReportTemplateActivityByUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'SharePoint Online Reports', link: '/Reports/SharePoint' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 20, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 90, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'objectId', label: 'Item', minWidth: 50, maxWidth: 140, align: 'left' },
    { id: 'itemType', label: 'Item Type', minWidth: 50, maxWidth: 40, align: 'left' },
    { id: 'listItemUniqueId', label: 'List Item Unique Id', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'sourceFileName', label: 'Item Name', minWidth: 50, maxWidth: 90, align: 'left' }
];

/*
ReportsSharePointAllActivityByUser report - calls the reusable template ReportTemplateActivityByUserAndDateRange.
*/
function ReportsSharePointAllActivityByUser(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: SharePoint - All Activity by User";
    const theSectionDesc = "This report can be used in an audit investigation to return all SharePoint activity by a User over a date range.  To run the report you must provide the User keyword and a valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "SharePointAllActivityByUser";


    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByUserAndDateRangeWithBarChart
            user={user}
            reportType={"SharePointAllActivityByUser"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageSharePoint} />

    );
}
export default ReportsSharePointAllActivityByUser;