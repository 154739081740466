// React Components
import * as React from 'react';
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

// Audit Vault Components
import AccessDenied from '../components/common/AccessDenied';
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from '../components/common/TrialPeriodNag';
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Common
import { getCompanyByCompanyId } from "../utilities/common-company";
import { verifyUserCanAccessCompanyData, searchAuditVaultUsers, removeAuditVaultUserPermission, removeCompanyAdminAccess } from '../utilities/common-user-utils';
import getRole from '../utilities/common-role-utils';
import { CardActions } from '@mui/material';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
import { ROLES } from "../constants/constants-roles";

const columns = [
    { id: 'displayName', label: 'Display Name', minWidth: 200, align: 'left' },
    { id: 'permissions', label: 'Permissions', minWidth: 200 },
    //{ id: 'accessToExchange', label: 'Exchange Access', minWidth: 50 },
    //{ id: 'accessToSp', label: 'SharePoint Access', minWidth: 50 },
    //{ id: 'accessToAzureAd', label: 'Azure AD Access', minWidth: 50 },
];

const rows = [];

function MyCompanyUsers(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;
    const queryParams = new URLSearchParams(window.location.search);

    // Component Constants
    const navigate = useNavigate();

    const [company, setCompany] = useState("");
    const [companyUsers, setCompanyUsers] = useState([]);

    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTenant, setSelectedTenant] = useState(0);
    const [auditVaultRole, setAuditVaultRole] = useState(6);
    var auditVaultRoleRef = useRef(1);

    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState('User Removed from Role');
    const [selectedTenantOption, setSelectedTenantOption] = useState(0);

    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Company', link: '/mycompany' },
        { name: 'Company Users', link: '/mycompanyusers' },
    ];
    const theSectionTitle = "Company Users";
    const theSectionDesc = "Review a listing of users that have access to Audit Vault.  Search for a specific user to see their level of permissions.";
    const isAdminPage = false;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    async function fetchData() {

        if (loading && user) {
            if (verifyUserCanAccessCompanyData(user)) {
                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);
                    await getUsers("0");

                    if (queryParams.get("successfulAdd") && queryParams.get("successfulAdd").length > 0) {
                        setOpenNotificationMsg("User Added to Role");
                        setOpenNotification(true);
                    }
                }
            }
            else {
                setLoading(false);
                setAccessDenied(true);
            }
        }
        else {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = async () => {
        setLoading(true);

        if (searchQuery.trim().length == 0) {

            await getUsers("0");
        }
        else
            await getUsers(searchQuery);
    }

    const getUsers = async (emailQuery) => {

        try {
            setLoading(true);
            const userResponse = await searchAuditVaultUsers(user.companyId, selectedTenant, emailQuery, auditVaultRole);
            if (userResponse) {
                setCompanyUsers(userResponse);
                setLoading(false);
            }

        }
        catch (e) {
            console.log("ERROR: MyCompanyUsers.getUsers:");
            console.log(e)

        }
        finally {
            setLoading(false);
        }
    }

    const handleRemoveAccess = async (permId, roleId, userRemovedFromRole) => {
        // Not need to keep roleId here since function is passed to component and other components use that value.
        if (permId > 0) {
            deletePerm(permId, roleId, userRemovedFromRole);
        }
    };


    const deletePerm = async (id, roleId, userRemovedFromRole) => {
        try {
            const response = await removeAuditVaultUserPermission(user, id, roleId, userRemovedFromRole);
            if (response) {
                setOpenNotificationMsg("User Removed from Role");
                handleSearch();
                setOpenNotification(true);
            }
        } catch (e) {
            console.log("ERROR: MyCompanyUsers.deletePerm");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    };

    const deleteCompanyAdmin = async (id) => {
        try {
            const response = await await removeCompanyAdminAccess(user, id);
            if (response) {
                setOpenNotificationMsg("User Removed from Role");
                handleSearch();
                setOpenNotification(true);
            }
        } catch (e) {
            console.log("ERROR: MyCompanyUsers.deleteCompanyAdmin");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    };

    const handleAddMembers = () => {

        navigate(`/mycompany-assign-role/${selectedTenant}/${auditVaultRole}`)

    };


    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    const handleTenantOptionChange = (event) => {
        console.log(`event.target.value: ${event.target.value}`)

        setSelectedTenantOption(event.target.value);
        if (!event.target.value.id) {
            setSelectedTenant(0);
        }
        else {
            setSelectedTenant(event.target.value.id);
        }

    };

    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Function for Sorting column headers.  
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // Function for Sorting column headers.
    function getComparator(sortConfig) {
        return (a, b) => {
            if (!sortConfig) {
                return 0;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // Component UI
    if (serviceIsDownError)
        return (<ServiceIsDown></ServiceIsDown>);

    if (accessDenied)
        return (<AccessDenied></AccessDenied>);

    if (!loading && company && companyUsers) {
        return (
            <>
                <TrialPeriodNag company={company}></TrialPeriodNag>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
                {loading &&
                    <div><ProgressBar loading={loading} message="Loading ..." /></div>
                }

                {!loading && companyUsers && company.setupStageComplete >= 5 &&

                    <>
                        <Card sx={{ maxWidth: 500 }} variant="outlined" key="card1">
                            <CardContent>
                                <Typography component="div"><b>Search for Company Users:</b></Typography>
                                <Typography component="div"><br></br></Typography>
                                <Table sx={{
                                    minWidth: 420, maxWidth: '100%', [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    }
                                }} size="small">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell key="search-desc"><b>User:</b></TableCell>
                                            <TableCell key="search-barcell">
                                                <TextField
                                                    id="search-bar"
                                                    style={{ width: 350 }}
                                                    onInput={(e) => {
                                                        setSearchQuery(e.target.value);
                                                    }}
                                                    value={searchQuery}
                                                    label="Name or email contains"
                                                    variant="outlined"
                                                    placeholder="Search..."
                                                    size="small" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell key="tenantSerch"><b>Tenant:</b></TableCell>
                                            <TableCell key="tenantSearchTxtbox">
                                                <TextField
                                                    required
                                                    select
                                                    id="tenant-select"
                                                    value={selectedTenantOption}
                                                    onChange={handleTenantOptionChange}
                                                    helperText=""
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ width: 350 }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                    <MenuItem key="tenantAll" value={0}>All</MenuItem>
                                                    {company.tenantList && company.tenantList.map((tenant) => (
                                                        <MenuItem key={tenant.id} value={tenant}>
                                                            {tenant.tenantUrl}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell key="flterRole"><b>Role:</b></TableCell>
                                            <TableCell key="filterRole-txt">
                                                <TextField required
                                                    select
                                                    name="auditVaultRoleRef"
                                                    inputRef={auditVaultRoleRef}
                                                    value={auditVaultRole}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ width: 250 }}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={event => setAuditVaultRole(event.target.value)}
                                                >
                                                    <MenuItem key="roleAll" value={6}>All</MenuItem>
                                                    {ROLES.map((option) => (
                                                        <MenuItem key={option.code} value={option.code}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                            <CardActions>
                                <Typography component="span" sx={{ width: '100%' }}>
                                    <Button type="submit" aria-label="search" onClick={() => handleSearch()}>Search <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
                                </Typography>
                                <IconButton sx={{ textAlign: "right" }} aria-label="addUser" onClick={(event) => handleAddMembers()}>
                                    <PersonAddAlt1Icon sx={{ color: "green" }} />
                                </IconButton>
                            </CardActions>
                        </Card>
                        <Typography component="div"><br></br></Typography>
                        <Table stickyHeader aria-label="sticky table" dense={true}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            onClick={() => requestSort(column.id)}
                                        >
                                            {column.label}
                                            {sortConfig && sortConfig.key === column.id && (
                                                <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    stableSort(companyUsers, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {

                                            return (

                                                <TableRow hover tabIndex={-1} key={`RowID-${row.id}-${row.microsoftGraphId}`}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        switch (column.id) {
                                                            case 'permissions':
                                                                return (
                                                                    <TableCell key={'TBL-' + row.id + column.id} align={column.align}>
                                                                        <List>

                                                                            {value.map((perm) => {
                                                                                return (
                                                                                    <ListItem dense >
                                                                                        <ListItemText sx={{ width: 150 }}>
                                                                                            {perm.tenantUrl}
                                                                                        </ListItemText>
                                                                                        <ListItemText sx={{ width: 150 }}>
                                                                                            {getRole(perm.roleId)}
                                                                                        </ListItemText>
                                                                                        <ListItemText sx={{ textAlign: 'right' }}>
                                                                                            <IconButton aria-label="delete" onClick={(event) => handleRemoveAccess(perm.id, perm.roleId, row.userEmail)}>
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </ListItemText>
                                                                                    </ListItem>);
                                                                            }
                                                                            )}
                                                                            {row.isCompanyAdmin &&

                                                                                <ListItem dense >
                                                                                    <ListItemText sx={{ width: 150 }}>
                                                                                    </ListItemText>
                                                                                    <ListItemText sx={{ width: 150 }}>
                                                                                        Company Administrator
                                                                                    </ListItemText>
                                                                                    <ListItemText sx={{ textAlign: 'right' }}>
                                                                                        <IconButton aria-label="delete" onClick={(event) => deleteCompanyAdmin(row.microsoftGraphId)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            }
                                                                        </List>
                                                                    </TableCell>
                                                                );

                                                            default:
                                                                return (

                                                                    <TableCell key={'Col-' + row.id} align={column.align}>
                                                                        {value.toString()}
                                                                    </TableCell>
                                                                );
                                                        }
                                                    })}

                                                </TableRow >
                                            )
                                        }
                                        )}

                            </TableBody>
                        </Table><TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="span"
                            count={companyUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} /></>
                }
                <div>
                    <Snackbar
                        open={openNotification}
                        autoHideDuration={6000}
                        onClose={handleNotificationClose}
                        message={openNotificationMsg}
                        action={openNotificationAction} />
                </div>
            </>
        );
    }
}
export default MyCompanyUsers;