import { ROLE_CODE_COMPANYADMINISTRATOR, ROLE_CODE_TENANTADMINISTRATOR, ROLE_CODE_TENANTREPORTREADER, ROLE_CODE_TENANTVIEWER, ROLE_CODE_NOACCESS, ROLE_CODE_SYSTEMADMINISTRATOR } from "../constants/constants-roles";



const getRole = (roleId) => {
    switch (roleId) {

        case ROLE_CODE_SYSTEMADMINISTRATOR:
            return "System Administrator";

        case ROLE_CODE_COMPANYADMINISTRATOR:
            return "Company Administrator";

        case ROLE_CODE_TENANTADMINISTRATOR:
            return "Tenant Administrator";

        case ROLE_CODE_TENANTREPORTREADER:
            return "Tenant Report Reader";

        case ROLE_CODE_TENANTVIEWER:
            return "Tenant Viewer";

        default:
            return "No Access";
    }

};

export default getRole;