
/*
CompanyCreated = 0,                                 // first created by user 
CompanySetupComplete = 1,                           // All company info is successfully created
TenantCreated = 2,                                  // Tenant Created
TenantAdminConsentGranted = 3,                      // Global Admin granted access to Audit Vault to Tenant
TenantPaymentCompleteButSetupCompleteRequired = 4,  // Global Admin granted access to Audit Vault to Tenant
TenantSetupComplete = 5,                            // Set up complete and first audit ran successfully
PaymentComplete = 6,   		                        // Payment completed
PaymentCompleteManually = 7                         // Payment Completed but done manually or delayed from Stripe (Bank Deposit)
*/

const COMPANY_SETUP_STAGES = [
    { value: 0, label: '0 of 5: Company created.' },
    { value: 1, label: '1 of 5: Company setup complete.' },
    { value: 2, label: '2 of 5: Tenant created.' },
    { value: 3, label: '3 of 5: Global Admin Consent access granted to tenant.' },
    { value: 4, label: '3 of 5: Global Admin Consent access granted to tenant (Payment Received).' },
    { value: 5, label: '4 of 5: Tenant setup complete.' },
    { value: 6, label: '5 of 5: Payment Complete.' },
    { value: 7, label: '5 of 5: Payment Complete (Manually).' },
];

export { COMPANY_SETUP_STAGES };