// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";

// Material UI Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// Audit Vault Utilities
import {formatInvoiceDate} from '../../utilities/common-date-utils';
import currencyFormatter from '../../utilities/common-currency-utils';

// Audit Vault Components
import DownloadInvoiceButton from "./DownloadInvoiceButton";

const rows = [];

export default function InvoiceList(props) {

  const { invoices } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let columns = [
    { id: 'dateCreated', label: 'Date', minWidth: 10, align: 'left' },
    { id: 'number', label: 'Invoice Number', minWidth: 30, align: 'left' },
    { id: 'amountDue', label: 'Amount Due', minWidth: 20,  align: 'right' },
    { id: 'amountPaid', label: 'Amount Paid', minWidth: 20, align: 'right' },
    { id: 'amountRemaining', label: 'Amount Remaining', minWidth: 20,  align: 'right' },
    { id: 'download', label: 'Download', minWidth: 10,  align: 'right' },
  ];

  return (
  
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }
              }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    switch (column.id)
                    {
                      case "dateCreated":
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {formatInvoiceDate(value)}
                          </TableCell>
                          )

                      case "amountPaid":
                      case "amountDue":
                      case "amountRemaining":
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {currencyFormatter(value/100)}
                          </TableCell>
                          )
                      
                      case "download":
                        return(<TableCell key={column.id} align={column.align}><DownloadInvoiceButton invoice={row} /></TableCell>);
                      
                      default:
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value 
                              ? value.toString()
                              : value
                              }
                          </TableCell>
                          )
                    }
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="span"
        count={invoices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
