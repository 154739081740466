// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Audit Vault Components
import ProgressBar from "./common/ProgressBar";
import AccessDenied from "./common/AccessDenied";
import ServiceIsDown from "./common/ServiceIsDown";

// Audit Vault Utilities
import { formatDate } from "../utilities/common-date-utils";
import { getFirstAuditImportStats } from "../utilities/common-tenant";

export default function FirstAuditImportStats(props) 
{
    const navigate = useNavigate();
    const { user, tenantId } = props;

    const [stats, setStats] = useState("");
    const [loading, setLoading] = useState(true);
    const [inProgressLoading, setInProgressLoading] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);
    const [myInterval, setMyInterval] = useState('');


    const getStatusColour = (status) => {
        if (status == 1)
            return "blue";
        if (status == 2)
            return "green";
        
    };

    const getStatusText = (status) => {
        if (status == 1)
            return "In Progress";
        if (status == 2)
            return "Complete";
        
    };

    async function fetchData() {
        var id = 0;
        try{
            
            if (user)
            {
                var result = await getFirstAuditImportStats(tenantId);
                if (result)
                {
                    setStats(result);
                    if (result.stage < 2)
                    {
                        setInProgressLoading(true);
                        id = setInterval(getImportStats, 5000);
                        setMyInterval(id);
                    }
                    else
                    {
                        setInProgressLoading(false);
                    }
                }
            }
            else
            {
                setAccessDenied(true);
            }
        }
        catch (e)
        {
            console.log("Error: FirstAuditImportStats");
            console.log(e);
            setServiceIsDown(true);
        }
        finally{
            
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        
    }, []);

    
    async function getImportStats() {
        console.log("Getting Import Stats ...");
        try {
            
            var result = await getFirstAuditImportStats(tenantId);
            if (result)
            {
                console.log(result);
                setStats(result);
                if (result.stage == 2)
                {
                    setInProgressLoading(false);
                    navigate(0);
                }
            }
        }
        catch (e) {
            console.log("ERROR: MyTenantValidate.getImportStats: ");
            console.log(e);
        }
        finally {
            
        }
        
    }
    

    if (accessDenied)
    {
        return(<AccessDenied></AccessDenied>)
    }

    if (serviceIsDown)
    {
        return(<ServiceIsDown />)
    }

    return (
        <Card sx={{ maxWidth: '80%' }} variant="outlined">
        <CardContent>
            {stats && stats.stage == 1 &&
                <>
                <Typography component="div" sx={{padding: 1, paddingBottom: 2}}>
                <Alert severity="info">
                    <AlertTitle>Import in Progress</AlertTitle>          
                    Audit Vault is importing the last 7 days worth of your Microsoft 365 audit log records into the system.  Please be patient this may take a while.
                    <br />After your initial import has been completed - options will be available to preserve additional audit log history if required (ex. 1 year back).
                </Alert>
                </Typography>
            </>
            }

            {stats && stats.stage == 2 &&
                <>
                <Typography component="div" sx={{padding: 1, paddingBottom: 2}}>
                <Alert severity="success">
                    <AlertTitle>Import Complete</AlertTitle>          
                    Audit Vault has completed the import of your audit logs for the last 7 days from Microsoft 365.
                    <br />
                    <br />Congratulations!  Audit Vault for M365 protection is active: Your important Microsoft 365 audit logs are now being automatically preserved, shielded and surfaced!  
                    <br />Please proceed to the <a href="/" >Home Page</a> and begin exploring the insights available at your fingertips.
                </Alert>
                </Typography>
            </>
            }
            <Typography component="div">
                <ProgressBar message="" loading={inProgressLoading} />
            </Typography>
            <Typography component="div">
            <b>Import Status:</b>
            </Typography>
            <ProgressBar message="Loading ..." loading={loading} />
            {!loading &&
            <>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ width: '40%' }}>Status:</TableCell>
                            <TableCell>
                                <Typography color={getStatusColour(stats.stage)}>
                                    {getStatusText(stats.stage)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>Start Date:</TableCell>
                            <TableCell>{formatDate(stats.startDate)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>End Date:</TableCell>
                            {stats && stats.stage < 2 &&
                                <TableCell>{formatDate(stats.endDate)}</TableCell>
                            }
                            {stats && stats.stage >= 2 &&
                                <TableCell>{formatDate(stats.endDate)}</TableCell>
                            }
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>Total SharePoint Records:</TableCell>
                            <TableCell>{stats.totalSharePointRecords}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>Total Exchange Records:</TableCell>
                            <TableCell>{stats.totalExchangeRecords}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>Total Entra ID Records:</TableCell>
                            <TableCell>{stats.totalEntraIDRecords}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>Total Teams Records:</TableCell>
                            <TableCell>{stats.totalTeamsRecords}</TableCell>
                        </TableRow>
                        {stats.errorOccured &&
                            <TableRow>
                                <TableCell style={{ width: '50%' }}>Errors:</TableCell>
                                <TableCell>{stats.errorOccured}</TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
                </>
            }
        </CardContent>
        <CardActions >
            <Typography component="span" sx={{ textAlign: "left", width: '50%'}}>
                <Button size="small" onClick={(event) => navigate(`/Tenant/MyTenant?tab=0`)}>Back</Button>
            </Typography>
            <Typography component="span" sx={{ textAlign: "right", width: '50%'}}>
                <Button size="small" variant="contained" onClick={(event) => navigate(`/reports/first-audit-import/${tenantId}`)}>View Details</Button>
            </Typography>
        </CardActions>
        </Card>
    );
}