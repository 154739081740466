// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from 'dayjs';

// Material UI Components
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Paper from '@mui/material/Paper';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Utilities
import { formatDate } from '../../utilities/common-date-utils';
import { getTenantStatusColour } from "../../utilities/common-tenant-utils";
import { encryptKey } from '../../utilities/common-encrypt-util';

// constants
const theSectionTitle = "Audit Vault System Job Orchestrators List";
const theSectionDesc = "";
const isAdminPage = true;

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'Job Orchestrators List', link: '/Admin/JobOrchestrators' }
];

function SystemOrchestratorsList(props) {

    const { user } = props;
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);

    const [orchestrators, setOrchestrators] = useState([]);

    // Need page variables for each audit type
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function getJobOrcType(type) {
        switch (type) {
            case 0:
                return 'Audit Import';

            case 1:
                return 'First Audit Import';

            case 2:
                return 'Historical Import';

            case 3:
                return 'Search';
        }
    };

    // Component Functions
    async function fetchData() {
        if (loading && user) {
            try {
                if (user.isSystemAdmin) {
                    var data = {
                        sysAdminId: user.microsoftGraphId,
                    };
                    var keyString = await encryptKey(JSON.stringify(data));
                    if (keyString) {
                        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetAllOrchestrators`, {
                            params: {
                                key: keyString,
                            }
                        });
                        if (result) {
                            console.log(result.data);
                            setOrchestrators(result.data);
                        }
                    }
                    else {
                        setAccessDenied(true);
                    }
                }
                else {
                    setAccessDenied(true);
                }
            }
            catch (e) {
                setServiceIsDown(true);
                console.log("ERROR: SystemOrchestratorsList:fetchData");
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const getOrchestratorHeath = (orchestrator) => {
        var status = 1;
        console.log(orchestrator)
        const oldDate = '01/01/1901 12:00:00 PM';
        if (Date.parse(orchestrator.lastRunDate) > Date.parse(oldDate)) {
            // Orchestrator may have not run yet

            // If the Orchestrator hasn't been success for more than 3 hours, mark as warning
            const warningDate = new Date(new Date().getTime() - (3 * 60 * 60 * 1000));
            if (Date.parse(orchestrator.lastRunDate) < Date.parse(warningDate)) {
                status = 2;
                console.log(status)
                console.log(warningDate)
            }

            // if the Orchestrator hasn't been successful for more than 6 days mark as Critical
            const criticalDate = new Date(new Date().getTime() - (6 * 60 * 60 * 1000));
            if (Date.parse(orchestrator.lastRunDate) < Date.parse(criticalDate)) {
                status = 3;
                console.log(status)
                console.log(warningDate)
            }
        }
        else {
            status = 0
        }

        return (
            <Typography color={getTenantStatusColour(status)}>
                <CircleRoundedIcon color={getTenantStatusColour(status)} sx={{ paddingRight: 1, alignItems: "center" }} />
            </Typography>
        )
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>)
    }

    if (serviceIsDown) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (loading) {
        return (<ProgressBar loading={loading} />);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
                <Table aria-label="HistoricalAuditTable">
                    <TableHead>
                        <TableRow>
                            <TableCell key="SPHeaderSucess">Health</TableCell>
                            <TableCell key="SPHeaderId">Id</TableCell>
                            <TableCell key="SPHeaderItems">Name</TableCell>
                            <TableCell key="SPHeaderTyep">Type</TableCell>
                            <TableCell key="SPHeaderServerNm">Server Name</TableCell>
                            <TableCell key="SPHeaderLastTime">Last Run Time</TableCell>
                            <TableCell key="SPHeaderNew Jobs">Allow New Jobs</TableCell>
                            <TableCell key="SPHeaderIsActive">Active</TableCell>
                            <TableCell key="SPHeaderEdit">Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orchestrators && orchestrators.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orchestrator) => (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {getOrchestratorHeath(orchestrator)}
                                </TableCell>
                                <TableCell align="left">{orchestrator.id}</TableCell>
                                <TableCell align="left">{orchestrator.orchestratorName}</TableCell>
                                <TableCell align="left">{getJobOrcType(orchestrator.orchestratorType)}</TableCell>
                                <TableCell align="left">{orchestrator.serverName}</TableCell>
                                <TableCell align="left">{formatDate(dayjs(orchestrator.lastRunDate))}</TableCell>
                                <TableCell component="th" align='center' scope="row">
                                    {orchestrator.availableForMoreJobQueues &&
                                        <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                                    }
                                    {!orchestrator.availableForMoreJobQueues &&
                                        <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {orchestrator.isActive &&
                                        <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                                    }
                                    {!orchestrator.isActive &&
                                        <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                                    }
                                </TableCell>
                                <TableCell align="left"><Link onClick={() => navigate(`/Admin/UpdateJobOrchestrator/${orchestrator.id}`)} component="button">Edit</Link></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="span"
                    count={orchestrators.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
            <Typography component="div"><br></br></Typography>
            <Typography component="div"><Button variant="contained" className="ml-auto" onClick={() => navigate("/Admin/UpdateJobOrchestrator")}>Add New</Button></Typography>
        </>
    );

}
export default SystemOrchestratorsList;