// React Components
import * as React from 'react';

// Material UI Components

// Audit Vault Components
import ReportTemplateActivityByTenantAndPeriodWithLineChart from './ReportTemplateActivityByTenantAndPeriodWithLineChart';

// Audit Vault Utilities


/*
Report returns the unique counts for SharePoint Page Views over a set period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- reportOnDate: The date from which the report is generated on (ex. today or yesterday, or 1 year ago). 
- displayDataSource: 0 or 1.  (if true displays table of sortable source data, if false includes link to report to view full details).
*/
export default function SharePointPageViews(props) {

    // Component Constants
    const { user, companyId, tenantId, periodToUse, loadData, filterByColumn } = props;        
    var theGraphTitle = "SharePoint Page Views";        

    // For the optional table to display data source.  815 px to work with.
    const columns = [
        { id: 'id', label: 'Id', minWidth: 20, maxWidth:40, align: 'left' },
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 70, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 145, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 200, align: 'left' },
        { id: 'objectId', label: 'Item', minWidth: 50, maxWidth: 200, align: 'left' },        
        { id: 'listItemUniqueId', label: 'List Item Unique Id', minWidth: 50, maxWidth: 160, align: 'left' },        
    ];    
    
    // Component Functions

    // Component UI.
    // Aug 14, 2024: ReportTemplateActivityByTenantAndPeriodWithLineChart no longer takes in the axiosReportUri or expandMoreNavigateTo links.
    return (
        <>            
            <ReportTemplateActivityByTenantAndPeriodWithLineChart 
                user={user}
                companyId={companyId}
                tenantId={tenantId} 
                reportType='SharePointPageViews'
                loadData={loadData}
                periodToUse={periodToUse} 
                theGraphTitle={theGraphTitle} 
                columns={columns} 
                filterByColumn={filterByColumn} />
        </>
    );    


}
