// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageExchange from '../imgs/iconExchange.svg';

// Audit Vault Utilities
import ReportTemplateActivityByIPAddressAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByIPAddressAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Exchange Reports', link: '/Reports/Exchange' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'clientIP', label: 'IP Address', minWidth: 90, maxWidth: 115, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'recordType', label: 'Record Type', minWidth: 40, maxWidth: 40, align: 'left' },
    { id: 'affectedItems', label: 'Affected Items', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'originatingServer', label: 'Originating Server', minWidth: 50, maxWidth: 100, align: 'left' }
];

/*
ReportsExchangeAllActivityByIPAddress report - calls the reusable template ReportTemplateActivityByIPAddressAndDateRange.
*/
function ReportsExchangeAllActivityByIPAddress(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: Exchange - All Activity by IP Address";
    const theSectionDesc = "This report can be used in an audit investigation to return all Exchange activity by an IP Address over a date range.  To run the report you must provide the IP Address keyword and a valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "ExchangeAllActivityByIPAddress";

    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByIPAddressAndDateRangeWithBarChart
            user={user}
            reportType={"ExchangeAllActivityByIP"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageExchange} />
    );
}
export default ReportsExchangeAllActivityByIPAddress;