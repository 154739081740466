// Material UI Components
import Button from '@mui/material/Button';

const API_ID = process.env.REACT_APP_LEGACY_IMPORTER_APP_ID;
const APP_URL = process.env.REACT_APP_WEB_URL;

export const GrantPowerShellConsentButton = (props) => {
  const { azureTenantId } = props
    
  const handleLogin = async () => {

      let grantAccessUrl = `https://login.microsoftonline.com/${azureTenantId}/adminconsent?client_id=${API_ID}&prompt=admin_consent&redirect_uri=${APP_URL}grantHistoricalImportConsent`;
      window.location.href=grantAccessUrl;
  };

  return (
    <div>
        <Button variant="contained" size="small" onClick={handleLogin}>Grant Consent</Button> 
    </div>
  );
}

export default GrantPowerShellConsentButton;
