// React Components
import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { CardActions, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Shop2Icon from '@mui/icons-material/Shop2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


// Audit Vault Components
import { COMPANY_SIZES } from '../../constants/constants-companysizes';
import { COUNTRIES } from '../../constants/constants-countries';
import ServiceIsDown from "../../components/common/ServiceIsDown";
import { LINK_TO_TERMS_OF_SERVICE, LINK_TO_PRIVACY_POLICY } from '../../constants/constants-links';
import { SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS } from '../../constants/constants-email';
import signInWithMsLogo from '../../imgs/buttonSignInWithMicrosoft.svg'

// Audit Vault Common
import { validateEmail } from '../../utilities/common-user-utils';
import { loginToMicrosoft } from '../../utilities/common-user-utils';
import { updateCompany, insertCompany, getCompanyByAzureTenantId } from '../../utilities/common-company';

// Audit Vault Constants
import ProgressBar from '../../components/common/ProgressBar';

const steps = ['1: Your Company Details', '2: Update Billing Info', '3: Start Using Audit Vault for M365']


function MyCompanySetupCreate(props) {

    const { user } = props;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const navigate = useNavigate();

    const activeStep = 0; // This page just works with inital setup

    // company info fields
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [companySizeValue, setCompanySizeValue] = useState(COMPANY_SIZES[0].value);
    const [countryValue, setCountryValue] = useState('US');
    const companySize = useRef("");
    const country = useRef("");
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [agreeToTermsValue, setAgreeToTermsValue] = useState(false);


    const [errorCompanyName, setCompanyNameError] = useState(false);
    const [errorFirstName, setFirstNameError] = useState(false);
    const [errorLastName, setLastNameError] = useState(false);
    const [errorEmail, setEmailError] = useState(false);
    const [errorPhone, setPhoneError] = useState(false);
    const [errorAgreeToTerms, setAgreeToTermsError] = useState(false);

    const companyDetailsTextFields = [
        {
            key: 'txtCompanyName',
            name: 'companyName',
            label: 'Company Name:',
            value: companyNameValue,
            onchange: setCompanyNameValue,
            error: errorCompanyName,
            errText: 'Company Name cannot be empty.',
            descText: '',
            placeholderText: 'Enter your company name here.',
            maxLength: { maxLength: 200 }
        },
    ];

    const contactTextFields = [
        {
            key: 'txtFirstName',
            name: 'firstName',
            label: 'First Name:',
            value: firstNameValue,
            onchange: setFirstNameValue,
            error: errorFirstName,
            errText: 'First Name cannot be empty.',
            descText: '',
            placeholderText: 'Enter your first name here.',
            maxLength: { maxLength: 100 }
        },

        {
            key: 'txtLastName',
            name: 'lastName',
            label: 'Last Name:',
            value: lastNameValue,
            onchange: setLastNameValue,
            error: errorLastName,
            errText: 'Last Name cannot be empty.',
            descText: '',
            placeholderText: 'Enter your last name here.',
            maxLength: { maxLength: 100 }
        },

        {
            key: 'txtEmailName',
            name: 'email',
            label: 'Email:',
            value: emailValue,
            onchange: setEmailValue,
            error: errorEmail,
            errText: 'Email must be valid and cannot be empty.',
            descText: '',
            placeholderText: 'Enter your contact email address here.',
            maxLength: { maxLength: 200 }
        },

        {
            key: 'txtPhoneName',
            name: 'phone',
            label: 'Business Phone:',
            value: phoneValue,
            onchange: setPhoneValue,
            error: errorPhone,
            errText: 'Business Phone number cannot be empty or longer than 15 characters.',
            descText: '',
            placeholderText: 'Enter your business phone number here.',
            maxLength: { maxLength: 15 }
        },
    ];


    const validateCompanyInfoHandler = () => {
        var isError = false;

        if (companyNameValue.trim() === '' ? setCompanyNameError(true) : setCompanyNameError(false));
        if (firstNameValue.trim() === '' ? setFirstNameError(true) : setFirstNameError(false));
        if (lastNameValue.trim() === '' ? setLastNameError(true) : setLastNameError(false));
        if (emailValue.trim() === '' ? setEmailError(true) : setEmailError(false));
        if (validateEmail(emailValue.trim()) == false ? setEmailError(true) : setEmailError(false));
        if (!phoneValue || phoneValue.trim() === '' || phoneValue.length > 15 ? setPhoneError(true) : setPhoneError(false));
        if (agreeToTermsValue == false ? setAgreeToTermsError(true) : setAgreeToTermsError(false))
            if (companyNameValue.trim() === '') { isError = true; }
        if (firstNameValue.trim() === '') { isError = true; }
        if (lastNameValue.trim() === '') { isError = true; }
        if (emailValue.trim() === '') { isError = true; }
        if (validateEmail(emailValue.trim()) == false) { isError = true; }
        if (!phoneValue || phoneValue.trim() === '' || phoneValue.length > 15) { isError = true; }
        if (countryValue.trim() === '') { isError = true; }

        if (agreeToTermsValue == false) { isError = true }

        return isError;
    }


    const handleInsert = async () => {

        let isError = validateCompanyInfoHandler();
        // check if a company already exists for this user
        console.log(accounts[0]);

        if (!isError) {

            var authByEmail = "";
            var authByGraphId = "";
            var authByTenantId = "";
            var authByDisplayName = "";
            if (isAuthenticated) {
                authByDisplayName = accounts[0].name;
                authByEmail = accounts[0].username;
                authByGraphId = accounts[0].localAccountId;
                authByTenantId = accounts[0].tenantId;
            }

            try {


                if (user.companyId == 0) {
                    var companyResponse = await insertCompany(
                        companyNameValue,
                        countryValue,
                        "",
                        firstNameValue,
                        lastNameValue,
                        emailValue,
                        phoneValue,
                        companySizeValue,
                        authByDisplayName,
                        authByEmail,
                        authByGraphId,
                        authByTenantId
                    );

                    if (companyResponse) {
                        console.log(companyResponse);
                        navigate('/mycompanysetupupdate')
                    }
                }
                else {


                    var updateResponse = await updateCompany(
                        company.id,
                        companyNameValue,
                        company.streetAddress,
                        company.streetAddressLine2,
                        company.city,
                        company.provinceState,
                        countryValue,
                        company.phone,
                        company.postalZip,
                        firstNameValue,
                        lastNameValue,
                        emailValue,
                        phoneValue,
                        companySizeValue,
                        company.billingEmail,
                        1,
                        authByDisplayName,
                        authByEmail,
                        authByGraphId,
                        authByTenantId,
                        null,
                        false
                    );

                    if (updateResponse) {
                        console.log("Update Company Response:");
                        console.log(updateResponse);
                        navigate('/mycompanysetupupdate')
                    }

                }
            }
            catch (e) {
                console.log("ERROR: CompanySetUpCreate.handleInsert");
                console.log(e);
                setServiceIsDownError(true);
            };
        }
    }

    const handleSignIn = async () => {
        if (!isAuthenticated) {
            // Login to Microsoft to verify the user
            loginToMicrosoft(instance);
        }
    }

    async function fetchData() {
        try {
            if (loading) {
                switch (isAuthenticated) {
                    case false:
                    // set the default values
                    //setCompanySizeValue(3000);

                    case true:
                        if (user) {
                            var response = await getCompanyByAzureTenantId(accounts[0].tenantId);
                            if (response) {
                                setCompany(response);
                                setCompanyNameValue(response.companyName);
                                setCompanySizeValue(response.companySize);
                                setCountryValue(response.country);
                                setFirstNameValue(response.primaryContactFirstName);
                                setLastNameValue(response.primaryContactLastName)
                                setEmailValue(response.primaryContactEmail);
                                setPhoneValue(response.primaryContactPhone);
                                setAgreeToTermsValue(true);
                            }
                        }
                        break;
                }
            }

        }
        catch (e) {
            console.log("ERROR: MyCompanySetupCreate.fetchData")
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (loading) {
        return (<ProgressBar loading={loading}></ProgressBar>)
    }

    if (!loading && company && company.setupStageComplete > 1) {
        // means comany has already been created
        navigate('/');
    }


    /* Company Setup Stages
           CompanyCreated = 0,         // first created by user 
           CompanySetupComplete = 1, 	// All company info is successfully created
           TenantCreated = 2,      	// Tenant Created
           GrantedAccess = 3,      	// Global Admin granted access to Audit Vault to Tenant
           TenantSetupComplete = 4, 	// Set up complete and first audit ran successfully
           PaymentComplete = 5,   		// Payment completed by Authorized user at organization
           PaymentCompleteManually = 6 // Payment Completed but done manually or delayed from Stripe (Bank Deposit)
   */

    if (!loading) {
        return (
            <>
                <Typography variant="h5" component="h5">Audit Vault for M365 - 3 Easy Steps to Sign Up</Typography>
                <div><br></br></div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} sx={{ paddingBottom: '3ch' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}

                    </Stepper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item sx={{ minWidth: 440, width: "50%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" component="div">A. Sign In With Microsoft</Typography>
                                            {!isAuthenticated && (
                                                <>
                                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Click on the Sign In With Microsoft button below to begin your setup.</Typography>
                                                    <Typography component="div" sx={{ border: 1, borderColor: 'lightgrey' }}>
                                                        <Typography component="div" sx={{ padding: 3 }}>
                                                            <b>We need to verify your information:</b><br />Please sign in with your existing Microsoft Account tied to the Organization you want to manage.<br />This account will be assigned as the initial Company Admin for this App (you can change this later).
                                                        </Typography>
                                                    </Typography>

                                                    <Typography component="div" sx={{ textAlign: 'center', paddingTop: '20px' }}>
                                                        <Button onClick={() => handleSignIn()} sx={{ padding: '3px', '&:hover': { backgroundColor: 'lightblue' } }}>
                                                            <img src={signInWithMsLogo} alt="Sign in with Microsoft" />
                                                        </Button>
                                                    </Typography>
                                                    <Typography component="div"><br></br></Typography>
                                                </>
                                            )}

                                            {isAuthenticated &&
                                                <>
                                                    <Typography component="div"><br></br></Typography>
                                                    <Alert severity="success">
                                                        <AlertTitle>Sign In With Microsoft Complete</AlertTitle>
                                                    </Alert>
                                                </>
                                            }
                                            <Typography component="div"><br></br></Typography>
                                            <Typography variant="h6" component="div">B: Your Company Details</Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Tell us about your company.</Typography>
                                            {companyDetailsTextFields.map((txtfld) => (
                                                <Typography key={txtfld.key} component="div">
                                                    <b>{txtfld.label}</b>
                                                    <br></br>
                                                    <TextField required
                                                        name={txtfld.name}
                                                        disabled={!isAuthenticated}
                                                        placeholder={txtfld.placeholderText}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ maxWidth: '90%' }}
                                                        InputLabelProps={{ shrink: true }}
                                                        value={txtfld.value}
                                                        onChange={event => txtfld.onchange(event.target.value)}
                                                        error={txtfld.error}
                                                        helperText={txtfld.error ? txtfld.errText : txtfld.descText}
                                                        inputProps={txtfld.maxLength} />
                                                </Typography>
                                            ))}
                                            <b>Company Size:</b><br></br>
                                            <TextField required
                                                select
                                                name="companySize"
                                                disabled={!isAuthenticated}
                                                variant="outlined"
                                                size="small"
                                                style={{ maxWidth: '90%' }}
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={companySize}
                                                value={companySizeValue}
                                                helperText="The number of Users in your Microsoft 365 Tenant."
                                                onChange={event => setCompanySizeValue(event.target.value)}
                                            >
                                                {COMPANY_SIZES.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <Typography component="div"><br></br></Typography>
                                            <Typography variant="h6" component="div">Contact Information </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Provide us your contact information.</Typography>
                                            {contactTextFields.map((txtfld) => (
                                                <Typography key={txtfld.key} component="div">
                                                    <b>{txtfld.label}</b>
                                                    <br></br>
                                                    <TextField required
                                                        name={txtfld.name}
                                                        disabled={!isAuthenticated}
                                                        placeholder={txtfld.placeholderText}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ maxWidth: '90%' }}
                                                        InputLabelProps={{ shrink: true }}
                                                        value={txtfld.value}
                                                        onChange={event => txtfld.onchange(event.target.value)}
                                                        error={txtfld.error}
                                                        helperText={txtfld.error ? txtfld.errText : txtfld.descText}
                                                        inputProps={txtfld.maxLength} />
                                                </Typography>
                                            ))}
                                            <Typography key='companyCountry' component="div">
                                                <b>Country:</b>
                                                <br></br>
                                                <TextField required
                                                    select
                                                    value={countryValue}
                                                    disabled={!isAuthenticated}
                                                    onChange={event => setCountryValue(event.target.value)}
                                                    name="country"
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ maxWidth: '90%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputRef={country}>
                                                    {COUNTRIES.map((option) => (
                                                        <MenuItem key={option.label} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Typography>
                                            <Typography component="div"><br></br></Typography>
                                            <Typography key='companyAgreeToTerms' component="span">
                                                {errorAgreeToTerms && (<div><Alert severity="error">
                                                    <AlertTitle>Please agree to the Terms of Service and Privacy Policy.</AlertTitle>
                                                </Alert>
                                                </div>)}
                                                <div>
                                                    <Checkbox required
                                                        disabled={!isAuthenticated}
                                                        checked={agreeToTermsValue}
                                                        onChange={event => { setAgreeToTermsValue(event.target.checked); }}
                                                        name="agreeToTermsValue"
                                                        controlid="agreeToTermsValue" />I attest as an authorized representative of Company and agree to the <a href={LINK_TO_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href={LINK_TO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </div>
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {user && user.companyId == 0 ?
                                                <Typography component="div"><Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleInsert()}>Create Account and Continue</Button></Typography>
                                                :
                                                <Typography component="div"><Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleInsert()}>Update Account and Continue</Button></Typography>
                                            }
                                        </CardActions>
                                    </Card>
                                </Paper>
                            </Grid>
                            <Grid item sx={{ minWidth: 440, width: "50%", padding: 2 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" component="div">Product Highlights</Typography>
                                        <hr />
                                        “Audit Vault for M365 empowers organizations to thrive in highly regulated environments by preserving, shielding, and intelligently surfacing your audit history information where and when you need it.”
                                        <br /><br />
                                        Benefits:
                                        <ul>
                                            <li>Eliminate worry about losing your audit history - Audit Vault for M365 preserves + secures your SharePoint and M365 audit history for as long as you require</li>
                                            <ul>
                                                <li>Preserve and prove legal chain of custody for your vital documents and records</li>
                                                <li>Remove concerns around losing your audit history after 90/180 days or 1 year (E3 and E5 defaults for M365), realize costs savings from not having to upgrade to E5 licensing (to obtain 1 year audit history preservation), and no need for complex configuration and guess-work around using 10 year audit retention rules!</li>
                                            </ul>
                                            <li>Enable your organization to monitor, report and search on your M365 audit history</li>
                                            <li>Expose and surface viewing "Audit History" in SharePoint Online to empower your end users, power users and SMEs to see item history within SharePoint</li>
                                            <li>Low cost and effective solution to unlock insights into your content, trends, and security recommendations</li>
                                            <li>Eliminate compliance risk and ensure your organization is compliant with policies, rules, and regulations like GDPR, GxP, GCP, FDA 21 CFR Part 11, HIPAA, Sarbanes-Oxley, and ISO 9001</li>
                                            <li>Easily manage who in your organization can access your Audit information</li>
                                        </ul>
                                        <hr />
                                        <Shop2Icon style={{ color: 'green' }} />&nbsp; <b><u><span style={{ color: 'green' }}>Try free for 14 days!</span></u></b>&nbsp; Your free trial period is for 14 days after which payment will be due.
                                        <br /><br /><b>Optional:</b> Concierge setup and quick-start service is available if you prefer concierge service to get started (get your solution setup with training for your Administrator(s)).&nbsp; <a href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS}?subject=${encodeURIComponent('Concierge Setup Inquiry for Audit Vault for M365')}`}>Contact us</a> to find out more and schedule a time if you require this add-on service.
                                    </CardContent>
                                    <CardActions>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Typography component="div" sx={{ textAlign: 'right' }}> <Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleInsert()}>Next</Button> </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </>
        );
    };
}

export default MyCompanySetupCreate;