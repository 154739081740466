// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// Audit Vault Components
import ProgressBar from "../common/ProgressBar";
import AccessDenied from "../common/AccessDenied";
import TenantDeleteConfirmationDialog from "./TenantDeleteConfirmationDialog";
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { verifyUserCanAccessTenantData } from "../../utilities/common-user-utils";
import { formatDate } from "../../utilities/common-date-utils";
import { getTenantById } from "../../utilities/common-tenant";

export default function TenantDetails(props) 
{
    const navigate = useNavigate();
    const { user, tenantId } = props;

    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState("loading ...");
    const [tenant, setTenant] = useState("");
    const [company, setCompany] = useState("");
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [objectIdToDelete, setObjectIdToDelete] = useState(null); 

    // For snackbar notification UI element.  Company deleted notification.
    const [openSnackbarNotification, setOpenSnackbarNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    // For snackbar close action.
  const handleSnackbarNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbarNotification(false);
  }

  // For snackbar notification action.
  const openSnackbarNotificationAction = (
    <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarNotificationClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
)


    async function fetchData() {
        if(loading && user)
        {
            try{
                if (verifyUserCanAccessTenantData(user, tenantId))
                {
                    var response = await getTenantById(tenantId);
                    if (response) 
                    {
                        setTenant(response);
                    }
                }
                else
                {
                    setAccessDenied(true);
                }
            }
            catch (e)
            {
                setServiceIsDownError(true);
                console.log(e);
            }
            finally{
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleDeleteClick = (value) => {
        setObjectIdToDelete(value); 
        setDeleteDialogOpen(true);
      };
    
    const handleDeleteConfirm = async () => {
    if (objectIdToDelete !== null) {

        setObjectIdToDelete(null);
        setDeleteDialogOpen(false);
    }    
    };
    
    const handleDeleteCancel = () => {
        setObjectIdToDelete(null); 
        setDeleteDialogOpen(false);
        navigate(`/tenant/mytenant`);
    };

    const handleEdit = (value) => {
        if (value  > 0) {
            navigate(`/tenant-update/${value}`);
        }
    };

    const handleValidate = (value) => {
        if (value  > 0) {
            navigate(`/mytenant-validate/${value}`);
        };
    };

    const handleCheckFirstAuditDate = (firstAuditDate) =>
    {
        var dt = new Date();
        dt.setDate(dt.getDate() - 7);
        dt = formatDate(dt);
        if (firstAuditDate > dt)
            return true;
        else
            return false;
    }


    if (accessDenied)
    {
        return(<AccessDenied></AccessDenied>)
    }
    
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (tenant)
    {
        return (
            <><Card sx={{ width: 600}} variant="outlined">
                <CardContent>
                    <ProgressBar message={loadingMsg} loading={loading} />
                    <Table sx={{ minWidth: 250}} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell key="tenantId" style={{ width: '40%' }}>Tenant Id:</TableCell>
                                <TableCell key="tenantIdValue">
                                    <Typography>{tenant.id}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell key="tenantUrl" style={{ width: '40%' }}>Domain:</TableCell>
                                <TableCell key="tenantUrlVal">
                                    <Typography>{tenant.tenantUrl}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell key="tenantLogSp" style={{ width: '40%' }}>Preserve SharePoint Audit:</TableCell>
                                <TableCell key="tenantLogSpVal">
                                    <Typography>{tenant.logSharePointAudit ? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell key="tenantLogExchange" style={{ width: '40%' }}>Preserve Exchange Audit:</TableCell>
                                <TableCell key="tenantLogExchangeVal">
                                    <Typography>{tenant.logExchangeAudit ? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell key="tenantLogAzureAd" style={{ width: '40%' }}>Preserve Entra ID Audit:</TableCell>
                                <TableCell key="tenantLogAuzerAdVal">
                                    <Typography>{tenant.logEntraIDAudit ? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell key="tenantLogTeams" style={{ width: '40%' }}>Preserve Teams Audit:</TableCell>
                                <TableCell key="tenantLogTeamsVal">
                                    <Typography>{tenant.logTeamsAudit ? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
                <CardActions>
                    <Typography component="span" sx={{ width: "50%", textAlign: "left"}}>
                    <Button size="small" onClick={(event) => handleEdit(tenant.id)}>Edit</Button>
                    {user.isSystemAdmin && <Button size="small" onClick={(event) => handleDeleteClick(tenant.id)}>Delete</Button>}
                    </Typography>
                    <Typography component="span" sx={{ width: "50%", textAlign: "right"}}>
                    {tenant.firstAuditImportStage == 0 && <Button size="small" onClick={(event) => handleValidate(tenant.id)}>Validate</Button>}
                    {tenant.firstAuditImportStage == 1 && <Button size="small" onClick={(event) => handleValidate(tenant.id)}>View Import Status</Button>}
                    {tenant.firstAuditImportStage == 2 && handleCheckFirstAuditDate(tenant.firstAuditImportCompleteDate) && <Button size="small" onClick={(event) => handleValidate(tenant.id)}>View First Import Stats</Button>}
                    </Typography>
                </CardActions>
            </Card>
            <TenantDeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={handleDeleteCancel}
                user={user}
                setCompany={setCompany}
                companyId={tenant.companyId}
                tenantId={tenant.id}
                onDelete={handleDeleteConfirm}
                snackbarNotification={setOpenSnackbarNotification}
                />
            <div>
                <Snackbar
                    open={openSnackbarNotification}
                    autoHideDuration={6000}
                    onClose={handleSnackbarNotificationClose}
                    message="Tenant Deleted"
                    action={openSnackbarNotificationAction}
                />
            </div>
            </>
                    
        );
    };
}