// React Components
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ProgressBar from "../components/common/ProgressBar";
import DiagnosticsList from "../components/DiagnosticsList";
import ServiceIsDown from "../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Utilities
import catchAxiosErr2 from "../utilities/common-axios-err-catch2";
import { verifyUserCanAccessTenantData } from '../utilities/common-user-utils';
import { encryptKey } from '../utilities/common-encrypt-util';

function ReportsDiagnostics(props) {

    const { user } = props;

    // Component Constants
    const { id } = useParams();
    const [logs, setLogs] = useState([]);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const theSectionTitle = "Report: Audit Vault Job Processing - Diagnostic Logs (Warnings or Errors)";
    const theSectionDesc = "This report displays all of the warnings or errors that have been generated in the last 7 days in the normal operation of your audit vault jobs.  We proactively monitor all service health and these logs are meant for diagnostic purposes only.";


    const isAdminPage = false;

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Reports', link: '/Reports/ReportsHome' },
    ];

    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    function getDiagnosticLogs() {
        // just a dummy function in case you are logged in as Sys Admin and viewing logs from the tenant pages
    }

    // Component Comments
    async function fetchData() {

        if (user) {

            if (verifyUserCanAccessTenantData(user, id)) {
                try {
                    var rsaKey = await encryptKey(`{tenantId: ${id}}`);
                    var response = null;
                    if (rsaKey) {
                        response = await axios.get(`${WEB_API_URL}reports/GetDiagnosticLogsForLast7DaysByTenantId`, {
                            params: {
                                key: `${rsaKey}`
                            }
                        });
                    }
                    if (response) {
                        setLoading(false);
                        setLogs(response.data);
                    }
                }
                catch (e) {
                    catchAxiosErr2(e, "ReportsDiagnostics.fetchData", setLoading, setServiceIsDownError);
                };
            }
            else {
                setAccessDenied(true);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    if (!loading) {
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

                <Alert severity="info">
                    <AlertTitle>About Errors and Warnings</AlertTitle>
                    <Typography component="div">
                        Note: For critical errors due to Microsoft network outage, Microsoft service unavailability, or Microsoft throttling behavior: these may be temporary in nature and are usually resolved on the next scheduled job run.
                        <br />All warnings and errors are displayed for informational purposes so you are aware of the service health for your Tenant.
                        <br />We proactively monitor all service health, if you see a prolonged period of errors please feel free to <a href="/Support/SupportTicket" target="_blank" rel="noopener noreferrer">submit a Support Ticket</a> for us to investigate.
                    </Typography>
                </Alert>
                <br />

                <Typography component="span">
                    {logs &&
                        <DiagnosticsList
                            user={user}
                            logs={logs}
                            getDiagnosticLogs={getDiagnosticLogs}
                        ></DiagnosticsList>}
                </Typography>
            </>
        );
    }

}
export default ReportsDiagnostics;