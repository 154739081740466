//React Components
import axios from "axios";

// Audit Vault Utilities
import { encryptKey } from './common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function getCompanyByCompanyId(companyId, setServiceIsDownError) {

    if (companyId) {
        try {
            var rsaKey = await encryptKey(`{Id: ${companyId}}`);
            if (rsaKey) {

                var result = await axios.get(`${WEB_API_URL}Company/GetCompany`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });
                if (result.data != null) {
                    return result.data;
                }
            }
        }
        catch (e) {
            console.log(`getCompanyByCompanyId Error:`, e.message);
            setServiceIsDownError(true);
        }
    }
    return "";
}

export async function getCompanyByAzureTenantId(azureTenantId) {

    var rsaKey = await encryptKey(`{AzureTenantId: "${azureTenantId}" }`);
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Company/GetCompanyByAzureTenantId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function updateCompany(companyId,
    companyName,
    streetAddress,
    streetAddressLine2,
    city,
    provinceState,
    country,
    phone,
    postalZip,
    primaryContactFirstName,
    primaryContactLastName,
    primaryContactEmail,
    primaryContactPhone,
    companySize,
    billingEmail,
    setupStageComplete,
    authorizedByDisplayName,
    authorizedByEmail,
    authorizedByGraphId,
    authorizedByTenantId,
    stripeCustomerId,
    enableSelfHostedDbOption) {

    var payload = `{
        id: ${companyId},
        CompanyName: "${companyName}",
        StreetAddress: "${streetAddress}",
        City: "${city}",
        Country: "${country}",`;

    if (provinceState) {
        payload = payload + `ProvinceState: "${provinceState}",`;
    }
    else {
        payload = payload + `ProvinceState: ${provinceState},`;
    }

    if (streetAddressLine2) {
        payload = payload + `StreetAddressLine2: "${streetAddressLine2}",`;
    }
    else {
        payload = payload + `StreetAddressLine2: ${streetAddressLine2},`;
    }


    if (phone) {
        payload = payload + `Phone: "${phone}",`;
    }
    else {
        payload = payload + `Phone: ${phone},`;
    }

    if (postalZip) {
        payload = payload + `PostalZip: "${postalZip}",`;
    }
    else {
        payload = payload + `PostalZip: ${postalZip},`;
    }

    if (billingEmail) {
        payload = payload + `BillingEmail: "${billingEmail}",`;
    }
    else {
        payload = payload + `BillingEmail: ${billingEmail},`;
    }

    payload = payload + `
        PrimaryContactFirstName: "${primaryContactFirstName}",
        PrimaryContactLastName: "${primaryContactLastName}",
        PrimaryContactEmail: "${primaryContactEmail}",
        PrimaryContactPhone: "${primaryContactPhone}",
        CompanySize: ${companySize},
        SetupStageComplete: "${setupStageComplete}",
        AuthorizedByDisplayName: "${await encryptKey(authorizedByDisplayName)}",
        AuthorizedByEmail: "${await encryptKey(authorizedByEmail)}",
        AuthorizedByGraphId : "${await encryptKey(authorizedByGraphId)}",
        AuthorizedByTenantId: "${authorizedByTenantId}",
        StripeCustomerId: "${stripeCustomerId}",
        EnableSelfHostedDbOption: ${enableSelfHostedDbOption},
      }`;

    var response = await axios.put(`${WEB_API_URL}Company/UpdateCompany`, null, {
        params: {
            key: payload,
        }
    })

    return response;

}

export async function insertCompany(
    companyName,
    country,
    phone,
    primaryContactFirstName,
    primaryContactLastName,
    primaryContactEmail,
    primaryContactPhone,
    companySize,
    authorizedByDisplayName,
    authorizedByEmail,
    authorizedByGraphId,
    authByTenantId) {


    var payload = `{
        CompanyName: "${(companyName)}",
        StreetAddress: "",
        City: "",
        ProvinceState: "",
        Country: "${country}",
        Phone: "${phone}",
        PostalZip: "",
        PrimaryContactFirstName: "${primaryContactFirstName}",
        PrimaryContactLastName: "${primaryContactLastName}",
        PrimaryContactEmail: "${primaryContactEmail}",
        PrimaryContactPhone: "${primaryContactPhone}",
        CompanySize: ${companySize},
        IsAccountAuthorized: true,
        SetupStageComplete: 0,
        AuthorizedByDisplayName: "${await encryptKey(authorizedByDisplayName)}",
        AuthorizedByEmail: "${await encryptKey(authorizedByEmail)}",
        AuthorizedByGraphId: "${await encryptKey(authorizedByGraphId)}",
        AuthorizedByTenantId: "${authByTenantId}"
      }`;

    console.log(payload);

    var response = await axios.post(`${WEB_API_URL}Company/InsertCompany`, null, {
        params: {
            key: payload,
        }
    })

    return response;
}