// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// Material UI Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import TenantDeleteConfirmationDialog from './tenant/TenantDeleteConfirmationDialog';

// Audit Vault Common

const rows = [];

export default function TenantList(props) {

  const { company, setCompany, isAdminPage, user } = props;

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [objectIdToDelete, setObjectIdToDelete] = useState(null);
  const [itemToDeleteId, setItemToDeleteId] = useState(0);

  // For snackbar notification UI element.  Company deleted notification.
  const [openSnackbarNotification, setOpenSnackbarNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

  
  // Component functions.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTenantSelect = (event, value) => {
    setSelectedTenant(value);
    getTenantUrl(value);
  }

  const handleDeleteClick = (value) => {
    setItemToDeleteId(value);
    setObjectIdToDelete(value); 
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (objectIdToDelete !== null) {
      
      setObjectIdToDelete(null); // Reset objectIdToDelete
      setDeleteDialogOpen(false);
    }    
  };

  const handleDeleteCancel = () => {
    setObjectIdToDelete(null); 
    setDeleteDialogOpen(false);
  };

  // For snackbar close action.
  const handleSnackbarNotificationClose = (event, reason) => {
    setOpenSnackbarNotification(false);
  }

  // For snackbar notification action.
  const openSnackbarNotificationAction = (
    <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarNotificationClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
)


  const handleEdit = (value) => {
    if (value  > 0) {
      if(isAdminPage == false) {
        navigate(`/tenant-update/${value}`);
      }
      else {
        navigate(`/Admin/tenant-update/${value}`);
      }
    }
  };

  const handleValidate = (value) => {
    if (value  > 0) {
        navigate(`/mytenant-validate/${value}`);
      };
  };

  let columns = [
    { id: 'id', label: 'Id', minWidth: 10, align: 'left' },
    { id: 'tenantUrl', label: 'TenantUrl', minWidth: 20,  align: 'left' },
    { id: 'logSharePointAudit', label: 'Audit SharePoint', minWidth: 50, align: 'center' },
    { id: 'logExchangeAudit', label: 'Audit Exchange', minWidth: 50, align: 'center' },
    { id: 'logEntraIDAudit', label: 'Audit Entra ID', minWidth: 50, align: 'center'},
    { id: 'logGeneralAudit', label: 'Audit General', minWidth: 50, align: 'center'},
    { id: 'edit', label: 'Edit', minWidth: 10, align: 'center'},
    { id: 'delete', label: 'Delete', minWidth: 10, align: 'center'},
    { id: 'validate', label: 'Validate', minWidth: 10, align: 'center' },
  ];

  return (
    <>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }
              }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {company.tenantList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    switch (column.id)
                    {            
                      case 'edit':
                        return (<TableCell key={row.id + "-" + column.id} align={column.align}><Link onClick={(event) => handleEdit(row.id)} component="button">Edit</Link></TableCell>)
                      
                      case 'delete':
                        return (<TableCell key={row.id + "-" + column.id} align={column.align}><Link onClick={(event) => handleDeleteClick(row.id)} component="button">Delete</Link></TableCell>)

                      case 'validate':
                        if (!row.companySetUpStage > 3)
                          {
                          return (<TableCell key={row.id + "-" + column.id} align={column.align}><Link onClick={(event) => handleValidate(row.id)} component="button">Validate</Link></TableCell>)
                        }
                        else
                          return (<TableCell key={row.id + "-" + column.id} align={column.align}>Validated</TableCell>)

                      case 'logSharePointAudit':
                        return (
                          <TableCell key={row.id + "-" + column.id} align={column.align}>
                            <Typography>{value? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                            <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                            </Typography>
                          </TableCell>)

                      case 'logExchangeAudit':
                        return (
                          <TableCell key={row.id + "-" + column.id} align={column.align}>
                            <Typography>{value? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                            <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                            </Typography>
                          </TableCell>)

                      case 'logEntraIDAudit':
                        return (
                          <TableCell key={row.id + "-" + column.id} align={column.align}>
                            <Typography>{value? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                            <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                            </Typography>
                          </TableCell>)

                        case 'logGeneralAudit':
                          return (
                            <TableCell key={row.id + "-" + column.id} align={column.align}>
                              <Typography>{value? <TaskAltIcon fontSize="small" sx={{color: 'green'}}></TaskAltIcon> : 
                              <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>}
                              </Typography>
                            </TableCell>)

                      default:
                        return (
                          <TableCell key={row.id + "-" + column.id} align={column.align}>
                            {value 
                              ? value.toString()
                              : value
                              }
                          </TableCell>
                          )
                    }
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="span"
        count={company.tenantList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <TenantDeleteConfirmationDialog
      isOpen={isDeleteDialogOpen}
      onClose={handleDeleteCancel}
      setCompany={setCompany}
      user={user}
      companyId={company.id}
      tenantId={itemToDeleteId}
      onDelete={handleDeleteConfirm}
      snackbarNotification={setOpenSnackbarNotification}
      />
      <div>
          <Snackbar
              open={openSnackbarNotification}
              autoHideDuration={6000}
              onClose={handleSnackbarNotificationClose}
              message="Tenant Deleted"
              action={openSnackbarNotificationAction}
          />
      </div>
    </>    
  );
}


