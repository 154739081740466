import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './invoice-components/InvoiceTitle';
import BillTo from './invoice-components/BillTo';
import InvoiceNumber from './invoice-components/InvoiceNumber';
import InvoiceItemsTable from './invoice-components/InvoiceItemsTable';
import InvoiceThankYouMsg from './invoice-components/InvoiceThankYouMsg';



const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });

// Create Document Component
export default function MyInvoice(props) {

  const { invoice } = props;

  return(
  <Document>
    <Page size="A4" style={styles.page}>
        <InvoiceTitle title='Invoice'/>
        <InvoiceNumber invoice={invoice}/>
        <BillTo invoice={invoice}/>
        <InvoiceItemsTable invoice={invoice} />
        <InvoiceThankYouMsg />
    </Page>
  </Document>)
};
