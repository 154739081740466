// React Components
import * as React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { formatDate } from '../../utilities/common-date-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const rows = [];


export default function JobsList(props) {

  const { selectedTenant } = props;

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState("");
  const [serviceIsDownError, setServiceIsDownError] = useState(false);

  const [loadingSharePointJob, setLoadingSharePoint] = useState(true);
  const [loadingExchangeJob, setLoadingExchange] = useState(true);
  const [loadingEntraIDJob, setLoadingEntraID] = useState(true);
  const [loadingGeneralJob, setLoadingGeneral] = useState(true);

  const [sharePointJobHistory, setSharePointJobHistory] = useState([]);
  const [exchangeJobHistory, setExchangeJobHistory] = useState([]);
  const [entraIDJobHistory, setEntraIDJobHistory] = useState([]);
  const [generalJobHistory, setGeneralJobHistory] = useState([]);

  const [sharePointAccordian, setSharePointAccordian] = useState(false); 
  const [exchangeAccordian, setExchangeAccordian] = useState(false);
  const [entraIDAccordian, setEntraIDAccordian] = useState(false);
  const [generalAccordian, setGeneralAccordian] = useState(false);

  // Need page variables for each audit type
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(25);

  const [page3, setPage3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(25);

  const [page4, setPage4] = React.useState(0);
  const [rowsPerPage4, setRowsPerPage4] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangePage2 = (event, newPage) => {
  setPage2(newPage);
};

const handleChangePage3 = (event, newPage) => {
  setPage3(newPage);
};

const handleChangePage4 = (event, newPage) => {
  setPage4(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};

const handleChangeRowsPerPage2 = (event) => {
  setRowsPerPage2(+event.target.value);
  setPage2(0);
};

const handleChangeRowsPerPage3 = (event) => {
  setRowsPerPage3(+event.target.value);
  setPage3(0);
};

const handleChangeRowsPerPage4 = (event) => {
  setRowsPerPage4(+event.target.value);
  setPage4(0);
};

async function fetchJobHistory(job) {
  if(selectedTenant && job)
  {
    try
    {
      switch (Number(job.auditTypeAssigned))
      {
        case 0:
          setLoadingSharePoint(true);

        case 1:
          setLoadingExchange(true);

        case 2:
          setLoadingEntraID(true);

        case 4:
          setLoadingGeneral(true);
      }

      var rsaKey = `{Id: "${await encryptKey(selectedTenant.toString())}", JobId: "${await encryptKey(job.id.toString())}" }`;
        if (rsaKey) {
            var result = await axios.get(`${WEB_API_URL}Jobs/GetJobHistoryForLastMonth`, {
                params: {
                    key: `${rsaKey}`
                }
            });
            if (result.data != null) {
              switch (Number(job.auditTypeAssigned))
              {
                case 0:
                  setSharePointJobHistory(result.data);
                  break;
      
                case 1:
                  setExchangeJobHistory(result.data);
                  break;
      
                case 2:
                  setEntraIDJobHistory(result.data);
                  break;

                case 4:
                  setGeneralJobHistory(result.data);
                  break;
                  
              }
            }
        }
    }
    catch (e)
    {
      console.log("ERROR: JobsList.fetchJobHistory");
      console.log(e);
      setServiceIsDownError(true);
    }
    finally
    {
      setLoadingSharePoint(false);
      setLoadingExchange(false);
      setLoadingEntraID(false);
      setLoadingGeneral(false);
    }
  }
}



const handleAccordionLoad = async (job) =>
{
  switch (Number(job.auditTypeAssigned))
  {
    case 0:
      if (!sharePointAccordian)
      {
        fetchJobHistory(job);
      }
      setSharePointAccordian(!sharePointAccordian);
      break;

    case 1:
      if (!exchangeAccordian)
      {
        fetchJobHistory(job);
      }
      setExchangeAccordian(!exchangeAccordian);
      break;

    case 2:
      if (!entraIDAccordian)
      {
        fetchJobHistory(job);
      }
      setEntraIDAccordian(!entraIDAccordian);
      break;

    case 4:
        if (!generalAccordian)
        {
          fetchJobHistory(job);
        }
        setGeneralAccordian(!generalAccordian);
        break;
  }
}
  
async function fetchJobsData() {
  try {
      setLoading(true);
      if (selectedTenant) {
        var rsaKey = `{Id: "${await encryptKey(selectedTenant.toString())}" }`;
        if (rsaKey) {
            var result = await axios.get(`${WEB_API_URL}Jobs/GetJobsByTenantId`, {
                params: {
                    key: `${rsaKey}`
                }
            });
            if (result.data != null) {
              setJobs(result.data);
            }
        }
      }
  }
  catch (e) {
    console.log("ERROR: MyTenant.fetchJobsData")
    console.log(e);
    setServiceIsDownError(true);
  }
  finally{
    setLoading(false);
  }
}

useEffect(() => {
    fetchJobsData();
}, []);


  // Component UI
  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  return (
    <><Card sx={{ width: 800 }} key="jobsList" variant="outlined">
      <CardContent>
      {jobs && jobs.map((row) => {
        if (row.auditTypeAssigned == 0)
        {
            return (
            <>
            <Accordion key="spAccordian" expanded={sharePointAccordian}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            onClick={event => handleAccordionLoad(row) } 
            id="panel1a-header"
            sx={{flexGrow: '1'}}
            >
            <Typography><b>SharePoint:</b>
            <Grid container spacing={1}>
                <Grid item xs={6}>Active:</Grid>
                <Grid item xs={6}>
                {row.isActive &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isActive &&
                  <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>
                }
                </Grid>
                <Grid item xs={6}>Last Run Successful:</Grid>
                <Grid item xs={6}>
                {row.isLastRunSuccessful &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isLastRunSuccessful &&
                  <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
                }
                </Grid>
                <Grid item xs={6}>Approx. Next Run Time:</Grid>
                <Grid item xs={6}>
                  {formatDate(dayjs(row.approxNextRunTime))}
                </Grid>
                <Grid item xs={6}>Items Processed During Last Run:</Grid>
                <Grid item xs={6}>{row.auditEntriesProcessed}</Grid>
            </Grid>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ProgressBar message="Loading ..." loading={loadingSharePointJob} />
            <Typography>Below is a listing of the dates and times this job has ran over the past month.</Typography>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
              <TableCell key="SPHeaderSucess">Successful</TableCell>
              <TableCell key="SPHeaderItems">Items Processed</TableCell>
              <TableCell key="SPHeaderJobStart">Job Start Time</TableCell>
              <TableCell key="SPHeaderJobEnd">Job End Time</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {sharePointJobHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((his) => {
              return(
              <TableRow>
              <TableCell key={'SP' + his.id}>
              {his.isLastRunSuccessful &&
                <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
              }
              {!his.isLastRunSuccessful &&
                <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
              }
              </TableCell>
              <TableCell key={'SPItems' + his.id}>{his.auditEntriesProcessed}</TableCell>
              <TableCell key={'SPStart' + his.id}>{formatDate(dayjs(his.lastJobRunStartTime))}</TableCell>
              <TableCell key={'SPEnd' + his.id}>{formatDate(dayjs(his.lastJobRunEndTime))}</TableCell>
              </TableRow>);
            })}
            </TableBody>
            </Table>
            {sharePointJobHistory.length > 25 &&
            <TablePagination
                  rowsPerPageOptions={[25, 50, 75]}
                  component="span"
                  count={sharePointJobHistory.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage} />
            }

            </AccordionDetails>
            </Accordion>
            </>
          );
        };

        if (row.auditTypeAssigned == 1)
        {
            return (
            <>
            <Accordion key="exchangeAccordian" expanded={exchangeAccordian}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            onClick={event => handleAccordionLoad(row) } 
            id="panel1a-header"
            sx={{flexGrow: '1'}}
            >
            <Typography><b>Exchange:</b>
            <Grid container spacing={1}>
                <Grid item xs={6}>Active:</Grid>
                <Grid item xs={6}>
                {row.isActive &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isActive &&
                  <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>
                }
                </Grid>
                <Grid item xs={6}>Last Run Successful:</Grid>
                <Grid item xs={6}>
                {row.isLastRunSuccessful &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isLastRunSuccessful &&
                  <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
                }
                </Grid>
                <Grid item xs={6}>Approx. Next Run Time:</Grid>
                <Grid item xs={6}>
                  {formatDate(dayjs(row.approxNextRunTime))}
                </Grid>
                <Grid item xs={6}>Items Processed During Last Run:</Grid>
                <Grid item xs={6}>{row.auditEntriesProcessed}</Grid>
            </Grid>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ProgressBar message="Loading ..." loading={loadingExchangeJob} />
            <Typography>Below is a listing of the dates and times this job has ran over the past month.</Typography>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
              <TableCell key="ExHeaderSucess">Successful</TableCell>
              <TableCell key="ExHeaderItems">Items Processed</TableCell>
              <TableCell key="ExHeaderJobStart">Job Start Time</TableCell>
              <TableCell key="ExHeaderJobEnd">Job End Time</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {exchangeJobHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((his) => {
              return(
              <TableRow>
              <TableCell key={'EX' + his.id}>
              {his.isLastRunSuccessful &&
                <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
              }
              {!his.isLastRunSuccessful &&
                <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
              }
              </TableCell>
              <TableCell key={'EXItems' + his.id}>{his.auditEntriesProcessed}</TableCell>
              <TableCell key={'EXStart' + his.id}>{formatDate(dayjs(his.lastJobRunStartTime))}</TableCell>
              <TableCell key={'EXEnd' + his.id}>{formatDate(dayjs(his.lastJobRunEndTime))}</TableCell>
              </TableRow>);
            })}
            </TableBody>
            </Table>
            {exchangeJobHistory.length > 25 &&
            <TablePagination
                  rowsPerPageOptions={[25, 50, 75]}
                  component="span"
                  count={exchangeJobHistory.length}
                  rowsPerPage={rowsPerPage2}
                  page={page2}
                  onPageChange={handleChangePage2}
                  onRowsPerPageChange={handleChangeRowsPerPage2} />
            }

            </AccordionDetails>
            </Accordion>
            </>
          );
        };

        if (row.auditTypeAssigned == 2)
        {
            return (
            <>
            <Accordion key="entraAccordian" expanded={entraIDAccordian}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            onClick={event => handleAccordionLoad(row) } 
            id="panel1a-header"
            sx={{flexGrow: '1'}}
            >
            <Typography><b>Entra ID:</b>
            <Grid container spacing={1}>
                <Grid item xs={6}>Active:</Grid>
                <Grid item xs={6}>
                {row.isActive &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isActive &&
                  <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>
                }
                </Grid>
                <Grid item xs={6}>Last Run Successful:</Grid>
                <Grid item xs={6}>
                {row.isLastRunSuccessful &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!row.isLastRunSuccessful &&
                  <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
                }
                </Grid>
                <Grid item xs={6}>Approx. Next Run Time:</Grid>
                <Grid item xs={6}>
                  {formatDate(dayjs(row.approxNextRunTime))}
                </Grid>
                <Grid item xs={6}>Items Processed During Last Run:</Grid>
                <Grid item xs={6}>{row.auditEntriesProcessed}</Grid>
            </Grid>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ProgressBar message="Loading ..." loading={loadingEntraIDJob} />
            <Typography>Below is a listing of the dates and times this job has ran over the past month.</Typography>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
              <TableCell key="EntraHeaderSucess">Successful</TableCell>
              <TableCell key="EntraHeaderItems">Items Processed</TableCell>
              <TableCell key="EntraHeaderJobStart">Job Start Time</TableCell>
              <TableCell key="EntraHeaderJobEnd">Job End Time</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {entraIDJobHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((his) => {
              return(
              <TableRow>
              <TableCell key={'Entra' + his.id}>
              {his.isLastRunSuccessful &&
                <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
              }
              {!his.isLastRunSuccessful &&
                <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
              }
              </TableCell>
              <TableCell key={'EntraItems' + his.id}>{his.auditEntriesProcessed}</TableCell>
              <TableCell key={'EntraStart' + his.id}>{formatDate(dayjs(his.lastJobRunStartTime))}</TableCell>
              <TableCell key={'EntraEnd' + his.id}>{formatDate(dayjs(his.lastJobRunEndTime))}</TableCell>
              </TableRow>);
            })}
            </TableBody>
            </Table>
            {entraIDJobHistory.length > 25 &&
            <TablePagination
                  rowsPerPageOptions={[25, 50, 75]}
                  component="span"
                  count={entraIDJobHistory.length}
                  rowsPerPage={rowsPerPage3}
                  page={page3}
                  onPageChange={handleChangePage3}
                  onRowsPerPageChange={handleChangeRowsPerPage3} />
            }

            </AccordionDetails>
            </Accordion>
            </>
          );
        };

        if (row.auditTypeAssigned == 4)
          {
              return (
              <>
              <Accordion key="generalAccordian" expanded={generalAccordian}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              onClick={event => handleAccordionLoad(row) } 
              id="panel1a-header"
              sx={{flexGrow: '1'}}
              >
              <Typography><b>General:</b>
              <Grid container spacing={1}>
                  <Grid item xs={6}>Active:</Grid>
                  <Grid item xs={6}>
                  {row.isActive &&
                    <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                  }
                  {!row.isActive &&
                    <RadioButtonUncheckedIcon fontSize="small"></RadioButtonUncheckedIcon>
                  }
                  </Grid>
                  <Grid item xs={6}>Last Run Successful:</Grid>
                  <Grid item xs={6}>
                  {row.isLastRunSuccessful &&
                    <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                  }
                  {!row.isLastRunSuccessful &&
                    <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
                  }
                  </Grid>
                  <Grid item xs={6}>Approx. Next Run Time:</Grid>
                  <Grid item xs={6}>
                    {formatDate(dayjs(row.approxNextRunTime))}
                  </Grid>
                  <Grid item xs={6}>Items Processed During Last Run:</Grid>
                  <Grid item xs={6}>{row.auditEntriesProcessed}</Grid>
              </Grid>
              </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <ProgressBar message="Loading ..." loading={loadingGeneralJob} />
              <Typography>Below is a listing of the dates and times this job has ran over the past month.</Typography>
              <Table stickyHeader aria-label="sticky table">
              <TableHead>
              <TableRow>
                <TableCell key="GeneralHeaderSucess">Successful</TableCell>
                <TableCell key="GeneralHeaderItems">Items Processed</TableCell>
                <TableCell key="GeneralHeaderJobStart">Job Start Time</TableCell>
                <TableCell key="GeneralHeaderJobEnd">Job End Time</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {generalJobHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((his) => {
                return(
                <TableRow>
                <TableCell key={'Entra' + his.id}>
                {his.isLastRunSuccessful &&
                  <TaskAltIcon fontSize="small" sx={{color: "green"}}></TaskAltIcon>
                }
                {!his.isLastRunSuccessful &&
                  <ErrorOutlineIcon fontSize="small" sx={{color: "red"}}></ErrorOutlineIcon>
                }
                </TableCell>
                <TableCell key={'EntraItems' + his.id}>{his.auditEntriesProcessed}</TableCell>
                <TableCell key={'EntraStart' + his.id}>{formatDate(dayjs(his.lastJobRunStartTime))}</TableCell>
                <TableCell key={'EntraEnd' + his.id}>{formatDate(dayjs(his.lastJobRunEndTime))}</TableCell>
                </TableRow>);
              })}
              </TableBody>
              </Table>
              {generalJobHistory.length > 25 &&
              <TablePagination
                    rowsPerPageOptions={[25, 50, 75]}
                    component="span"
                    count={generalJobHistory.length}
                    rowsPerPage={rowsPerPage4}
                    page={page4}
                    onPageChange={handleChangePage4}
                    onRowsPerPageChange={handleChangeRowsPerPage4} />
              }
  
              </AccordionDetails>
              </Accordion>
              </>
            );
          };

        })}
      </CardContent>
      </Card>
    </>
  );
}
