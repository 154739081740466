// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


// Function to insert our Team Name selector (textfield).
// Later enhance to auto-complete on Team Name.
export default function TeamChannelSelector(props){

    const { tenantId, selectedChannel, setSelectedChannel, selectedChannelValue, errorSelectedChannel } = props;

    return(
        
        <Grid item xs={12}>
            {/* User input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Channel Name:</b>
                </div>
                <div>
                    <TextField                        
                        required
                        name="Channel Name Text Field"
                        id="channelNameTextfield"
                        placeholder="Enter all or a part of the name of a Channel (ex. Announcements)."
                        variant="outlined"
                        size="small"
                        style={{ width: 600 }}
                        inputProps={{ maxLength: 300 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setSelectedChannel(event.target.value)}
                        inputRef={selectedChannelValue}
                        value={selectedChannel}
                        error={errorSelectedChannel}
                        helperText={errorSelectedChannel ? 'Channel Name cannot be empty.' : ''}                     
                    />
                </div>
            </div>
        </Grid>
    );
};