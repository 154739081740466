// React Components
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Material UI Components

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import ExchangeDeletedItems from "../components/reports/ExchangeDeletedItems.jsx";
import ImageExchange from '../imgs/iconExchange.svg';

// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";


/*
ReportsExchangeDeletedItems report.
*/
function ReportsExchangeDeletedItems(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, periodToUse, filterByColumn } = useParams();

    const [accessDenied, setAccessDenied] = useState(false);

    const theSectionTitle = "Report: Exchange Deleted Items";
    const theSectionDesc = "From your Exchange Audit records this report will return deleted items such as Contacts and E-mails.";
    const isAdminPage = false;

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Reports', link: '/Reports/ReportsHome' },
        { name: 'Exchange Reports', link: '/Reports/Exchange' },
    ];


    // Component Functions

    useEffect(() => {

        // Check if current user has Report Reader access to the specified tenant.
        var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTREPORTREADER);
        if (!user || !checkReportAccessForTenant) {
            setAccessDenied(true);
        }
        else {
            setAccessDenied(false);
        }

    }, []);

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageExchange} alt="Exchange Reports" />
                </div>
            </div>
            <div>
                <ExchangeDeletedItems
                    user={user}
                    companyId={user.companyId}
                    tenantId={parseInt(tenantId)}
                    periodToUse={parseInt(periodToUse)}
                    loadData={true}
                    filterByColumn={filterByColumn} />
            </div>
        </>
    );
}
export default ReportsExchangeDeletedItems;