// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import ServiceIsDown from "../../components/common/ServiceIsDown";
import AccessDenied from "../../components/common/AccessDenied";
import ProgressBar from "../../components/common/ProgressBar";
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';

// Audit Vault Utilities
import catchAxiosErr2 from '../../utilities/common-axios-err-catch2';
import { formatDate } from '../../utilities/common-date-utils';
import { HEARTBEAT_SEARCH_ERROR_AFTER } from '../../constants/constants-search';
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


/** 
 * HeartbeatForSearch page
 * Can be used for both full page access and or previewOnly mode (small sub component page).
 * To use as a subcomponent call HeartbeatForSearch and pass in param name previewOnly = true.
 * 
*/
function HeartbeatForSearch(props) {

    // Component Constants    
    const { user, previewOnly } = props;

    const navigate = useNavigate();
    const [searchOrchestrators, setSearchOrchestrators] = useState([]);

    const [loading, setLoading] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    // For snackbar notification UI element.  Item deleted notification.
    const [openDeletedNotification, setDeletedNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    const [isAdminPage] = useState(true);
    const theSectionTitle = "System - Search Orchestrators List and Status";
    const theSectionDesc = "Below are the System Search Orchestrators and related status.  Heartbeat status uses a threshold of " + HEARTBEAT_SEARCH_ERROR_AFTER + " minutes before indicating error state. From this page you can register new Search Orchestrators or disable them.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Search Orchestrators', link: '/Admin/HeartbeatForSearch' }
    ];


    // Component Functions
    useEffect(() => {
        fetchData();
    }, []);

    // Fetch data.
    async function fetchData() {
        setLoading(true);    
        var result;
        try {
            // We double check the user's permissions - they have to be a System Admin to proceed.
            if (user && user.isSystemAdmin) {
                // Load the search orchestrators list.          
                var data = {
                    sysAdminId: user.microsoftGraphId,                
                };                                                
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) 
                {                                                
                    result = await axios.get(`${WEB_API_URL}SearchOrchestrator/GetAllSearchOrchestrators`, {
                        params: {
                            key: keyString,
                        }
                    }); 
                    if(result) {
                        setSearchOrchestrators(result.data);  // Store the Search Orchestrators.                        
                    }
                }
                else {
                    setAccessDenied(true);    
                }
            }
            else {
                setAccessDenied(true);
            }
            setLoading(false);
        }
        catch (e) {
            catchAxiosErr2(e, "HeartbeatForSearch.fetchData", setLoading, setServiceIsDownError);
            setAccessDenied(true);
        }
    }

    // Return the color string depending on the last run date.
    function getStatusLastRunDate(theDate) {
        if (!theDate) {
            return 'black';
        }

        const currentDate = new Date();
        const dateToCheck = new Date(theDate);

        // Calculate the difference in milliseconds between the current date and theDate
        const differenceInMs = currentDate - dateToCheck;

        // Calculate the difference in minutes
        const differenceInMinutes = differenceInMs / (1000 * 60);

        // If the date is older than 5 minutes from the current date, return 'red', else return 'green'
        if (differenceInMinutes > HEARTBEAT_SEARCH_ERROR_AFTER) {
            return 'red';
        } else {
            return 'green';
        }

    }

    // Asynchronous event handler for delete function.  
    const handleDelete = async (id) => {
        try {
            var result = null;
            // Delete.
            var data = {
                id: id,                
            };                                                
            var keyString = await encryptKey(JSON.stringify(data));
            if (keyString) 
            {                                                
                result = await axios.delete(`${WEB_API_URL}SearchOrchestrator/DeleteSearchOrchestrator`, {
                    params: {
                        key: keyString,
                    }
                });                
                if (result) {
                    // Hooks to parent to update data and the UI.                
                    setDeletedNotification(true);  // Set snackbar notification to enabled to display it.
                    fetchData();  // Reload the Search Orchestrators list.    
                }    
            }
        }
        catch (e) {
            catchAxiosErr2(e, "HearbeatForSearch.handleDelete", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
        }
    };

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeletedNotification(false);
    };

    // For snackbar notification action.
    const deletedNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );





    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }


    if (previewOnly == null || previewOnly == false) {
        // Non-preview mode - display full Heartbeat Page view.
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                {
                    searchOrchestrators != null && searchOrchestrators.length > 0 &&
                    (
                        <Grid container spacing={2}>
                            {searchOrchestrators.map((orchestrator, index) => (
                                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>ID:</TableCell>
                                                    <TableCell>{orchestrator.id}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Orchestrator Name:</TableCell>
                                                    <TableCell>{orchestrator.orchestratorName}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Server Name:</TableCell>
                                                    <TableCell>{orchestrator.serverName}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell># of Processors:</TableCell>
                                                    <TableCell> {orchestrator.numOfProcessors}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Max # Tasks Per Run:</TableCell>
                                                    <TableCell>{orchestrator.maxNumOfConcurrentTasks}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Is Active:</TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" color={orchestrator.isActive == true ? "green" : "red"} >
                                                            <CircleRoundedIcon color={orchestrator.isActive == true ? "green" : "red"} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                            {orchestrator.isActive == true ? "Active" : "Inactive"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Last Run Date:</TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" color={getStatusLastRunDate(orchestrator.lastRunDate)} >
                                                            <CircleRoundedIcon color={getStatusLastRunDate(orchestrator.lastRunDate)} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                            {orchestrator.lastRunDate != null ? formatDate(orchestrator.lastRunDate) : "N/A"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><b>Overall Heartbeat Status:</b></TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" color={getStatusLastRunDate(orchestrator.lastRunDate)} >
                                                            <CircleRoundedIcon color={getStatusLastRunDate(orchestrator.lastRunDate)} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Link onClick={() => navigate(`/Admin/EditSearchOrchestrator/` + orchestrator.id)} component="button">Edit</Link>
                                                        &nbsp; &nbsp; |&nbsp; &nbsp;
                                                        <Link onClick={(event) => handleDelete(orchestrator.id)} component="button">Delete</Link>
                                                        &nbsp; &nbsp; |&nbsp; &nbsp;
                                                        <Link onClick={(event) => navigate('/Admin/ViewSearchOrchestratorLogs/' + orchestrator.id)} component="button">View Diagnostic Logs</Link>
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                            ))
                            }
                        </Grid>
                    )
                }
                <br /><Button variant="contained" type="button" onClick={() => navigate("/Admin/NewSearchOrchestrator")}>New Search Orchestrator</Button>
                <div>
                    <Snackbar
                        open={openDeletedNotification}
                        autoHideDuration={6000}
                        onClose={handleNotificationClose}
                        message="Search Orchestrator Successfully Deleted"
                        action={deletedNotificationAction}
                    />
                </div>
            </>
        );
    }
    else {
        // Preview mode.
        return (
            <>
                <h6>{theSectionTitle}</h6>
                <br />
                <Grid container spacing={1}>

                    {
                        searchOrchestrators != null && searchOrchestrators.length > 0 &&
                        (
                            <>
                                {
                                    searchOrchestrators.map((orchestrator, index) => (
                                        <Grid sx={{ minWidth: 200, width: "50%", padding: 2 }}>
                                            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>

                                                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>{orchestrator.id}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>{orchestrator.orchestratorName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>{orchestrator.serverName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="h6" color={getStatusLastRunDate(orchestrator.lastRunDate)} >
                                                                    <CircleRoundedIcon color={getStatusLastRunDate(orchestrator.lastRunDate)} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>

                                            </Paper>
                                        </Grid>
                                    ))
                                }
                            </>
                        )
                    }

                </Grid>
                <br /><br /><Button size="small" onClick={() => navigate("/Admin/HeartbeatForSearch")}>Open Search Orchestrators List</Button>
            </>
        );
    }
}
export default HeartbeatForSearch;