// React Components
import React from 'react';
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { CardActions, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Shop2Icon from '@mui/icons-material/Shop2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


// Audit Vault Components
import { COUNTRIES } from '../../constants/constants-countries';
import { PROVINCE_STATES } from '../../constants/constants-provincestates';
import ServiceIsDown from "../../components/common/ServiceIsDown";
import { SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS } from '../../constants/constants-email';

// Audit Vault Common
import { validateEmail, getAuditVaultUser } from '../../utilities/common-user-utils';
import currencyFormatter from '../../utilities/common-currency-utils';
import { getCompanyByAzureTenantId } from '../../utilities/common-company';
import { updateCompany } from '../../utilities/common-company';


// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

import ProgressBar from '../../components/common/ProgressBar';

const steps = ['1: Your Company Details', '2: Update Billing Info', '3: Start Using Audit Vault for M365']


function MyCompanySetupUpdate(props) {

    const { user, setUser } = props;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const navigate = useNavigate();

    const activeStep = 1; // This page just works with an existing ompany that was just set up.


    const country = useRef("");


    // Product Info From Stripe
    const [stripeProducts, setStripeProducts] = useState("");

    // Billing info fields
    const [product, setProduct] = useState("");
    const [billingInterval, setBillingInterval] = useState("year");
    const [billingCustomerName, setBillingCustomerName] = useState("");
    const [billingCustomerPhone, setBillingCustomerPhone] = useState("");
    const [billingCustomerEmail, setBillingCustomerEmail] = useState("");
    const [billingCustomerAddressLine1, setBillingCustomerAddressLine1] = useState("");
    const [billingCustomerAddressLine2, setBillingCustomerAddressLine2] = useState(null);
    const [billingCustomerCity, setBillingCustomerCity] = useState("");
    const [billingCustomerProvince, setBillingCustomerProvince] = useState("");
    const [billingCustomerPostalCode, setBillingCustomerPostalCode] = useState("");
    const [billingCustomerCountry, setBillingCustomerCountry] = useState("");

    const [billingCustomerNameError, setBillingCustomerNameError] = useState(false);
    const [billingCustomerPhoneError, setBillingCustomerPhoneError] = useState(false);
    const [billingCustomerEmailError, setBillingCustomerEmailError] = useState(false);
    const [billingCustomerAddressLine1Error, setBillingCustomerAddressLine1Error] = useState(false);
    const [billingCustomerCityError, setBillingCustomerCityError] = useState(false);
    const [billingCustomerProvinceError, setBillingCustomerProvinceError] = useState(false);
    const [billingCustomerPostalCodeError, setBillingCustomerPostalCodeError] = useState(false);

    const [totalPrice, setTotalPrice] = useState("");
    const provState = useRef('');

    const billingTextFields = [
        {
            key: 'txtCustomerName',
            name: 'customerName',
            required: true,
            label: 'Customer Name:',
            value: billingCustomerName,
            onchange: setBillingCustomerName,
            error: billingCustomerNameError,
            errText: 'Customer Name cannot be empty.',
            descText: '',
            placeholderText: 'Enter your company name here.',
            maxLength: { maxLength: 200 }
        },
        {
            key: 'txtCustomerPhone',
            name: 'customerPhone',
            required: true,
            label: 'Company Phone Number:',
            value: billingCustomerPhone,
            onchange: setBillingCustomerPhone,
            error: billingCustomerPhoneError,
            errText: 'Billing Phone number cannot be empty or longer than 15 characters.',
            descText: '',
            placeholderText: 'Enter your phone number here.',
            maxLength: { maxLength: 15 }
        },
        {
            key: 'txtCustomerEmail',
            name: 'customerEmail',
            required: true,
            label: 'Billing Email:',
            value: billingCustomerEmail,
            onchange: setBillingCustomerEmail,
            error: billingCustomerEmailError,
            errText: 'Billing Email cannot be empty and must be a valid email address.',
            descText: '',
            placeholderText: 'Enter your email address here.',
            maxLength: { maxLength: 200 }
        },
        {
            key: 'txtCustomerAddressLine1',
            name: 'customerAddressLine1',
            required: true,
            label: 'Address Line 1:',
            value: billingCustomerAddressLine1,
            onchange: setBillingCustomerAddressLine1,
            error: billingCustomerAddressLine1Error,
            errText: 'Address Line 1 cannot be empty.',
            descText: '',
            placeholderText: 'Enter your address here.',
            maxLength: { maxLength: 200 }
        },
        {
            key: 'txtCustomerAddressLine2',
            name: 'customerAddressLine2',
            required: false,
            label: 'Address Line 2:',
            value: billingCustomerAddressLine2,
            onchange: setBillingCustomerAddressLine2,
            errText: '',
            descText: '',
            placeholderText: '',
            maxLength: { maxLength: 200 }
        },
        {
            key: 'txtCustomerCity',
            name: 'customerCity',
            label: 'City:',
            required: true,
            value: billingCustomerCity,
            onchange: setBillingCustomerCity,
            error: billingCustomerCityError,
            errText: 'Billing City cannot be empty.',
            descText: '',
            placeholderText: 'Enter your city here.',
            maxLength: { maxLength: 100 }
        },
        {
            key: 'txtProvinceState',
            name: 'customerProvinceState',
            label: 'Province/State:',
            required: true,
            value: billingCustomerProvince,
            onchange: setBillingCustomerProvince,
            error: billingCustomerProvinceError,
            errText: 'Province/State cannot be empty.',
            descText: '',
            placeholderText: 'Enter your province/state here.'
        },
        {
            key: 'txtCustomerPostalCode',
            name: 'customerPostalCode',
            required: false,
            label: 'Postal/Zip Code:',
            value: billingCustomerPostalCode,
            onchange: setBillingCustomerPostalCode,
            error: billingCustomerPostalCodeError,
            errText: 'Postal/Zip Code cannot be empty.',
            descText: '',
            placeholderText: 'Enter your zip or postal code here.',
            maxLength: { maxLength: 15 }
        },
    ];

    const handleBillingInterval = async (val) => {

        console.log(val);
        setBillingInterval(val);
        var response = await getProductAndPriceIds(val);
        if (response) {
            setTotalPrice(response.pricePerUnit / 100);
        }
    }

    async function getProductAndPriceIds(val) {

        for (let i = 0; i < stripeProducts.length; i++) {
            if (company.companySize > stripeProducts[i].minUserCount) {
                setProduct(stripeProducts[i]);
                for (let x = 0; x < stripeProducts[i].productPrices.length; x++) {
                    if (stripeProducts[i].productPrices[x].billingInterval == val) {
                        return stripeProducts[i].productPrices[x];
                    }
                }
                break;
            }
        }
    }

    const validateCompanyInfoHandler = () => {
        var isError = false;

        if (billingCustomerName.trim() === '' ? setBillingCustomerNameError(true) : setBillingCustomerNameError(false));
        if (billingCustomerName.trim() === '') { isError = true; }
        if (billingCustomerPhone) {
            if (billingCustomerPhone.trim() === '' || billingCustomerPhone.length > 15 ? setBillingCustomerPhoneError(true) : setBillingCustomerPhoneError(false));
            if (billingCustomerPhone.trim() === '' || billingCustomerPhone.length > 15) { isError = true; }
        }
        else {
            setBillingCustomerPhoneError(true)
            isError = true;
        }
        if (billingCustomerEmail) {
            if (billingCustomerEmail.trim() === '' ? setBillingCustomerEmailError(true) : setBillingCustomerEmailError(false));
            if (billingCustomerEmail.trim() === '') { isError = true; }
            if (validateEmail(billingCustomerEmail.trim()) == false) { isError = true; setBillingCustomerEmailError(true) }
        }
        else {
            isError = true;
            setBillingCustomerEmailError(true);
        }
        if (billingCustomerAddressLine1.trim() === '' ? setBillingCustomerAddressLine1Error(true) : setBillingCustomerAddressLine1Error(false));
        if (billingCustomerAddressLine1.trim() === '') { isError = true; }
        if (billingCustomerCity.trim() === '' ? setBillingCustomerCityError(true) : setBillingCustomerCityError(false));
        if (billingCustomerCity.trim() === '') { isError = true; }

        if (billingCustomerCountry === 'US' || billingCustomerCountry === 'CA') {
            if (billingCustomerPostalCode.trim() === '') {
                setBillingCustomerPostalCodeError(true);
                isError = true;
            }
            else {
                setBillingCustomerPostalCodeError(false);
            }

            console.log(billingCustomerProvince)
            if (billingCustomerProvince.trim() === '' || billingCustomerProvince.trim() === 'null' || billingCustomerProvince == 'NA') {
                setBillingCustomerProvinceError(true);
                isError = true;
            }
            else {
                setBillingCustomerProvinceError(false);
            }
        }
        else {
            setBillingCustomerPostalCodeError(false);
            setBillingCustomerProvinceError(false);
        }

        return isError;
    }

    const handleGoBack = async () => {

        const response = await getAuditVaultUser(isAuthenticated, accounts[0]);
        if (response) {
            setUser(response);

            navigate('/MyCompanySetupCreate')
        }
    }

    const handleUpdate = async () => {

        var isError = validateCompanyInfoHandler();
        if (!isError && company.id) {
            try {
                var response = await updateCompany(
                    company.id,
                    billingCustomerName,
                    billingCustomerAddressLine1,
                    billingCustomerAddressLine2,
                    billingCustomerCity,
                    billingCustomerProvince,
                    billingCustomerCountry,
                    billingCustomerPhone,
                    billingCustomerPostalCode,
                    company.primaryContactFirstName,
                    company.primaryContactLastName,
                    company.primaryContactEmail,
                    company.primaryContactPhone,
                    company.companySize,
                    billingCustomerEmail,
                    1,
                    accounts[0].name,
                    accounts[0].username,
                    accounts[0].localAccountId,
                    accounts[0].tenantId,
                    null,
                    false
                );

                if (response) {
                    console.log("Update Company Response:");
                    console.log(response);
                    navigate('/MyCompanySetupComplete');
                }
            }
            catch (e) {
                console.log("ERROR: CompanySetup.handleUpdate");
                console.log(e);
                setServiceIsDownError(true);
            }
        }

    }


    async function fetchData() {
        try {
            if (loading) {
                var response = await getCompanyByAzureTenantId(accounts[0].tenantId);
                if (response) {

                    setCompany(response);
                    setBillingCustomerName(response.companyName);
                    setBillingCustomerEmail(response.billingEmail);
                    setBillingCustomerCountry(response.country);
                    setBillingCustomerPhone(response.phone);

                    if (response.streetAddress)
                        setBillingCustomerAddressLine1(response.streetAddress);
                    if (response.streetAddressLine2)
                        setBillingCustomerAddressLine2(response.streetAddressLine2);
                    if (response.city)
                        setBillingCustomerCity(response.city);
                    if (response.provinceState)
                        setBillingCustomerProvince(response.provinceState);
                    if (response.postalZip)
                        setBillingCustomerPostalCode(response.postalZip);

                    var stripeProductsResponse = await axios.get(`${WEB_API_URL}Billing/get-products`);
                    if (stripeProductsResponse) {
                        setStripeProducts(stripeProductsResponse.data);
                        setProduct(stripeProductsResponse.data[0]);

                        for (let i = 0; i < stripeProductsResponse.data.length; i++) {
                            if (response.companySize > stripeProductsResponse.data[i].minUserCount) {
                                setProduct(stripeProductsResponse.data[i]);
                                for (let x = 0; x < stripeProductsResponse.data[i].productPrices.length; x++) {
                                    if (stripeProductsResponse.data[i].productPrices[x].billingInterval == billingInterval) {
                                        setTotalPrice(stripeProductsResponse.data[i].productPrices[x].pricePerUnit / 100);
                                    }
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log("ERROR: MyCompanySetupCreate.fetchData")
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (loading) {
        return (<ProgressBar loading={loading}></ProgressBar>)
    }

    /* Company Setup Stages
            CompanyCreated = 0,         // first created by user 
            CompanySetupComplete = 1, 	// All company info is successfully created
            TenantCreated = 2,      	// Tenant Created
            GrantedAccess = 3,      	// Global Admin granted access to Audit Vault to Tenant
            TenantSetupComplete = 4, 	// Set up complete and first audit ran successfully
            PaymentComplete = 5,   		// Payment completed by Authorized user at organization
            PaymentCompleteManually = 6 // Payment Completed but done manually or delayed from Stripe (Bank Deposit)
    */

    if (!loading) {
        return (
            <>
                <Typography variant="h5" component="h5">Audit Vault for M365 - 3 Easy Steps to Sign Up</Typography>
                <div><br></br></div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} sx={{ paddingBottom: '3ch' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}

                    </Stepper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item sx={{ minWidth: 440, width: "50%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" component="div">2: Add Billing Info</Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Please provide your billing information.</Typography>
                                            <Box
                                                sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '45ch', marginLeft: '0px' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                {billingTextFields.map((txtfld) => (
                                                    <Typography key={txtfld.key} component="div">
                                                        <b>{txtfld.label}</b>
                                                        <br></br>
                                                        {txtfld.key != 'txtProvinceState' ?
                                                            <TextField required
                                                                name={txtfld.name}
                                                                placeholder={txtfld.placeholderText}
                                                                variant="outlined"
                                                                size="small"
                                                                style={{ width: 350 }}
                                                                InputLabelProps={{ shrink: true }}
                                                                value={txtfld.value}
                                                                onChange={event => txtfld.onchange(event.target.value)}
                                                                error={txtfld.error}
                                                                helperText={txtfld.error ? txtfld.errText : txtfld.descText}
                                                                inputProps={txtfld.maxLength} />
                                                            :
                                                            <TextField
                                                                required
                                                                select
                                                                value={billingCustomerProvince}
                                                                onChange={event => setBillingCustomerProvince(event.target.value)}
                                                                name={txtfld.name}
                                                                variant="outlined"
                                                                size="small"
                                                                style={{ width: 350 }}
                                                                InputLabelProps={{ shrink: true }}
                                                                inputRef={provState}
                                                                error={txtfld.error}
                                                                helperText={txtfld.error ? txtfld.errText : txtfld.descText}
                                                            >
                                                                {PROVINCE_STATES.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value} >
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        }
                                                    </Typography>
                                                ))}
                                                <Typography key='lblCountry' component="div">
                                                    <b>Country:</b>
                                                    <br></br>
                                                    <TextField required
                                                        select
                                                        value={billingCustomerCountry}
                                                        onChange={event => setBillingCustomerCountry(event.target.value)}
                                                        name="country"
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ width: 350 }}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputRef={country} >
                                                        {COUNTRIES.map((option) => (
                                                            <MenuItem key={option.label} value={option.value} >
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <Typography component="div"> <Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleUpdate()}>Save and Continue</Button> </Typography>
                                        </CardActions>
                                    </Card>
                                </Paper>
                            </Grid>
                            <Grid item sx={{ minWidth: 440, width: "50%", padding: 2 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" component="div">Selected Subscription Plan</Typography>
                                        <hr />
                                        <>
                                            <Typography variant="h6" component="div">{product.name}</Typography>
                                            <Typography component="div">{product.description}</Typography>
                                            <Typography><br />Estimated Total Price: {currencyFormatter(totalPrice)}/{billingInterval}</Typography>
                                            <Typography component="div" sx={{ fontSize: '0.8rem' }}>{product.productHighlights}</Typography>
                                            <Typography component="div"><br></br></Typography>
                                            <Typography component="div" sx={{ paddingBottom: 1 }}>
                                                <Typography sx={{ paddingRight: 3 }}>
                                                    Billing Frequency:
                                                </Typography>
                                                <Typography>
                                                    <TextField required
                                                        select
                                                        value={billingInterval}
                                                        onChange={event => handleBillingInterval(event.target.value)}
                                                        name="billingInterval"
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        style={{ width: 125 }}
                                                        InputLabelProps={{ shrink: true }} >
                                                        <MenuItem key='year' value='year'>Yearly</MenuItem>
                                                        <MenuItem key='month' value='month'>Monthly</MenuItem>
                                                    </TextField>
                                                </Typography>
                                            </Typography>
                                        </>
                                        <hr />
                                        <Shop2Icon style={{ color: 'green' }} />&nbsp; <b><u><span style={{ color: 'green' }}>Try free for 14 days!</span></u></b>&nbsp; Your free trial period is for 14 days after which payment will be due.
                                        <br /><br /><b>Optional:</b> Concierge setup and quick-start service is available if you prefer concierge service to get started (get your solution setup with training for your Administrator(s)).&nbsp; <a href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS}?subject=${encodeURIComponent('Concierge Setup Inquiry for Audit Vault for M365')}`}>Contact us</a> to find out more and schedule a time if you require this add-on service.
                                        <br />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography component="div" sx={{ textAlign: 'left' }}> <Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleGoBack()}>Back</Button> </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography component="div" sx={{ textAlign: 'right' }}> <Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => handleUpdate()}>Next</Button> </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    };
}

export default MyCompanySetupUpdate;