// React Components
import * as React from 'react';
import { useState } from "react";

// Material UI Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// Audit Vault Utilities
import {formatDate} from '../../utilities/common-date-utils'

const columns = [
  { id: 'id', label: 'Id', minWidth: 50, align: 'left' },
  { id: 'companyId', label: 'Company Id', minWidth: 50, align: 'left' },
  { id: 'creationTime', label: 'Creation Time', minWidth: 50, align: 'left' },
  { id: 'operation', label: 'Operation', minWidth: 50, align: 'left' },
  { id: 'userEmail', label: 'User', minWidth: 100, align: 'left' },
  { id: 'userMicrosoftId', label: 'User Id', minWidth: 50, align: 'left' },
  { id: 'additionalDetails', label: 'Details', minWidth: 150, align: 'left'},
];

const rows = [];


export default function AuditVaultUserActivityReportList(props) {

  const { logs } = props;

  // Optional table - settings.
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

  
  // Function for Sorting column headers.
  function getComparator(sortConfig) {
    return (a, b) => {
      if (!sortConfig) {
        return 0;
      }        
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    };
  }

  // Function for Sorting column headers.  
  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }

  // Column header sort function.
  const requestSort = (key) => {
      let direction = 'asc';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
      }
      setSortConfig({ key, direction });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (

      <Paper sx={{ width: '100%' }}>
      <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
              onClick={() => requestSort(column.id)}
            >
              {column.label}
              {sortConfig && sortConfig.key === column.id && (
                <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
              )}
            </TableCell>
          ))}
          
        </TableRow>
      </TableHead>
      <TableBody>
        {stableSort(logs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                {columns.map((column) => {
                  const value = row[column.id];
                  
                  if (column.id == 'creationTime') {
                    return (
                      <TableCell key={column.id + row.id} align={column.align} minWidth={column.minWidth}>
                        {formatDate(value)}
                      </TableCell>);
                  }
                  else
                  {
                    return (
                        <TableCell key={column.id + row.id} align={column.align}>
                          {value}
                        </TableCell>);
                  }
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
    <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="span"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
    </Paper>
  );
}
