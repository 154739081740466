// React Components
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

// Audit Vault Components
import ProgressBar from '../components/common/ProgressBar';

// Audit Vault Utiltities
import { encryptKey } from '../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
import { LINK_TO_OUR_WEBSITE_HELP_CENTER } from '../constants/constants-links';

function GrantAccess(props) {

    const { user } = props;

    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);

    const adminConsent = queryParams.get("admin_consent");
    const tenantId = queryParams.get("tenant");
    const err = queryParams.get("error");
    const errDesc = queryParams.get("error_description");

    console.log(`Admin Consent: ${adminConsent}`);
    console.log(`Tenant Id: ${tenantId}`);
    console.log(`Error: ${err}`);
    console.log(`Error Description: ${errDesc}`);
    console.log(`User Id: ${user.id}`);
    console.log(`User Company Id: ${user.companyId}`);

    async function getData() {

        if (loading && isAuthenticated && user) {
            console.log(`Company Id: ${user.companyId}`);

            var payload = `{
                "microsoftGraphId": "${await encryptKey(user.microsoftGraphId)}",
                "displayName": "${await encryptKey(accounts[0].name)}",
                "email": "${await encryptKey(accounts[0].username)}",
                "azureTenantId": "${await encryptKey(tenantId)}"
                }`;


            try {
                var updateResponse = await axios.put(`${WEB_API_URL}Company/UpdateCompanySetupToAdminConsentGranted`, null, {
                    params: {
                        key: payload,
                    }
                })

                if (updateResponse != null) {

                    console.log(`Admin Consent granted!`)
                    navigate(`/mycompany`);
                }
            }
            catch (e) {
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    if (adminConsent == 'True' && err == null) {
        useEffect(() => {
            if (loading && user) {
                getData();
            };
        }, []);
    }

    if (err) {
        useEffect(() => {
            setLoading(false);
        }, []);
    }

    return (
        <>
            <ProgressBar message="Granting Admin Consent ..." loading={loading} />
            {err && (
                <Alert severity="error">
                    <AlertTitle>Unable to Obtain M365 Admin Consent.</AlertTitle>
                    <br />
                    <div>Error: <strong>{err}</strong></div>
                    <div>{errDesc}</div>
                    <div>Reason: This App requires access to your M365 Tenant to access and manage your audit information - but was not successfully granted access.</div>
                    <div>Resolution: Your Microsoft 365 Global Administrator needs to consent + grant access so this App can securely manage your audit information.</div>
                    <br /><a href={LINK_TO_OUR_WEBSITE_HELP_CENTER} target="_blank" rel="noopener noreferrer">Help Center: See "Learn about Granting Admin Consent" in our getting started guides</a>
                    <br /><br /><Link onClick={() => navigate(`/`)} component="button">Go back to Home Page and try again</Link>

                </Alert>)}
        </>
    );
}

export default GrantAccess;