//React Components
import axios from "axios";

// Audit Vault Utilities
import { encryptKey } from './common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


// Health Stats will return only results needed based off the page user has rendered
//Reports = 1,
//SharePointReports = 2,
//ExchangeReports = 3,
//EntraIdReports = 4,
//TeamsReports = 5,
//ReportStats= 6,
// ReportsInsightsSearch = 7  
// ReportsThreatIntelligence = 8

export async function getHealthStatsByTenantId(tenantId, reportPageId, user) {

    var usrEmail = "N/A";
    if (user.userEmail)
        usrEmail = user.userEmail;

    var payload = `{
        tenantId: ${tenantId},
        userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
        userEmail: "${await encryptKey(usrEmail)}",
        reportPageId: ${reportPageId},             
    }`;

    if (payload) {
        var result = await axios.get(`${WEB_API_URL}HealthStats/GetByTenantId`, {
            params: {
                key: `${payload}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getTenantDBStats(user, tenantId, getUsersCount) {

    var usrEmail = "N/A";
    if (user.userEmail)
        usrEmail = user.userEmail;

    var payload = `{
        tenantId: ${tenantId},
        userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
        userEmail: "${await encryptKey(usrEmail)}",
        getCompanyUsersCount: ${getUsersCount},             
    }`;

    if (payload) {
        var result = await axios.get(`${WEB_API_URL}HealthStats/GetTenantDBStats`, {
            params: {
                key: `${payload}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}
