// React Components
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Link from '@mui/material/Link';

// Material UI Components
import { Grid, Paper } from '@mui/material';

// Audit Vault Components
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../components/common/DisplayBreadCrumb';
import AdminControlDatabaseSummary from "../components/AdminControlDatabaseSummary";
import HeartbeatForSearch from "./admin/HeartbeatForSearch";
import AdminJobStats from "../components/jobs/AdminJobStats";
import AdminOrchestratorStats from "../components/AdminOrchestratorStats";

// Audit Vault Utilities

function AdminHome(props) {

    const { user } = props;
    const navigate = useNavigate();
    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "Welcome to Audit Vault for M365 - Admin Pages";
    const theSectionDesc = "The Admin Pages and related functionality are accessible only by Audit Vault Admin Users.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
    ];


    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <Grid container spacing={2}>
                <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', textAlign: 'left' }}>
                        <h6>Admin Function Quick Links</h6>
                        <Link onClick={() => navigate(`/Admin/CompanyListAdmin`)} component="button">Company List</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/JobOrchestrators`)} component="button">System Audit Job Orchestrators List</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/HeartbeatForSearch`)} component="button">System Search Orchestrators List</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/MessageCenterAdmin`)} component="button">Admin Message Center</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/systemadminusers`)} component="button">System Administrators List</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/SystemEmails`)} component="button">System Emails List</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/system-diagnostics`)} component="button">System Diagnostics Report</Link>
                        <br />
                        <Link onClick={() => navigate(`/Admin/system-user-activity`)} component="button">System User Activity Logs</Link>
                        <br />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                        <AdminJobStats user={user} />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                        <AdminOrchestratorStats user={user} />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        <HeartbeatForSearch user={user} previewOnly={true} />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                        <h6>Database Health Summary - System Control Database</h6>
                        <AdminControlDatabaseSummary user={user} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
export default AdminHome;