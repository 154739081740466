// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

// Material UI Components
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Audit Vault Components
import AccessDenied from '../../components/common/AccessDenied';
import ProgressBar from '../../components/common/ProgressBar';
import TenantList from "../../components/TenantList";
import ServiceIsDown from '../../components/common/ServiceIsDown';
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../../utilities/common-company";




function TenantsAdmin(props) {

    // Component Constants
    const { user } = props;

    const navigate = useNavigate();
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [company, setCompany] = useState("");

    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "Tenant List";
    const theSectionDesc = "Below is a list of the Tenants for the specified Company.";

    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Company List', link: '/Admin/CompanyListAdmin' },
        { name: 'Tenants', link: `/Admin/tenant-admin/${companyId}` }
    ];

    // Component Functions
    async function fetchData() {
        try {
            if (user && user.isSystemAdmin) {

                if (loading && companyId) {
                    var response = await getCompanyByCompanyId(companyId, setServiceIsDownError);
                    if (response != null) {
                        setCompany(response);
                    }
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>)
    }

    return (
        <>
            <ProgressBar message="Loading ..." loading={loading} />

            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <div>
                {company && (
                    <>
                        <Typography variant="h6" component="h6">Tenants for Company: {company.companyName}</Typography><br />
                        <TenantList company={company}
                            setCompany={setCompany}
                            isAdminPage={isAdminPage}
                            user={user}></TenantList>
                    </>
                )}

            </div>
            <div><br></br></div>
            <div>
                <Button
                    variant="contained"
                    type="button"
                    size="small"
                    onClick={() => navigate(`/Admin/tenant-create/${company.id}`)}
                >
                    Add A New Tenant
                </Button>
            </div>
        </>
    );
}
export default TenantsAdmin;