// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

// Audit Vault Constants
import {REPORT_PERIOD_LIST} from "../../constants/constants-reportperiods";


// Function to insert our tenant dropdown list (textfield).
export default function PeriodDropDown(props){

    const { selectedPeriodOption, 
        handlePeriodOptionChange} = props;

    return(
        <Grid container spacing={2} padding={0} paddingBottom={2}>
        <Grid item minWidth={100} style={{ display: 'flex', alignItems: 'center' }}><b>View Past:</b></Grid>
        <Grid item>
            <TextField
                    required
                    select
                    id="period-select"
                    value={selectedPeriodOption}
                    onChange={handlePeriodOptionChange}
                    helperText=""
                    variant="outlined"
                    size="small"
                    style={{ width: 128 }}
                    InputLabelProps={{ shrink: true }}
                >
                    {REPORT_PERIOD_LIST && REPORT_PERIOD_LIST.map((period) => (
                        <MenuItem key={period.id} value={period}>
                            {period.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
    </Grid>)
};