// React Components
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material UI Components


// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import ProgressBar from "../../components/common/ProgressBar";
import UserSearchAssignRoleForm from "../../components/role/UserSearchAssignRoleForm";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Common
import { verifyUserCanAccessCompanyData } from '../../utilities/common-user-utils';


// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
const theSectionTitle = "Assign System Admin Roles";
const theSectionDesc = "Assign a Search for a user and assign them full access to the Audit Vault System.";
const isAdminPage = true;

function SystemAdminAssignRole(props) {

    const { user } = props;
    const { roleId } = useParams();

    // Component Constants
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'System Administrators List', link: '/Admin/SystemAdminUsers' },
        { name: 'Assign System Admin Access', link: '/admin/assign-systemadmin-users' }
    ];

    async function fetchData() {
        if (loading && user) {
            if (verifyUserCanAccessCompanyData(user)) {
                setLoading(false);
            }
            else {
                setAccessDenied(true)
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();

    }, []);

    // Component UI
    if (accessDenied) {
        return <AccessDenied></AccessDenied>
    }

    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (!loading) {
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <UserSearchAssignRoleForm user={user} tenantId={0} roleId={0} sourcePage={"SystemAdmin"} />
            </>
        )
    }

}

export default SystemAdminAssignRole;