// React Components
import { useEffect, useState } from "react";

// Material UI Components
import { Grid, Paper } from '@mui/material';

// Audit Vault Components
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../components/common/DisplayBreadCrumb';
import MessageListPreview from "../components/MessageListPreview";
import ProductFeedback from "./ProductFeedback";
import SupportTicket from './SupportTicket';
import HelpCenter from './HelpCenter';

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { getAuditVaultUsersCompanyAdminsByCompanyId } from "../utilities/common-user-utils";

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function SupportHome(props) {

    // Component Constants
    const { user } = props;

    const [company, setCompany] = useState("");
    const [companyAdminUsers, setCompanyAdminUsers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    // Section titles.
    const [isAdminPage] = useState(false);
    const theSectionTitle = "Support Home";
    const theSectionDesc = "Welcome to your Support Dashboard.  This is where you can locate the resources you need to find answers to your questions, review status of planned maintenance and announcements, contact us, or provide us your valuable product feature feedback.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Support', link: '/Support/SupportHome' },
    ];


    // Component Functions
    useEffect(() => {
        async function fetchData() {
            try {
                if (user) {
                    const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);
                        var responseCa = await getAuditVaultUsersCompanyAdminsByCompanyId(user.companyId);
                        if (responseCa) {
                            setCompanyAdminUsers(responseCa);
                        }
                    }
                }
            }
            catch (e) {
                console.log("ERROR: SupportHome.fetchData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <Grid container spacing={2}>
                <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        {/* Overview Component */}
                        <h6>Overview</h6>
                        {
                            company.companyName && company.companyName.length > 0 ?
                                (
                                    <>
                                        Audit Vault for M365 is managing your organization's audit information.  You are logged in with Company:
                                        <b>&nbsp; {company.companyName}</b>
                                        <br /><br />Your Company Administrators (ex. for support with your Company or Tenant security access role assignments in this application):
                                        <ul>
                                            {companyAdminUsers.map((user, index) => (
                                                <li key={index}>
                                                    <a href={`mailto:${user.userEmail}?subject=${encodeURIComponent('User requesting assistance with Audit Vault for M365.')}`}>{user.displayName}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )
                                :
                                (
                                    <>
                                        Your Company has not yet been setup to use Audit Vault for M365.
                                        <br /><a href="/">Click here</a> to setup your Company in Audit Vault for M365.
                                        <br /><br />
                                    </>
                                )
                        }
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        {/* Message Center Component */}
                        <h6>Message Center</h6>
                        <MessageListPreview theCompanyId={company.id}></MessageListPreview><br />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        {/* Product Feedback */}
                        <ProductFeedback user={user} previewOnly={true} />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        {/* Help Center Documentation section */}
                        <HelpCenter user={user} previewOnly={true} />
                    </Paper>
                </Grid>
                <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                        {/* Contact Support */}
                        <SupportTicket user={user} previewOnly={true} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
export default SupportHome;