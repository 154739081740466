// React Components
import * as React from 'react';

// Material UI Components
import { Doughnut} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Legend, Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 

// Audit Vault Components

// Audit Vault Utilities


// Note, below is the tree-shakable way to decrease the bundle size.
ChartJS.register(ArcElement, Title, Legend, Tooltip);
ChartJS.register(ChartDataLabels);


export default function SearchResultsByWorkloadSource(props) {

    // Component Constants
    const { totalResults, totalSharePointResults, totalExchangeResults, totalEntraIDResults, totalTeamsResults } = props;    
    var theGraphTitle = "Summary of Search Results (total results=" + totalResults + ")";                                  
    
    const data = {
        labels: [
        'SharePoint',
        'Exchange',
        'Entra ID',
        'Teams'
        ],
        datasets: [{          
          data: [totalSharePointResults, totalExchangeResults, totalEntraIDResults, totalTeamsResults],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(18, 211, 74)'
          ],
          hoverOffset: 4
        }]
    };

    const options = {
        // Chart.js options (if needed)
        responsive: true,         
        aspectRatio: 1.5,       
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const dataset = ctx.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, dataPoint) => acc + dataPoint, 0);
                const percentage = ((value * 100) / total).toFixed(1);
                return percentage + '%';
              },
              color: '#FFF', 
              font: {
                weight: 'bold',
              },
              anchor: 'center',
              align: 'center',
            },
            legend: {
                display: true,
                position: 'right',                                                
                labels: {
                    fontSize: 12,                     
                    responsiveFontSize: true,                 
                    // Include counts for each item in the legend
                    generateLabels: (chart) => {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map((label, index) => {
                                const value = (data.datasets[0].data[index]);
                                return {
                                    text: `${label}: ${value}`,
                                    fillStyle: data.datasets[0].backgroundColor[index],
                                };
                            });
                        }
                        return [];
                    },
                },
            },
          },
    };

    
    // Component Functions

    // Component UI

    return (
        <>
          <div style={{ height: '200px', width: '460px' }}>
          <h6>{theGraphTitle}</h6>  
          <Doughnut data={data} options={options} />             
          </div>        
        </>
    );

}
