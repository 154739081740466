const API_ENDPOINTS = [
    {
        value: '0',
        label: 'Enterprise'
    },
    {
        value: '1',
        label: 'GccGovernment'
    },
    {
        value: '2',
        label: 'GccHighGovernment'
    },
    {
        value: '3',
        label: 'DodGovernment'
    },
];

export { API_ENDPOINTS };
