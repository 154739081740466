
const SEARCH_STATUS_QUEUED = 0;
const SEARCH_STATUS_INPROGRESS = 1;
const SEARCH_STATUS_FAILED = 2;
const SEARCH_STATUS_COMPLETED = 3;
const SEARCH_STATUS_DELETED = 4;

const SEARCH_STATUS_LOOKUP = [
    { status: SEARCH_STATUS_QUEUED, label: 'Queued' },
    { status: SEARCH_STATUS_INPROGRESS, label: 'In Progress' },
    { status: SEARCH_STATUS_FAILED, label: 'Failed' },
    { status: SEARCH_STATUS_COMPLETED, label: 'Completed' },
    { status: SEARCH_STATUS_DELETED, label: 'Deleted' },
];

export { SEARCH_STATUS_LOOKUP, SEARCH_STATUS_QUEUED,  SEARCH_STATUS_INPROGRESS, SEARCH_STATUS_FAILED, SEARCH_STATUS_COMPLETED, SEARCH_STATUS_DELETED };