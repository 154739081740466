import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatInvoiceDate } from '../../../utilities/common-date-utils';
import currencyFormatter from '../../../utilities/common-currency-utils';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 12,
        width: '100%',
    },
    invoiceRowContainer: {
        flexDirection: 'row',
        textAlign: 'left',
        width: '50%'
    },
    invoiceSummaryContainer: {
        textAlign: 'left',
        width: '50%',
        backgroundColor: 'lightyellow',
        borderTop: 1,
        borderTopColor: 'lightgray',
        borderBottom: 1,
        borderBottomColor: 'lightgray',
    },
    invoiceRowContainerCol1: {
        width: 400
    },
    invoiceRowContainerCol2: {
        width: 500
    },
    invoiceNo: {
        fontSize: 11,
        fontWeight: 900,
    },
    invoiceText: {
        fontSize: 11,
    },
    billingSummaryHeader: {
        fontSize: 14,
        margin: 5,
    },
    billingSummaryDetailsContainer: {
        flexDirection: 'row',
        margin: 5,
    },
    billingSummaryCol1: {
        width: 125
    },
  });


  const InvoiceNumber = ({invoice}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
            <View style={styles.invoiceRowContainer}>
                <View style={styles.invoiceRowContainerCol1}>
                    <Text style={styles.invoiceNo}>Invoice Number: </Text>
                    <Text style={styles.invoiceNo}>Date of Issue: </Text>
                    <Text style={styles.invoiceNo}>Due Date: </Text>
                    <Text style={styles.invoiceNo}>Currency: </Text>
                </View>
                <View style={styles.invoiceRowContainerCol2}>
                    <Text style={styles.invoiceNo}>{invoice.number ? invoice.number : "N/A"}</Text>
                    <Text style={styles.invoiceNo}>{formatInvoiceDate(invoice.dateCreated)}</Text>
                    <Text style={styles.invoiceNo}>{formatInvoiceDate(invoice.dateCreated)}</Text>
                    <Text style={styles.invoiceNo}>{invoice.currency.toUpperCase()}</Text>
                </View>
            </View>
            <View style={styles.invoiceSummaryContainer}>
                <Text style={styles.billingSummaryHeader}>Billing Summary:</Text>
                <View style={styles.billingSummaryDetailsContainer}>
                <View style={styles.billingSummaryCol1}>
                    <Text style={styles.invoiceText}>Status: </Text>
                    <Text style={styles.invoiceText}>Amount Due: </Text>
                    <Text style={styles.invoiceText}>Amount Paid: </Text>
                    <Text style={styles.invoiceText}>Amount Remaining: </Text>
                </View>
                <View>
                    <Text style={styles.invoiceText}>{invoice.status.toUpperCase()}</Text>
                    <Text style={styles.invoiceText}>{currencyFormatter(invoice.amountDue/100)}</Text>
                    <Text style={styles.invoiceText}>{currencyFormatter(invoice.amountPaid/100)}</Text>
                    <Text style={styles.invoiceText}>{currencyFormatter(invoice.amountRemaining/100)}</Text>
                </View>
                </View>
            </View>
            </View>
        </Fragment>
  );
  
  export default InvoiceNumber