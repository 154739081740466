// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Audit Vault Components
import AccessDenied from '../common/AccessDenied';
import DisplayBreadCrumb from "../common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../common/DisplaySectionTitleAndDescription";
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from '../common/ProgressBar';

// Audit Vault Constants
import { COMPANY_SIZES } from "../../constants/constants-companysizes";
import { PROVINCE_STATES } from "../../constants/constants-provincestates";
import { COUNTRIES } from "../../constants/constants-countries";
import {COMPANY_SETUP_STAGES} from "../../constants/constants-companysetupstages";

// Audit Vault Utilities
import {validateEmail} from "../../utilities/common-user-utils";
import { getCompanyByCompanyId, updateCompany } from '../../utilities/common-company';


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function CompanyForm(props) {

  const { user, companyId, isAdminPage } = props;  

  // Component Constants
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddressLine2, setStreetAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [provinceState, setProvinceState] = useState('NA');
  const [country, setCountry] = useState('CA');
  const [phone, setPhone] = useState('');
  const [postalZip, setPostalZip] = useState('');
  const [primaryContactFirstName, setPrimaryContactFirstName] = useState('');
  const [primaryContactLastName, setPrimaryContactLastName] = useState('');
  const [primaryContactEmail, setPrimaryContactEmail] = useState('');
  const [primaryContactPhone, setPrimaryContactPhone] = useState('');
  const [companySize, setCompanySize] = useState(100);
  const [billingEmail, setBillingEmail] = useState('');
  const [authorizedByDisplayName, setAuthorizedByDisplayName] = useState('');
  const [authorizedByEmail, setAuthorizedByEmail] = useState('');
  const [authorizedByGraphId, setAuthorizedByGraphId] = useState('');
  const [authorizedByTenantId, setAuthorizedByTenantId] = useState('');
  const [setupStageComplete, setSetupStageComplete] = useState(0);
  const [setupStageCompleteDate, setSetupStageCompleteDate] = React.useState(dayjs('1900-01-01'));
  const [accountCreatedDate, setAccountCreatedDate] = React.useState(dayjs());
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [enableSelfHostedDbOption, setEnableSelfHostedDbOption] = useState(false);

  const [companyNameError, setCompanyNameError] = useState(false);
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [primaryContactEmailError, setPrimaryContactEmailError] = useState(false);
  const [primaryContactPhoneError, setPrimaryContactPhoneError] = useState(false);
  const [authByError, setAuthByError] = useState(false);
  const [authByTenantIdError, setAuthByTenantIdError] = useState(false);
  const [authByGraphIdError, setAuthByGraphIdError] = useState(false);
  const [billingEmailError, setBillingEmailError] = useState(false);
  

  // Section titles.   
  const updateSectionTitle = "Update Company";
  const createSectionTitle = "New Company";
  const theSectionDesc = "Below are the editable fields.  Please ensure you provide complete and accurate information.";    
  // Breadcrumb definitions.
  if(isAdminPage == true) {
    var updateBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin' },
    { name: 'Company List', link: '/Admin/CompanyListAdmin' },
    { name: 'Update Company', link: `/Admin/company-update/${companyId}`}
    ];    
  }
  else {
    var updateBreadcrumbPath = [
      { name: 'Home', link: '/' },
      { name: 'Company', link: '/MyCompany' },      
      { name: 'Update Company', link: `/MyCompany/company-update/${companyId}`}
      ];
  }
  const createBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin' },
    { name: 'Company List', link: '/Admin/CompanyListAdmin' },
    { name: 'Add New Company', link: `/Admin/Company-create`}
  ];
  

  if (companyId && user)
  {
    useEffect(() => {
      
      async function fetchData() {
        try
        {
          if (user.companyId == companyId || user.isSystemAdmin)
          {
            const response = await getCompanyByCompanyId(companyId, setServiceIsDownError);
            if (response) {
              setCompanyName(response.companyName);
              setCompanySize(response.companySize);
              setStreetAddress(response.streetAddress);
              setStreetAddressLine2(response.streetAddressLine2);
              setCity(response.city);

              if (response.provinceState)
                  setProvinceState(response.provinceState);
              if (response.postalZip)
                setPostalZip(response.postalZip);
              setCountry(response.country);
              if (response.phone)
                setPhone(response.phone);
              setPrimaryContactFirstName(response.primaryContactFirstName);
              setPrimaryContactLastName(response.primaryContactLastName);
              setPrimaryContactEmail(response.primaryContactEmail);
              setPrimaryContactPhone(response.primaryContactPhone);
              setSetupStageComplete(response.setupStageComplete);
              setSetupStageCompleteDate(dayjs(response.setupCompleteDate))
              setBillingEmail(response.billingEmail);
              setAuthorizedByDisplayName(response.authorizedByDisplayName);
              setAuthorizedByEmail(response.authorizedByEmail);
              if (response.authorizedByGraphId)
                setAuthorizedByGraphId(response.authorizedByGraphId);
              if (response.authorizedByTenantId)
                setAuthorizedByTenantId(response.authorizedByTenantId);
              setAccountCreatedDate(dayjs(response.accountCreatedDate));
              setStripeCustomerId(response.stripeCustomerId);
              setEnableSelfHostedDbOption(response.enableSelfHostedDbOption);
            }
          }
          else
          {
            setAccessDenied(true);
          }
        }
        catch (e)
        {
          console.log("CompanyForm: fetchData:");
          console.log(e);
        }
        finally
        {
          setLoading(false);
        }
      }
      fetchData();
        
    }, []);
  }
  else
  {
      // New Company
      useEffect(() => {
        setLoading(false);
      }, []);
  };

 

  const companyFields = [
    
    { key: 'txtCompanyName', 
      name: 'companyName', 
      type: 'textField',
      required: true,
      label: 'Company Name:',  
      value: companyName,
      onchange: setCompanyName, 
      error: companyNameError, 
      errText: 'Company Name cannot be empty.', 
      descText: '', 
      placeholderText: 'Enter your company name here.',
      editableFlag: true,
      maxLength: { maxLength: 200 }
    },

    { key: 'txtCompanySize', 
      name: 'companySize', 
      type: 'dropDown',
      required: true,
      label: 'Company Size:', 
      value: companySize,
      onchange: setCompanySize, 
      menuItem: COMPANY_SIZES, 
      editableFlag: true ,
      maxLength: { maxLength: 10 }
    },
      
    { key: 'txtStreetAddress', 
      name: 'streetAddress', 
      type: 'textField',
      required: true,
      label: 'Address Line 1:', 
      value: streetAddress,
      onchange: setStreetAddress, 
      error: streetAddressError, 
      errText: 'Address cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your business address here.',
      editableFlag: true,
      maxLength: { maxLength: 200}
    },

    { key: 'txtStreetAddressLine2', 
      name: 'streetAddressLine2', 
      type: 'textField',
      required: true,
      label: 'Address Line 2:', 
      value: streetAddressLine2,
      onchange: setStreetAddressLine2, 
      descText: '',
      editableFlag: true,
      maxLength: { maxLength: 200}},
  
    { key: 'txtCity', 
      name: 'city', 
      type: 'textField',
      required: true,
      label: 'City:',  
      value: city,
      onchange: setCity, 
      error: cityError, 
      errText: 'City cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your City here.',
      editableFlag: true,
      maxLength: { maxLength: 100} },
  
    { key: 'txtProvinceState', 
      name: 'provinceState', 
      type: 'dropDown',
      required: true,
      label: 'Province/State:', 
      value: provinceState,
      onchange: setProvinceState, 
      menuItem: PROVINCE_STATES, 
      editableFlag: true,
      maxLength: { maxLength: 50} },
  
    { key: 'txtPostalCodeZip', 
      name: 'postalCodeZip', 
      type: 'textField',
      required: false,
      label: 'Postal Code/Zip:', 
      value: postalZip,
      onchange: setPostalZip, 
      error: '', 
      errText: '', 
      descText: '',
      placeholderText: '', 
      editableFlag: true,
      maxLength: { maxLength: 15} },
    
    { key: 'txtCountry', 
      name: 'country', 
      type: 'dropDown',
      required: true,
      label: 'Country:', 
      value: country,
      onchange: setCountry, 
      menuItem: COUNTRIES, 
      editableFlag: true,
      maxLength: { maxLength: 100} },
    
    { key: 'txtCompanyPhone', 
      name: 'phone', 
      type: 'textField',
      required: true,
      label: 'Company Phone:', 
      value: phone,
      onchange: setPhone, 
      error: phoneError, 
      errText: 'Company phone number cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your companys phone number here.',
      editableFlag: true,
      maxLength: { maxLength: 15} },

    { key: 'txtSetUpStageComplete', 
      name: 'setupStage', 
      type: 'dropDown',
      required: true,
      label: 'Setup Stage Complete:', 
      value: setupStageComplete,
      onchange: setSetupStageComplete, 
      menuItem: COMPANY_SETUP_STAGES, 
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 20} },

    { key: 'txtSetupStageCompletedDate', 
      name: 'setupStageCompletedDate', 
      type: 'dateField',
      required: true,
      label: 'Setup Stage Completed Date:', 
      value: dayjs(setupStageCompleteDate),
      onchange: setSetupStageCompleteDate, 
      error: '', 
      errText: '', 
      descText: '',
      placeholderText: 'Enter the company billing email address here.' , 
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 20} },

    { key: 'txtBillingEmail', 
      name: 'billingEmail', 
      type: 'textField',
      required: true,
      label: 'BillingEmail:', 
      value: billingEmail,
      onchange: setBillingEmail, 
      error: billingEmailError, 
      errText: 'Missing or invalid Billing Email', 
      descText: '',
      placeholderText: 'Enter the Customer Id from Stripe here.',
      editableFlag: true,
      maxLength: { maxLength: 200} },

    { key: 'txtStripeCustomerId', 
      name: 'stripeCustomerId', 
      type: 'textField',
      required: false,
      label: 'Stripe Customer Id:', 
      value: stripeCustomerId,
      onchange: setStripeCustomerId, 
      error: '', 
      errText: '', 
      descText: '',
      placeholderText: 'Enter the Customer Id from Stripe here.',
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 100} },

    { key: 'txtAuthorizedBy', 
      name: 'authorizedBy', 
      type: 'textField',
      required: true,
      label: 'Authorized By Email:', 
      value: authorizedByEmail,
      onchange: setAuthorizedByEmail, 
      error: authByError, 
      errText: 'Authorized By cannot be empty', 
      descText: '',
      placeholderText: 'Enter the user email address of the account that authorized the use of Audt Vault.',
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 200} },
      
      { key: 'txtAuthorizedByGraphId', 
      name: 'authorizedByGraphId', 
      type: 'textField',
      required: true,
      label: 'Authorized By Graph Id:', 
      value: authorizedByGraphId,
      onchange: setAuthorizedByGraphId, 
      error: authByGraphIdError, 
      errText: 'Authorized By Microsoft Graph Id cannot be empty', 
      descText: '',
      placeholderText: 'Enter the microsoft graph Id of the user that authorized the use of Audt Vault.',
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 200} },

    { key: 'txtAuthorizedByTenantId', 
      name: 'authorizedByTenantId', 
      type: 'textField',
      required: true,
      label: 'Authorized By Tenant Id:', 
      value: authorizedByTenantId,
      onchange: setAuthorizedByTenantId, 
      error: authByTenantIdError, 
      errText: 'Authorized By Tenant Id cannot be empty', 
      descText: '',
      placeholderText: 'Enter the tenant Id that the account that authorized the use of Audt Vault.',
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 100} },

    { key: 'txtAccountCreatedDate', 
      name: 'accountCreatedDate', 
      type: 'dateField',
      required: true,
      label: 'Account Created Date:', 
      value: dayjs(accountCreatedDate),
      onchange: setAccountCreatedDate, 
      error: '', 
      errText: '', 
      descText: '',
      placeholderText: '',
      editableFlag: isAdminPage ? true : false,
      maxLength: { maxLength: 50} },

    { key: 'txtContactFirstName', 
      name: 'firstName', 
      type: 'textField',
      required: true,
      label: 'Primary Contact First Name:', 
      value: primaryContactFirstName,
      onchange: setPrimaryContactFirstName, 
      error: firstNameError, 
      errText: 'First Name cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your First name here.',
      editableFlag: true,
      maxLength: { maxLength: 100} },

    { key: 'txtContactLastName', 
      name: 'lastName', 
      type: 'textField',
      required: true,
      label: 'Primary Contact Last Name:', 
      value: primaryContactLastName,
      onchange: setPrimaryContactLastName, 
      error: lastNameError, 
      errText: 'Last Name cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your Last name here.',
      editableFlag: true,
      maxLength: { maxLength: 100} },

    { key: 'txtContactEmail', 
      name: 'contactEmail', 
      type: 'textField',
      required: true,
      label: 'Primary Contact Email:', 
      value: primaryContactEmail,
      onchange: setPrimaryContactEmail, 
      error: primaryContactEmailError, 
      errText: 'Email must be valid and cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your email address here.', 
      editableFlag: true,
      maxLength: { maxLength: 200} },

    { key: 'txtContactPhone', 
      name: 'contactPhone', 
      type: 'textField',
      required: true,
      label: 'Primary Contact Phone:', 
      value: primaryContactPhone,
      onchange: setPrimaryContactPhone, 
      error: primaryContactPhoneError, 
      errText: 'Phone number must be valid and cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your phone number here.', 
      editableFlag: true,
      maxLength: { maxLength: 15} },

    { key: 'chkEnableSelfHostedDbOption]', 
      name: 'enableSelfHostedDbOption]', 
      type: 'checkBox',
      required: true,
      label: 'Self Hosted Database Option:', 
      value: enableSelfHostedDbOption,
      onchange: setEnableSelfHostedDbOption, 
      editableFlag: isAdminPage ? true : false,
      additionalText: isAdminPage ? "Note: Update this setting before the company creates its tenant.  If the company has already created it's tenant, a system admin must delete that tenant or this setting is obsolete." : "",
      },
      
  ];

        
  const handleInsert = () => {

    let isError = validateInfoHandler();

    if (!isError)
    {
        var payload = {
            CompanyName: companyName,
            StreetAddress: streetAddress,
            City: city,
            ProvinceState: provinceState,
            Country: country,
            Phone: phone,
            PostalZip: postalZip,
            PrimaryContactFirstName: primaryContactFirstName,
            PrimaryContactLastName: primaryContactLastName,
            PrimaryContactEmail: primaryContactEmail,
            PrimaryContactPhone: primaryContactPhone,
            CompanySize: companySize,
            SetupStageComplete: 0,
            AuthorizedByEmail: authorizedByEmail,
            AuthorizedByGraphId: authorizedByGraphId,
            authorizedByTenantId: authorizedByTenantId,
            IsAccountAuthorized: true,
            StripeCustomerId: stripeCustomerId,
        };
        
        console.log(`Payload: ${JSON.stringify(payload)}`);

        axios.post(`${WEB_API_URL}Company`, payload).then((response) => {
            navigate("/admin/CompanyListAdmin");
            }).catch(e => {
                console.log(e)
            });
        }
    }
            
    function validateInfoHandler() 
    {
      let isError = false;

      if (companyName.trim() === '')
      {
        setCompanyNameError(true);
        isError = true;
      }
      else
        setCompanyNameError(false);

      if (streetAddress.trim() === '')
      {
        setStreetAddressError(true);
        isError = true;
      }
      else
        setStreetAddressError(false);
    
      if (city.trim() === '')
      {
        setCityError(true);
        isError = true;
      }
      else
        setCityError(false);

      if (phone.trim() === '')
      {
        setPhoneError(true);
        isError = true;
      }
      else
        setPhoneError(false);

      if (primaryContactFirstName.trim() === '')
      {
        setFirstNameError(true);
        isError = true;
      }
      else
        setFirstNameError(false);

      if (primaryContactLastName.trim() === '')
      {
        setLastNameError(true);
        isError = true;
      }
      else
        setLastNameError(false);

      if (primaryContactEmail.trim() === '')
      {
        setPrimaryContactEmailError(true);
        isError = true;
      }
      else
      {
        let isValidEmail = validateEmail(primaryContactEmail);
        setPrimaryContactEmailError(!validateEmail(primaryContactEmail));
        if (!isValidEmail)
        {
          isError = true;
        }
      }

      if (billingEmail.trim() === '')
        {
          setBillingEmailError(true);
          isError = true;
        }
        else
        {
          let isValidEmail = validateEmail(billingEmail);
          setBillingEmailError(!validateEmail(billingEmail));
          if (!isValidEmail)
          {
            isError = true;
          }
        }

      if (primaryContactPhone.trim() === '')
      {
        setPrimaryContactPhoneError(true);
        isError = true;
      }
      else
        setPrimaryContactPhoneError(false);

      if (authorizedByEmail.trim() === '')
      {
        setAuthByError(true);
        isError = true;
      }
      else
        setAuthByError(false);

      if (authorizedByGraphId.trim() === '')
      {
        setAuthByGraphIdError(true);
        isError = true;
      }
      else
        setAuthByGraphIdError(false);

      if (authorizedByTenantId.trim() === '')
      {
        setAuthByTenantIdError(true);
        isError = true;
      }
      else
      setAuthByTenantIdError(false);

      return isError;
    }

    const handleUpdate = async () => {
    {
        let isError = validateInfoHandler();
        if (!isError)
        {
          try{

            var response = await updateCompany(
              companyId,
              companyName,
              streetAddress,
              streetAddressLine2,
              city,
              provinceState,
              country,
              phone,
              postalZip,
              primaryContactFirstName,
              primaryContactLastName,
              primaryContactEmail,
              primaryContactPhone,
              companySize,
              billingEmail,
              setupStageComplete,
              user.displayName,
              authorizedByEmail,
              authorizedByGraphId,
              authorizedByTenantId,
              stripeCustomerId,
              enableSelfHostedDbOption
              );

            if (response != null)
            {
                if(isAdminPage == true) {
                  navigate("/admin/CompanyListAdmin");
                }
                else {
                  navigate("/MyCompany");
                }
            };
          }
          catch(e)
          {
            console.log("ERROR: handleUpdate");
            console.log(e);
            setServiceIsDownError(true);
          }
        }
    }
  }

  function renderSwitch(txtfld) {
    switch(txtfld.type) {
      case 'textField':
        return (
          <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
            <b>{txtfld.label}</b>
            <br></br>
            <TextField 
              required={txtfld.required}
              name={txtfld.name}
              placeholder={txtfld.placeholderText}
              value={txtfld.value}
              variant="outlined" 
              size="small"
              style={{ width: 400}}
              InputLabelProps={{ shrink: true }}
              onChange={event => txtfld.onchange(event.target.value) }
              error={txtfld.error}
              helperText={txtfld.error ? txtfld.errText : txtfld.descText}
              disabled={!txtfld.editableFlag} 
              inputProps={txtfld.maxLength}/>
            </Typography>
        );

        case 'checkBox':
          return (
            <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
              <b>{txtfld.label}</b>
              <br></br>
              <Checkbox required
                  name={txtfld.name}
                  checked={txtfld.value} 
                  controlid={txtfld.name} 
                  disabled={!txtfld.editableFlag}
                  onChange={event => txtfld.onchange(event.target.checked) } 
              /> 
              {txtfld.additionalText &&
                <><br></br>{txtfld.additionalText}</>
              }
              </Typography>
          );

      case "dateField":
        return(
          <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
            <b>{txtfld.label}</b>
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{width: 450}} components={['DatePicker', 'DatePicker']}>
                <DatePicker 
                  {...txtfld.disabled === 'true' ? '' : "disabled=true"}
                  disabled={!txtfld.editableFlag}
                  value={txtfld.value}
                  onChange={(newValue) => txtfld.onchange(newValue)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
        </Typography>
        )
        
      default:
        return (
          <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
            <b>{txtfld.label}</b>
            <br></br>
            <TextField 
                required 
                select 
                onChange={event => txtfld.onchange(event.target.value)} 
                name={txtfld.name}  
                variant="outlined" 
                size="small"
                style={{ width: 400}}
                InputLabelProps={{ shrink: true }}
                inputRef={txtfld.inputRef} 
                value={txtfld.value} 
                disabled={!txtfld.editableFlag} // Set disabled prop based on editableFlag
                >
            {txtfld.menuItem.map((option) => (
              <MenuItem key={option.value} value={option.value} >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </Typography>
        );
    }
  };

// Component UI

if(accessDenied)
{
  return(<AccessDenied></AccessDenied>);
}


if (serviceIsDownError) {
  return (<><ServiceIsDown></ServiceIsDown></>);
}

return(
  <>
    <ProgressBar message="Loading ..." loading={loading} />
    {!loading && companyId > 0 ?       
       <><DisplayBreadCrumb paths={updateBreadcrumbPath} /><DisplaySectionTitleAndDescription sectionTitle={updateSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} /></>       
       :
       <><DisplayBreadCrumb paths={createBreadcrumbPath} /><DisplaySectionTitleAndDescription sectionTitle={createSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} /></>
    }
    
    <Grid
      container
      marginTop={0}
      spacing={0}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
        <Grid item xs={12}>
        <Card variant="outlined" sx={{maxWidth: "500px"}}>
        <CardContent>
            {companyFields.map((txtfld) => (            
              renderSwitch(txtfld)
          ))}
        </CardContent>
        </Card>
        </Grid>
        </Grid>      

      <div style={{ marginLeft: '0px' }} >
        <br />
        {
          !companyId ? <Button variant="contained" size='small' className="ml-auto" onClick={() => handleInsert()}> Create Company </Button>
                      : <Button variant="contained" size='small' className="ml-auto" onClick={() => handleUpdate()}> Save Changes </Button>
        }
        &nbsp; 
        {
          isAdminPage ? <Button variant="contained" size='small' className="ml-auto" onClick={() => navigate("/Admin/CompanyListAdmin")}> Discard </Button>
                      : <Button variant="contained" size='small' className="ml-auto" onClick={() => navigate("/MyCompany")}> Discard </Button>
        }        
      </div>
      </>
  )
}