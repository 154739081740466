// React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

//Material UI Components

// Audit Vault Components
import ProgressBar from "../common/ProgressBar";
import {formatDate} from "../../utilities/common-date-utils";
import {getSubscriptionInfo2} from "../../utilities/common-getBillingInfo";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function NextBillingDate(props) {
    const { user, companyId } = props

    const [loading, setLoading] = useState(true);
    const [stripeError, setStripeError] = useState(false);
    const [nextInvoiceDate, setNextInvoiceDate] = useState('Unavailable');

    useEffect(() => {
        fetchData();
    }, []);

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date);
    
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
    
        newDate.setHours(hours);
    
        return formatDate(newDate);   
    }

    async function fetchData() {
        try
        {
            if (user && user.isSystemAdmin && companyId)
            {
                var result = await axios.get(`${WEB_API_URL}products/GetProductsByCompanyId/${companyId}`);
                if (result) {
                    var result2 = await getSubscriptionInfo2(result.data[0].stripeSubscriptionId);
                   console.log(result2);
                    if (result2)
                    {
                        var date = convertUTCDateToLocalDate(new Date(result2.currentPeriodEndDate));
                        setNextInvoiceDate(date);
                    }
                }
            }
        }catch(e)
        {
            setStripeError(true);
            console.log(e);
        }
        finally{
            setLoading(false);
        }
    }

    if (stripeError)
    {
        return ("ERROR: Unable to get Stripe Data.");
    }

    if (loading)
    {
        return(<ProgressBar loading={loading}></ProgressBar>)
    }

    if (!loading)
    {
        return (
            <>
            {nextInvoiceDate && 
                nextInvoiceDate
            }
            </>
        );
    }
}
export default NextBillingDate;