// React Components
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import axios from "axios";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import AuditVaultUserActivityReportList from "../components/reports/AuditVaultUserActivityReportList";
import AccessDenied from "../components/common/AccessDenied";
import ProgressBar from "../components/common/ProgressBar";
import ServiceIsDown from "../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import StartAndEndDateSelector from "../components/reports/StartAndEndDateSelector";
import TrialPeriodNag from "../components/common/TrialPeriodNag";

// Audit Vault Utilities
import { encryptKey } from '../utilities/common-encrypt-util';
import { combineDateJsDateAndTimeToDateTimeOffset, presetDatasetColors } from "../utilities/common-report-utils";
import { getCompanyByCompanyId } from "../utilities/common-company";

//Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theSectionTitle = "User Activity Logs";
const theSectionDesc = "Review a comprehensive audit trail of all user actions within the Audit Vault for M365 app.  This page helps ensure your compliance and security requirements are met for monitoring activities performed by any user, including administrative and operational actions.  This page is accessible only by the Company Administrator(s).";
const isAdminPage = false;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Company', link: '/MyCompany' },
    { name: 'User Activity Logs', link: '/MyCompany/user-activity-logs' },
];


function ReportsUserActivity(props) {

    const { user } = props;

    const [company, setCompany] = useState();
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const [startDt, setStartDt] = useState();
    const [startTime, setStartTime] = useState('00:00');
    const [endDt, setEndDt] = useState(nextDay);
    const [endTime, setEndTime] = useState('00:00');

    const [logs, setLogs] = useState([]);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    // Component Functions

    async function fetchData() {
        try {
            if (user.isCompanyAdmin || user.isSystemAdmin) {
                setAccessDenied(false);

                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log("ERROR: fetchData");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function getUserActivityLogs() {

        try {
            setLoading(true);

            var usrEmail = "N/A";
            if (user.userEmail)
                usrEmail = user.userEmail;

            var formattedStartDt = dayjs(startDt).format('YYYY-MM-DD');
            var formattedEndDt = dayjs(endDt).format('YYYY-MM-DD');

            var payload = `{
                CompanyId: ${user.companyId},
                UserMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
                UserEmail: "${await encryptKey(usrEmail)}",
                StartDt: "${combineDateJsDateAndTimeToDateTimeOffset(formattedStartDt, startTime)}",
                EndDt: "${combineDateJsDateAndTimeToDateTimeOffset(formattedEndDt, endTime)}"}`;

            if (payload) {
                var result = await axios.get(`${WEB_API_URL}Reports/GetUserActivityReport`, {
                    params: {
                        key: `${payload}`
                    }
                });
                if (result) {
                    setLogs(result.data);
                }
            }
        } catch (e) {
            setServiceIsDownError(true);
            console.log("ERROR: getUserActivityLogs")
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    return (
        <>
            <TrialPeriodNag company={company}></TrialPeriodNag>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>

            </div>
            <Card sx={{ width: '50%', minWidth: 550 }} variant="outlined">
                <CardContent>
                    <Typography component="div"><b>View User Activity Logs:</b></Typography>
                    <Typography component="div"><br></br></Typography>
                    <Table sx={{
                        minWidth: 500, width: '100%', [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }} size="small">
                        <TableHead>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell key="filterDates">
                                    <StartAndEndDateSelector
                                        selectedStartDate={startDt}
                                        setSelectedStartDate={setStartDt}
                                        selectedStartTime={startTime}
                                        setSelectedStartTime={setStartTime}
                                        selectedEndTime={endTime}
                                        selectedEndDate={endDt}
                                        setSelectedEndDate={setEndDt}
                                        setSelectedEndTime={setEndTime} />

                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>

                </CardContent>
                <CardActions>
                    <Button type="submit" aria-label="search" onClick={() => getUserActivityLogs()}>Search <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
                </CardActions>
            </Card>
            <Typography component="div"><br></br></Typography>
            {logs &&
                <AuditVaultUserActivityReportList
                    logs={logs}
                ></AuditVaultUserActivityReportList>
            }
        </>
    );
}
export default ReportsUserActivity;
