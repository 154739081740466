
const COMPANY_SIZES = [
  {
    value: '250',
    label: '1 - 250',
    minValue: 1
  },
  {
    value: '500',
    label: '251 - 500',
    minValue: 251
  },
  {
    value: '750',
    label: '501 - 750',
    minValue: 501
  },
  {
    value: '1000',
    label: '751 - 1000',
    minValue: 751
  },
  {
    value: '1500',
    label: '1001 - 1500',
    minValue: 1001
  },
  {
    value: '2000',
    label: '1501 - 2000',
    minValue: 1501
  },
  {
    value: '3000',
    label: '2000 +',
    minValue: 2001
  },
];

export { COMPANY_SIZES };
