// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MailIcon from '@mui/icons-material/Mail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// Audit Vault Components
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import {formatDate} from '../utilities/common-date-utils';
import { encryptKey } from '../utilities/common-encrypt-util';


// We will display a nice simple and brief Message Center Preview.
// Include: Icon with # of unread messages, show Subject Line and read/unread status of top 5 messages in Message Center.  Provide "more" link to MessageCenter from this preview component.
// This component is self-enclosed, you just provide it the companyId and it loads what it needs.

function MessageListPreview(props) {
    
    
    // Component Constants
    // Parent page should be providing companyId, company
    const { theCompanyId } = props;            
    
    const navigate = useNavigate();
    const messagesToPreviewUpTo = 5;

    const [loading, setLoading] = useState(true);    
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    
    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    // Store the MessageList array for companyID.
    const [myMessages, setMessages] = useState([]);
    // Section titles.
    // There are no titles or breadcrumb for this preview sub component.

    // Component Functions
    useEffect(() => {
        async function fetchData() {            
            try {          
                // Now load the messages. 
                if (theCompanyId)
                {                         
                    var rsaKey = await encryptKey(`{companyId: ${theCompanyId}, returnDeleted: false}`);
                    if (rsaKey) {
                        var response = await axios.get(`${WEB_API_URL}MessageCenter/GetAllMessagesForCompany`, {
                            params: {
                                key: `${rsaKey}`
                            }
                        });
                        if (response)
                        {
                            setMessages(response.data);
                        }
                    }
                }                        
            }   
            catch(e) {            
                console.log("ERROR: MessageListPreview.getData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally{
                setLoading(false);
            }                               
        }
        fetchData();
    }, []);


    // Component UI
    
    // If we are still loading data, display that in the UI...
    if (loading) {
        //console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    const displayedMessages = myMessages.slice(0, messagesToPreviewUpTo); // Display the first 5 messages
    let countUnreadMessages = 0;  // Count the number of unread messages to highlight in the badge.
    for(var i = 0; i < myMessages.length; i++) {
        console.log("Check: " + myMessages[i].shadowIsRead);
        if(myMessages[i].shadowIsRead == false) {
            countUnreadMessages++;
        }
    }

    // Once data has loaded now render the UI for the component.
    return (        

        <>                
                Stay up-to-date on the latest system-wide notifications such as planned outages or system updates.
                <br /><br />                
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge badgeContent={ countUnreadMessages } color="primary">
                    <MailIcon color="action" style={{ fontSize: 30}} />
                    </Badge>
                    <span style={{ marginLeft: '10px' }}>...{ countUnreadMessages } Unread Messages</span>
                </div>
                <br />
                {
                    displayedMessages.map((message, index) => (                    
                    <div key={message.id}>    
                        { index === 0 && <>Latest messages:<br /></> }                    
                        <MailOutlineIcon sx={{ fontSize: 20 }} color="action" />&nbsp; 
                        {message.shadowIsRead === false ? (
                            <b>{message.title} ({formatDate(message.publishedDate ? message.publishedDate.toString() : message.publishedDate)})</b>                            
                            ) : (
                            <>{message.title} ({formatDate(message.publishedDate ? message.publishedDate.toString() : message.publishedDate)})</>
                        )}                                          
                        <br />
                        {index !== displayedMessages.length && <Divider style={{ backgroundColor: 'darkgray' }} />}
                    </div>
                ))}
                <br /><Button size="small" onClick={() => navigate("/Support/MessageCenter")}>Show All</Button>
            
        </>
    );
}
export default MessageListPreview;