// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Material UI Components
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Link from '@mui/material/Link';
import StorageIcon from '@mui/icons-material/Storage';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import { green, orange, red } from '@mui/material/colors';
import Button from '@mui/material/Button';

// Audit Vault Components
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';
import { AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_1_INFORM, AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_2_STRONGWARN, AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_3_CRITICAL, AUDIT_VAULT_TENANT_DBUNITSPURCHASED_GB_PER_UNIT } from "../constants/constants-admindatabasewarnings";


// Audit Vault Utilities
import { SUPPORT_EMAIL_TO_RECEIVE_SUPPORT_REQUESTS } from '../constants/constants-email';
import { getTenantDBStats } from '../utilities/common-healthstats';

// In this component we will display a nice simple summary of a Tenants DB Stats.
// This component is self-enclosed, you just call it from the HomePage or CompanyDashboardPage.
// Can be called with property viewFullSize = true or false.  If true it will display the full size status, if false will display a mini status for the admin pages etc.
// Optional param of showLinkInFullSize, if set to true will display the report link when displaying full size, otherwise it won't be displayed if false or not passed in.
// Optional param showTenantUserCount, if set to true will load Tenant User Count from MS Graph and display the user count (now this may not be all licensed users obviously, but it's the users that exist in AD).

// FUTURE TODO: REPLACE THE CONTACT US LINK WITH A LINK TO THE CORRECT BILLING PAGE TO ADD MORE STORAGE.

function TenantDBStats(props) {
    
    // Component Constants    
    const { user, tenant, viewFullSize, showLinkInFullSize, showTenantUserCount } = props;
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);    
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    
    const [tenantDbStats, setTenantDbStats] = useState("");
 

    // Component Functions
    useEffect(() => {
        async function fetchData() {            
            try {                                                      
            
                // Now load the messages.                                                        
                const response = await getTenantDBStats(user, tenant.id, showTenantUserCount);
                if (response)
                {
                    setTenantDbStats(response);
                };      
                //console.log(response);
            }   
            catch(e) {            
                // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
                console.log("ERROR: TenantDBStats.fetchData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally{
                setLoading(false);  
            }                            
        }
        fetchData();
    }, []);


    // Component UI
    
    // If we are still loading data, display that in the UI...
    if (loading) {        
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    // PW: Indicate if you are self-hosting ex.) IsDatabaseHostedByCompany
    // If self hosting just display the %'s for each and indicate overall health
    // If hosted by us - we try to upsell, request more storage space etc. 
    if(tenant.isDatabaseHostedByCompany == false) {
        // The database is hosted by us.
        // Once data has loaded now render the UI for the component.

        // Perform analysis and process the results here.    
        // Calculate current percentage of DB use (DB + Logs).
        //var currentOverallUsageInGB = (tenantDbStats.dbCurrentSizeInGB + tenantDbStats.logCurrentSizeInGB);
        var totalUnitsOfStorageInGB = (tenantDbStats.dbUnitsPurchased * AUDIT_VAULT_TENANT_DBUNITSPURCHASED_GB_PER_UNIT);
        var currentOverallUsagePercent = (((tenantDbStats.currentOverallUsageInGB / totalUnitsOfStorageInGB)) * 100).toFixed(1);            
        var remainingStorageInGB = (totalUnitsOfStorageInGB - tenantDbStats.currentOverallUsageInGB).toFixed(3);
        
        
        var hostedByUsStatusMessage = "";  // Track the status message to display.
        var hostedByUsStatus = 0;
        if(currentOverallUsagePercent >= AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_3_CRITICAL) {
            hostedByUsStatusMessage = "Your storage space usage is at a critical level, please contact Support to increase your available storage space immediately to avoid running out of space.";
            hostedByUsStatus = 3;
        } else {
            if(currentOverallUsagePercent >= AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_2_STRONGWARN) {
                hostedByUsStatusMessage = "Your storage space remaining is nearing capacity, we strongly recommend you contact Support to increase your available storage space.";
                hostedByUsStatus = 2;
            }
            else {
                if(currentOverallUsagePercent >= AUDIT_VAULT_TENANT_SOFTLIMIT_ON_DBSIZE_WARNPERCENTAGE_1_INFORM) {
                    hostedByUsStatusMessage = "Your storage space remaining is nearing our first warning level, we recommend you proactively start thinking about increasing your available storage space or contacting us at Support.";
                    hostedByUsStatus = 1;
                }
                else {
                    hostedByUsStatusMessage = "You have plenty of storage for now."
                    hostedByUsStatus = 0;
                }
            }
        }

        // Display different UI depending on the size param.
        if(viewFullSize == true) 
        {    
            // In case the tenant was not yet configured or there are no dbUnitsPurchased yet.
            if(totalUnitsOfStorageInGB == 0) {
                return (<div>For Tenant Domain: <b>{ tenant.tenantUrl }</b> <br /><br />Not yet provisioned or setup.</div>);
            }

            // Display full size status.
            return (                        
                <>                                
                    <div>                     
                        {/* Display the Storage Usage Info. */}
                        For Tenant Domain: <b>{ tenant.tenantUrl }</b>
                        { showTenantUserCount == true && (<>&nbsp; (User Count: {tenantDbStats.totalNumberOfUsers})</>) }
                        <br /><br />                        
                        <Tooltip title={`Your storage usage indicates the cumulative amount of data and logs being managed by Audit Vault for M365 for this specific tenant (${tenant.tenantUrl}).`} arrow>
                            <StorageIcon color="action" />
                        </Tooltip>
                        &nbsp;                                             
                        Storage Usage Info<br />                    
                        <div style={{ fontSize: '0.8rem' }}>         
                            <br />           
                            You've used { currentOverallUsagePercent }% of your storage<br />
                            <LinearProgress
                                variant="determinate"
                                value={currentOverallUsagePercent}
                                style={{ width: '100%', marginLeft: '0px', height: '10px', borderRadius: '5px' }}
                            />                            
                            { remainingStorageInGB } GB remaining available of { totalUnitsOfStorageInGB } GB <br />
                            Overall Usage: { tenantDbStats.currentOverallUsageInGB.toFixed(2) } GB<br />
                            Your current plan's storage entitlement is { totalUnitsOfStorageInGB } GB <br />
                            <br />
                            <li style={{ display: 'flex', alignItems: 'left' }}>   
                            <b>Current Status:&nbsp; </b>                            
                            { hostedByUsStatus === 0 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><Avatar sx={{ bgcolor: green[500], height: 15, width: 15}}> </Avatar></Tooltip><span style={{fontWeight: 'bold', color: 'green'}}>&nbsp; {hostedByUsStatusMessage}</span></> ) : null }
                            { hostedByUsStatus === 1 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><Avatar sx={{ bgcolor: orange[500], height: 15, width: 15}}> </Avatar></Tooltip><span style={{fontWeight: 'bold', color: 'orange'}}>&nbsp; {hostedByUsStatusMessage}</span></> ) : null }
                            { hostedByUsStatus === 2 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><Avatar sx={{ bgcolor: red[500], height: 15, width: 15}}> </Avatar></Tooltip><span style={{fontWeight: 'bold', color: 'red'}}>&nbsp; {hostedByUsStatusMessage} <Link href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SUPPORT_REQUESTS}`} >Contact Us</Link></span></> ) : null }
                            { hostedByUsStatus === 3 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><Avatar sx={{ bgcolor: red[500], height: 15, width: 15}}> </Avatar></Tooltip><span style={{fontWeight: 'bold', color: 'red'}}>&nbsp; {hostedByUsStatusMessage} <Link href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SUPPORT_REQUESTS}`}>Contact Us</Link></span></> ) : null }
                            </li>                
                            <br />                                        
                        </div>
                        {
                            ((user && user.permissions.length > 0 && user.permissions[0].roleId <= 3) || (user && user.isSystemAdmin)) &&
                            showLinkInFullSize && <Button size="small" onClick={() => navigate(`/Reports/ReportsHome`)}>See Usage Reports</Button>
                        }                        
                    </div>
                </>
            );
        }
        else {
            // Display mini size status.

            // In case the tenant was not yet configured or there are no dbUnitsPurchased yet.
            if(totalUnitsOfStorageInGB == 0) {
                return (
                        <div>Tenant Id: {tenant.id} <br />                        
                        Domain: <b>{ tenant.tenantUrl }</b> <br />
                        Not provisioned or setup.<br /></div>
                );
            }

            var displayText = currentOverallUsagePercent + "% (" + tenantDbStats.currentOverallUsageInGB.toFixed(2) + " of " + totalUnitsOfStorageInGB + " GB)";

            return (
                <>                                
                    <div>                     
                        {/* Display the Storage Usage Info. */}
                        Tenant Id: {tenant.id} <br />                        
                        Domain: <b>{ tenant.tenantUrl }</b>
                        { showTenantUserCount == true && (<>&nbsp; (User Count: {tenantDbStats.totalNumberOfUsers})</>) }
                        <br /><u>Storage Usage:</u>
                        <div style={{ fontSize: '0.8rem' }}>                       
                            <li style={{ display: 'flex', alignItems: 'left' }}>
                            { hostedByUsStatus === 0 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><CircleRoundedIcon sx={{ color: green[500], height: 15, width: 15}} /></Tooltip>&nbsp;{displayText}</>) : null }
                            { hostedByUsStatus === 1 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><CircleRoundedIcon sx={{ color: orange[500], height: 15, width: 15}} /></Tooltip>&nbsp;{displayText}</>) : null }
                            { hostedByUsStatus === 2 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><CircleRoundedIcon sx={{ color: red[500], height: 15, width: 15}} /></Tooltip>&nbsp;{displayText}</>) : null }
                            { hostedByUsStatus === 3 ? ( <><Tooltip title={hostedByUsStatusMessage} arrow><CircleRoundedIcon sx={{ color: red[500], height: 15, width: 15}} /></Tooltip>&nbsp;{displayText}</>) : null }  
                            </li>
                            <LinearProgress
                                variant="determinate"
                                value={currentOverallUsagePercent}
                                style={{ width: '100%', marginLeft: '0px', height: '10px', borderRadius: '5px' }}
                            />
                        </div>
                        <u>Job Health:</u>
                        <br />
                        { tenantDbStats.totalNumberOfErrors > 0 &&
                            <CircleRoundedIcon sx={{ color: red[500], height: 15, width: 15}} />
                        }
                        { tenantDbStats.totalNumberOfErrors == 0 &&
                            <CircleRoundedIcon sx={{ color: green[500], height: 15, width: 15}} />
                        }
                        &nbsp;{tenantDbStats.totalNumberOfErrors} Job Errors.                        
                        <br />                      
                    </div>
                </>
            );
        }
    }
    else {

        // The database is hosted by the customer/company.  Just give summary of data and logs used.
        // Perform analysis and process the results here.         
        var dbUsagePercentage = (tenantDbStats.dbCurrentSizeInGB / (tenantDbStats.dbCurrentSizeInGB + tenantDbStats.dbReportedFreeSpaceInGB) *100).toFixed(2);
        var logUsagePercentage = (tenantDbStats.logCurrentSizeInGB / (tenantDbStats.logCurrentSizeInGB + tenantDbStats.logReportedFreeSpaceInGB) * 100).toFixed(2);

        if(viewFullSize == true) 
        {    
            // Display full size status.
            return (
                <>
                    <div>
                    For Tenant Domain: <b>{ tenant.tenantUrl }</b> <br /><br />
                    Your Database is self hosted.  Please ensure you proactively maintain, monitor and backup your data.<br /><br />
                    Storage Usage Info<br />
                    </div>
                    {/* Display the Database Stats. */}
                    <Tooltip title="The Audit Vault Control SQL Database: Data in the SQL Server database system, used for storing and retrieving data efficiently." arrow>
                        <StorageIcon color="action" />
                    </Tooltip>
                    &nbsp; Database<br />                    
                    <div style={{ fontSize: '0.8rem' }}>                    
                        You've used { dbUsagePercentage }% of your database storage<br />
                        <LinearProgress variant="determinate" value={dbUsagePercentage} style={{ width: '100%', marginLeft: '0px', height: '10px', borderRadius: '5px' }} />
                        Current size: { (tenantDbStats.dbCurrentSizeInGB).toFixed(2) } GB<br/>
                        Reported free space: { (tenantDbStats.dbReportedFreeSpaceInGB).toFixed(2) } GB<br/>
                        Reported max size: {tenantDbStats.maxSizeIsUnlimitied ? ( <>Unlimited</> ) : ( (tenantDbStats.dbReportedMaxSizeInGB).toFixed(2) )} GB<br />  
                    </div>                
                    {/* Display the Transaction Log Stats. */}                    
                    <Tooltip title="The Audit Vault Control SQL Transaction Log: A file that records all modifications made to a SQL Server database, ensuring transactional consistency, durability, and providing the ability to recover the database to a specific point in time." arrow>
                        <Inventory2Icon color="action" />
                    </Tooltip>                    
                    &nbsp; Transaction Log<br />
                    <div style={{ fontSize: '0.8rem' }}>     
                        You've used { logUsagePercentage }% of your log storage<br />
                        <LinearProgress variant="determinate" value={logUsagePercentage} style={{ width: '100%', marginLeft: '0px', height: '10px', borderRadius: '5px' }} />               
                        Current Size: { (tenantDbStats.logCurrentSizeInGB).toFixed(2) } GB<br/>
                        Reported Free Space: { (tenantDbStats.logReportedFreeSpaceInGB).toFixed(2) } GB<br/>
                        Reported Max Size: {tenantDbStats.maxSizeIsUnlimitied ? ( <>Unlimited</> ) : ( (tenantDbStats.logReportedMaxSizeInGB).toFixed(2) )} GB <br/>
                    </div>
                    <br />
                    {
                    ((user.permissions.length > 0 && user.permissions[0].roleId <= 3) || (user.isSystemAdmin)) &&
                        <Button size="small" onClick={() => navigate(`/Reports`)}>See Usage Reports</Button>
                    }

                </>
            )
        }
        else {
            // Display mini size status.
            return (
                <div>
                Tenant Id: {tenant.id} <br />           
                Domain: <b>{ tenant.tenantUrl }</b>
                { showTenantUserCount == true && (<>&nbsp; (User Count: {tenantDbStats.totalNumberOfUsers})</>) } <br />
                <u>Storage Usage:</u><br />
                Database is self hosted.<br />                
                <u>Job Health:</u>
                <br />
                { tenantDbStats.totalNumberOfErrors > 0 &&
                    <CircleRoundedIcon sx={{ color: red[500], height: 15, width: 15}} />
                }
                { tenantDbStats.totalNumberOfErrors == 0 &&
                    <CircleRoundedIcon sx={{ color: green[500], height: 15, width: 15}} />
                }
                &nbsp;{tenantDbStats.totalNumberOfErrors} Job Errors.
                <br />                                                      
                </div>

            );

        }
    }


}
export default TenantDBStats;