import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        marginTop: 28,
        width: '100%',
    },
    columnnContainer: {
        width: '50%',
    },
    billTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const BillTo = ({invoice}) => (
    <View style={styles.headerContainer}>
        <View style={styles.columnnContainer}>
            <Text>ECM Insights Corp.</Text>
            <Text>100, 111 5 Ave SW, Suite #261</Text>
            <Text>Calgary, AB</Text>
            <Text>T2P 3Y6</Text>
            <Text>Canada</Text>
            <Text>support@ecminsights.com</Text>
        </View>
        <View style={styles.columnnContainer}>
            <Text style={styles.billTo}>Bill To:</Text>
            <Text>{invoice.customerName}</Text>
            <Text>{invoice.customerAddressLine1}</Text>
            <Text>{invoice.customerCity} {invoice.customerProvState}</Text>
            <Text>{invoice.customerPostalZipCode}</Text>
            <Text>{invoice.customerCountry}</Text>
            <Text>{invoice.customerEmail}</Text>
        </View>
    </View>
  );
  
  export default BillTo