const MESSAGE_STATUS = [
    {
        value: 0,
        label: 'Notification Only'
    },
    {
        value: 1,
        label: 'Planned Outage'
    },
    {
        value: 2,
        label: 'Service Degredation'
    },
    {
        value: 3,
        label: 'Service Restored'
    },
];

export { MESSAGE_STATUS };
