// React Components
import * as React from 'react';
import { useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

// Material UI Components
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// Audit Vault Components
import DeleteDiagnosticeLogsConfirmation from './DeleteDiagnosticsLogsConfirmation';

// Audit Vault Utilities
import {formatDate} from '../utilities/common-date-utils'
import getAuditSource from '../utilities/common-auditsource-util';
import { encryptKey } from '../utilities/common-encrypt-util';

const columns = [
  { id: 'id', label: 'Id', minWidth: 50, align: 'left' },
  { id: 'jobId', label: 'Job Id', minWidth: 50 },
  { id: 'logDateTime', label: 'Log Date', minWidth: 50 },
  { id: 'logDiagnosticLevel', label: 'Severity', minWidth: 50 },
  { id: 'auditSource', label: 'Source', minWidth: 50 },
  { id: 'tenantId', label: 'Tenant Id', minWidth: 50 },
  { id: 'message', label: 'Message', minWidth: 400},
];

const rows = [];

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function DiagnosticsList(props) {

  const { user, logs, getDiagnosticLogs } = props;

  // Optional table - settings.
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

  const [openNotification, setOpenNotification] = useState(false);
  const [openNotificationMsg, setOpenNotificationMsg] = useState("Log deleted");
  const [open, setOpen] = React.useState(false);

  const getSeverity = (id) => {

    switch (id) {

        case 1:
            return "None";

        case 2:
            return "Critical";

        case 3:
            return "Warning";

        case 4:
            return "Information";

        default:
            return "Verbose";
    }
};

// Functions for Delete Dialog
const handleClose = (value) => {
  setOpen(false);
};

function handleClickOpen(val, compNm) {
  setOpen(true);
};

DeleteDiagnosticeLogsConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

async function deleteLog(id) {

  if (user) {
      if (user.isSystemAdmin) {
          try {
              var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}", Id: "${id}"}`;
              if (rsaKey) {

                  var result = await axios.delete(`${WEB_API_URL}AdminUtilities/DeleteDiagnosticLog`, {
                      params: {
                          key: `${rsaKey}`
                      }
                  });

                  if (result) {
                    setOpenNotificationMsg("Log deleted.");
                    setOpenNotification(true);
                  }
              }
          } catch (e) {
              console.log("ERROR: Common-admin-utilities.deleteLog")
              console.log(e);
          }
      }
  }
  return false; // just the default value.
}

async function deleteAllLogs() {

  if (user) {
      if (user.isSystemAdmin) {
          try {
              var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}"}`;
              if (rsaKey) {

                  var result = await axios.delete(`${WEB_API_URL}AdminUtilities/DeleteAllDiagnosticLogs`, {
                      params: {
                          key: `${rsaKey}`
                      }
                  });

                  if (result) {
                    setOpenNotificationMsg("All Logs deleted.");
                    setOpenNotification(true);
                  }
              }
          } catch (e) {
              console.log("ERROR: Common-admin-utilities.deleteAllLogs")
              console.log(e);
          }
      }
  }
  return false; // just the default value.
}

// For snackbar close action.
const handleNotificationClose = (event, reason) => {
  if (reason === 'clickaway') {
      getDiagnosticLogs();
      return;
  }
  getDiagnosticLogs();
  setOpenNotification(false);
};

// For snackbar notification action.
const openNotificationAction = (
  <React.Fragment>
      <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleNotificationClose}
      >
          <CloseIcon fontSize="small" />
      </IconButton>
  </React.Fragment>
);

  // Function for Sorting column headers.
  function getComparator(sortConfig) {
    return (a, b) => {
      if (!sortConfig) {
        return 0;
      }        
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    };
  }

  // Function for Sorting column headers.  
  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }

  // Column header sort function.
  const requestSort = (key) => {
      let direction = 'asc';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
      }
      setSortConfig({ key, direction });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  

  return (
      <>

  <DeleteDiagnosticeLogsConfirmation
    onClose={handleClose}
    open={open}
    snackbarNotification={setOpenNotification}
    updateDataFunc={getDiagnosticLogs}
    user={user}
  ></DeleteDiagnosticeLogsConfirmation>

      <Paper sx={{ width: '100%' }}>
      <Table sx={{width: 'auto'}}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
              onClick={() => requestSort(column.id)}
            >
              {column.label}
              {sortConfig && sortConfig.key === column.id && (
                <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
              )}
            </TableCell>
          ))}
          {user && user.isSystemAdmin &&
            <TableCell
              key='sysAdminDel'
              align='right'
              style={{ minWidth: 40, maxWidth: 40 }}
            >Delete</TableCell>
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {stableSort(logs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                {columns.map((column) => {
                  const value = row[column.id];
                  if (column.id == 'logDiagnosticLevel') {
                    return (
                      <TableCell key={column.id + row.id} align={column.align}>
                        {getSeverity(value)}
                      </TableCell>
                    );
                  }
                  if (column.id == 'logDateTime') {
                    return (
                      <TableCell key={column.id + row.id} align={column.align}>
                        {formatDate(value)}
                      </TableCell>);
                  }

                  if (column.id == 'auditSource') {
                    return (
                      <TableCell key={column.id + row.id} align={column.align}>
                        {getAuditSource(value)}
                      </TableCell>);
                  }

                  if (column.id != 'logDateTime') {
                    return (
                      <TableCell key={column.id + row.id} align={column.align}>
                        {value
                          ? value.toString()
                          : value}
                      </TableCell>);
                  }
                })}
                {user && user.isSystemAdmin &&
                    <TableCell key={'Del-' + row.id} align='right'>
                      <Link onClick={() => deleteLog(row.id)} component="button">Delete</Link>
                    </TableCell>
                }
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="span"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
    </Paper>
    {user.isSystemAdmin &&
        <><br /><Button variant="contained" type="button" onClick={(event) => handleClickOpen()}>Delete All Logs</Button><br /></>
    }
    <div>
        <Snackbar
          open={openNotification}
          autoHideDuration={3000}
          onClose={handleNotificationClose}
          message={openNotificationMsg}
          action={openNotificationAction} />
      </div>
    </>
  );
}
