// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chat from '@mui/icons-material/Chat';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Audit Vault Components
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../components/common/DisplayBreadCrumb';

// Audit Vault Constants
//import { SUPPORT_EMAIL_TO_RECEIVE_PRODUCT_FEEDBACK } from "../constants/constants-email";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { getCompanyByCompanyId } from "../utilities/common-company";
import { encryptKey } from '../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function ProductFeedback(props) {

    // Component Constants    
    const { user, previewOnly } = props;

    // State variables to store form inputs
    const [feedback, setFeedback] = useState('');
    const [satisfactionCompany, setSatisfactionCompany] = useState(0);
    const [satisfactionProduct, setSatisfactionProduct] = useState(0);
    const [contactPermission, setContactPermission] = useState(true);
    const [alternateContact, setAlternateContact] = useState('');
    const [feedbackError, setFeedbackError] = useState(false);

    const navigate = useNavigate();
    const [company, setCompany] = useState("");
    const { instance, accounts } = useMsal();

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    // For snackbar notification UI element.  Notification.
    const [openNotification, setOpenNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Section titles.
    const [isAdminPage] = useState(false);
    const theSectionTitle = "Product Feedback";
    const theSectionDesc = "We appreciate your valuable feedback and strive to listen to your requirements.  Your feedback helps us improve our products and services in order to serve you better!";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Support', link: '/Support/SupportHome' },
        { name: 'Product Feedback', link: '/Support/ProductFeedback' }
    ];


    // Component Functions
    useEffect(() => {
        async function fetchData() {
            try {
                if (user) {
                    const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);
                    }
                }
            }
            catch (e) {
                catchAxiosErr2(e, "ProductFeedback.fetchData", setLoading, setServiceIsDownError);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    // Handle form submission (in a real application, this would send the data to your server)
    const handleSubmit = async () => {

        let isError = validateInfoHandler();  // Call to validate the controls.

        if (!isError) {
            // There were no errors.  We can submit the payload.
            var payload = {
                userName: await encryptKey(accounts[0].username),
                feedback: feedback,
                companySatisfactionRating: satisfactionCompany,
                productSatisfactionRating: satisfactionProduct,
                followUp: contactPermission,
                alternateContact: alternateContact,
                azureTenantId: await encryptKey(accounts[0].tenantId),
            };

            //console.log(`MK1: Handle Submit - Payload: ${JSON.stringify(payload)}`);
            var result = await axios.post(`${WEB_API_URL}AdminUtilities/SendProductFeedbackEmail`, null, {
                params: {
                    key: JSON.stringify(payload),
                }
            });

            if (result) {
                // If the empty string was returned that means the feedback was sent as an email.
                // Enable the snackbar notification telling the user that the feedback was successfully sent.
                setSnackbarMessage("Your feedback was successfully sent.  Thank-you, we appreciate your feedback.");
                setOpenNotification(true);
                // Clear the form.
                setFeedback('');
                setSatisfactionCompany(0);
                setSatisfactionProduct(0);
                setContactPermission(true);
                setAlternateContact('');
            }
            else {
                setSnackbarMessage(response.data);  // Set the snackbar message to the error message returned from the webapi call.
                setOpenNotification(true);
                // On error, we don't clear the form.
            }
        }
    };

    // Function to validate the form.
    function validateInfoHandler() {
        let retVal = false;
        if (feedback.trim() === '' || feedback.length < 10) {
            setFeedbackError(true);
            retVal = true;
        }
        else {
            setFeedbackError(false);
        }

        return retVal;
    }

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (previewOnly == null || previewOnly == false) {
        // Non-preview mode - display the Product Feedback submission form.
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', minWidth: 320 }}>
                                <b>Please provide us your product feedback below</b><br /><br />
                                <form>
                                    Do you have any suggestions to improve our product or service?
                                    <br></br>
                                    <TextField
                                        name="feedback"
                                        required
                                        multiline
                                        rows={5}
                                        inputProps={{ maxLength: 1024 }}
                                        style={{ width: 650 }}
                                        variant="outlined"
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        error={feedbackError}
                                        helperText={feedbackError ? 'Feedback provided must be between 10 and 1024 characters.' : ''}
                                    />
                                    <br /><br />
                                    <div>
                                        <Typography variant="subtitle1">How satisfied are you with our company and our support? (0 to 5 stars)</Typography>
                                        <Rating
                                            name="satisfaction-rating"
                                            required
                                            value={satisfactionCompany}
                                            onChange={(event, newValue) => {
                                                setSatisfactionCompany(newValue);
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <Typography variant="subtitle1">How satisfied are you with our products? (0 to 5 stars)</Typography>
                                        <Rating
                                            name="satisfaction-rating"
                                            required
                                            value={satisfactionProduct}
                                            onChange={(event, newValue) => {
                                                setSatisfactionProduct(newValue);
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <Checkbox
                                            checked={contactPermission}
                                            onChange={(e) => setContactPermission(e.target.checked)}
                                            color="primary"
                                        />
                                        <label>It's okay to contact me about this feedback (your e-mail is: {accounts[0].username})</label>
                                    </div>
                                    <br />
                                    <div>
                                        <Typography variant="subtitle1">Optional: Provide your alternate contact info (ex. your phone number)</Typography>
                                        <TextField
                                            name="alternateContact"
                                            inputProps={{ maxLength: 100 }}
                                            style={{ width: 650 }}
                                            variant="outlined"
                                            value={alternateContact}
                                            onChange={(e) => setAlternateContact(e.target.value)}
                                        />
                                    </div>
                                    <br /><br />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Submit Product Feedback
                                    </Button>
                                    &nbsp; <Button variant="contained" className="ml-auto" onClick={() => navigate("/Support/SupportHome")}> Discard</Button>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Snackbar
                        open={openNotification}
                        autoHideDuration={6000}
                        onClose={handleNotificationClose}
                        message={snackbarMessage}
                        action={openNotificationAction}
                    />
                </div>

            </>
        );
    }
    else {
        // Preview mode.
        return (
            <>
                <TableContainer><Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ maxWidth: 20, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}><Chat style={{ fontSize: 45, color: 'blue' }} /></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}><h6>Product Feedback</h6></TableCell>
                        </TableRow>
                    </TableBody>
                </Table></TableContainer>
                <br />
                ECM Insights strives to bring the best experience to our valued customers from small businesses to multi-national organizations around the world through its innovative software and service offerings.
                <br /><br />Whether it's telling us about a new feature that you think would help your organization, or giving us kudos for something that works well - we want to hear from you.
                <br /><br />ECM Insights welcomes your valued feedback on its products!
                <br /><br /><Button size="small" onClick={() => navigate("/Support/ProductFeedback")}>Provide your feedback</Button>

            </>
        );
    }
}
export default ProductFeedback;