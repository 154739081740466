// React Components
import * as React from 'react';

// Material UI Components

// Audit Vault Components
import ReportTemplateActivityByTenantAndPeriodWithLineChart from './ReportTemplateActivityByTenantAndPeriodWithLineChart';

// Audit Vault Utilities


/*
Report returns the unique counts for Exchange deleted mailboxes over a set period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- reportOnDate: The date from which the report is generated on (ex. today or yesterday, or 1 year ago). 
- displayDataSource: 0 or 1.  (if true displays table of sortable source data, if false includes link to report to view full details).
*/
export default function ExchangeMailboxesDeleted(props) {

    // Component Constants
    const { user, companyId, tenantId, periodToUse, loadData, filterByColumn } = props;        
    var theGraphTitle = "Exchange Mailboxes Deleted";    

    // For the optional table to display data source.  815 px to work with.    
    const columns = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth:40, align: 'left' },        
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 125, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },        
        { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth:65, align: 'left' },        
        { id: 'recordType', label: 'Record Type', minWidth: 40, maxWidth: 40, align: 'left' },        
        { id: 'affectedItems', label: 'Affected Items', minWidth: 40, maxWidth: 115, align: 'left' },        
        { id: 'parameters', label: 'Parameters', minWidth: 40, maxWidth: 115, align: 'left' },        
        { id: 'originatingServer', label: 'Originating Server', minWidth: 60, maxWidth: 50, align: 'left' }
    ];    


    // Component Functions

    // Component UI.
    return (
        <>        
            <ReportTemplateActivityByTenantAndPeriodWithLineChart 
                user={user}
                companyId={companyId}
                tenantId={tenantId} 
                periodToUse={periodToUse} 
                theGraphTitle={theGraphTitle} 
                reportType={"ExchangeMailboxesDeleted"}
                columns={columns}
                loadData={loadData}
                filterByColumn={filterByColumn} />
        </>
    );    


}