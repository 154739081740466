import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const ServiceIsDown = () => {
    return (
        <>
        <Alert severity="error">
          <AlertTitle>There seems to be an issue with the service.</AlertTitle>
          Please try your request again momentarily. If the issue persists please raise a support ticket.
        </Alert>
      </>);
}
export default ServiceIsDown;