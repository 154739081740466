// React Components
import * as React from 'react';
import { useState } from "react";

// Audit Vault Components
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';
import SearchOrchestratorForm from '../../components/SearchOrchestratorForm';


function NewSearchOrchestrator(props) {

    // Component Constants.
    const { user } = props;
    
    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "New Search Orchestrator";
    const theSectionDesc = "Register a New Search Orchestrator for use with an application server.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Search Orchestrators', link: '/Admin/HeartbeatForSearch' }
    ];    

    // Component UI    
    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <SearchOrchestratorForm user={user}></SearchOrchestratorForm>            
        </>
    );
}

export default NewSearchOrchestrator;