// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

// Audit Vault Components
import AccessDenied from "./common/AccessDenied";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';


// Audit Vault Utilities
import {formatDate } from '../utilities/common-date-utils';
import { getTenantStatus, getTenantStatusColour } from "../utilities/common-tenant-utils";
import { verifyUserHasTenantRolePermissions  } from '../utilities/common-user-utils';
import { ROLE_CODE_TENANTADMINISTRATOR } from "../constants/constants-roles";

export default function TenantStatsDetails(props) 
{
    const { user, stats } = props;
    const navigate = useNavigate();
    const [accessDenied, setAccessDenied] = useState(false);

    // Section titles.
    const isAdminPage = false;
    const theSectionTitle = "Tenant Statistics";
    const theSectionDesc = "Displays details about your Audit Source jobs, and general statistics of your job runs including last run time and next run time.";
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Company', link: '/mycompany' },
        { name: 'Tenant Stats', link: `/tenant-stats/${stats.tenantId}` },
      ];

    useEffect(() => {
        if (!verifyUserHasTenantRolePermissions(user, stats.tenantId, ROLE_CODE_TENANTADMINISTRATOR))
        {
            setAccessDenied(true);
        }
    }, []);

    

    if (accessDenied)
    {
        return(<AccessDenied></AccessDenied>);
    }
        
    return (
        <>
        <DisplayBreadCrumb paths={theBreadcrumbPath} />
        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />                
        <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', maxWidth: '80%', minWidth: 500}}>
        <Card variant="outlined">
        <CardContent>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell key="tcHealth" style={{ width: '30%' }}>Health:</TableCell>
                <TableCell key="tcStatus">
                    <Typography variant="h6" color={getTenantStatusColour(stats.status)} >
                        <CircleRoundedIcon color={getTenantStatusColour(stats.status)} sx={{paddingRight: 1, alignItems: "center"}} />
                        {getTenantStatus(stats.status)}
                    </Typography>                                                               
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell key="tcErrors" style={{ width: '30%' }}>Errors over the last 24 hours:</TableCell>
                <TableCell key="tcErrorsCount">{stats.errorsIn24Hours}</TableCell>
            </TableRow><TableRow>
                    <TableCell key="tcWarnings" style={{ width: '30%' }}>Warnings over the last 24 hours:</TableCell>
                    <TableCell key="tcWarningsCount">{stats.warningsIn24Hours}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        {stats.status != 0 ?
            
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                    <TableHead>
                        <TableCell key="tcAuditSource">Audit Source</TableCell>
                        <TableCell key="tcLast24Hours">Last 24 Hours</TableCell>
                        <TableCell key="tcTotals">Total</TableCell>
                        <TableCell key="tcLastRunTime">Last Successful Run Time</TableCell>
                        <TableCell key="tcNextRunTime">Approx. Next Run Time</TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell key="tcSharePoint">SharePoint:</TableCell>
                            <TableCell key="tcSharePointTotal24Hours">{stats.totalSharePointAuditRecordsIn24Hours}</TableCell>
                            <TableCell key="tcSharePointTotal">{stats.totalSharePointAuditRecords}</TableCell>
                            <TableCell key="tcSharePointLastRunTime">{formatDate(stats.sharePointAuditLastSuccessfulRunTime)}</TableCell>
                            <TableCell key="tcSharePointNextRunTime">{formatDate(stats.sharePointAuditNextRunTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key="tcExchange">Exchange:</TableCell>
                            <TableCell key="tcExchangeTotal24Hours">{stats.totalExchangeAuditRecordsIn24Hours}</TableCell>
                            <TableCell key="tcExchangeTotal">{stats.totalExchangeAuditRecords}</TableCell>
                            <TableCell key="tcExchangeLastRunTime">{formatDate(stats.exchangeAuditLastSuccessfulRunTime)}</TableCell>
                            <TableCell key="tcExchangeNextRunTime">{formatDate(stats.exchangeAuditNextRunTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key="tcAzureAd">Entra ID:</TableCell>
                            <TableCell key="tcAzureAdTotal24Hours">{stats.totalEntraIDAuditRecordsIn24Hours}</TableCell>
                            <TableCell key="tcAzureAdTotal">{stats.totalEntraIDAuditRecords}</TableCell>
                            <TableCell key="tcAzureAdLastRunTime">{formatDate(stats.entraIDAuditLastSuccessfulRunTime)}</TableCell>
                            <TableCell key="tcAzureAdNextRunTime">{formatDate(stats.entraIDAuditNextRunTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key="tcTeams">Teams:</TableCell>
                            <TableCell key="tcTeamsTotal24Hours">{stats.totalTeamsAuditRecordsIn24Hours}</TableCell>
                            <TableCell key="tcTeamsTotal">{stats.totalTeamsAuditRecords}</TableCell>
                            <TableCell key="tcTeamsLastRunTime">{formatDate(stats.teamsAuditLastSuccessfulRunTime)}</TableCell>
                            <TableCell key="tcTeamsNextRunTime">{formatDate(stats.teamsAuditNextRunTime)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
           
            : ""
            }
             </CardContent>
            <CardActions>
                <Typography component="span" sx={{ textAlign: "left", width: '30%'}}>
                    <Button size="small" onClick={(event) => navigate(-1)}>Back</Button>
                </Typography>
                <Typography component="span" sx={{ textAlign: "right", width: '70%'}}>
                    <Button size="small" sx={{ paddingRight: 5}} onClick={(event) => navigate(`/Tenant/MyTenant?tab=3`)}>View Job History</Button> 
                    {stats.errorsIn24Hours > 0 || stats.warningsIn24Hours > 0 ?
                        <Button size="small" variant="contained" onClick={() => navigate(`/Reports/DiagnosticsReport/${stats.tenantId}`)}>View Logs</Button>
                    :
                        <Button size="small" onClick={() => navigate(`/Reports/DiagnosticsReport/${stats.tenantId}`)}>View Logs</Button>
                    }
                </Typography>
            </CardActions>
            </Card>
            </Paper>
        </>
        );
}