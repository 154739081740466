// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageSharePoint from '../imgs/iconSharePoint.svg';

// Audit Vault Utilities
import ReportTemplateActivityBySpSiteAndUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityBySpSiteAndUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'SharePoint Online Reports', link: '/Reports/SharePoint' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 20, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 90, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'objectId', label: 'Item', minWidth: 50, maxWidth: 140, align: 'left' },
    { id: 'itemType', label: 'Item Type', minWidth: 50, maxWidth: 40, align: 'left' },
    { id: 'listItemUniqueId', label: 'List Item Unique Id', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'sourceFileName', label: 'Item Name', minWidth: 50, maxWidth: 90, align: 'left' }
];

/*
ReportsSharePointAllActivityBySite report - calls the reusable template ReportTemplateActivityBySpSiteAndUserAndDateRange.
*/
function ReportsSharePointAllActivityBySite(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: SharePoint - All Activity by Site Collection, Location or File";
    const theSectionDesc = "This report can be used in an audit investigation to return all SharePoint activity in a Site Collection or specific location/file over a date range.  To run the report you must provide the required Site/Location/file keyword, optional User, and the required valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "SharePointAllActivityBySiteLocationOrFile";

    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityBySpSiteAndUserAndDateRangeWithBarChart
            user={user}
            reportType={"SharePointAllActivityBySiteLocationOrFile"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageSharePoint} />
    );
}
export default ReportsSharePointAllActivityBySite;