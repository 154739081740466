// React Components
import { useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';
import PolicyIcon from '@mui/icons-material/Policy';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Audit Vault Components
import AccessDenied from "../common/AccessDenied";
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from "../common/ProgressBar";
import InvalidTenant from '../common/InvalidTenant';
import DisplayBreadCrumb from "../common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../common/DisplaySectionTitleAndDescription";
import ThresholdCountSelector from "./ThresholdCountSelector";
import StartAndEndDateSelector from "./StartAndEndDateSelector";
import DisplayExportableDataSource from "./DisplayExportableDataSource";

// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../../constants/constants-roles";
import { combineDateJsDateAndTimeToDateTimeOffset } from "../../utilities/common-report-utils";
import { MAX_RESULTS_WHEN_RUNNING_THREAT_DETECTION_SEARCH_QUERY } from "../../constants/constants-reportthreats";
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

/*
TemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart report.

This template is intended to be used with a Report page with UI threshold count and date range input.

Reusable report template used by various reports for any workload source with default input of threshold count and Date Range.
Parameters:
    user
	tenantId
    theSectionTitle
	theSectionDesc
    isAdminPage
    theBreadcrumbPath
    columns
	csvFilenameTitle (prefix) -- the specific unique file name will be appended with the suffix (extension) automatically.
	axiosReportUri    
Optional params:  (if these params are all specified then the report should use these values and run the report automatically, this allows us to use this report in the alert section on demand)
    optionalParamEndDate
    optionalParamStartDate
    optionalParamThresholdCount            
*/
function ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart(props) {

    const { user, tenantId, reportType, theSectionTitle, theSectionDesc, isAdminPage, theBreadcrumbPath, columns, csvFilenameTitle, axiosReportUri, defaultThresholdCount,
            optionalParamEndDate, optionalParamStartDate, optionalParamThresholdCount    
          } = props;

    // Component Constants

    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    
    const [invalidTenantError, setInvalidTenantError] = useState(false);    
    

    const reportInputData = [];  // This data structure stores the input data.
    const [reportResults, setReportResults] = useState([]);  // This data structure stores the audit log results.
    const [reportThreatAnalysisResults, setReportThreatAnalysisResults] = useState([]);  // This data structure stores the threat analysis results.
    // Threshold Count selection related.
    const selectedThresholdCountValue = useRef("");    
    const [errorSelectedThresholdCount, setErrorSelectedThresholdCount] = useState(false);
    const [selectedThresholdCount, setSelectedThresholdCount] = useState(defaultThresholdCount);
    // Date selection related.    
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedStartTime, setSelectedStartTime] = useState('00:00');
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [selectedEndTime, setSelectedEndTime] = useState('00:00');    
    // Form input related.
    const [formErrorText, setFormErrorText] = useState('');
    const [threatResultAnalysisItemsVisible, setThreatResultAnalysisItemsVisible] = useState(true);  // Show the result items by default.
 
    const [csvFilenameSpecific, setCSVFilenameSpecific] = useState('');
    var csvFilenameExtension = ".csv";
    

    // Component Functions.
    const toggleResultAnalysisItemsVisibility = () => {
        setThreatResultAnalysisItemsVisible(!threatResultAnalysisItemsVisible);
    };

    async function fetchData() 
    {                        
        setLoading(true);
        try 
        {
            var usrEmail = "N/A";
            if (user.userEmail)
                usrEmail = user.userEmail;

            var payload = `{
                companyId: ${user.companyId},
                tenantId: ${tenantId},
                reportType: "${reportType}",
                startDt: "${reportInputData[0].periodStartDate}",                                        
                endDt: "${reportInputData[0].periodEndDate}",
                thresholdCount: ${selectedThresholdCount},
                userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
                userEmail: "${await encryptKey(usrEmail)}"               
            }`; 

            var response = await axios.get(`${WEB_API_URL}ReportThreat/RunThreatDetectionReport`, {
                params: {
                    key: `${payload}`
                }
            });
            if (response)
            {                                                
                const results = []; // Temporary array to store the fetched result
                results.push(response.data.auditLogs); // Store the fetched data in the temporary array            
                console.log(response);
                setReportThreatAnalysisResults(response.data);
                setReportResults(results);  // The report results array is stored in memory.   
            }
            else {
                setInvalidTenantError(true);                
            }
        }   
        catch(e) {
            setAccessDenied(true);
            console.log("ERROR: ReportTemplateActivityByUserAndDateRangeWithBarChart.fetchData");
            console.log(e);
        }
        finally{
            setLoading(false);
        }                             
    }

    /*
    async function fetchData() 
    {                        
        setLoading(true);
        try {                                                      
            let response1 = null;
            let response2 = null; 
            let data = null;
                                          
            // Load the tenant and make sure it's valid before continuing.  99% of the time the Tenant is pre-verified in the UI, in the 1% where Sys Admin changes the Tenant ID is where we want to make sure even though the extra call is probably overkill and can be handled on backend error checking, we do both.
            response1 = await getTenantById(tenantId);
            if (response1 != '') {
                // It is a valid tenant since non-blank data was returned in the response.
                // Load results.                                                   
                const results = []; // Temporary array to store the fetched result           
                // Prep the encrypted params to pass to the webapi.                
                data = {
                    tenantId: await encryptKey(tenantId),  
                    thresholdCount: selectedThresholdCount,
                    startDate: reportInputData[0].periodStartDate,                                        
                    endDate: reportInputData[0].periodEndDate
                };                                              
                var keyString = JSON.stringify(data);
                if (keyString) {                                        
                    response2 = await axios.get(`${WEB_API_URL}${axiosReportUri}`, {
                        params: {
                            key: `${keyString}`
                        }
                    });                    
                    results.push(response2.data.auditLogs); // Store the fetched data in the temporary array            
                }  
                setReportThreatAnalysisResults(response2.data);  // Store the threat analysis results in memory.
                setReportResults(results);  // The report results audit log array is stored in memory.                    
                //console.log(response2.data);  // Display the raw data we received from the webapi.
                setLoading(false);                
            }
            else {
                // It is not a valid tenant.
                setLoading(false);
                setInvalidTenantError(true);                
            }
        }   
        catch(e) {            
            // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
            catchAxiosErr2(e, "ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart.fetchData (" + axiosReportUri + ")", setLoading, setServiceIsDownError)
        }                                 
    }
    */

    useEffect(() => {        

        setLoading(true);                
        // Check if User has minimially Report Access role to the specified Tenant.
        var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTREPORTREADER);
        // console.log("MK1: User: " + user + ", tenantId: " + tenantId + ", checkAccess: " + checkReportAccessForTenant);
        if(!user || !checkReportAccessForTenant) {                    
            setAccessDenied(true);
        }
        setLoading(false);  
        
        populateStateFromOptionalParams();

    }, [formErrorText, optionalParamStartDate, optionalParamEndDate, optionalParamThresholdCount]);


    // Function to populate controls based on the optional params that are passed in.
    // If no optional param values are populated for the optional params, it just waits for user input.
    // If all optional param values are populated try to run the report.
    const populateStateFromOptionalParams = () => {        
        // Check if all optional parameters are not null
        if (optionalParamStartDate && optionalParamEndDate && optionalParamThresholdCount) {            
                        
            // Parse optionalParamStartDate and optionalParamEndDate using dayjs
            const startDate = dayjs(optionalParamStartDate);            
            const endDate = dayjs(optionalParamEndDate);                        
            const daysDifference = endDate.diff(startDate, 'day');
    
            // Set selectedStartDate and selectedEndDate state values
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate); 
            // Set selectedThresholdCount state value
            setSelectedThresholdCount(optionalParamThresholdCount);

            // Call handleRun();
            handleRun();
        }
        else {
            // No optional params specified, default the date period to 1 week.
            const nextDay = new Date();
            nextDay.setDate(nextDay.getDate() + 1);
            const oneWeekAgo = new Date(nextDay); // Create a copy of nextDay
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Subtract 7 days
            setSelectedStartDate(dayjs(oneWeekAgo));
            setSelectedEndDate(dayjs(nextDay));        
        }
    };

    // HandleRun - check and validate input controls before running.
    async function handleRun()
    {
        let formattedSelectedStartDate = dayjs(selectedStartDate).format('YYYY-MM-DD'); 
        let formattedSelectedEndDate = dayjs(selectedEndDate).format('YYYY-MM-DD');        
        // Clear the errors at start.
        setErrorSelectedThresholdCount(false);        
        let myFormError = "";        

        // Check the thresholdCount field.
        if(selectedThresholdCount == null || selectedThresholdCount.length === 0) {
            myFormError = "The Threshold Count must be a number and cannot be empty.";            
            setErrorSelectedThresholdCount(true);  // Flag the error.
        }
        // Check if the selectedThresholdCount is not a number
        if (isNaN(selectedThresholdCount)) {
            myFormError = "The Threshold Count must be a number and cannot be empty.";            
            setErrorSelectedThresholdCount(true);  // Flag the error.
        }
        else {
            if(selectedThresholdCount > 100000 || selectedThresholdCount == 0) {
                myFormError = "The ThresholdCount must be between 1 and 100000.";
                setErrorSelectedThresholdCount(true);  // Flag the error.
            }
        }
        // % or * is not valid.
                        
        // Check the start date and end date are valid.
        if(formattedSelectedStartDate > formattedSelectedEndDate) {            
            myFormError = myFormError + " The start date cannot be before the end date.";
        }
        else {
            if(formattedSelectedStartDate == formattedSelectedEndDate) {                
                if(selectedStartTime >= selectedEndTime) {
                    myFormError = myFormError + " The start time must be before the end time when the start date and end date are the same.";
                }
            }
            // Check if the date range exceeds 4 weeks            
            const startDate = dayjs(selectedStartDate);
            const endDate = dayjs(selectedEndDate);            
            const daysDifference = endDate.diff(startDate, 'day');
            if (daysDifference >= 30) {
                myFormError = "The date range must be within 30 days maximum.";
            }
            // Else the dates are not the same - in this case it's all valid at this point.                        
        }

        if (myFormError.length == 0) 
        {
            // There were no errors.
            setFormErrorText("");
            // Format the input data.  The dates assume 0 time offset, so they are in effect UTC comparison.
            reportInputData.push({
                period: 0,
                periodStartDate: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedStartDate, selectedStartTime),
                periodEndDate: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedEndDate, selectedEndTime),
            });            
            // Set specific CSV filename details to use.
            setCSVFilenameSpecific("_ThresholdCountOf" + selectedThresholdCount + "_" + reportInputData[0].periodStartDate + "_to_" + reportInputData[0].periodEndDate);
            //console.log("SUBMIT: " + reportInputData[0].periodStartDate + ", " + reportInputData[0].periodEndDate);
        
            // Fetch the result data.            
            fetchData();            
        }
        else {
            setFormErrorText(myFormError);                        
        }
    }


    // Component UI

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }
    
    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }    

    // Add check to see if invalid tenant error detected.
    if (invalidTenantError) {
        return (<><InvalidTenant></InvalidTenant></>);
    }
    

    // Chart prep items.
    const totalSummaryText = "";

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><PolicyIcon style={{ fontSize: '3rem' }} />
                </div>
            </div>
            <div>
                <Grid container spacing={2}>

                    { /* Render reusable UserID Textfield control. */ }
                    <ThresholdCountSelector selectedThresholdCount={selectedThresholdCount} setSelectedThresholdCount={setSelectedThresholdCount} selectedThresholdCountValue={selectedThresholdCountValue} errorSelectedThresholdCount={errorSelectedThresholdCount} />
                    { /* Render reusable Start and End date picker control. */}
                    <StartAndEndDateSelector selectedStartDate={selectedStartDate} setSelectedStartDate={setSelectedStartDate} selectedStartTime={selectedStartTime} setSelectedStartTime={setSelectedStartTime} selectedEndTime={selectedEndTime} selectedEndDate={selectedEndDate} setSelectedEndDate={setSelectedEndDate} setSelectedEndTime={setSelectedEndTime} />

                    <Grid item xs={12}>
                        <Button variant="contained" type="button" onClick={() => handleRun()}>Run Report</Button>                
                    </Grid>
                </Grid>

                {
                    formErrorText.length > 0 && 
                    (                        
                        <Grid item xs={12}>     
                        <br />                                                   
                        <Alert severity="error">                            
                            <AlertTitle>Form Error: {formErrorText}</AlertTitle>
                        </Alert>
                        </Grid>
                    )                    
                }

                {                    
                    // Display informational note if the threat detection results exceeded the total # of allowable results set on the backend.
                    (!loading && reportThreatAnalysisResults.completedAnalysis != null && reportThreatAnalysisResults.auditLogs.length >= MAX_RESULTS_WHEN_RUNNING_THREAT_DETECTION_SEARCH_QUERY) &&                                
                    (
                        <Grid item xs={12}>
                            <br />
                            <Alert severity="warning">
                                <AlertTitle>The Threat Intelligence Report Results have exceeded the total number of allowed results in a Report.</AlertTitle>
                                The maximum number of results in a Threat Intelligence Report is truncated at: {MAX_RESULTS_WHEN_RUNNING_THREAT_DETECTION_SEARCH_QUERY}.
                                <br />Please refine your Threat Intelligence Report Criteria (above) to narrow down the results.
                            </Alert>
                        </Grid>
                    )                    
                }                                                                                                     

                <br />
                <ProgressBar message="Loading ..." loading={loading} />                                          
                {
                    // Display the Threat Analysis Results.
                    !loading && reportThreatAnalysisResults.completedAnalysis != null &&
                    (                        
                        <>
                        <br />                        
                        <h6>
                            Threat Analysis Results
                        </h6>                           
                        <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', maxWidth: '80%', minWidth: 500}}>
                            <Card variant="outlined">
                            <CardContent>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">                                
                                <TableHead></TableHead>
                                <TableBody>
                                <TableRow>
                                    <TableCell key="tcThreatDetected" style={{ width: '30%' }}>Threat Detected</TableCell>
                                    <TableCell key="tcThreatDetectedValue">
                                    <Typography variant="h6">
                                        <span style={{ color: reportThreatAnalysisResults.wasThreatDetected ? "red" : "green" }}>                                
                                        <CircleRoundedIcon color={reportThreatAnalysisResults.wasThreatDetected ? "red" : "green"} sx={{paddingRight: 1, alignItems: "center"}} />
                                        {reportThreatAnalysisResults.wasThreatDetected ? "Yes" : "No"}
                                        </span>                                        
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell key="tcThresholdCount" style={{ width: '30%' }}>Threshold Count to Flag as Threat</TableCell>
                                    <TableCell key="tcThresholdCountValue">
                                        &gt; { reportThreatAnalysisResults.thresholdCountToDetect }&nbsp; (occurrences per user)
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell key="tcDateRange" style={{ width: '30%' }}>Scanned Date Range</TableCell>
                                    <TableCell key="tcDateRangeValue">
                                        {reportThreatAnalysisResults.startDate} to {reportThreatAnalysisResults.endDate}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell key="tcTotalRecords" style={{ width: '30%' }}>Audit Logs Analyzed</TableCell>
                                    <TableCell key="tcTotalRecordsValue">
                                        {reportThreatAnalysisResults.auditLogs ? reportThreatAnalysisResults.auditLogs.length : 0}
                                    </TableCell>
                                </TableRow>
                                </TableBody>
                            </Table>
                            </CardContent>
                            </Card>
                        </Paper>                            
                        <br />
                        </>
                    )
                }
                {
                    // Threat Analysis Details is hidden, allow option to display it.
                    !loading && reportThreatAnalysisResults.completedAnalysis != null &&
                    (
                        <>
                        <br />                        
                        <a href="#" onClick={toggleResultAnalysisItemsVisibility}>
                            {threatResultAnalysisItemsVisible ? 'Hide Threat Analysis Summary' : 'Show Threat Analysis Summary'}
                        </a>                        
                        <br />
                        </>
                    )                    
                }  
                {
                    // Display the Threat Analysis Details
                    !loading && reportThreatAnalysisResults.completedAnalysis != null && threatResultAnalysisItemsVisible &&
                    (                        
                        <>
                        <br />                        
                        <h6>
                            Threat Analysis Summary Details
                        </h6>                           
                        <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', maxWidth: '100%', minWidth: 500}}>
                            <Card variant="outlined">
                            <CardContent>

                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                            <TableHead>
                                <TableRow key="main">
                                    <TableCell key="tcUser">User</TableCell>
                                    <TableCell key="tcCount">Detected Count</TableCell>
                                    <TableCell key="tcItemThreatDetected">Threat Detected</TableCell>
                                    <TableCell key="tcIdentifiers">Other Identifiers</TableCell>                                
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportThreatAnalysisResults.completedAnalysis.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.userId}</TableCell>
                                        <TableCell>{item.count}</TableCell>
                                        <TableCell>
                                            <span style={{ color: item.wasThreatDetected ? "red" : "green" }}>
                                                <CircleRoundedIcon color={item.wasThreatDetected ? "red" : "green"} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                {item.wasThreatDetected ? "Yes" : "No"}
                                            </span>
                                        </TableCell>
                                        <TableCell>                                            
                                            {
                                            /* Iterate through all the misc fields.  */
                                            Object.entries({
                                                'IP Addresses': item.ipAddresses,
                                                'SP Platforms': item.spPlatforms,
                                                'SP User Agents': item.spUserAgents,
                                                'Entra ID Extended Properties': item.entraIDExtendedProperties,
                                                'Entra ID Device Properties': item.entraIDDeviceProperties
                                            }).map(([identifierName, data]) => (
                                                data != null && Object.keys(data).length > 0 && (
                                                    <div key={identifierName}>
                                                        <b>{identifierName}:</b>
                                                        <ul>
                                                            {Object.entries(data).map(([key, value]) => (
                                                                <li key={key}>{key} (Count: {value})</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))
                                            }                         
                                        </TableCell>
                                    </TableRow>
                                ))}                      
                            </TableBody>
                            </Table>

                            </CardContent>
                            </Card>
                        </Paper>          
                        <br />                                          
                        </>
                    )
                }                
                {                    
                    // Display the audit log data.
                    !loading &&
                    (   
                        <>
                        <br />
                        <h6>Audit Log Details</h6>                                                                                       
                        <DisplayExportableDataSource columns={columns} reportResults={reportResults} csvFilename={(csvFilenameTitle + csvFilenameSpecific + csvFilenameExtension)} tenantId={tenantId} reportType={reportType} user={user} />                                                
                        </>
                    )                    
                }
            
            </div>
        </>
    );
}
export default ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart;