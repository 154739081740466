// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Audit Vault Components
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from '../common/ProgressBar';

// Audit Vault Constants
import { PROVINCE_STATES } from "../../constants/constants-provincestates";
import { COUNTRIES } from "../../constants/constants-countries";

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function UpdateBillingAddressForm(props) {

  const { 
    companyId,
    stripeCustomer,
    setStripeCustomer,
    setShowUpdateBilling,
    setUpdateBillingMsg, 
    fetchBillingAddressData} = props;  

  // Component Constants
  const [loading, setLoading] = useState(true);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const navigate = useNavigate();

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [provinceState, setProvinceState] = useState('NA');
  const [country, setCountry] = useState('CA');
  const [postalZip, setPostalZip] = useState('');
  
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  
  const addressLine1Value = useRef("");
  const addressLine2Value = useRef("");
  const cityValue = useRef("");
  const provinceStateValue = useRef("");
  const countryValue = useRef('');
  const postalZipValue = useRef("");


  if (stripeCustomer)
  {
    useEffect(() => {
      
      async function fetchData() {
        addressLine1Value.current.value = stripeCustomer.addressLine1;
        addressLine2Value.current.value = stripeCustomer.addressLine2;
        cityValue.current.value = stripeCustomer.city;
        if (stripeCustomer.provinceState)
            provinceStateValue.current.value = stripeCustomer.provinceState;
        else
            provinceStateValue.current.value = 'NA';

        if (stripeCustomer.postalZipCode)
            postalZipValue.current.value = stripeCustomer.postalZipCode;
        else
            postalZipValue.current.value = '';
            
        if (stripeCustomer.country)
            countryValue.current.value = stripeCustomer.country;
         
          setAddressLine1(stripeCustomer.addressLine1);
          setAddressLine2(stripeCustomer.addressLine2);
          setCity(stripeCustomer.city);

          if (stripeCustomer.provinceState)
              setProvinceState(stripeCustomer.provinceState);
          if (stripeCustomer.postalZipCode)
            setPostalZip(stripeCustomer.postalZipCode);
          setCountry(stripeCustomer.country);

          setUpdateBillingMsg(false);
                  
      }
      fetchData();
        
    }, []);
  }
  else
  {
      useEffect(() => {
        setLoading(false);
      }, []);
  };

 

  const billingAddressFields = [
    
    { key: 'txtAddressLine1', 
      name: 'addressLine1', 
      type: 'textField',
      required: true,
      label: 'Address Line 1:',  
      inputRef: addressLine1Value,
      onchange: setAddressLine1, 
      error: addressLine1Error, 
      errText: 'Address cannot be empty.', 
      descText: '', 
      placeholderText: 'Enter your address here.',
      editableFlag: true
    },

    { key: 'txtAddressLine2', 
        name: 'addressLine2', 
        type: 'textField',
        required: false,
        label: 'Address Line 2:',  
        inputRef: addressLine2Value,
        onchange: setAddressLine2, 
        error: false,
        errText: '', 
        descText: '', 
        placeholderText: 'Enter your address line 2 here.',
        editableFlag: true
    },

    { key: 'txtCity', 
      name: 'city', 
      type: 'textField',
      required: true,
      label: 'City:',  
      inputRef: cityValue,
      onchange: setCity, 
      error: cityError, 
      errText: 'City cannot be empty.', 
      descText: '',
      placeholderText: 'Enter your City here.',
      editableFlag: true },
  
    { key: 'txtProvinceState', 
      name: 'provinceState', 
      type: 'dropDown',
      required: true,
      label: 'Province/State:', 
      inputRef: provinceStateValue,
      value: provinceState,
      onchange: setProvinceState, 
      error: false,
      menuItem: PROVINCE_STATES, 
      editableFlag: true },
  
    { key: 'txtPostalCodeZip', 
      name: 'postalZipCode', 
      type: 'textField',
      required: false,
      label: 'Postal Code/Zip:', 
      inputRef: postalZipValue,
      onchange: setPostalZip, 
      error: false,
      errText: '', 
      descText: '',
      placeholderText: '', 
      editableFlag: true },
    
    { key: 'txtCountry', 
      name: 'country', 
      type: 'dropDown',
      required: true,
      label: 'Country:', 
      inputRef: countryValue,
      value: country,
      onchange: setCountry, 
      error: false,
      menuItem: COUNTRIES, 
      editableFlag: true },
    
  ];

    function validateInfoHandler() 
    {
      let isError = false;

      if (addressLine1.trim() === '')
      {
        setAddressLine1Error(true);
        isError = true;
      }
      else
        setAddressLine1Error(false);

      if (city.trim() === '')
      {
        setCityError(true);
        isError = true;
      }
      else
        setCityError(false);

      return isError;
    }

    const handleUpdate = async () => {
    {
        let isError = validateInfoHandler();
        if (!isError)
        {
          // create the customer
        var payload =`{
            "id": "${await encryptKey(stripeCustomer.id)}",
            "companyId": ${companyId},
            "name": "${await encryptKey(stripeCustomer.name)}", 
            "city": "${city}",
            "country": "${country}",
            "addressLine1": "${addressLine1}",
            "addressLine2": "${addressLine2}",
            "postalZipCode": "${postalZip}",
            "provinceState": "${provinceState}"
        }`;
        console.log(payload);
        try
        {
              var result = await axios.put(`${WEB_API_URL}Billing/update-billing-address`, null, {
                params: {
                    key: payload,
                }
              });
              if (result)
              {
                  setShowUpdateBilling(false);
                  setUpdateBillingMsg(true);
                  fetchBillingAddressData();
              }
          }
          catch (e)
          {
              console.log("ERROR: UpdateBillingAddressForm.handleUpdate");
              consolelog(e);
              setServiceIsDownError(true);
          }
          finally
          {
              setLoading(false);
          }
        }
    }
}
  

  function renderSwitch(txtfld) {
    switch(txtfld.type) {
      case 'textField':
        return (
          <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
            <b>{txtfld.label}</b>
            <br></br>
            <TextField 
              required={txtfld.required}
              name={txtfld.name}
              placeholder={txtfld.placeholderText}
              inputRef={txtfld.inputRef}
              variant="outlined" 
              size="small"
              style={{ width: 450}}
              InputLabelProps={{ shrink: true }}
              onChange={event => txtfld.onchange(event.target.value) }
              error={txtfld.error}
              helperText={txtfld.error ? txtfld.errText : txtfld.descText}
              disabled={!txtfld.editableFlag} />
            </Typography>
        );

      
      default:
        return (
          <Typography key={txtfld.key} sx={{ marginBottom: 3}} component="div">
            <b>{txtfld.label}</b>
            <br></br>
            <TextField 
                required 
                select 
                onChange={event => txtfld.onchange(event.target.value)} 
                name={txtfld.name}  
                variant="outlined" 
                size="small"
                style={{ width: 450}}
                InputLabelProps={{ shrink: true }}
                inputRef={txtfld.inputRef} 
                value={txtfld.value} 
                disabled={!txtfld.editableFlag} // Set disabled prop based on editableFlag
                >
            {txtfld.menuItem.map((option) => (
              <MenuItem key={option.value} value={option.value} >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </Typography>
        );
    }
  };

// Component UI
if (loading && name) {
  return (<ProgressBar message="Loading ..." loading={loading} />);
}

if (serviceIsDownError) {
  return (<><ServiceIsDown></ServiceIsDown></>);
}

return(
  <>
    
    <Grid
      container
      marginTop={0}
      spacing={0}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
        <Grid item xs={8} >
        
            {billingAddressFields.map((txtfld) => (            
              renderSwitch(txtfld)
          ))}
          
        </Grid>
    </Grid>
      <div style={{ marginLeft: '0px' }} >
        <br />
        {
          !stripeCustomer ? '' : <Button variant="contained" className="ml-auto" onClick={() => handleUpdate()}> Save Changes </Button>
        }
          
      </div>
      </>
  )
}