// React Components
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

// Material UI Components
import { Grid, Paper } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import DisplayExportableDataSource from "../components/reports/DisplayExportableDataSource";
import SearchResultsByWorkloadSource from "../components/reports/SearchResultsByWorkloadSource";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { getCompanyByCompanyId } from "../utilities/common-company";
import { SEARCH_STATUS_COMPLETED } from "../constants/constants-searchjobstatus";
import { formatDate } from '../utilities/common-date-utils';
import { MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY } from "../constants/constants-search";
import { encryptKey } from '../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Insights Search', link: '/Reports/InsightsSearch/All' },
];

const theSectionTitle = "Insights Search: View Your Search Results";
const theSectionDesc = "Your search results are displayed below with a summary of your corresponding search criteria. Note: Only the original Search Performer and/or the Company Administrator may view this specific Search Result.";
const isAdminPage = false;


/*
ReportsSearchResultsCompleted report.

Note:
A User can only view a SearchJob and SearchResults if they are the one who created the Search or if they are the SearchJob.tenantID's company admin or obviously sys admin.
Searches can only be viewed by the Search performer and or the Company Admin.  Tenant Admin's and other Report Users cannot view other people's search results.

*/
function ReportsSearchResultsCompleted(props) {

    // Component Constants
    const { user } = props;
    const { searchJobId } = useParams();

    const [company, setCompany] = useState("");
    const [accessDenied, setAccessDenied] = useState(false);
    const [searchIsNotCompletedOrInvalid, setSearchIsNotCompletedOrInvalid] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [searchJob, setSearchJob] = useState("");
    const [searchResultsInfo, setSearchResultsInfo] = useState("");
    const [searchCriteriaVisible, setSearchCriteriaVisible] = useState(false);  // Hide the Search Criteria by default.
    const [searchProcessInfoVisible, setSearchProcessInfoVisible] = useState(false);  // Hide the Search Process info by default.  This intended only viewable by an Admin level user.
    const [tabValue, setTabValue] = useState(0);
    const [tenantId, setTenantId] = useState(0);

    // Data table columns for SharePoint.  815 px to work with.
    const columnsWorkloadSharePoint = [
        { id: 'id', label: 'Id', minWidth: 20, maxWidth: 40, align: 'left' },
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 90, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
        { id: 'objectId', label: 'Item', minWidth: 50, maxWidth: 140, align: 'left' },
        { id: 'itemType', label: 'Item Type', minWidth: 50, maxWidth: 40, align: 'left' },
        { id: 'listItemUniqueId', label: 'List Item Unique Id', minWidth: 50, maxWidth: 160, align: 'left' },
        { id: 'sourceFileName', label: 'Item Name', minWidth: 50, maxWidth: 90, align: 'left' }
    ];

    // Data table columns for Exchange.  815 px to work with.
    const columnsWorkloadExchange = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 125, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
        { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth: 65, align: 'left' },
        { id: 'recordType', label: 'Record Type', minWidth: 40, maxWidth: 40, align: 'left' },
        { id: 'affectedItems', label: 'Affected Items', minWidth: 40, maxWidth: 115, align: 'left' },
        { id: 'parameters', label: 'Parameters', minWidth: 40, maxWidth: 115, align: 'left' },
        { id: 'originatingServer', label: 'Originating Server', minWidth: 60, maxWidth: 50, align: 'left' }
    ];

    // Data table columns for EntraID.  815 px to work with.
    const columnsWorkloadEntraID = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
        { id: 'objectId', label: 'ObjectId', minWidth: 50, maxWidth: 100, align: 'left' },
        { id: 'modifiedProperties', label: 'Modified Properties', minWidth: 50, maxWidth: 100, align: 'left' },
        { id: 'actor', label: 'Actor', minWidth: 50, maxWidth: 100, align: 'left' },
        { id: 'target', label: 'Target', minWidth: 50, maxWidth: 100, align: 'left' },
    ];

    // Data table columns for Teams.  815 px to work with.
    const columnsWorkloadTeams = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
        { id: 'teamName', label: 'Team Name', minWidth: 40, maxWidth: 85, align: 'left' },
        { id: 'channelName', label: 'Channel Name', minWidth: 40, maxWidth: 85, align: 'left' },
        { id: 'members', label: 'Members', minWidth: 100, maxWidth: 115, align: 'left' },
        { id: 'attendees', label: 'Attendees', minWidth: 100, maxWidth: 115, align: 'left' }
    ];


    // Component Functions.
    const toggleSearchCriteriaVisibility = () => {
        setSearchCriteriaVisible(!searchCriteriaVisible);
    };

    const toggleSearchProcessInfoVisibility = () => {
        setSearchProcessInfoVisible(!searchProcessInfoVisible);
    };

    useEffect(() => {
        /*
        We load the SearchJob.        
          Check that the SearchJob was created by the Current User or the Current User is the Company Administrator or Sys Admin for the Tenant/Company.
          If User has access
            Check if the SearchJob is completed.
                If SearchJob is completed then load the SearchResults and display them.
                Else display error message.
          If User does not have access then display error message.
        */
        async function fetchData() {
            setLoading(true);
            var hasAccess = false;
            var rsaKey = null;
            try {
                if (user && user.companyId) {
                    // The companyId was specified.
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {
                        // Company is valid.  We reference Company using response because setting the Company may take some time.  
                        setCompany(response1);
                        // Now load the Search Job specified.
                        rsaKey = await encryptKey(`{searchJobId: ${searchJobId}}`);
                        if (rsaKey) {
                            var response2 = await axios.get(`${WEB_API_URL}Search/GetSearchJobById`, {
                                params: {
                                    key: `${rsaKey}`
                                }
                            });
                            if (response2) {

                                // We reference response2 here because setting the SearchJob may take some time.                                    
                                setSearchJob(response2.data);  // Store the SearchJob.                                                                                        
                                if (response2.data.id != null) {
                                    // Make sure the job isn't blank -- meaning the Search Job doesn't exist.                                                                
                                                                        
                                    setTenantId(response2.data.tenantId);  // Store the tenant Id.
                                    // Check that the Current User has permissions to view the Search Job and the Search Results.
                                    if (response2.data.searchCreatedByUser == user.userEmail) {
                                        hasAccess = true;  // The current user created the search, set to true.
                                    }
                                    else {
                                        // The current user did not create the search.  Check if they are Company or Sys Admin.
                                        if (user.isSystemAdmin == true) {
                                            hasAccess = true;
                                        }
                                        else {
                                            if (user.isCompanyAdmin == true) {
                                                // Make sure the SearchJob.tenantID is in the Tenant list for the User's corresponding Company.                                        
                                                if (response1.tenantList != null) {
                                                    var isSearchJobsTenantInTenantList = response1.tenantList.some(tenant => tenant.id === response2.data.tenantId);
                                                    if (isSearchJobsTenantInTenantList == true) {
                                                        // User is a Company Admin and the Tenant specified in the Search Job is in the User's company tenant list.
                                                        hasAccess = true;
                                                    }
                                                }
                                            }
                                            // Else the user does not have access to the SearchJob and SearchResults.                                    
                                        }
                                    }
                                    if (hasAccess) {
                                        setAccessDenied(false);
                                        // Confirm that the Search Job is actually completed.
                                        if (response2.data.jobStatus != SEARCH_STATUS_COMPLETED) {
                                            // The search is not completed, display an error.
                                            setSearchIsNotCompletedOrInvalid(true);  // Set to true.
                                        }
                                        // Load the Search Results.  We load it here versus the sub components because we can pass it to both child sub components instead of loading individually.
                                        rsaKey = await encryptKey(`{tenantId: ${response2.data.tenantId}, searchJobId: ${response2.data.id}}`);
                                        if (rsaKey) {
                                            var response3 = await axios.get(`${WEB_API_URL}Search/GetSearchResultsForCompletedSearchJob`, {
                                                params: {
                                                    key: `${rsaKey}`
                                                }
                                            });
                                            if (response3) {
                                                setSearchResultsInfo(response3.data);  // Store the Search Results Info.
                                            }
                                        }
                                    }
                                    else {
                                        setAccessDenied(true);
                                    }
                                }  // End if jobStatus == 0
                                else {
                                    // No such job.
                                    setSearchIsNotCompletedOrInvalid(true);  // Set to true.
                                }
                            }  // End if response2.
                        }  // End if rsakey.
                    }  // End if response1.
                }  // End if User.

                setLoading(false);
            }
            catch (e) {
                catchAxiosErr2(e, "Report.fetchData", setLoading, setServiceIsDownError);
                setSearchIsNotCompletedOrInvalid(true);  // Set to true to display a nice error message.
            }
        }

        fetchData();
    }, []);

    // Tab functionality related.
    // Tab panel.
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        value: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };




    // Component UI
    if (loading) {
        console.log("Loading Data ...")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Get the counts if applicable.
    if (!searchIsNotCompletedOrInvalid && !accessDenied) {
        // The user has access, so let's pull up the Search Result stats.
        var resultsSharePointCount = 0;
        var resultsExchangeCount = 0;
        var resultsEntraIDCount = 0;
        var resultsTeamsCount = 0;
        if (searchResultsInfo && searchResultsInfo.sharePointAuditHistoryResults != null) {
            resultsSharePointCount = searchResultsInfo.sharePointAuditHistoryResults.length;
        }
        if (searchResultsInfo && searchResultsInfo.exchangeAuditHistoryResults != null) {
            resultsExchangeCount = searchResultsInfo.exchangeAuditHistoryResults.length;
        }
        if (searchResultsInfo && searchResultsInfo.entraIDAuditHistoryResults != null) {
            resultsEntraIDCount = searchResultsInfo.entraIDAuditHistoryResults.length;
        }
        if (searchResultsInfo && searchResultsInfo.teamsAuditHistoryResults != null) {
            resultsTeamsCount = searchResultsInfo.teamsAuditHistoryResults.length;
        }
        //console.log("RESULTS: " + resultsSharePointCount + ", " + resultsExchangeCount + ", " + resultsEntraIDCount  + ", " + resultsTeamsCount);
    }

    return (

        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br />
                    <ManageSearchIcon style={{ fontSize: '3rem' }} />
                </div>
            </div>

            <div>
                <Grid container spacing={2}>

                    {
                        accessDenied &&
                        <Grid item xs={12}><AccessDenied /></Grid>
                    }

                    {
                        searchIsNotCompletedOrInvalid &&
                        (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Cannot view the Search Results for a Search that has not yet been completed or is invalid.
                                    </AlertTitle>
                                </Alert>
                            </Grid>
                        )
                    }


                    { /* Display the Search Job Info and Criteria */}
                    {
                        (!searchIsNotCompletedOrInvalid && !accessDenied) &&
                        (
                            <>
                                {/* There were no search results.  Display informational note to refine your Search Criteria. */}
                                {
                                    (resultsSharePointCount + resultsExchangeCount + resultsEntraIDCount + resultsTeamsCount === 0) &&
                                    (
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                <AlertTitle>The Search did not return any search results.</AlertTitle>
                                                If you are expecting results - please refine your Search Criteria and try again.
                                            </Alert>
                                        </Grid>
                                    )
                                }
                                {/* Display informational note if any of the workloads exceeded the total # of allowable search results being exceeded. */}
                                {
                                    (resultsSharePointCount >= MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY || resultsExchangeCount >= MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY || resultsEntraIDCount >= MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY || resultsTeamsCount >= MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY) &&
                                    (
                                        <Grid item xs={12}>
                                            <Alert severity="warning">
                                                <AlertTitle>One of more of the returned Search Results has exceeded the total number of allowed results in a Search Result.</AlertTitle>
                                                The maximum number of Search Results per Workload is: {MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY}.
                                                <br />Please refine your Search Criteria to narrow down the Search Results.
                                            </Alert>
                                        </Grid>
                                    )
                                }

                                <Grid item xs={6}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>

                                        <b>Search Name:</b> {searchJob.searchName} <br />
                                        <b>Search Performed By:</b> {searchJob.searchCreatedByUser} <br />
                                        <b>Search Date:</b> {formatDate(searchJob.searchCreatedDate)} <br /><br />
                                        <a href="#" onClick={toggleSearchCriteriaVisibility}>
                                            {searchCriteriaVisible ? 'Hide Search Criteria' : 'Show Search Criteria'}
                                        </a>
                                        {
                                            user.isSystemAdmin &&
                                            (
                                                <>
                                                    &nbsp; |&nbsp; <a href="#" onClick={toggleSearchProcessInfoVisibility}>
                                                        {searchProcessInfoVisible ? 'Hide Search Process Metadata (Admin Only)' : 'Show Search Process Metadata (Admin Only)'}
                                                    </a>
                                                </>
                                            )
                                        }
                                        {
                                            // Hidden Search Criteria by default.
                                            searchCriteriaVisible == true &&
                                            (
                                                <>
                                                    <br /><br />
                                                    <span style={{ fontWeight: 'bold' }}>Search Criteria</span><br />
                                                    <span style={{ fontSize: 'smaller' }}>
                                                        <br />
                                                        System Search ID: {searchJob.id}<br />
                                                        Start Date (UTC): {searchJob.criteriaStartDateUtc}<br />
                                                        End Date (UTC): {searchJob.criteriaEndDateUtc}<br />
                                                        Keyword: {searchJob.criteriaKeyword}<br />
                                                        Site or Location: {searchJob.criteriaSiteOrLocation}<br />
                                                        User(s): {searchJob.criteriaForUsers}<br />
                                                        Workload: {searchJob.criteriaForWorkload}<br />
                                                        <br />
                                                        SharePoint Activities<br />
                                                        <li>Operation Names: {searchJob.criteriaActivitiesSharePoint}</li>
                                                        <li>Friendly Names: {searchJob.criteriaActivitiesFriendlySharePoint}</li>
                                                        Entra ID Activities<br />
                                                        <li>Operation Names: {searchJob.criteriaActivitiesEntraID}</li>
                                                        <li>Friendly Names: {searchJob.criteriaActivitiesFriendlyEntraID}</li>
                                                        Exchange Activities<br />
                                                        <li>Operation Names: {searchJob.criteriaActivitiesExchange}</li>
                                                        <li>Friendly Names: {searchJob.criteriaActivitiesFriendlyExchange}</li>
                                                        Teams Activities<br />
                                                        <li>Operation Names: {searchJob.criteriaActivitiesTeams}</li>
                                                        <li>Friendly Names: {searchJob.criteriaActivitiesFriendlyTeams}</li>
                                                    </span>
                                                </>
                                            )
                                        }
                                        {
                                            // Hidden Search Process Info metadata -- intended to be viewed only by Sys Admin level users.
                                            (user.isSystemAdmin && searchProcessInfoVisible == true) &&
                                            (
                                                <>
                                                    <br /><br />
                                                    <span style={{ fontWeight: 'bold' }}>Search Process Metadata</span><br />
                                                    <span style={{ fontSize: 'smaller' }}>
                                                        <br />
                                                        Process Start Time: {formatDate(searchJob.processSearchStartTime)}<br />
                                                        Process End Time: {formatDate(searchJob.processSearchEndTime)}<br />
                                                        Search Orchestrator Id: {searchJob.processSearchOrchestratorId}<br />
                                                        Search Server Name: {searchJob.processSearchServerNm}<br />
                                                    </span>
                                                </>

                                            )
                                        }
                                    </Paper>
                                </Grid>

                                {/* Display a Summary of the Search Results with a chart. */}
                                <Grid item xs={6}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                                        <SearchResultsByWorkloadSource totalResults={searchJob.totalResults} totalSharePointResults={resultsSharePointCount} totalExchangeResults={resultsExchangeCount} totalEntraIDResults={resultsEntraIDCount} totalTeamsResults={resultsTeamsCount} />
                                        <br />
                                    </Paper>
                                </Grid>

                                {/* Display the actual search results by workload. */}
                                <Grid item xs={12}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                                        <h6>Search Results</h6><br />

                                        {/* Tab Panel. */}
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="Search Results by Workload">
                                                    <Tab label="SharePoint" {...a11yProps(0)} />
                                                    <Tab label="Exchange" {...a11yProps(1)} />
                                                    <Tab label="EntraID" {...a11yProps(2)} />
                                                    <Tab label="Teams" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                            <TabPanel value={tabValue} index={0}>
                                                <DisplayExportableDataSource columns={columnsWorkloadSharePoint} reportResults={searchResultsInfo.sharePointAuditHistoryResults} csvFilename={searchJob.searchName + "_ResultsForSharePoint.csv"} tenantId={tenantId} reportType={"SearchResultsSharePoint"} user={user}/>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={1}>
                                                <DisplayExportableDataSource columns={columnsWorkloadExchange} reportResults={searchResultsInfo.exchangeAuditHistoryResults} csvFilename={searchJob.searchName + "_ResultsForExchange.csv"} tenantId={tenantId} reportType={"SearchResultsExchange"} user={user}/>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={2}>
                                                <DisplayExportableDataSource columns={columnsWorkloadEntraID} reportResults={searchResultsInfo.entraIDAuditHistoryResults} csvFilename={searchJob.searchName + "_ResultsForEntraID.csv"} tenantId={tenantId} reportType={"SearchResultsEntra"} user={user} />
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={3}>
                                                <DisplayExportableDataSource columns={columnsWorkloadTeams} reportResults={searchResultsInfo.teamsAuditHistoryResults} csvFilename={searchJob.searchName + "_ResultsForTeams.csv"} tenantId={tenantId} reportType={"SearchResultsTeams"} user={user} />
                                            </TabPanel>
                                        </Box>

                                    </Paper>
                                </Grid>


                            </>

                        )  // End valid display area.
                    }


                </Grid>
            </div>

        </>
    );


}
export default ReportsSearchResultsCompleted;