import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const InvalidTenant = () => {
    return (
        <>
        <Alert severity="error">
          <AlertTitle>The Tenant you are trying to access is invalid.</AlertTitle>          
          If you believe you are getting this message in error, please raise a support ticket.
        </Alert>
      </>);
}
export default InvalidTenant;