// React Components
import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CardActions, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


// Audit Vault Components
import ServiceIsDown from "../../components/common/ServiceIsDown";
import CompanyNag from '../../components/common/CompanyNag';
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Components
import { getCompanyByAzureTenantId } from '../../utilities/common-company';
import { getAuditVaultUser } from '../../utilities/common-user-utils';

const steps = ['1: Your Company Details', '2: Update Billing Info', '3: Start Using Audit Vault for M365']


function MyCompanySetupComplete(props) {

    const { user, openCompanyNag, setOpenCompanyNag, setUser } = props;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const navigate = useNavigate();

    const activeStep = 2; // This page just works with inital setup

    async function fetchData() {
        try {
            if (loading) {
                switch (isAuthenticated) {
                    case false:
                        navigate("/MyCompanySetupCreate");
                        break;

                    case true:
                        var response = await getCompanyByAzureTenantId(accounts[0].tenantId);
                        if (response) {
                            setCompany(response);

                            const response2 = await getAuditVaultUser(isAuthenticated, accounts[0]);
                            if (response2) {
                                setUser(response2);
                            }
                        }
                        break;
                }
            }

        }
        catch (e) {
            console.log("ERROR: MyCompanySetupComplete.fetchData")
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (loading) {
        return (<ProgressBar loading={loading}></ProgressBar>)
    }

    /* Company Setup Stages
           CompanyCreated = 0,         // first created by user 
           CompanySetupComplete = 1, 	// All company info is successfully created
           TenantCreated = 2,      	// Tenant Created
           GrantedAccess = 3,      	// Global Admin granted access to Audit Vault to Tenant
           TenantSetupComplete = 4, 	// Set up complete and first audit ran successfully
           PaymentComplete = 5,   		// Payment completed by Authorized user at organization
           PaymentCompleteManually = 6 // Payment Completed but done manually or delayed from Stripe (Bank Deposit)
   */

    if (!loading) {
        return (
            <>
                <Typography variant="h5" component="h5">Audit Vault for M365 - 3 Easy Steps to Sign Up</Typography>
                <div><br></br></div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} sx={{ paddingBottom: '3ch' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}

                    </Stepper>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item sx={{ minWidth: 440, width: "100%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" component="div">Company Setup Complete!</Typography>
                                            <Typography component="div"><br></br></Typography>
                                            <Typography component="div">Congratulations!  You've taken the first step in preserving your Audit History from Microsoft 365.</Typography>
                                            <Typography component="div"><br></br></Typography>
                                            <Typography component="div">Your 14 day free trial begins once you have set up and configured your tenant.</Typography>
                                            <Typography component="div"><br></br></Typography>
                                            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
                                        </CardContent>
                                        <CardActions>

                                        </CardActions>
                                    </Card>
                                </Paper>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component="div" sx={{ textAlign: 'left' }}> <Button variant="contained" size="small" disabled={!isAuthenticated} onClick={() => navigate("/MyCompanySetupUpdate")}>Back</Button> </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
            </>
        );
    };
}

export default MyCompanySetupComplete;