// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageEntraID from '../imgs/iconEntraID.svg';

// Audit Vault Utilities
import ReportTemplateActivityByUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Entra ID Reports', link: '/Reports/EntraID' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'userType', label: 'User Type', minWidth: 40, maxWidth: 40, align: 'left' },
    { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth: 60, align: 'left' },
    { id: 'actorIpAddress', label: 'IP Address', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'applicationId', label: 'Application ID', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'deviceProperties', label: 'Device Properties', minWidth: 50, maxWidth: 100, align: 'left' }
];

/*
ReportsEntraIDAllActivityByUser report - calls the reusable template ReportTemplateActivityByUserAndDateRange.
*/
function ReportsEntraIDAllActivityByUser(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: Entra ID (Azure AD) - All Activity by User";
    const theSectionDesc = "This report can be used in an audit investigation to return all Entra ID activity by a User over a date range.  To run the report you must provide the User keyword and a valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "EntraIDAllActivityByUser";

    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByUserAndDateRangeWithBarChart
            user={user}
            reportType={"EntraIDAllActivityByUser"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageEntraID} />

    );
}
export default ReportsEntraIDAllActivityByUser;