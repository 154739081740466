import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import currencyFormatter from '../../../utilities/common-currency-utils';

const styles = StyleSheet.create({
    topRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        fontStyle: 'bold',
    },
    subDescription: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        fontSize: 11,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
        fontStyle: 'bold',
    },
    subTotal: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
        fontSize: 11,
    },
  });


const InvoiceTableFooter = ({invoice}) => {
    return(
        <>
        <View style={styles.topRow}>
            <Text style={styles.subDescription}>Sub Total</Text>
            <Text style={styles.subTotal}>{currencyFormatter(invoice.subTotal/100)}</Text>
        </View>
        {invoice.isDiscountable && invoice.totalDiscountsAmount > 0 &&
        (
            <View style={styles.row}>
            <Text style={styles.subDescription}>Discounts</Text>
            <Text style={styles.subTotal}>({currencyFormatter(invoice.totalDiscountsAmount/100)})</Text>
            </View>
        )

        }
        <View style={styles.row}>
            <Text style={styles.subDescription}>Tax</Text>
            <Text style={styles.subTotal}>{currencyFormatter(invoice.tax/100)}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>{currencyFormatter(invoice.total/100)}</Text>
        </View>
        </>
    )
};
  
export default InvoiceTableFooter