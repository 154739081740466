// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Utilities.
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function SystemEmailForm(props) {

    const { user } = props;
    const { type } = useParams();

    // Component Constants
    const navigate = useNavigate();

    const [msg, setMsg] = useState("");
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [loading, setLoading] = useState(true);

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [errorSubject, setErrorSubject] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const subjectValue = useRef('');
    const messageValue = useRef("");

    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState('Test email Sent');

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'System Emails List', link: '/Admin/SystemEmails' },
        { name: 'Update System Email', link: `/Admin/SystemEmail-update/${type}` }
    ];
    const theSectionTitle = "Update System Email";
    const theSectionDesc = "";
    const isAdminPage = true;

    useEffect(() => {
        getSystemEmail();
    }, []);

    const getSystemEmail = async () => {

        try {
            if (user && user.isSystemAdmin) {
                var data = {
                    type: type,
                };
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) {
                    var response = await axios.get(`${WEB_API_URL}AdminUtilities/GetSystemEmailByType`, {
                        params: {
                            key: keyString,
                        }
                    });
                    if (response) {
                        console.log(response);
                        setMsg(response.data);
                        setSubject(response.data.subject);
                        setMessage(response.data.message);
                    }
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log("ERROR: SystemEmailForm.getSystemEmail");
            console.log(e);
            setAccessDenied(true);
            //setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    const getEmailType = (val) => {
        switch (val) {
            case 0:
                return "CustomerWelcome";
            case 1:
                return "SupportNewCustomerSignup";
            case 2:
                return "SuportEmail";
            case 3:
                return "CustomerNotification";
            case 4:
                return "SupportProductFeedback";
            case 5:
                return "SupportSupportTicket";
            case 6:
                return "CustomerSupportTicket";
        }
    };

    function handleValidate() {
        let isError = false;

        if (subject.trim() === '' ? setErrorSubject(true) : setErrorSubject(false));
        if (subject.trim() === '') { isError = true; }

        if (message.trim() === '' ? setErrorMessage(true) : setErrorMessage(false));
        if (message.trim() === '') { isError = true; }

        return isError;
    }

    const handleSendTest = async () => {
        try {
            if (user && user.isSystemAdmin) {
                setOpenNotificationMsg(`Test email sent to ${user.userEmail}`)
                let isError = handleValidate();
                if (!isError) {
                    var payload = {
                        microsoftGraphId: await encryptKey(user.microsoftGraphId),
                        subject: subject,
                        message: message
                    };

                    var response = await axios.post(`${WEB_API_URL}AdminUtilities/SendTestEmail`, null, {
                        params: {
                            key: JSON.stringify(payload),
                        }
                    });
                    if (response) {
                        setOpenNotification(true);
                    }
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log("ERROR: SystemEmailForm.handleSendTest");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        try {
            if (user && user.isSystemAdmin) {
                let isError = handleValidate();
                if (!isError) {
                    var payload = {
                        id: msg.id,
                        messageType: msg.messageType,
                        subject: await encryptKey(subject),
                        message: message
                    };

                    var result = await axios.put(`${WEB_API_URL}AdminUtilities/UpdateSystemEmail`, null, {
                        params: {
                            key: JSON.stringify(payload),
                        }
                    });

                    if (result) {
                        navigate('/Admin/SystemEmails');
                    }
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log("ERROR: SystemEmailForm.handleUpdate");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    };

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // Component UI
    if (serviceIsDownError) {
        return (<ServiceIsDown></ServiceIsDown>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <Typography component="span">
                {!loading &&
                    <>
                        <Typography><b>Update System Email:</b></Typography>
                        <Typography><br></br></Typography>
                        <Card variant="outlined" sx={{ maxWidth: 800 }}>
                            <CardContent>
                                <ProgressBar message="Loading ..." loading={loading} />
                                <Typography key='txtType' sx={{ marginBottom: 3 }} component="div">
                                    <b>Email Type:</b>
                                    <br></br>
                                    {getEmailType(msg.messageType)}
                                </Typography>
                                <Typography key='txtSubject' sx={{ marginBottom: 3 }} component="div">
                                    <b>Subject:</b>
                                    <br></br>
                                    <TextField required
                                        name="subjectRef"
                                        value={subject}
                                        inputRef={subjectValue}
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 450 }}
                                        inputProps={{ maxLength: 100 }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={event => setSubject(event.target.value)}
                                        error={errorSubject}
                                        helperText={errorSubject ? 'Subject cannot be empty.' : ''}
                                    >
                                    </TextField>
                                </Typography>
                                <Typography key='txtMessage' sx={{ marginBottom: 3 }} component="div">
                                    <b>Message:</b>
                                    <br></br>
                                    <TextField required
                                        name="messageRef"
                                        value={message}
                                        inputRef={messageValue}
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        maxRows={20}
                                        style={{ width: 750 }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={event => setMessage(event.target.value)}
                                        error={errorMessage}
                                        helperText={errorMessage ? 'Message cannot be empty.' : ''}
                                    >
                                    </TextField>
                                </Typography>
                                <CardActions>
                                    <Button size="small" onClick={() => navigate('/Admin/SystemEmails')}>Back</Button>
                                    <Typography sx={{ width: '100%', textAlign: 'right' }}>
                                        <Button size="small" onClick={() => handleSendTest()}>Send Test Email</Button>
                                        <Button size="small" onClick={() => handleUpdate()}>Save</Button>
                                    </Typography>
                                </CardActions>
                            </CardContent>
                        </Card>
                    </>
                }
            </Typography>
            <div>
                <Snackbar
                    open={openNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message={openNotificationMsg}
                    action={openNotificationAction} />
            </div>
        </>);
}


export default SystemEmailForm;