// REact Components
import React from 'react';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

// Mateerial UI Components
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Audit Vault Components
import MyInvoice from "./MyInvoice";

// Audit Vault Common
import { formatInvoiceDate } from '../../utilities/common-date-utils';

const DownloadInvoiceButton = (props) => {

  const { invoice } = props;

  const downloadPdf = async () => {
    console.log(invoice)
    const fileName = `AuditVault-Invoice-${invoice.number}-${formatInvoiceDate(invoice.dateCreated)}.pdf`;
    const blob = await pdf(<MyInvoice invoice={invoice} />).toBlob();
    saveAs(blob, fileName);
  };

  return <Button onClick={downloadPdf} ><PictureAsPdfIcon sx={{color: 'red' }}/></Button>;
};

export default DownloadInvoiceButton;