// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components

// Audit Vault Utilities
import ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart from "../components/reports/ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart";


const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Threat Intelligence', link: '/Reports/ThreatIntelligence' },
];

// For the optional table to display data source.  815 px to work with.    
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'userType', label: 'User Type', minWidth: 40, maxWidth: 40, align: 'left' },
    { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth: 60, align: 'left' },
    { id: 'actorIpAddress', label: 'IP Address', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'applicationId', label: 'Application ID', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'deviceProperties', label: 'Device Properties', minWidth: 50, maxWidth: 100, align: 'left' }
];

/*
ReportsThreatEntraIDFailedLoginsInvalidPassword report - calls the reusable template ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart.
*/
function ReportsThreatEntraIDFailedLoginsInvalidPassword(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, paramEndDate, paramStartDate, paramThresholdCount } = useParams();  // If paramEndDate, paramStartDate, and paramThresholdCount are included then run the report automatically.    

    const theSectionTitle = "Report: Threat Intelligence - Identify Failed Logins Due to Invalid Password";
    const theSectionDesc = "This report can be used to identify suspicious failed logins due to invalid passwords over a specified date range.  Even a small number of login failures due to invalid passwords may indicate malicious brute force login attempts.  To run the report you must provide the abnormal threshold count (# of failed logins per user) and the corresponding date range to flag (maximum 30 day period to scan, default is 7 days).";
    const isAdminPage = false;
    const csvFilenameTitle = "ReportsThreatEntraIDFailedLoginsDueToInvalidPassword";
    const defaultThresholdCount = 5;

    // Component Functions.
    return (

        // Call ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart and pass in parameters.
        <ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart
            user={user}
            reportType={"EntraIDThreatDetectionFailedLoginsByInvalidPassword"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            defaultThresholdCount={defaultThresholdCount}
            optionalParamEndDate={paramEndDate}
            optionalParamStartDate={paramStartDate}
            optionalParamThresholdCount={paramThresholdCount}
        />
    );

}
export default ReportsThreatEntraIDFailedLoginsInvalidPassword;