// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Grid from '@mui/material/Grid'; 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Audit Vault Constants
import { MESSAGE_STATUS } from "../constants/constants-messagestatus";

// Audit Vault Components
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { encryptKey } from '../utilities/common-encrypt-util';

// The MessageForm component is used for both editing an existing message and creating a new message.
export default function MessageForm(props) {

    // Component Constants.
    const { isAdminPage, messageId } = props
    const navigate = useNavigate();    

    const [title, setTitle] = useState('');
    //const [publishedDate, setPublishedDate] = useState('');
    const [messageMarkdown, setMessageMarkdown] = useState('');
    const [status, setStatus] = useState(0);
    
    const [titleError, setTitleError] = useState(false);
    const [messageMarkdownError, setMessageMarkdownError] = useState(false);
    //const [statusError, setStatusError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const titleValue = useRef("");
    //const publishedDateValue = useRef("");
    const messageMarkdownValue = useRef("");
    const statusValue = useRef(0);
  
    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    useEffect(() => {
        getData();
    }, []);


    async function getData() {
        setLoading(true);
        try {
            // We attempt to load the message.
            if(messageId) 
            {
                var rsaKey = await encryptKey(`{companyId: -1, messageId: ${messageId}}`);
                if (rsaKey) {
                    var response1 = await axios.get(`${WEB_API_URL}MessageCenter/GetMessage`, {
                        params: {
                            key: `${rsaKey}`
                        }
                    });
                    // Now update the hooks.
                    setTitle(response1.data.title);                
                    setMessageMarkdown(response1.data.messageMarkdown);
                    setStatus(response1.data.status);                                
                }                
            }
            // Else creating a message - do nothing.
            setLoading(false);
        }
        catch (e) {
            catchAxiosErr2(e, "MessageForm.getData", setLoading, setServiceIsDownError);
        }
    }


    // Function to handle inserting / creation.
    const handleInsert = async () => {
        let isError = false;
        isError = validateInfoHandler();  // Call to validate the controls.

        if(!isError) {
            // There were no errors.  We can submit the payload.
            /*
            {
                "id": 0,
                "title": "string",
                "publishedDate": "2023-06-13T21:41:52.789Z",
                "messageMarkdown": "string",
                "status": 0,
                "shadowIsRead": true,
                "shadowIsDeleted": true
            }
            */
            var payload = `{
                id: 0,
                title: "${title}",                
                messageMarkdown: "${messageMarkdown}",
                status: "${status}",
                shadowIsRead: false,
                shadowIsDeleted: false,            
            }`;            
            //console.log(`Handle Insert - Payload: ${JSON.stringify(payload)}`);

            axios.post(`${WEB_API_URL}MessageCenter/InsertMessage`, null, 
                { 
                    params: {
                        key: payload,
                    }
                }
            ).then((response) => 
            {
                navigate("/admin/MessageCenterAdmin");
            }).catch(e => {
                console.log(e);
                navigate("/admin/MessageCenterAdmin");
            });                    
        }    

    }


    // Function to handle performing updates.
    const handleUpdate = async () => {

        let isError = false;
        isError = validateInfoHandler();  // Call to validate the controls.

        if(!isError) {
            // There were no errors.  We can submit the payload.
            /*
            {
                "id": 0,
                "title": "string",
                "publishedDate": "2023-06-13T21:41:52.789Z",
                "messageMarkdown": "string",
                "status": 0,
                "shadowIsRead": true,
                "shadowIsDeleted": true
            }
            */
            var payload = `{
                id: "${messageId}",
                title: "${title}",
                messageMarkdown: "${messageMarkdown}",
                status: "${status}",
                shadowIsRead: false,
                shadowIsDeleted: false,            
            }`;            
            console.log(`Handle Edit - Payload: ${JSON.stringify(payload)}`);
      
            axios.put(`${WEB_API_URL}MessageCenter/UpdateMessage`, null, 
                {
                    params: {
                        key: payload,
                    }
                }
            )
            .then((response) => {
              navigate("/admin/MessageCenterAdmin");
            });
            
        }

    }
     
    
    // Function to validate the form.
    function validateInfoHandler() 
    {
        let retVal = false;
        if (title.trim() === '') {
            setTitleError(true);
            retVal = true;
        } 
        else {
            setTitleError(false);
        }
        if(messageMarkdown.trim() === '') {
            setMessageMarkdownError(true);
            retVal = true;
        }
        else {
            setMessageMarkdownError(false);
        }

        return retVal;        
    }


    // UI related.
    if (loading) {
        console.log("Loading Data ...")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    return(
        <React.Fragment>             
            <Box component="form" sx={{'& .MuiTextField-root': { m: 1, width: '100ch', marginLeft: '0px' },}} noValidate autoComplete="off">                            
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField 
                        required 
                        name="title" 
                        controlid="title" 
                        label="Message Title:" 
                        variant="filled" 
                        size="small"               
                        InputLabelProps={{ shrink: true }}
                        fullWidth     
                        inputProps={{ maxLength: 200 }}
                        onChange={event => setTitle(event.target.value) }
                        inputRef={titleValue}                        
                        value={title}
                        error={titleError}
                        helperText={titleError ? 'Message Title must be between 1 and 200 characters.' : ''} />
                </Grid>
                <Grid item xs={12}>

                    <TextField 
                        required 
                        select 
                        onChange={event => setStatus(event.target.value)} 
                        name="status" 
                        controlid="status" 
                        label="Status:" 
                        variant="filled" 
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        inputRef={statusValue}
                        value={status}
                        >
                    {MESSAGE_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value} >
                        {option.label}
                    </MenuItem>
                    ))}
                    </TextField>
                
                </Grid>
                <Grid item xs={12}>        
                
                    <TextField 
                    required 
                    name="messageMarkdown" 
                    controlid="messageMarkdown" 
                    label="Message:" 
                    variant="filled" 
                    size="small"
                    multiline
                    rows={15}             
                    inputProps={{ maxLength: 3499 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth                               
                    onChange={event => setMessageMarkdown(event.target.value) }
                    inputRef={messageMarkdownValue}
                    value={messageMarkdown}
                    error={messageMarkdownError}
                    helperText={messageMarkdownError ? 'Message Markdown must be between 1 and 3500 characters.' : ''} />            

                </Grid>
            </Grid>
            </Box>            
            <div>
                {!messageId ? <Button variant="contained" className="ml-auto" onClick={() => handleInsert()}> Send </Button>
                    : <Button variant="contained" className="ml-auto" onClick={() => handleUpdate()}> Save Changes </Button>}
                &nbsp; <Button variant="contained" className="ml-auto" onClick={() => navigate("/admin/MessageCenterAdmin")}> Discard </Button>
            </div>

        </React.Fragment>
    )

}


