// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// Audit Vault Components
import ProgressBar from '../components/common/ProgressBar';
import ServiceIsDown from '../components/common/ServiceIsDown';
import AccessDenied from '../components/common/AccessDenied';

// Audit Vault Common
import { getCompanyByCompanyId } from '../utilities/common-company';
import { verifyUserHasTenantRolePermissions } from '../utilities/common-user-utils';
import { ROLE_CODE_TENANTADMINISTRATOR } from "../constants/constants-roles";
import { getTenantById, testIfPowershellEnabled, createHistoricalAuditHistoryJobs } from '../utilities/common-tenant';

// Audit Vault Components
import GrantPowerShellConsentButton from '../components/tenant/GrantPowerShellConsentButton';
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import TrialPeriodNag from '../components/common/TrialPeriodNag';

// Section titles.
const isAdminPage = false;
const theSectionTitle = "Historical Audit Import";
const theSectionDesc = "Audit Vault for M365 allows you to import historical audit history from your Tenant. Even before you started using Audit Vault.";


function MyTenantSetupHistoricalImport(props) {

    const { user } = props;
    const navigate = useNavigate();

    // Component Constants
    const { tenantId } = useParams();
    const [company, setCompany] = useState("");
    const [tenant, setTenant] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState("loading ...");
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [loadingTestConnection, setLoadingTestConnection] = useState(false);
    const [disableTestConnectionButton, setDisableTestConnectionButton] = useState(false);
    const [connectionError, setConnectionError] = useState(false);

    const [loadingCreateJobs, setLoadingCreateJobs] = useState(false);
    const [disableCreateJobsButton, setDisableCreateJobsButton] = useState(false);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Tenant', link: '/Tenant/MyTenant?tab=4' },
        { name: 'Historical Audit Import', link: `/tenant-historical-import/${tenantId}` },
    ];

    const handleTestConnection = async () => {
        if (tenantId) {
            setDisableTestConnectionButton(true);
            setLoadingTestConnection(true);
            setConnectionError(false);
            try {
                var result = await testIfPowershellEnabled(tenantId);
                if (result != null) {
                    if (!result) {
                        setConnectionError(true);
                    }
                    else {
                        var response2 = await getTenantById(tenantId);
                        if (response2) {
                            setTenant(response2);
                        }
                    }
                }
            }
            catch (e) {
                console.log(`ERROR MyTenantSetupHistoricalImport : handleTestConnection:`, e.message);
                setConnectionError(true);
            }
            finally {
                setDisableTestConnectionButton(false);
                setLoadingTestConnection(false);
            }
        }
    }

    const handleCreateJobs = async () => {
        if (tenantId) {
            console.log(tenantId);
            setDisableCreateJobsButton(true);
            setLoadingCreateJobs(true);
            try {
                var result = await createHistoricalAuditHistoryJobs(tenantId);
                if (result) {
                    var response2 = await getTenantById(tenantId);
                    if (response2) {
                        setTenant(response2);
                    }
                }
            }
            catch (e) {
                console.log(`ERROR MyTenantSetupHistoricalImport : handleCreateJobs:`, e.message);
                setServiceIsDownError(true);
            }
            finally {
                setDisableCreateJobsButton(false);
                setLoadingCreateJobs(false);
            }
        }

    }

    async function fetchData() {

        if (loading && user) {
            try {
                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);

                    if (response != null) {

                        // check if the tuser can Tenant Admin activities on this tenant
                        if (verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTADMINISTRATOR)) {
                            var response2 = await getTenantById(tenantId);
                            if (response2) {
                                setTenant(response2);
                                console.log(response2.historicalAuditImportStage)
                            }
                        }
                        else {
                            setAccessDenied(true);
                        }
                    }
                }
            }
            catch (e) {
                setServiceIsDownError(true);
                console.log(e)
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    },);


    // Component UI
    if (loading) {
        return <ProgressBar message={loadingMsg} loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }


    return (
        <>
            <TrialPeriodNag company={company}></TrialPeriodNag>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <Typography component="div">If you have enabled auditing on your Tenant prior to signing up, you can follow the following steps to preserve your legacy audit history in Audit Vault permanently.</Typography>
            <Typography><br></br></Typography>
            <Alert severity="warning">
                <AlertTitle>Important Information Regarding Historical Audit Log Import:</AlertTitle>
                <Typography><br></br></Typography>
                <Typography>Historical Audit Log History can only be imported if the following condition is met:</Typography>
                <Typography component="ul"><li>Auditing on your Tenant has been enabled for at least 7 days prior to Validating your Tenant in Audit Vault.</li></Typography>
                <Typography><br></br></Typography>
                <Typography>Time Period for which Historical Data can be extracted:</Typography>
                <Typography component="ul">
                    <li>Audit Vault will import as much history as possible from Microsoft 365.</li>
                    <li>The audit logs and time period available will depend on your licenses with Microsoft.</li>
                </Typography>
            </Alert>
            <Typography><br></br></Typography>
            {company && !loading &&

                <>
                    <Typography><b>Step 1: Grant Consent</b></Typography>
                    <Typography>Have a Global Admin for your Tenant grant consent for Audit Vault to import and preserve your historical audit history in Audit Vault.</Typography>
                    <Typography><br></br></Typography>
                    <Typography>
                        {!tenant.powerShellConsentGrantedByMicrosoftId &&
                            <GrantPowerShellConsentButton azureTenantId={tenant.azureTenantId}></GrantPowerShellConsentButton>
                        }
                        {tenant.powerShellConsentGrantedByMicrosoftId &&
                            <Typography><TaskAltIcon sx={{ color: 'green' }}></TaskAltIcon> Completed</Typography>
                        }
                    </Typography>
                    <Typography><br></br></Typography>
                    <Typography><b>Step 2: Add Access in Entra Admin Center (Azure) To Import Historical Audit Records</b></Typography>
                    <Typography>Have a Global Admin log into the Entra Admin Center and do the following.</Typography>
                    <Typography><br></br></Typography>
                    <Typography>
                        1. Log into your Tenant's Entra Admin Center<br></br>
                        2. Navigate to Identity<br></br>
                        3. Click on Roles & Admins<br></br>
                        4. Search for Exchange Administrator <br></br>
                        5. Click on Exchange Administrator <br></br>
                        6. Click on Add Assignment <br></br>
                        7. Search for ECM Insights : Historical Audit History Importer<br></br>
                        8. Click Add
                    </Typography>
                    {loadingTestConnection &&
                        <>
                            <Typography><br></br></Typography>
                            <Typography><ProgressBar message="Testing Connection ..." loading={loadingTestConnection} /></Typography>
                        </>
                    }
                    {connectionError &&
                        <>
                            <Typography><br></br></Typography>
                            <Alert severity="error">
                                <AlertTitle>Connection Failed</AlertTitle>
                                Please ensure your have completed all the instructions above and try again.
                            </Alert>
                        </>
                    }
                    <Typography><br></br></Typography>
                    <Typography>
                        {tenant.historicalAuditImportStage == 0 &&
                            <Button variant="contained" size="small" disabled="true">Test Connection</Button>
                        }
                        {tenant.historicalAuditImportStage == 1 &&
                            <Button variant="contained" size="small" disabled={disableTestConnectionButton} onClick={() => handleTestConnection()}>Test Connection</Button>
                        }
                        {tenant.historicalAuditImportStage > 1 &&
                            <Typography><TaskAltIcon sx={{ color: 'green' }}></TaskAltIcon> Completed</Typography>
                        }
                    </Typography>
                    <Typography><br></br></Typography>
                    <Typography><b>Step 3: Start Import</b></Typography>
                    <Typography>
                        By default this operation will pull in all available Microsoft 365 audit log history for your Tenant from the last year.
                        <br />Microsoft E5 or equivalent user licenses will have up to 1 year of audit log history; E3 or equivalent user licenses will have up to 180 days of audit log history.
                        <br />Note: In special cases if you know you have more than 1 year of audit log history available, before proceeding please <a href="/Support/SupportTicket" target="_blank" rel="noopener noreferrer">submit a ticket and speak with our Support Team</a> to configure this.
                        <br />Otherwise, click on the button below to start importing your historical Microsoft 365 audit log history into Audit Vault!

                    </Typography>
                    {loadingCreateJobs &&
                        <>
                            <Typography><br></br></Typography>
                            <Typography><ProgressBar message="Creating Import jobs ...." loading={loadingCreateJobs} /></Typography>
                        </>
                    }
                    <Typography><br></br></Typography>
                    <Typography>
                        {tenant.historicalAuditImportStage < 2 &&
                            <Button variant="contained" size="small" disabled="true">Start Import</Button>
                        }
                        {tenant.historicalAuditImportStage == 2 &&
                            <Button variant="contained" size="small" disabled={disableCreateJobsButton} onClick={() => handleCreateJobs()}>Start Import</Button>
                        }
                        {tenant.historicalAuditImportStage > 2 &&
                            <>
                                <Typography><TaskAltIcon sx={{ color: 'green' }}></TaskAltIcon> Completed</Typography>
                                <Typography><br></br></Typography>
                                <Typography>Follow the progress of your historical import from your Tenant History page.</Typography>
                                <Typography><br></br></Typography>
                                <Typography component="div">
                                    <Typography component="span"><Button variant="contained" type="button" size='small' onClick={() => navigate('/Tenant/MyTenant?tab=4')}>Back to Tenant History Page</Button></Typography>
                                </Typography>
                            </>
                        }
                    </Typography>
                </>

            }
        </>
    );
}
export default MyTenantSetupHistoricalImport;