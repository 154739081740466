// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../components/common/DisplayBreadCrumb';
import MessageList from "../components/MessageList";

// Audit Vault Utilities
import { encryptKey } from '../utilities/common-encrypt-util';
import { getCompanyByCompanyId } from "../utilities/common-company";

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


function MessageCenter(props) {
    // Component Constants

    // The companyId is optional, actionCompleted is optional and can store the type of action that was completed (ex. 1=deleted, 2=updated,...)
    const { user } = props;
    var { companyId } = useParams();
    const [company, setCompany] = useState("");

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    // Store the MessageList array for companyID.
    const [myMessages, setMessages] = useState([]);
    // Section titles.
    const [isAdminPage] = useState(false);
    const theSectionTitle = "Message Center";
    const theSectionDesc = "The Message Center allows you to keep track of upcoming changes, including new and changed features, planned maintenance, or other important announcements.  Messages Center notifications are the primary way we communicate the timing of individual changes in Audit Vault for M365.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Support', link: '/Support/SupportHome' },
        { name: 'Message Center', link: '/Support/MessageCenter' }
    ];


    // For snackbar notification UI element.  Message deleted notification.
    const [openNotification, setOpenNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    // For child component - lifted state to the parent (here) so that on child component reloads we remember the state of these items.
    // Whenever the child component does a MarkAsRead, MarkAsUnread, or Delete operation the Parent does not need to be refreshed, only the child component needs to be updated.  As such maintaining state in the parent is important for optimized Virtual DOM and DOM updating.
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRow, setExpandedRow] = useState(null);  // For Accordion control.  Will be a base 10 digit or NaN if not specified.
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

    // Component Functions

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };


    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );



    // PW: We should replace the user and companyID loading with common library call.
    // Code below checks to see if the companyId is specified, if so loads the company.  If companyId is not specified, use current user to load the correct home company.
    // PW: Also looks like the catch portion is throwing an error.  This needs to be handled.
    async function getData() {
        try {
            if (companyId) {
                console.log("Company loading from param");
                // Get the company from the param specified (only Admin level user can do this).
                // A companyId was specified.  Get company and messages by companyId.
                const response = await getCompanyByCompanyId(companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);  // Store the Company.
                    // Load the messages for the specified Company.
                    var rsaKey = await encryptKey(`{companyId: ${companyId}, returnDeleted: false}`);
                    if (rsaKey) {
                        var response2 = await axios.get(`${WEB_API_URL}MessageCenter/GetAllMessagesForCompany`, {
                            params: {
                                key: `${rsaKey}`
                            }
                        });
                        if (response2) {
                            setMessages(response2.data);
                        }
                    }
                }
            }
            else {
                // Get the company from the user.        
                if (user && user.companyId > 0) {
                    console.log("Company loading from user");
                    // Get company and messages by userId.
                    const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);  // Store the Company.
                        // Load the messages for the logged in User.
                        var rsaKey = await encryptKey(`{companyId: ${user.companyId}, returnDeleted: false}`);
                        if (rsaKey) {
                            var response2 = await axios.get(`${WEB_API_URL}MessageCenter/GetAllMessagesForCompany`, {
                                params: {
                                    key: `${rsaKey}`
                                }
                            });
                            if (response2) {
                                setMessages(response2.data);
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log("MessageCenter.js.getDatata ERROR: ")
            console.log(e)
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    // Component UI

    // If we are still loading data, display that in the UI...
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <div>
                {/*<br /> {myMessages.length}*/}
                <MessageList myMessages={myMessages} theCompanyId={user.companyId} theUser={user} updateDataFunc={getData} snackbarNotification={setOpenNotification} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} expandedRow={expandedRow} setExpandedRow={setExpandedRow} sortConfig={sortConfig} setSortConfig={setSortConfig} setLoading={setLoading} setServiceIsDownError={setServiceIsDownError} isAdminPage={isAdminPage}></MessageList>
            </div>
            <div>
                <Snackbar
                    open={openNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message="Message Deleted"
                    action={openNotificationAction}
                />
            </div>
        </>
    );
}
export default MessageCenter;