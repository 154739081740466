import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import currencyFormatter from '../../../utilities/common-currency-utils';
import { formatInvoiceDate } from '../../../utilities/common-date-utils';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '50%',
        textAlign: 'left',
        paddingLeft: 5,
    },
    period:
    {
        width: '15%',
        textAlign: 'center',
        paddingLeft: 5,
    },
    qty: {
        width: '9%',
        textAlign: 'center',
    },
    rate: {
        width: '13%',
        textAlign: 'center',
    },
    amount: {
        width: '13%',
        textAlign: 'right',
        paddingRight: 5,
    },
  });


const InvoiceTableRow = ({items}) => {
    const rows = items.map( item => 
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.description}>{item.description}</Text>
            <Text style={styles.period}>{formatInvoiceDate(item.periodEndDate)}</Text>
            <Text style={styles.qty}>{item.quantity}</Text>
            <Text style={styles.rate}>{currencyFormatter(item.amount/100)}</Text>
            <Text style={styles.amount}>{(currencyFormatter(item.quantity * (item.amount/100)))}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow