
// React Components
import * as React from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Utilities
import getRole from '../../utilities/common-role-utils';
import { removeAuditVaultUserPermission } from '../../utilities/common-user-utils';

const columns = [
    { id: 'displayName', label: 'Display Name', minWidth: 200 },
    { id: 'permissions', label: 'Permissions', minWidth: 200 },
    //{ id: 'accessToExchange', label: 'Exchange Access', minWidth: 50 },
    //{ id: 'accessToSp', label: 'SharePoint Access', minWidth: 50 },
    //{ id: 'accessToAzureAd', label: 'Azure AD Access', minWidth: 50 },
  ];
  
  const rows = [];

  export default function UserList(props) {

    // Component Constants
    const { user, users, tenantId, fetchRoleData } = props;
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //const [loading, setLoading] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState("User Removed from Role");
  
    // Component Functions
    const handleChangePage = (_event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const deletePerm = async (id, roleId, userRemovedFromRole) => {
      try {
        const response = await removeAuditVaultUserPermission(user, id, roleId, userRemovedFromRole); 
        if (response)
        {
          if (response)
          {
            setOpenNotificationMsg("User Removed from Role");
            setOpenNotification(true);
            fetchRoleData();
          }
        }
      } catch (e) {
        console.log("ERROR: UserList.deletePerm")
        console.log(e);
        setServiceIsDownError(true);
      }
    };

    const handleRemoveAccess = async (permId, roleId, userRemovedFromRole) => {
      if (permId > 0) {
        deletePerm(permId, roleId, userRemovedFromRole);
      }
    };

  // For snackbar close action.
  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenNotification(false);
};

    // For snackbar notification action.
const openNotificationAction = (
  <React.Fragment>
      <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleNotificationClose}
      >
          <CloseIcon fontSize="small" />
      </IconButton>
  </React.Fragment>
);

  
    return (
      <><Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={`RowID-${row.id}`}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      switch (column.id) {
                        case 'permissions':
                          console.log(row)
                          if (!row.isCompanyAdmin && !row.isSystemAdmin)
                          {
                            return (
                              <TableCell key={column.id} align={column.align}>

                                <Table stickyHeader aria-label="sticky table">

                                  {value.map((perm) => {
                                    if (perm.roleId > 1 && perm.tenantId == tenantId) {
                                      return (
                                        <TableRow>
                                          <TableCell
                                            key={perm.id + " - " + row.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                          >
                                            {perm.tenantUrl}
                                          </TableCell>
                                          <TableCell
                                            key={"Role-" +perm.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                          >
                                            {getRole(perm.roleId)}
                                          </TableCell>
                                          <TableCell key={`CellID-${column.id}-${row.id}`} align="right">
                                            <IconButton aria-label="delete" onClick={(_event) => handleRemoveAccess(perm.id, perm.roleId, row.userEmail)}>
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>);
                                    }
                                  }
                                  )}
                                </Table>
                              </TableCell>
                            );
                          }
                          if (row.isCompanyAdmin)
                          {
                            return (
                              <TableRow>
                                <TableCell
                                  key={"CompanyAdmin - " + row.id}
                                >
                                 Company Administrator
                                </TableCell>
                                </TableRow>);

                          }
                          
                          ;

                        default:
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value
                                ? value.toString()
                                : value}
                            </TableCell>
                          );
                      }
                    })}

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="span"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper><div>
          <Snackbar
            open={openNotification}
            autoHideDuration={6000}
            onClose={handleNotificationClose}
            message={openNotificationMsg}
            action={openNotificationAction} />
        </div></>
    );
  }
  