// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// Audit Vault Components
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../components/common/DisplayBreadCrumb';

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { encryptKey } from '../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function SupportTicket(props) {

    // Component Constants    
    const { user, previewOnly } = props;

    // State variables to store form inputs    
    const [issueSubject, setIssueSubject] = useState("");
    const [descriptionOfIssue, setDescriptionOfIssue] = useState("");
    const [phoneNumberContact, setPhoneNumberContact] = useState("");
    const [issueSubjectError, setIssueSubjectError] = useState(false);
    const [descriptionOfIssueError, setDescriptionOfIssueError] = useState(false);

    const navigate = useNavigate();
    const [company, setCompany] = useState("");
    const { instance, accounts } = useMsal();

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    // For snackbar notification UI element.  Notification.
    const [openNotification, setOpenNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Section titles.
    const [isAdminPage] = useState(false);
    const theSectionTitle = "Create New Support Ticket";
    const theSectionDesc = "If you can't find the answer to your questions using our Help Center documentation we are here to help.  Please feel free to create a ticket to get in touch with our Audit Vault Support Team.  Our Support Team will review and respond to tickets during normal business hours (Monday through Friday 9am - 5pm Mountain Time).";

    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Support', link: '/Support/SupportHome' },
        { name: 'Create New Support Ticket', link: '/Support/SupportTicket' }
    ];


    // Component Functions
    useEffect(() => {
        async function fetchData() {
            try {
                if (user) {
                    const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);
                    }
                }
            }
            catch (e) {
                console.log("ERROR: SupportTicket.fetchData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    // Handle form submission (in a real application, this would send the data to your server)
    const handleSubmit = async () => {

        let isError = validateInfoHandler();  // Call to validate the controls.
        try {
            if (!isError) {
                // There were no errors.  We can submit the payload.
                var payload = {
                    userName: await encryptKey(accounts[0].username),
                    tenantId: accounts[0].tenantId,
                    phoneNumber: phoneNumberContact,
                    subject: issueSubject,
                    descriptionOfIssue: descriptionOfIssue
                };

                //console.log(`MK1: Handle Submit - Payload: ${JSON.stringify(payload)}`);

                var result = await axios.post(`${WEB_API_URL}AdminUtilities/SendSupportTicketEmail`, null, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                });
                if (result) {
                    setSnackbarMessage("Your support ticket was successfully sent.  Thank-you, someone on our Support Team will review and get back to you.");
                    setOpenNotification(true);
                    // Clear the form.
                    setIssueSubject("");
                    setDescriptionOfIssue("");
                    setPhoneNumberContact("");
                    setIssueSubjectError(false);
                    setDescriptionOfIssueError(false);

                    // We disable receipt notification for now - since we are limited to 30 outbound emails per minute.
                    /*
                    // Add a receipt notification -- send a nice email to the user submitting the ticket.
                    var payloadReceipt = {
                        emailTo: accounts[0].username,
                        emailSubject: "Audit Vault for M365 - Ticket Received.",
                        emailBody: "Dear Customer," + 
                                "<br />We received the support ticket you submitted.<br />" + 
                                "Subject: " + issueSubject + "<br />" +                           
                                "Ticket submitted on: " + theDate + "<br /><br />" +                           
                                "Our support team will review this question and reply to you soon!<br /><br />" +
                                "Thank you for choosing Audit Vault for M365!</br>" +
                                "<a href='https://www.ecminsights.com'>https://www.ecminsights.com</a></br>"
                    };
                    axios.post(`${WEB_API_URL}AdminUtilities/SendExternalEmail`, payloadReceipt).then((response) => {
                    }).catch(e => {
                        console.log("ERROR: " + e)
                    });
                    */
                }
                else {
                    setSnackbarMessage(response.data);  // Set the snackbar message to the error message returned from the webapi call.
                    setOpenNotification(true);
                    // On error, we don't clear the form.
                }

            }
        }
        catch (e) {
            setSnackbarMessage(e.message);  // Set the snackbar message to the error message returned from the webapi call.
            setOpenNotification(true);
        };

    };

    // Function to validate the form.
    function validateInfoHandler() {
        let retVal = false;

        if (issueSubject.trim() === '' || issueSubject.length < 10) {
            setIssueSubjectError(true);
            retVal = true;
        }
        if (descriptionOfIssue.trim() === '' || descriptionOfIssue.length < 10) {
            setDescriptionOfIssueError(true);
            retVal = true;
        }
        return retVal;
    }

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (previewOnly == null || previewOnly == false) {
        // Non-preview mode - display the Support Ticket submission form.
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', minWidth: 320 }}>
                                <b>Create New Support Ticket</b><br /><br />
                                <form>
                                    <div>
                                        <Typography variant="subtitle1">Subject:</Typography>
                                        <TextField
                                            name="subject"
                                            required
                                            multiline
                                            rows={1}
                                            inputProps={{ maxLength: 256 }}
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            value={issueSubject}
                                            onChange={(e) => setIssueSubject(e.target.value)}
                                            error={issueSubjectError}
                                            helperText={issueSubjectError ? 'Subject must be between 10 and 256 characters.' : ''}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <Typography variant="subtitle1">Description:</Typography>
                                        <TextField
                                            name="description"
                                            required
                                            multiline
                                            rows={5}
                                            inputProps={{ maxLength: 1024 }}
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            value={descriptionOfIssue}
                                            onChange={(e) => setDescriptionOfIssue(e.target.value)}
                                            error={descriptionOfIssueError}
                                            helperText={descriptionOfIssueError ? 'Description must be between 10 and 1024 characters.' : ''}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <Typography variant="subtitle1">Optional: Provide your phone number if you wish to be contacted by phone.</Typography>
                                        <TextField
                                            name="phoneNumberContact"
                                            inputProps={{ maxLength: 100 }}
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            value={phoneNumberContact}
                                            onChange={(e) => setPhoneNumberContact(e.target.value)}
                                        />
                                    </div>
                                    <br /><br />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Submit Support Ticket
                                    </Button>
                                    &nbsp; <Button variant="contained" className="ml-auto" onClick={() => navigate("/Support/SupportHome")}> Discard</Button>
                                </form>
                            </Paper>

                        </Grid>
                    </Grid>

                </div>
                <div>
                    <Snackbar
                        open={openNotification}
                        autoHideDuration={6000}
                        onClose={handleNotificationClose}
                        message={snackbarMessage}
                        action={openNotificationAction}
                    />
                </div>

            </>
        );
    }
    else {
        // Preview mode.  Display an excerpt and link to the Support Ticket form.
        return (
            <>
                <TableContainer><Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ maxWidth: 20, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}><SupportAgentIcon style={{ fontSize: 45, color: 'blue' }} /></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}><h6>Contact Support</h6></TableCell>
                        </TableRow>
                    </TableBody>
                </Table></TableContainer>
                <br />

                If you are unable to find the assistance you are looking for in the Help Center documentation, feel free to reach out to our Support Team. One of our Support Analysts will review your question and get back to you!
                <br /><br /><Button size="small" onClick={() => navigate("/Support/SupportTicket")}>Create New Support Ticket</Button>
            </>
        );
    }
}
export default SupportTicket;