// React Components
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MenuBookIcon from '@mui/icons-material/MenuBook';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import ProgressBar from "../components/common/ProgressBar";
import ServiceIsDown from "../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import ImageSharePoint from '../imgs/iconSharePoint.svg';
import DisplayExportableDataSource from "../components/reports/DisplayExportableDataSource";

// Audit Vault Utilities
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { encryptKey } from '../utilities/common-encrypt-util';

// Audit Vault Constants
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'SharePoint Online Reports', link: '/Reports/SharePoint' },
];

const columns = [
    { id: 'operation', label: 'Event', minWidth: 100, maxWidth: 100, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 100, maxWidth: 100, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 100, maxWidth: 200, align: 'left' },
    { id: 'objectId', label: 'Item', minWidth: 100, maxWidth: 200, align: 'left' },
];

/*
ReportsSharePointByListItemUniqueId report.
This page uses regular permissions - requires minimum Tenant Report+ access.
*/
function ReportsSharePointByListItemUniqueId(props) {

    const { user } = props;

    // Component Constants
    const { id } = useParams();
    const [history, setHistory] = useState([]);

    const [loading, setLoading] = useState(true);
    const [formatError, setFormatError] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const listItemUniqueIdValue = useRef("");
    const [errorListItemUniqueId, setErrorListItemUniqueId] = useState("");
    const [listItemUniqueId, setListItemUniqueId] = useState(id);
    const [tenantId, setTenantId] = useState(0);

    const theSectionTitle = "Report: SharePoint Item Audit History";
    const theSectionDesc = "";
    const isAdminPage = false;


    function getItemId() {
        var itemId = id;
        var itemId = "";
        if (listItemUniqueId) {
            itemId = listItemUniqueId;
        }
        if (!id) {
            itemId = listItemUniqueId;
        }

        return itemId;
    }

    // Component Functions
    async function fetchData() {

        var itemId = getItemId();

        setLoading(true);
        setListItemUniqueId(itemId);

        if (user && itemId && isValidGuid(itemId)) {

            try {
                // We load the Audit History for the GUID given the User's company id.  WebAPI call will check all of their tenants.  In theory only 1 of the tenant's would match on the guid.
                // Before creating the JSON we need to convert the guid to a string so it can be converted to JSON.
                var usrEmail = "N/A";
                if (user.userEmail)
                    usrEmail = user.userEmail;

                var payload = `{
                    "userMicrosoftId": "${await encryptKey(user.microsoftGraphId)}",
                    "userEmail": "${await encryptKey(usrEmail)}",
                    "companyId": ${user.companyId},
                    "listItemUniqueId": "${itemId.toString()}",
                    "isSpoReport": ${false},
                  }`;

                if (payload) {
                    var response = await axios.get(`${WEB_API_URL}Reports/GetSharePointAuditHistoryByListItemUniqueId`, {
                        params: {
                            key: `${payload}`
                        }
                    });
                    if (response) {
                        console.log(response.data.auditHistory)
                        setLoading(false);
                        if (response.data.auditHistory[0]) {
                            // Let's get the first element's tenantId.
                            var checkTenantId = response.data.auditHistory[0].tenantId;  // Check that the tenantId ()
                            // In order to access reports the current user must have at least Tenant Report+ access to the specified tenant.
                            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, checkTenantId, ROLE_CODE_TENANTREPORTREADER);
                            if (checkReportAccessForTenant == false) {
                                setAccessDenied(true)
                            }
                            else {
                                setAccessDenied(false);
                                setHistory(response.data.auditHistory);
                                setTenantId(checkTenantId);
                            }
                        }
                        else {
                            setHistory([]);
                        }
                    }
                }
            }
            catch (e) {
                setFormatError(true);
                setErrorListItemUniqueId(true);
                setServiceIsDownError(true);
                console.log("ERROR: ReportsSharePointByListItemUniqueId.fetchData");
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }

    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = () => {

        let isError = false;
        if (listItemUniqueId) {
            let isGuid = isValidGuid(listItemUniqueId.toLowerCase());
            if (!isGuid) {
                isError = true;
            }
        }

        if (listItemUniqueId && listItemUniqueId.trim() === '') { isError = true; }
        setErrorListItemUniqueId(isError);
        setHistory([]);

        if (!isError) {
            fetchData();
        }
    }

    function isValidGuid(val) {
        if (val.length == 0) return false;
        if (val.length > 0) {
            if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(val)) {
                console.log(`Is valid Guid: false`)
                return false;
            }
        }
        return true;
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    var csvFilename = "ItemAuditHistory_" + listItemUniqueId + ".csv";
    // Specify the icon to represent the SharePoint item.
    let iconComponent;
    if (!loading && history != null && history.length > 0) {
        switch (history[0].itemType) {
            case 'Folder':
                iconComponent = <FolderIcon style={{ color: 'blue' }} />;
                break;
            case 'File':
                iconComponent = <InsertDriveFileIcon style={{ color: 'blue' }} />;
                break;
            case 'Page':
                iconComponent = <MenuBookIcon style={{ color: 'blue' }} />;
                break;
            default:  // List and everything else use this icon.
                iconComponent = <ReceiptLongIcon style={{ color: 'blue' }} />;
                break;
        }
    }


    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageSharePoint} alt="SharePoint Reports" />
                </div>
            </div>

            {/* Display the input guid box. */}
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '100ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container >
                    <Grid item xs={12}>
                        <Typography component="div"><b>List Item Unique Id:</b></Typography>
                        <Typography component="span">
                            <TextField required
                                name="listItemUniqueId"
                                placeholder="Enter List Item Unique Id Here."
                                variant="outlined"
                                size="small"
                                style={{ width: 450 }}
                                InputLabelProps={{ shrink: true }}
                                onChange={event => setListItemUniqueId(event.target.value)}
                                inputRef={listItemUniqueIdValue}
                                value={listItemUniqueId}
                                error={errorListItemUniqueId}
                                helperText={errorListItemUniqueId ? 'List Item Unique Id cannot be empty and must be a valid Guid.' : ''} />
                        </Typography>
                        <Typography component="span" >
                            <Button variant="contained" sx={{ marginTop: 1 }} type="button" onClick={() => handleSearch()}>Get Audit History</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Box >
            <br />
            <ProgressBar message="Loading ..." loading={loading} />

            { /* Display information about the SP Item.  Do not display the header for GUID of 0000000...which is a special case for many items. */}
            {
                !loading && history != null && history.length > 0 && history[0].listItemUniqueId != "00000000-0000-0000-0000-000000000000" &&
                (
                    <div>
                        <hr />
                        {/* Display item summary section. */}
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Name</TableCell>
                                        <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                            {history[0].sourceFileName === null ? history[0].objectId : history[0].sourceFileName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Type</TableCell>
                                        <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>{iconComponent}&nbsp; {history[0].itemType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Path</TableCell>
                                        <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>{history[0].objectId}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <hr />
                    </div>
                )
            }


            {!loading && history != null &&
                <>
                    <div>
                        <DisplayExportableDataSource columns={columns} reportResults={history} csvFilename={csvFilename} tenantId={tenantId} reportType={"SharePointViewAuditHistory"} user={user} />
                    </div>
                </>
            }

        </>
    );
}
export default ReportsSharePointByListItemUniqueId;