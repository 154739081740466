// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ProgressBar from '../../components/common/ProgressBar';
import { getSystemAdmins, removeSystemAdminAccess } from '../../utilities/common-user-utils';

// Audit Vault Common

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'System Administrators List', link: '/Admin/SystemAdminUsers' }
];
const theSectionTitle = "System Administrators";
const theSectionDesc = "Below are the users with full access to the Audit Vault System";
const isAdminPage = true;

function SystemAdminUsersList(props) {

    const { user } = props;
    const queryParams = new URLSearchParams(window.location.search);

    // Component Constants
    const navigate = useNavigate();

    const [systemAdmins, setSystemAdmins] = useState([]);

    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const [systemAdminsloading, setSystemAdminsLoading] = useState(true);
    const [systemAdminsAccordian, setSystemAdminsAccordian] = useState(true);
    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState('User Removed from Role');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        // Notify if a new user has been added.
        if (queryParams.get("roleId") && queryParams.get("roleId").length > 0) {
            setOpenNotificationMsg("User Added to Role");
            setOpenNotification(true);
        }
        getSystemAdminsWithSysAminId();
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleAddMembers = (roleIdValue) => {
        if (roleIdValue == 0) {
            navigate(`/admin/assign-systemadmin-users`)
        }
    };

    const getSystemAdminsWithSysAminId = async () => {
        try {

            if (user && user.isSystemAdmin) {
                var responseSa = await getSystemAdmins(user.microsoftGraphId);
                if (responseSa) {
                    setSystemAdmins(responseSa);
                }
            }
        }
        catch (e) {
            console.log("ERROR: SystemaDminUsersList.getSystemAdminsWithSysAminId");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setSystemAdminsLoading(false);
        }
    }

    const handleRemoveAccess = async (id) => {
        try {
            const response = await removeSystemAdminAccess(user.microsoftGraphId, id);
            if (response) {
                setSystemAdminsLoading(true);
                setOpenNotificationMsg("User Removed from Role");
                getSystemAdminsWithSysAminId();
                setOpenNotification(true);
            }
        } catch (e) {
            console.log("ERROR: TenantRoleList.deletePerm");
            console.log(e);
            setServiceIsDownError(true);
        }

    };


    const handleAccordionLoad = async () => {

        setSystemAdminsAccordian(systemAdminsAccordian);
        if (!systemAdminsAccordian) {
            setSystemAdminsLoading(true);
            getSystemAdminsWithSysAminId();
        }
        setSystemAdminsAccordian(!systemAdminsAccordian);

    }

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    // Component UI
    if (serviceIsDownError) {
        return (<ServiceIsDown></ServiceIsDown>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <Typography component="span">
                <Typography><b>System Administrators Roles:</b></Typography>
                <Typography><br></br></Typography>
                <Card sx={{ width: 600 }} variant="outlined">
                    <CardContent>
                        <Accordion expanded={systemAdminsAccordian}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                onClick={event => handleAccordionLoad()}
                                id="panel1a-header"
                            >
                                <Typography><b>System Administrators</b><br />Has complete access to the Audit Vault System.</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProgressBar message="Loading ..." loading={systemAdminsloading} />
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {systemAdmins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            console.log(row)
                                            if (row.isSystemAdmin) {
                                                return (
                                                    <TableRow>
                                                        <TableCell key={'TA' + row.displayName + row.id}>{row.displayName}</TableCell>
                                                        <TableCell key={'TADEL' + row.displayName + row.id} sx={{ textAlign: "right" }}>
                                                            <IconButton aria-label="delete" onClick={(event) => handleRemoveAccess(row.microsoftGraphId)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                        }
                                    </TableBody>
                                </Table>
                                {systemAdmins.length > 25 &&
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 75]}
                                        component="span"
                                        count={systemAdmins.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage} />
                                }
                            </AccordionDetails>
                            <AccordionActions>
                                <IconButton sx={{ textAlign: "right" }} aria-label="addUser" onClick={(event) => handleAddMembers(0)}>
                                    <PersonAddAlt1Icon sx={{ color: "green" }} />
                                </IconButton>
                            </AccordionActions>
                        </Accordion>
                    </CardContent>
                </Card>
            </Typography>
            <div>
                <Snackbar
                    open={openNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message={openNotificationMsg}
                    action={openNotificationAction} />
            </div>
        </>
    );
}

export default SystemAdminUsersList;