// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';


// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Utilities.
import { encryptKey } from '../../utilities/common-encrypt-util';


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function SystemEmailsList(props) {

    const { user } = props;

    // Component Constants
    const navigate = useNavigate();

    const [systemEmails, setSystemEmails] = useState([]);

    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'System Emails List', link: '/Admin/SystemEmails' }
    ];
    const theSectionTitle = "System Emails";
    const theSectionDesc = "Below are the the system emails that get sent from the Audit Vault System.  Edit them as needed.";
    const isAdminPage = true;


    useEffect(() => {
        getSystemEmails();
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getEmailType = (val) => {
        switch (val) {
            case 0:
                return "CustomerWelcome";
            case 1:
                return "SupportNewCustomerSignup";
            case 2:
                return "SupportEmail";
            case 3:
                return "CustomerNotification";
            case 4:
                return "SupportProductFeedback";
            case 5:
                return "SupportSupportTicket";
            case 6:
                return "CustomerSupportTicket";
        }
    };


    const getSystemEmails = async () => {

        try {
            if (user && user.isSystemAdmin) {
                // Load the search orchestrators list.          
                var data = {
                    sysAdminId: user.microsoftGraphId,                
                };                                                
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) 
                {                                                
                    var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetSystemEmails`, {
                        params: {
                            key: keyString,
                        }
                    }); 
                    if(result) {
                        console.log(result);
                        setSystemEmails(result.data);
                    }
                }
            }
            else {
                setAccessDenied(true);
            }
        }
        catch (e) {
            console.log("ERROR: SystemEmailsList.getSystemEmails");
            console.log(e);
            setAccessDenied(true);
            //setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    const handleEdit = (value) => {
        navigate(`/Admin/SystemEmail-update/${value}`);
    };

    // Component UI
    if (serviceIsDownError) {
        return (<ServiceIsDown></ServiceIsDown>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <Typography component="span">
                <Typography><b>System Emails:</b></Typography>
                <Typography><br></br></Typography>
                <Card variant="outlined">
                    <CardContent>
                        <ProgressBar message="Loading ..." loading={loading} />
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableCell key={'Table_Head_Email_Type'}>Type</TableCell>
                                <TableCell key={'Table_Head_Email_Subject'}>Subject</TableCell>
                                <TableCell key={'Table_Head_Email_Message'}>Message</TableCell>
                                <TableCell key={'Table_Head_Email_Edit'}>Edit</TableCell>
                            </TableHead>
                            <TableBody>
                                {systemEmails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    console.log(row)
                                    return (
                                        <TableRow>
                                            <TableCell sx={{ verticalAlign: "top" }} key={'Email_' + row.messageType}>{getEmailType(row.messageType)}</TableCell>
                                            <TableCell sx={{ verticalAlign: "top" }} key={'Email_' + row.messageType + row.subject}>{row.subject}</TableCell>
                                            <TableCell sx={{ verticalAlign: "top" }} key={'Email_' + row.id}>{row.message}</TableCell>
                                            <TableCell sx={{ verticalAlign: "top" }} key={'Email_' + row.id}><Link onClick={(event) => handleEdit(row.messageType)} component="button">Edit</Link></TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100]}
                            component="span"
                            count={systemEmails.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </CardContent>
                </Card>
            </Typography>
        </>
    );
}

export default SystemEmailsList;