// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Audit Vault Components
import AccessDenied from "../../components/common/AccessDenied";
import ProgressBar from '../../components/common/ProgressBar';
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from '../../components/common/ServiceIsDown';

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';

// Generating an array of values from 1 to 25.
const values = Array.from({ length: 25 }, (_, index) => index + 1);

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


const theSectionDesc = "";
const isAdminPage = true;

const JOB_ORCHESTRATOR_TYPES = [
    {
        value: 0,
        label: 'Audit Import'
    },
    {
        value: 1,
        label: 'First Audit Import'
    },
    {
        value: 2,
        label: 'Historical Import'
    },
    {
        value: 3,
        label: 'Search'
    }
];

export default function JobOrchestratorForm(props) {

    // Component Constants.
    const { user } = props;
    const { orchestratorId } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false);

    const [orchestratorName, setOrchestratorName] = useState('');
    const [orchestratorType, setOrchestratorType] = useState(0);
    const [serverName, setServerName] = useState('');
    const [numProcessors, setNumProcessors] = useState(1);
    const [maxNumConcurrentTasks, setMaxNumConcurrentTasks] = useState(1);
    const [availableForMoreJobQueues, setAvailableForMoreJobQueues] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [lastRunDate, setLastRunDate] = useState('');

    const [errOrchestratorName, setErrOrchestratorName] = useState(false);
    const [errServerName, setErrServerName] = useState(false);

    const orchestratorNameValue = useRef("");
    const orchestratorTyepValue = useRef(0);
    const serverNameValue = useRef("");
    const numProcessorsValue = useRef(1);
    const maxNumConcurrentTasksValue = useRef(1);

    var theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Job Orchestrators List', link: '/Admin/JobOrchestrators' },
        { name: 'Create Job Orchestrator', link: '/Admin/UpdateJobOrchestrator' }
    ];
    var theSectionTitle = "Create Job Orchestrator";

    if (orchestratorId && orchestratorId > 0) {
        theBreadcrumbPath = [
            { name: 'Home', link: '/' },
            { name: 'Admin', link: '/Admin/AdminHome' },
            { name: 'Job Orchestrators List', link: '/Admin/JobOrchestrators' },
            { name: 'Update Job Orchestrator', link: `/Admin/UpdateJobOrchestrator/${orchestratorId}` }
        ];
        theSectionTitle = "Update Job Orchestrator";
    }

    // Component Functions
    async function fetchData() {
        if (loading) {
            try {
                if (user.isSystemAdmin && orchestratorId && orchestratorId > 0) {
                    var data = {
                        sysAdminId: await encryptKey(user.microsoftGraphId),
                        id: orchestratorId
                    };
                    var keyString = JSON.stringify(data);
                    if (keyString) {
                        var response = await axios.get(`${WEB_API_URL}AdminUtilities/GetOrchestratorById`, {
                            params: {
                                key: `${keyString}`
                            }
                        });
                        if (response) {
                            console.log(response.data);
                            setOrchestratorName(response.data.orchestratorName);
                            setOrchestratorType(response.data.orchestratorType);
                            setServerName(response.data.serverName);
                            setNumProcessors(response.data.numOfProcessors);
                            setMaxNumConcurrentTasks(response.data.maxNumOfConcurrentTasks);
                            setAvailableForMoreJobQueues(response.data.availableForMoreJobQueues);
                            setIsActive(response.data.isActive);
                            setLastRunDate(response.data.lastRunDate);
                        }
                        else {
                            setAccessDenied(true);
                        }
                    }
                }
                else if (!user.isSystemAdmin) {
                    setAccessDenied(true);
                }
            }
            catch (e) {
                setServiceIsDown(true);
                console.log("ERROR: JobOrchestrator:fetchData");
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleInsert = async () => {
        let isError = false;
        isError = validateInfoHandler();

        if (!isError) {

            var payload = {
                id: 0,
                orchestratorName: await encryptKey(orchestratorName),
                orchestratorType: orchestratorType,
                serverName: await encryptKey(serverName),
                numOfProcessors: numProcessors,
                maxNumOfConcurrentTasks: maxNumConcurrentTasks,
                availableForMoreJobQueues: availableForMoreJobQueues,
                isActive: isActive,
                lastRunDate: new Date()
            };

            try {

                var result = await axios.post(`${WEB_API_URL}AdminUtilities/CreateJobOrchestrator/${user.microsoftGraphId}`, null, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                });

                if (result) {
                    navigate("/Admin/JobOrchestrators");
                }
            }
            catch (e) {
                console.log("ERROR: handleInsert");
                console.log(e);
                setServiceIsDown(true);
            }
        }
    }

    const handleDelete = async () => {
        try {

            var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}", Id: "${await encryptKey(orchestratorId.toString())}"}`;
            if (rsaKey) {

                var result = await axios.delete(`${WEB_API_URL}AdminUtilities/DeleteJobOrchestrator`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });

                if (result) {
                    navigate("/Admin/JobOrchestrators");
                }
            }
        }
        catch (e) {
            console.log("ERROR: handleDelete");
            console.log(e);
            setServiceIsDown(true);
        }
    }

    const handleUpdate = async () => {

        let isError = false;
        isError = validateInfoHandler();  // Call to validate the controls.

        if (!isError) {

            var payload = {
                id: orchestratorId,
                orchestratorName: await encryptKey(orchestratorName),
                orchestratorType: orchestratorType,
                serverName: await encryptKey(serverName),
                numOfProcessors: numProcessors,
                maxNumOfConcurrentTasks: maxNumConcurrentTasks,
                availableForMoreJobQueues: availableForMoreJobQueues,
                isActive: isActive,
                lastRunDate: lastRunDate
            };

            try {

                var result = await axios.put(`${WEB_API_URL}AdminUtilities/UpdateJobOrchestrator`, null, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                });

                if (result) {
                    navigate("/Admin/JobOrchestrators");
                }

            }
            catch (e) {
                console.log("ERROR: handleUpdate");
                console.log(e);
                setServiceIsDown(true);
            }
        }

    }

    function validateInfoHandler() {
        let retVal = false;
        if (orchestratorName.trim() === '') {
            setErrOrchestratorName(true);
            retVal = true;
        }
        else {
            setErrOrchestratorName(false);
        }

        if (serverName.trim() === '') {
            setErrServerName(true);
            retVal = true;
        }
        else {
            setErrServerName(false);
        }

        return retVal;
    }


    // Component UI
    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    if (loading) {
        return (<><ProgressBar loading={loading} /></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

            <Card variant="outlined" sx={{ maxWidth: 650 }}>
                <CardContent>
                    {orchestratorId &&
                        <Typography key="orchestratorId" sx={{ marginBottom: 2 }} component="div">
                            <Typography component="span" sx={{ paddingRight: '10px' }}><b>Id: </b></Typography>
                            <Typography component="span">{orchestratorId}</Typography>
                        </Typography>
                    }
                    <Typography key="orchestratorName" sx={{ marginBottom: 2 }} component="div">
                        <b>Orchestrator Name:</b>
                        <br />
                        <TextField
                            required
                            name="Orchestrator Name"
                            id="orchestratorName"
                            placeholder="Job Orchestrator Name."
                            variant="outlined"
                            size="small"
                            style={{ width: 600 }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            onChange={event => setOrchestratorName(event.target.value)}
                            value={orchestratorName}
                            inputRef={orchestratorNameValue}
                            error={errOrchestratorName}
                            helperText={errOrchestratorName ? 'Orchestrator Name must be between 1 and 50 characters.' : ''}
                        />
                    </Typography>
                    <Typography key="orchestratorType" sx={{ marginBottom: 2 }} component="div">
                        <b>Orchestrator Type:</b>
                        <br />
                        <TextField
                            required
                            select
                            name="orchestratorType"
                            id="orchestratorType"
                            variant="outlined"
                            size="small"
                            style={{ width: 200 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setOrchestratorType(event.target.value)}
                            inputRef={orchestratorTyepValue}
                            value={orchestratorType}
                        >
                            {JOB_ORCHESTRATOR_TYPES.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Typography>
                    <Typography key="serverName" sx={{ marginBottom: 2 }} component="div">
                        <b>Server Name:</b>
                        <br />
                        <TextField
                            required
                            name="Server Name"
                            id="serverName"
                            placeholder="Job Orchestrator Server Name"
                            variant="outlined"
                            size="small"
                            style={{ width: 600 }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            onChange={event => setServerName(event.target.value)}
                            inputRef={serverNameValue}
                            value={serverName}
                            error={errServerName}
                            helperText={errServerName ? 'Server Name must be between 1 and 50 characters.' : ''}
                        />
                    </Typography>
                    <Typography key="numProcessors" sx={{ marginBottom: 2 }} component="div">
                        <b>Number of Processors:</b>
                        <br />
                        <TextField
                            required
                            select
                            name="Num Processors"
                            id="numProcessors"
                            variant="outlined"
                            size="small"
                            style={{ width: 100 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setNumProcessors(event.target.value)}
                            inputRef={numProcessorsValue}
                            value={numProcessors}
                        >
                            {values.map((value, index) => (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Typography>
                    <Typography key="maxTasks" sx={{ marginBottom: 2 }} component="div">
                        <b>Maximum Tasks Per Run:</b>
                        <br />
                        <TextField
                            required
                            select
                            name="Max Tasks"
                            id="maxTasks"
                            variant="outlined"
                            size="small"
                            style={{ width: 100 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setMaxNumConcurrentTasks(event.target.value)}
                            inputRef={maxNumConcurrentTasksValue}
                            value={maxNumConcurrentTasks}
                        >
                            {values.map((value, index) => (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Typography>
                    <Typography key="availableForMoreJobQueues" component="div">
                        <b>Available to Assign More Jobs to Orchestrator:</b>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox required
                                checked={availableForMoreJobQueues}
                                name="availableForMoreJobQueues"
                                controlid="availableForMoreJobQueues"
                                onChange={event => setAvailableForMoreJobQueues(event.target.checked)}
                            />
                        </div>
                    </Typography>
                    <Typography key="isActive" component="div">
                        <b>Is Active:</b>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox required
                                checked={isActive}
                                name="isActive"
                                controlid="isActive"
                                onChange={event => setIsActive(event.target.checked)}
                            />
                        </div>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Typography component="span" sx={{ width: '50%' }}>
                        <Button size="small" onClick={() => navigate(`/Admin/JobOrchestrators`)}>Back</Button>
                    </Typography>
                    <Typography component="span" sx={{ width: '50%', textAlign: 'right' }}>
                        {!orchestratorId ? <Button className="ml-auto" onClick={() => handleInsert()}> Create </Button>
                            : <>
                                <Button className="ml-auto" onClick={() => handleDelete()}>Delete</Button>
                                <Button className="ml-auto" onClick={() => handleUpdate()}>Save Changes</Button>
                            </>}
                    </Typography>
                </CardActions>
            </Card>
        </>
    )
}
