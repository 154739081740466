// React Components
import React from "react";
import { useEffect } from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// Audit Vault Utilities
import { AUDIT_ACTIVITIES_TEAMS, AUDIT_ACTIVITIES_EXCHANGE, AUDIT_ACTIVITIES_ENTRA, AUDIT_ACTIVITIES_SHAREPOINT } from "../../constants/constants-auditlogactivities";


// Function to insert our Activities selector (textfield).
export default function ActivitiesSelector(props){

    const { tenantId, selectedActivities, setSelectedActivities, selectedSharePointOperations, setSelectedSharePointOperations, 
            selectedExchangeOperations, setSelectedExchangeOperations, selectedEntraIDOperations, setSelectedEntraIDOperations,
            selectedTeamsOperations, setSelectedTeamsOperations, 
            selectedSharePointFriendly, setSelectedSharePointFriendly, selectedExchangeFriendly, setSelectedExchangeFriendly,
            selectedEntraIDFriendly, setSelectedEntraIDFriendly, selectedTeamsFriendly, setSelectedTeamsFriendly } = props;
                
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
        },
    };


    // Event handlers toggling events for each of Exchange, Entra, SharePoint and Teams specific events.
    // We store the operation which is what is actually used to search against the database column tables. 
    const handleActivityToggle = (activity, correspondingOperation, category) => {
        // Check if the activity is already selected
        if (selectedActivities.includes(activity)) {
          // Remove the activity from the selectedActivities array
          setSelectedActivities(selectedActivities.filter((item) => item !== activity));
    
          // Remove the activity (operation) from the corresponding category array
          if (category === 'SharePoint') {
            setSelectedSharePointFriendly(selectedSharePointFriendly.filter((item) => item !== activity));
            setSelectedSharePointOperations(selectedSharePointOperations.filter((item) => item !== correspondingOperation));
          } else if (category === 'Exchange') {
            setSelectedExchangeFriendly(selectedExchangeFriendly.filter((item) => item !== activity));
            setSelectedExchangeOperations(selectedExchangeOperations.filter((item) => item !== correspondingOperation));
          } else if (category === 'EntraID') {
            setSelectedEntraIDFriendly(selectedEntraIDFriendly.filter((item) => item !== activity));
            setSelectedEntraIDOperations(selectedEntraIDOperations.filter((item) => item !== correspondingOperation));
          }
          else if (category === 'Teams') {
            setSelectedTeamsFriendly(selectedTeamsFriendly.filter((item) => item !== activity));
            setSelectedTeamsOperations(selectedTeamsOperations.filter((item) => item !== correspondingOperation));
          }
        } else {
          // Add the activity to the selectedActivities array
          setSelectedActivities([...selectedActivities, activity]);
    
          // Add the activity (operation) to the corresponding category array
          if (category === 'SharePoint') {
            setSelectedSharePointFriendly([...selectedSharePointFriendly, activity]);
            setSelectedSharePointOperations([...selectedSharePointOperations, correspondingOperation]);
          } else if (category === 'Exchange') {
            setSelectedExchangeFriendly([...selectedExchangeFriendly, activity]);
            setSelectedExchangeOperations([...selectedExchangeOperations, correspondingOperation]);
          } else if (category === 'EntraID') {
            setSelectedEntraIDFriendly([...selectedEntraIDFriendly, activity]);
            setSelectedEntraIDOperations([...selectedEntraIDOperations, correspondingOperation]);
          } else if (category === 'Teams') {
            setSelectedTeamsFriendly([...selectedTeamsFriendly, activity]);
            setSelectedTeamsOperations([...selectedTeamsOperations, correspondingOperation]);
          }
          
        }
    };


    return(
        

        <Grid item xs={12}>
            {/* Activities - friendly name, input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Activities:</b>
                </div>
                <div>
                    <Select
                        labelId="activities-multiple-checkbox-label"
                        id="activities-multiple-checkbox"
                        multiple
                        value={selectedActivities}
                        onChange={event => setSelectedActivities(event.target.value)}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        style={{ width: 600 }}
                    >
                        {AUDIT_ACTIVITIES_SHAREPOINT.map((activityGroup) => ([
                            <MenuItem key={activityGroup.Title} disabled value="">
                                <em>SharePoint - {activityGroup.Title}</em>
                            </MenuItem>,
                            ...activityGroup.ChildArray.map((activity) => (
                                <MenuItem key={activity.FriendlyName} value={activity.FriendlyName}>
                                    <Checkbox
                                        checked={selectedActivities.includes(activity.FriendlyName)}
                                        onChange={() => handleActivityToggle(activity.FriendlyName, activity.Operation, 'SharePoint')}
                                    />
                                    <ListItemText primary={activity.FriendlyName} />
                                </MenuItem>
                            )),
                        ]))}
                        {AUDIT_ACTIVITIES_EXCHANGE.map((activityGroup) => ([
                            <MenuItem key={activityGroup.Title} disabled value="">
                                <em>Exchange - {activityGroup.Title}</em>
                            </MenuItem>,
                            ...activityGroup.ChildArray.map((activity) => (
                                <MenuItem key={activity.FriendlyName} value={activity.FriendlyName}>
                                    <Checkbox
                                        checked={selectedActivities.includes(activity.FriendlyName)}
                                        onChange={() => handleActivityToggle(activity.FriendlyName, activity.Operation, 'Exchange')}
                                    />
                                    <ListItemText primary={activity.FriendlyName} />
                                </MenuItem>
                            )),
                        ]))}
                        {AUDIT_ACTIVITIES_ENTRA.map((activityGroup) => ([
                            <MenuItem key={activityGroup.Title} disabled value="">
                                <em>EntraID - {activityGroup.Title}</em>
                            </MenuItem>,
                            ...activityGroup.ChildArray.map((activity) => (
                                <MenuItem key={activity.FriendlyName} value={activity.FriendlyName}>
                                    <Checkbox
                                        checked={selectedActivities.includes(activity.FriendlyName)}
                                        onChange={() => handleActivityToggle(activity.FriendlyName, activity.Operation, 'EntraID')}
                                    />
                                    <ListItemText primary={activity.FriendlyName} />
                                </MenuItem>
                            )),
                        ]))}
                        {AUDIT_ACTIVITIES_TEAMS.map((activityGroup) => ([
                            <MenuItem key={activityGroup.Title} disabled value="">
                                <em>Teams - {activityGroup.Title}</em>
                            </MenuItem>,
                            ...activityGroup.ChildArray.map((activity) => (
                                <MenuItem key={activity.FriendlyName} value={activity.FriendlyName}>
                                    <Checkbox
                                        checked={selectedActivities.includes(activity.FriendlyName)}
                                        onChange={() => handleActivityToggle(activity.FriendlyName, activity.Operation, 'Teams')}
                                    />
                                    <ListItemText primary={activity.FriendlyName} />
                                </MenuItem>
                            )),
                        ]))}

                    </Select>
                </div>
            </div>
        </Grid>

    );
};