// React Components
import * as React from 'react';
import { useState } from "react";

// Audit Vault Components
import CompanyForm from '../../components/company/CompanyForm';

function AddCompany() {

  const [isAdminPage] = useState(true);

  return (
    <>
      <CompanyForm isAdminPage={isAdminPage}></CompanyForm>
    </>
  );
}
export default AddCompany;