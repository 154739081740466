
// This is used to enforce how many saved searches each user can have active.  This is to prevent malicious users from bringing the system down by submitting hundreds of searches at a time.
const MAX_SAVED_SEARCHES_PER_USER = 35;  
// Used for Insight Search query limits per workload.  This const is used to display warning when detected.
const MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY = 10000;
// Used for regular report result limits.  This const is used to display warning when detected.  
const MAX_RESULTS_REGULAR_REPORT_QUERY = 5000;  
// If a Search Orchestrator does not return a heartbeat within 10 minutes - it's an error state.
const HEARTBEAT_SEARCH_ERROR_AFTER = 10;  

export { MAX_SAVED_SEARCHES_PER_USER, MAX_RESULTS_PER_WORKLOAD_SEARCH_QUERY, MAX_RESULTS_REGULAR_REPORT_QUERY, HEARTBEAT_SEARCH_ERROR_AFTER };