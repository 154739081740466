const getAuditSource = (id) => {

    //SharePointAudit = 0,
    //ExchangeAudit = 1,
    //AzureAdAudit = 2,
    //DlpAudit = 3,
    //GeneralAudit = 4,
    //TeamsAudit = 5

    switch (id) {

        case 0:
            return "SharePoint";

        case 1:
            return "Exchange";

        case 2:
            return "Entra ID";

        case 3:
            return "DLP";

        case 4:
            return "General";

        case 5:
            return "Teams";

        case 6:
            return "Historical Audit";

        default:
            return "N/A";
    }

};

export default getAuditSource;