// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components

// Audit Vault Utilities
import ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart from "../components/reports/ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Threat Intelligence', link: '/Reports/ThreatIntelligence' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 20, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 90, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'objectId', label: 'Item', minWidth: 50, maxWidth: 140, align: 'left' },
    { id: 'itemType', label: 'Item Type', minWidth: 50, maxWidth: 40, align: 'left' },
    { id: 'listItemUniqueId', label: 'List Item Unique Id', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'sourceFileName', label: 'Item Name', minWidth: 50, maxWidth: 90, align: 'left' }
];

/*
ReportsThreatSharePointPermsUpdateAddedToGroup report - calls the reusable template ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart.
*/
function ReportsThreatSharePointPermsUpdateAddedToGroup(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, paramEndDate, paramStartDate, paramThresholdCount } = useParams();  // If paramEndDate, paramStartDate, and paramThresholdCount are included then run the report automatically.    

    const theSectionTitle = "Report: Threat Intelligence - Identify Bulk SharePoint Permissions Updates (AddedToGroup)";
    const theSectionDesc = "This report can be used to identify insider threats where an authorized User has made a large number of Permission Updates (User AddedToGroup) in SharePoint over a specified date range.  To run the report you must provide the abnormal threshold count (# of AddedToGroup actions per user) and the corresponding date range to flag (maximum 30 day period to scan, default is 7 days).";
    const isAdminPage = false;
    const csvFilenameTitle = "ReportsThreatSharePointPermsUpdateAddedToGroup";
    const defaultThresholdCount = 50;

    // Component Functions.
    return (

        // Call ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart and pass in parameters.
        <ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart
            user={user}
            reportType={"SharePointThreatDetectionBulkPermissionUpdates"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            defaultThresholdCount={defaultThresholdCount}
            optionalParamEndDate={paramEndDate}
            optionalParamStartDate={paramStartDate}
            optionalParamThresholdCount={paramThresholdCount}
        />
    );
}
export default ReportsThreatSharePointPermsUpdateAddedToGroup;