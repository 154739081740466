// React Components
import * as React from 'react';
import axios from "axios";
import ReactMarkdown from 'react-markdown';  // Markdown use.
import { useNavigate } from "react-router-dom";

// Material UI Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// Accordion related.
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Menu related.
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';

// Audit Vault Components
import { MESSAGE_STATUS } from "../constants/constants-messagestatus";

// Audit Vault Utilities
import {formatDate} from '../utilities/common-date-utils';
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { encryptKey } from '../utilities/common-encrypt-util';


// PW: 2023-06-13: 
// Added additional prop to allow this to be used with the Admin pages (isAdminPage).  
//   If true, it will change the delete button to purge call instead of straight delete for a regular company.  
//   It true, it will also populate a new option - Edit in the menu.
export default function MessageList(props) {

  // Component Constants
  const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
  const { myMessages, theCompanyId, theUser, updateDataFunc, snackbarNotification, page, setPage, rowsPerPage, setRowsPerPage, expandedRow, setExpandedRow, sortConfig, setSortConfig, setLoading, setServiceIsDownError, isAdminPage } = props;    

  // Only display delete option if you are a Tenant Admin and up.
  const columns = [  
    { id: 'title', label: 'Message Title', minWidth: 200, align: 'left'},
    { id: 'publishedDate', label: 'Last Updated', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 50 },  
    theUser.permissions.length > 0 && theUser.permissions[0].roleId <= 2 || theUser.isCompanyAdmin ?
        { id: 'delete', label: 'Delete', minWidth: 10 } : null,        
    { id: 'action', label: 'Action', minWidth: 10 }  
  ].filter((column) => column !== null); // Remove null entries.  

  const rows = [];  
  const navigate = useNavigate();

  
  // React Menu specific items.  Each row in the data table has it's own anchor element (button) hence why we need an array of hooks.
  const [anchorEls, setAnchorEls] = React.useState({});  
  const [menuSelectedRowId, setMenuSelectedRowId] = React.useState(null);


  // Component Functions

  // Menu functions - event handler for when a specific menu object is clicked.
  const handleMenuClick = (event, rowId) => {    
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [rowId]: event.currentTarget,
    }));
    setMenuSelectedRowId(rowId);
  };

  // Menu functions - when a specific menu is closed.
  const handleMenuClose = (rowId) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [rowId]: null,
    }));
  };
  
  
  // Function for Sorting column headers.
  function getComparator(sortConfig) {
    return (a, b) => {
      if (!sortConfig) {
        return 0;
      }        
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    };
  }    
  // Function for Sorting column headers.  
  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }
  // Column header sort function.
  const requestSort = (key) => {
      let direction = 'asc';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
      }
      setSortConfig({ key, direction });
  };
  // Table navigation.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  // Accordion row clicked.  Toggles the message open or closed.
  const handleRowClick = (id, rowIsRead) => {
    if (expandedRow === id) {      
      setExpandedRow(null);
    } 
    else {      
      setExpandedRow(id);      
      if(rowIsRead == false) {
        // Row item has not yet been read - mark it as read please and thanks.        
        handleMarkAsRead(id);        
        // PW: Update we solved the above and removed the navigate.  We just moved the hooks for page, rowsPerPage and expandedRow up the parent so they are maintained in the non-updated parent.
        // Deprecated: Because handleMarkAsRead calls parent to update the data, it will cause this MessageList component to re-render which would make the expanded row option not work.
        // To solve this - we force re-navigate back to MessageCenter and focus on the expanded message via optional parameter.  
        // This ensures on page refresh (navigate) it displays the current message expanded.
        // navigate(`/MessageCenter/${theCompanyId}/${id}`);          
      }                        
    }
  };


  // Event handler for when the view option is chosen from the menu.
  const handleMessageView = (rowId, rowIsRead) => {
    handleMenuClose(rowId);  // Close the menu nicely first.                 
    if (!(expandedRow === rowId)) {      
      // Only view if not opened.
      setExpandedRow(rowId);      
      if(rowIsRead == false) {
        // Row item has not yet been read - mark it as read please and thanks.        
        handleMarkAsRead(rowId);        
      }                                    
    } 
  }


  // Event handler for when the message is edited (Admin level user only).
  const handleMessageEdit = (rowId) => {
    handleMenuClose(rowId);  // Close the menu nicely first.
    // Navigate to the page.    
    navigate(`/admin/EditMessage/${rowId}`); 
  }

  
  // Asynchronous event handler for Mark Delete.  
  const handleMarkDelete = async (rowId) => {  
    handleMenuClose(rowId);  // Close the menu nicely first.                 
    try {
      var result = null;
      if(isAdminPage === false) {        
        // Delete is not being called from the Admin Page - so just mark the message as deleted for the Company specified.
        var rsaKey = await encryptKey(`{companyId: ${theCompanyId}, messageId: ${rowId}}`);
        if (rsaKey) {
            var result = await axios.put(`${WEB_API_URL}MessageCenter/UpdateSetShadowMessageAsDeletedByCompany`, null, {
                params: {
                    key: `${rsaKey}`
                }
            })
        }                        
      }
      else {        
        // Delete is being called from the Admin Page - so we purge the message from the System entirely (ie. from the shadow table, and the actual message table).
        var rsaKey = await encryptKey(`{id: ${rowId}}`);
        if (rsaKey) {
            var result = await axios.delete(`${WEB_API_URL}MessageCenter/Delete`, {
                params: {
                    key: `${rsaKey}`
                }
            })
        }                
      }
      if (result)
      {      
        // Hooks to parent to update data and the UI.
        snackbarNotification(true);
        updateDataFunc();      
      }    
    }
    catch(e) {
      catchAxiosErr2(e, "MessageList.handleMarkDelete", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
    }
  };


  // Asynchronous event handler for Mark As Read.
  const handleMarkAsRead = async (rowId) => {      
    handleMenuClose(rowId);  // Close the menu nicely first.     
    try {
      var rsaKey = await encryptKey(`{companyId: ${theCompanyId}, messageId: ${rowId} }`);
      const result = await axios.put(`${WEB_API_URL}MessageCenter/UpdateSetShadowMessageAsReadByCompany/`, null, 
      {
        params: {
          key: `${rsaKey}`
        }
      });
      if (result)
      {
        // Hooks to parent to update data.
        updateDataFunc();      
      }      
    }
    catch(e)
    {       
      catchAxiosErr2(e, "MessageList.handleMarkAsRead", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
    }
  };


  // Asynchronous event handler for Mark As Unread.
  const handleMarkAsUnread = async (rowId) => {       
    handleMenuClose();  // Close the menu nicely first.
    try {
      var rsaKey = await encryptKey(`{companyId: ${theCompanyId}, messageId: ${rowId} }`);
      const result = await axios.put(`${WEB_API_URL}MessageCenter/UpdateSetShadowMessageAsUnreadByCompany/`, null, 
      {
        params: {
          key: `${rsaKey}`
        }
      });
      if (result)
      {
        // Hooks to parent to update data.            
        updateDataFunc();      
      }    
    }
    catch(e) {      
      catchAxiosErr2(e, "MessageList.handleMarkAsUnread", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.      
    }
  };


  // Component UI

  // Style.
  const iconStyle = {
    color: 'black',
    textDecoration: 'none',
  };

  return (
        
    <Paper sx={{ width: '100%' }}>        
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                onClick={() => requestSort(column.id)}
                >
                {column.label}
                {sortConfig && sortConfig.key === column.id && (
                    <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              stableSort(myMessages, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                                      
              .map((row) => {                
                return (
                  <React.Fragment key={row.id}>
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];                                                                                     

                      // Look at the column id, if it's NOT MarkAsReadOrUnread or Delete or Status then just display the value in the cell.  Else display the action.
                      switch(column.id) {
                        case 'title':                              
                            if(row['shadowIsRead'] == true)
                            {                                                                                        
                                // Mail message has already been read - the title is not bold.
                                return(                   
                                    
                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id, row['shadowIsRead'])}>                                
                                      {                                                                         
                                        value ? value.toString() : value
                                      }         
                                    </TableCell>                                    
                                )                                
                            }
                            else {                                                                
                                // Mail message has not been read - the title is bold.
                                return(                                
                                    
                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id, row['shadowIsRead'])}>
                                    <span style={{ fontWeight: 'bold' }}>
                                    {                                   
                                        value ? value.toString() : value
                                    }                              
                                    </span>
                                    </TableCell>                                    
                                )
                            }                            
                            break;
                        case 'publishedDate':
                            // Display the formatted Published Date for the message.
                            return(
                                <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id, row['shadowIsRead'])}>
                                  {
                                    formatDate(value ? value.toString() : value)                                    
                                  }                              
                                </TableCell>
                            )
                            break;
                        case 'status':
                            // Display the message status type.
                            var theMatchingStatus = MESSAGE_STATUS.find(findStatus => findStatus.value === value);
                            var theMatchingStatusText = theMatchingStatus ? theMatchingStatus.label : 'Invalid message status code.';  
                            return(
                                <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id, row['shadowIsRead'])}>
                                {
                                    theMatchingStatusText
                                }                              
                                </TableCell>    
                            );
                            
                            /*
                            var theStatus = "";
                            switch(row['status']) {
                                case 0:
                                    theStatus = "Notification Only";                                    
                                    break;
                                case 1:
                                    theStatus = "Planned Outage";                                    
                                    break;
                                case 2:
                                    theStatus = "Service Degradation";                                    
                                    break;
                                case 3:
                                    theStatus = "Service Restored";                                    
                                    break;
                            }                        
                            return(
                                <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id, row['shadowIsRead'])}>
                                {
                                  theStatus
                                }                              
                                </TableCell>
                            )
                            */
                            break;
                        case 'delete':
                            // Display action for deleting a Message.                                   
                            return (
                              <TableCell key={column.id} align="left">                                
                                {isAdminPage === false ? (
                                  <Link onClick={(event) => handleMarkDelete(row.id)} component="button">Delete</Link>
                                ) : (
                                  <Link onClick={(event) => handleMarkDelete(row.id)} component="button">Purge</Link>
                                )}                                                                
                              </TableCell>)
                            break;                            
                        case 'action':
                            // Display the menu for the row item.                            
                            return (
                            <TableCell key={column.id} align="left">                              
                              <Button
                                id={`av365button-${row.id}`}
                                aria-controls={`av365menu-${row.id}`}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anchorEls[row.id])}
                                onClick={(event) => handleMenuClick(event, row.id)}
                              >
                                <MoreVertIcon fontSize="small" style={iconStyle}/>
                              </Button>
                              <Menu
                                id={`av365menu-${row.id}`}
                                anchorEl={anchorEls[row.id]}
                                open={Boolean(anchorEls[row.id])}
                                onClose={() => handleMenuClose(row.id)}
                                MenuListProps={{
                                  'aria-labelledby': `av365button-${row.id}`,
                                }}
                              > 
                                <MenuItem onClick={(event) => handleMessageView(row.id, row['shadowIsRead']) }>View</MenuItem>
                                {isAdminPage === true && (
                                  <MenuItem onClick={(event) => handleMessageEdit(row.id)}>Edit</MenuItem>                                
                                )}                                                                                                
                                {row['shadowIsRead'] === false ? (
                                  <MenuItem onClick={(event) => handleMarkAsRead(row.id)}>Mark As Read</MenuItem>
                                ) : (
                                  <MenuItem onClick={(event) => handleMarkAsUnread(row.id)}>Mark As Unread</MenuItem>
                                )}
                                {
                                  isAdminPage === false && theUser.permissions.length > 0 && theUser.permissions[0].roleId <= 2 || theUser.isCompanyAdmin // User is at least Tenant Admin.
                                    ? <MenuItem onClick={(event) => handleMarkDelete(row.id)}>Delete</MenuItem>
                                    : isAdminPage === true
                                      ? <MenuItem onClick={(event) => handleMarkDelete(row.id)}>Purge</MenuItem>
                                      : null // Display nothing if none of the conditions are met.
                                }
                              </Menu>
                            </TableCell>
                            )                            
                            break;                          
                        default:
                            // Any other field without formatting or special actions.
                            <TableCell key={column.id} align={column.align}>
                            {
                                value ? value.toString() : value
                            }                              
                            </TableCell>
                            break;
                      }                      
                        
                    })}                    
                  </TableRow>                    
                  {expandedRow === row.id && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Accordion>
                          {/* We don't include an AccordionSummary. */}                          
                          <AccordionDetails>
                              <span style={{ fontWeight: 'bold' }}>Message Summary</span><br />
                              <ReactMarkdown>{row['messageMarkdown']}</ReactMarkdown>                                                        
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  )}
                  </React.Fragment>              
                );
              })}
          </TableBody>
        </Table>        
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={myMessages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>            

  );
}
