// React Components
import React from 'react';
import { UseCountdown } from './UseCountdown';
import CountdownTimeDisplay from './CountdownTimeDisplay';
import { useNavigate } from "react-router-dom";

// Material UI Components
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const ExpiredNotice = () => {
  const navigate = useNavigate();
  navigate(0);

  return (
    <div className="expired-notice">
      <span>Done!</span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <>
      <Alert severity="info">
        <div className="show-counter">
          <Typography variant='h6' sx={{ textAlign: 'center' }}><b>Please Wait:</b></Typography>
          <br></br>
          <Typography sx={{ textAlign: 'center' }}>
            We are waiting for Microsoft to propogate the appropriate permissions to your M365 Tenant.  Once the operation has completed the page will refresh and you will be able to continue.
          </Typography>
          <br></br>
          <Typography sx={{ textAlign: 'center' }}>The estimated time of completion is:</Typography>
          <Typography><br></br></Typography>
          {minutes >= 0 && seconds >= 0 &&

            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="countdown-link"
              >
                <CountdownTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                <p>:</p>
                <CountdownTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
              </a>
            </>
          }

        </div>
      </Alert >
    </>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = UseCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
