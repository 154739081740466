// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components

// Audit Vault Utilities
import ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart from "../components/reports/ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Threat Intelligence', link: '/Reports/ThreatIntelligence' },
];

// For the optional table to display data source.  815 px to work with.    
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 125, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 150, align: 'left' },
    { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth: 65, align: 'left' },
    { id: 'recordType', label: 'Record Type', minWidth: 40, maxWidth: 40, align: 'left' },
    { id: 'affectedItems', label: 'Affected Items', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'parameters', label: 'Parameters', minWidth: 40, maxWidth: 115, align: 'left' },
    { id: 'originatingServer', label: 'Originating Server', minWidth: 60, maxWidth: 50, align: 'left' }
];

/*
ReportsThreatExchangeDeletedItemsreport - calls the reusable template ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart.
*/
function ReportsThreatExchangeDeletedItems(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, paramEndDate, paramStartDate, paramThresholdCount } = useParams();  // If paramEndDate, paramStartDate, and paramThresholdCount are included then run the report automatically.    

    const theSectionTitle = "Report: Threat Intelligence - Identify Bulk Exchange Deleted Items/Emails";
    const theSectionDesc = "This report can be used to identify insider threats where an authorized User has Deleted a large number of items from Exchange over a specified date range.  To run the report you must provide the abnormal threshold count (# of items deleted per user) and the corresponding date range to flag (maximum 30 day period to scan, default is 7 days).";
    const isAdminPage = false;
    const csvFilenameTitle = "ReportsThreatExchangeDeletedItems";
    const defaultThresholdCount = 200;

    // Component Functions.
    return (

        // Call ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithChart and pass in parameters.
        <ReportTemplateThreatAllActivityByUserDateRangeAndThresholdWithNoChart
            user={user}
            reportType={"ExchangeThreatDetectionDeletedItems"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            defaultThresholdCount={defaultThresholdCount}
            optionalParamEndDate={paramEndDate}
            optionalParamStartDate={paramStartDate}
            optionalParamThresholdCount={paramThresholdCount}
        />

    );


}
export default ReportsThreatExchangeDeletedItems;