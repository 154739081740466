// React Components
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import ImageTeams from '../imgs/iconTeams.svg';

// Audit Vault Utilities
import ReportTemplateActivityByTeamChannelAndUserAndDateRangeWithBarChart from "../components/reports/ReportTemplateActivityByTeamAndChannelAndUserAndDateRangeWithBarChart";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Teams Reports', link: '/Reports/Teams' },
];

// The source data table columns to display.  815 px to work with.
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, maxWidth: 40, align: 'left' },
    { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
    { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
    { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
    { id: 'teamName', label: 'Team Name', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'channelName', label: 'Channel Name', minWidth: 40, maxWidth: 85, align: 'left' },
    { id: 'members', label: 'Members', minWidth: 100, maxWidth: 115, align: 'left' },
    { id: 'attendees', label: 'Attendees', minWidth: 100, maxWidth: 115, align: 'left' }
];

/*
ReportsTeamsAllActivityByTeamOrChannel report - calls the reusable template ReportTemplateActivityByTeamChannelAndUserAndDateRangeWithBarChart.
*/
function ReportsTeamsAllActivityByTeamOrChannel(props) {

    const { user } = props;

    // Component Constants
    const { tenantId } = useParams();

    const theSectionTitle = "Report: Teams - All Activity by Team or Channel";
    const theSectionDesc = "This report can be used in an audit investigation to return all Teams activity in a Team or Channel over a date range.  To run the report you must provide the optional Team, Channel, or User, and the required valid date range.";
    const isAdminPage = false;
    const csvFilenameTitle = "TeamsAllActivityByTeamOrChannel";

    // Component Functions.
    return (

        // Call reusable report template - ActivityByUserAndDateRange and pass in the properties required.
        <ReportTemplateActivityByTeamChannelAndUserAndDateRangeWithBarChart
            user={user}
            reportType={"TeamsAllActivityByTeamOrChannel"}
            tenantId={tenantId}
            theSectionTitle={theSectionTitle}
            theSectionDesc={theSectionDesc}
            isAdminPage={isAdminPage}
            theBreadcrumbPath={theBreadcrumbPath}
            columns={columns}
            csvFilenameTitle={csvFilenameTitle}
            reportImageIcon={ImageTeams} />

    );


}
export default ReportsTeamsAllActivityByTeamOrChannel;