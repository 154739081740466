// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";


// Audit Vault Components
import TenantStatsDetails from "../components/TenantStatsDetails";
import ProgressBar from '../components/common/ProgressBar';
import ServiceIsDown from '../components/common/ServiceIsDown';
import AccessDenied from '../components/common/AccessDenied';
import InvalidTenant from "../components/common/InvalidTenant";

// Audit Vault Common
import { verifyUserCanAccessTenantData } from '../utilities/common-user-utils';
import { getHealthStatsByTenantId } from '../utilities/common-healthstats';


function MyTenantStats(props) {

    const { user } = props;

    // Component Constants
    const { id } = useParams();
    const [stats, setStats] = useState("");
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [invalidTenantError, setInvalidTenantError] = useState(false);


    // Component Functions
    async function fetchData() {
        if (user) {
            if (loading) {
                if (verifyUserCanAccessTenantData(user, id)) {
                    try {
                        var response = await getHealthStatsByTenantId(id, 6, user);
                        if (response) {
                            if (response.companyId == user.companyId) {
                                setStats(response);
                            }
                            else {
                                setAccessDenied(true);
                            }
                        };
                    }
                    catch (e) {
                        setAccessDenied(true);
                        console.log("ERROR: MyTenantStats.fetchData");
                        console.log(e);
                    }
                    finally {
                        setLoading(false);
                    }
                }
                else {
                    setAccessDenied(true);
                    setLoading(false);
                }
            };

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (invalidTenantError) {
        return (<><InvalidTenant></InvalidTenant></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <TenantStatsDetails user={user} stats={stats} />
        </>
    );
}
export default MyTenantStats;