// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Grid, Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';

// Audit Vault Components
import ServiceIsDown from '../../components/common/ServiceIsDown';
import ProgressBar from '../../components/common/ProgressBar';
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';

// Audit Vault Utilities
import catchAxiosErr2 from '../../utilities/common-axios-err-catch2';
import { formatDate } from '../../utilities/common-date-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';

// View and delete Search Orchestrator logs for a specific Search Orchestrator.
function ViewSearchOrchestratorLogs(props) {

    // Component Constants
    const { user } = props;    
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    // Store search logs.
    const [diagnosticSearchLogs, setDiagnosticSearchLogs] = useState([]);
    
    // Section titles.
    const [isAdminPage] = useState(true);
    const theSectionTitle = "View Search Orchestrator - Diagnostic Logs";
    const theSectionDesc = "Below is a list of the Search Diagnostic Logs for a specific Search Orchestrator.  You may delete one or all logs from this screen.";
    // Breadcrumb.
    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Admin', link: '/Admin/AdminHome' },
        { name: 'Search Orchestrators', link: '/Admin/HeartbeatForSearch' },
        { name: 'View Search Orchestrator Diagnostic Logs', link: '/Admin/ViewSearchOrchestratorLogs/' + id }
    ];

    // For snackbar notification UI element.  Message deleted notification.
    const [openDeleteNotification, setOpenDeleteNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.
    // The source data table columns to display.  815 px to work with.
    const columns = [
        { id: 'id', label: 'Id', minWidth: 50, align: 'left' },
        { id: 'searchOrchestratorId', label: 'Search Orch. Id', minWidth: 100, align: 'left' },
        { id: 'isRunSuccessful', label: 'Success', minWidth: 50, align: 'left' },
        { id: 'logDateStartTime', label: 'Start Time', minWidth: 100, align: 'left' },
        { id: 'logDateEndTime', label: 'End Time', minWidth: 100, align: 'left' },
        { id: 'message', label: 'Message', minWidth: 315, align: 'left' },        
        { id: 'action', label: 'Action', minWidth: 100, align: 'left' }
    ];    
    

    // Component Functions

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDeleteNotification(false);
    };

    // For snackbar notification action.
    const openDeleteNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    async function getData() {        
        setLoading(true);  // Progress bar.                               
        try {         
            // Now load the logs.
            var result;
            var data = {
                id: id,
            };                                                
            var keyString = await encryptKey(JSON.stringify(data));
            if (keyString) 
            {                                                
                result = await axios.get(`${WEB_API_URL}SearchDiagnostic/GetDiagnosticSearchLogsByOrchestratorId`, {
                    params: {
                        key: keyString,
                    }
                }); 
                if(result) {
                    setDiagnosticSearchLogs(result.data);                    
                }                
            }    
            setLoading(false);        
        }
        catch (e) {
            // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
            catchAxiosErr2(e, "ViewSearchOrchestratorLogs.getData", setLoading, setServiceIsDownError);
        }        
    }

    useEffect(() => {
        getData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Style.
    const iconStyle = {
        color: 'black',
        textDecoration: 'none',
    };

    // Function for Sorting column headers.
    function getComparator(sortConfig) {
        return (a, b) => {
            if (!sortConfig) {
                return 0;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }

    // Function for Sorting column headers.  
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // Asynchronous event handler for Mark Delete a single Diagnostic Search Log.
    const handleMarkDelete = async (rowId) => {          
        try {
            var result = null;
            var data = {
                logId: rowId,
            };                                                
            var keyString = await encryptKey(JSON.stringify(data));
            if (keyString) 
            {                                                
                result = await axios.delete(`${WEB_API_URL}SearchDiagnostic/PurgeDiagnosticSearchLog`, {
                    params: {
                        key: keyString,
                    }
                }); 
                if(result) {
                    // Hooks to parent to update data and the UI.
                    setOpenDeleteNotification(true);
                    // Reload the data.
                    getData();                                   
                }                
            }    
        }
        catch(e) {
            catchAxiosErr2(e, "ViewSearchOrchestratorLogs.handleMarkDelete", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
        }
    };

    // Asynchronous event handler for Deleting all DiagnosticSearch Logs for the Search Orchestrator.
    const handleDeleteAll = async () => {          
        try {
            var result = null;
            var data = {
                id: id,
            };                                                
            var keyString = await encryptKey(JSON.stringify(data));
            if (keyString) 
            {                                                
                result = await axios.delete(`${WEB_API_URL}SearchDiagnostic/PurgeDiagnosticSearchLogsByOrchestratorId`, {
                    params: {
                        key: keyString,
                    }
                }); 
                if(result) {
                    // Hooks to parent to update data and the UI.
                    setOpenDeleteNotification(true);
                    // Reload the data.
                    getData();                                  
                }                
            }    
        }
        catch(e) {
            catchAxiosErr2(e, "ViewSearchOrchestratorLogs.handleDeleteAll", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
        }
    };



    // Component UI

    // If we are still loading data, display that in the UI...
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <div>
                
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>                   
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        onClick={() => requestSort(column.id)}
                                    >
                                        {column.label}
                                        {sortConfig && sortConfig.key === column.id && (
                                            <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stableSort(diagnosticSearchLogs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        switch (column.id) {
                                                            case 'logDateStartTime':
                                                                return(
                                                                    <TableCell key={column.id} align={column.align}>
                                                                      {
                                                                        formatDate(value ? value.toString() : value)                                    
                                                                      }                              
                                                                    </TableCell>
                                                                )
                                                                break;
                                                            case 'logDateEndTime':
                                                                return(
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            formatDate(value ? value.toString() : value)                                    
                                                                        }                              
                                                                    </TableCell>
                                                                )
                                                                break;
                                                            case 'action':
                                                                // Display action for deleting Diagnostic Search Log  
                                                                return (
                                                                    <TableCell key={column.id} align="left">                                
                                                                        <Link onClick={(event) => handleMarkDelete(row.id)} component="button">Delete</Link>                                                                    
                                                                    </TableCell>)
                                                                break;                
                                                            default:
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} >
                                                                        {value
                                                                            ? value.toString()
                                                                            : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                                break;
                                                        }
                                                    })}
                                                </TableRow>
                                            </React.Fragment>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="span"
                        count={diagnosticSearchLogs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>


            </div>
            <br /><Button variant="contained" type="button" onClick={(event) => handleDeleteAll()}>Delete All Logs</Button>            
            <div>
                <Snackbar
                    open={openDeleteNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message="Successfully Deleted Log(s)."
                    action={openDeleteNotificationAction}
                />
            </div>
        </>
    );
}
export default ViewSearchOrchestratorLogs;