// React Components
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material UI Components

// Audit Vault Components
import AccessDenied from "../common/AccessDenied";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import ProgressBar from "../../components/common/ProgressBar";
import UserSearchAssignRoleForm from "./UserSearchAssignRoleForm";

// Audit Vault Common
import getRole from '../../utilities/common-role-utils';

// Section titles.   
const theSectionTitle = "Assign Users to Role:";
const theSectionDesc = "Select the users from the list that you would like to have permissions to this role.";
const isAdminPage = false;

function TenantRoleAssignUserForm(props) {

    const { user } = props;

    // Component Constants
    const { tenantId, roleId } = useParams();
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Tenant', link: '/Tenant/MyTenant' },
        { name: `Role Assignments : ${getRole(Number(roleId))}`, link: `/mytenant-roles/${tenantId}/${roleId}` },
    ];

    useEffect(() => {
        setLoading(false);
    }, []);

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if(accessDenied)
    {
        return(<AccessDenied></AccessDenied>);
    }

    if (!loading) {
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <UserSearchAssignRoleForm user={user} tenantId={tenantId} roleId={roleId} sourcePage={"MyTenant"} ></UserSearchAssignRoleForm>
            </>
        )
    }

}

export default TenantRoleAssignUserForm;