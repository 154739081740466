// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Select } from '@mui/material';


// Function to insert a default start and end date selector (defaults to startDate - yesterday, and endDate - today).
export default function StartAndEndDateSelector(props){

    const { selectedStartDate, setSelectedStartDate, selectedStartTime, setSelectedStartTime, selectedEndDate, setSelectedEndDate, selectedEndTime, setSelectedEndTime } = props;

    // Create an array of times in 30-minute increments.
    const generateTimes = () => {
        const times = [];
        for (let hours = 0; hours < 24; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 30) {
                const formattedHour = hours.toString().padStart(2, '0');
                const formattedMinute = minutes.toString().padStart(2, '0');
                times.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return times;
    };

    const times = generateTimes();

    // Event handler for when the date picker control changes value.
    const handleDateChange = (date, setSelectedDate) => {
        // Use dayjs to format the date as needed
        const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Adjust the format as needed
        setSelectedDate(formattedDate);
    };

    return(
        <>
        <Grid item xs={12}>
            {/* Start Date input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Start Date (UTC):</b>
                </div>
                <div>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker    
                            name="startDate"                            
                            value={dayjs(selectedStartDate)}                            
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            onChange={ (date) => handleDateChange(date, setSelectedStartDate) }                                           
                        />
                    </LocalizationProvider>
                </div>            
                <div style={{ width: '65px', display: 'flex', alignItems: 'center' }}>
                    &nbsp;&nbsp;<b>Time:</b>
                </div>
                <div>
                    <Select
                        name="startTime"
                        value={selectedStartTime}
                        onChange={(event) => setSelectedStartTime(event.target.value)}                         
                    >
                        {times.map((time, index) => (
                        <MenuItem key={index} value={time}>
                            {time}
                        </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div style={{ height: '15px' }}></div>

            {/* End Date input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>End Date (UTC):</b>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker    
                            name="endDate"                            
                            value={dayjs(selectedEndDate)}                            
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            onChange={ (date) => handleDateChange(date, setSelectedEndDate) }
                        />
                    </LocalizationProvider>                
                </div>
                <div style={{ width: '65px', display: 'flex', alignItems: 'center' }}>
                    &nbsp;&nbsp;<b>Time:</b>
                </div>
                <div>
                    <Select
                        name="endTime"
                        value={selectedEndTime}
                        onChange={(event) => setSelectedEndTime(event.target.value)}                         
                    >
                        {times.map((time, index) => (
                        <MenuItem key={index} value={time}>
                            {time}
                        </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </Grid>
        </>
    );
};