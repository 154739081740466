
// This list comes from Microsoft documentation on Oct 11, 2023.
// See url: https://learn.microsoft.com/en-us/office/office-365-management-api/office-365-management-activity-api-schema#auditlogrecordtype
// I would assume Microsoft would not change these values but add on to the values.
// This array is used to determine the audit log record type and display the memberName in place of the value # as a convenience.

const AUDIT_LOG_RECORD_TYPE = [
    {
      value: 1,
      memberName: 'ExchangeAdmin',
      description: 'Events from the Exchange admin audit log.',
    },
    {
      value: 2,
      memberName: 'ExchangeItem',
      description: 'Events from an Exchange mailbox audit log for actions that are performed on a single item, such as creating or receiving an email message.',
    },
    {
      value: 3,
      memberName: 'ExchangeItemGroup',
      description: 'Events from an Exchange mailbox audit log for actions that can be performed on multiple items, such as moving or deleting one or more email messages.',
    },
    {
      value: 4,
      memberName: 'SharePoint',
      description: 'SharePoint events.',
    },
    {
      value: 6,
      memberName: 'SharePointFileOperation',
      description: 'SharePoint file operation events.',
    },
    {
      value: 7,
      memberName: 'OneDrive',
      description: 'OneDrive for Business events.',
    },
    {
      value: 8,
      memberName: 'AzureActiveDirectory',
      description: 'Azure Active Directory events.',
    },
    {
      value: 9,
      memberName: 'AzureActiveDirectoryAccountLogon',
      description: 'Azure Active Directory OrgId logon events (deprecated).',
    },
    {
      value: 10,
      memberName: 'DataCenterSecurityCmdlet',
      description: 'Data Center security cmdlet events.',
    },
    {
      value: 11,
      memberName: 'ComplianceDLPSharePoint',
      description: 'Data loss protection (DLP) events in SharePoint and OneDrive for Business.',
    },
    {
      value: 13,
      memberName: 'ComplianceDLPExchange',
      description: 'Data loss protection (DLP) events in Exchange, when configured via Unified DLP Policy. DLP events based on Exchange Transport Rules are not supported.',
    },
    {
      value: 14,
      memberName: 'SharePointSharingOperation',
      description: 'SharePoint sharing events.',
    },
    {
      value: 15,
      memberName: 'AzureActiveDirectoryStsLogon',
      description: 'Secure Token Service (STS) logon events in Azure Active Directory.',
    },
    {
      value: 16,
      memberName: 'SkypeForBusinessPSTNUsage',
      description: 'Public Switched Telephone Network (PSTN) events from Skype for Business.',
    },
    {
      value: 17,
      memberName: 'SkypeForBusinessUsersBlocked',
      description: 'Blocked user events from Skype for Business.',
    },
    {
      value: 18,
      memberName: 'SecurityComplianceCenterEOPCmdlet',
      description: 'Admin actions from the Security & Compliance Center.',
    },
    {
      value: 19,
      memberName: 'ExchangeAggregatedOperation',
      description: 'Aggregated Exchange mailbox auditing events.',
    },
    {
      value: 20,
      memberName: 'PowerBIAudit',
      description: 'Power BI events.',
    },
    {
      value: 21,
      memberName: 'CRM',
      description: 'Dynamics 365 events.',
    },
    {
      value: 22,
      memberName: 'Yammer',
      description: 'Yammer events.',
    },
    {
      value: 23,
      memberName: 'SkypeForBusinessCmdlets',
      description: 'Skype for Business events.',
    },
    {
      value: 24,
      memberName: 'Discovery',
      description: 'Events for eDiscovery activities performed by running content searches and managing eDiscovery cases in the Security & Compliance Center.',
    },
    {
      value: 25,
      memberName: 'MicrosoftTeams',
      description: 'Events from Microsoft Teams.',
    },
    {
      value: 28,
      memberName: 'ThreatIntelligence',
      description: 'Phishing and malware events from Exchange Online Protection and Microsoft Defender for Office 365.',
    },
    {
      value: 29,
      memberName: 'MailSubmission',
      description: 'Submission events from Exchange Online Protection and Microsoft Defender for Office 365.',
    },
    {
      value: 30,
      memberName: 'MicrosoftFlow',
      description: 'Microsoft Power Automate (formerly called Microsoft Flow) events.',
    },
    {
      value: 31,
      memberName: 'AeD',
      description: 'Advanced eDiscovery events.',
    },
    {
      value: 32,
      memberName: 'MicrosoftStream',
      description: 'Microsoft Stream events.',
    },
    {
      value: 33,
      memberName: 'ComplianceDLPSharePointClassification',
      description: 'Events related to DLP classification in SharePoint.',
    },
    {
      value: 34,
      memberName: 'ThreatFinder',
      description: 'Campaign-related events from Microsoft Defender for Office 365.',
    },
    {
      value: 35,
      memberName: 'Project',
      description: 'Microsoft Project events.',
    },
    {
      value: 36,
      memberName: 'SharePointListOperation',
      description: 'SharePoint List events.',
    },
    {
      value: 37,
      memberName: 'SharePointCommentOperation',
      description: 'SharePoint comment events.',
    },
    {
      value: 38,
      memberName: 'DataGovernance',
      description: 'Events related to retention policies and retention labels in the Security & Compliance Center.',
    },
    {
      value: 39,
      memberName: 'Kaizala',
      description: 'Kaizala events.',
    },
    {
      value: 40,
      memberName: 'SecurityComplianceAlerts',
      description: 'Security and compliance alert signals.',
    },
    {
      value: 41,
      memberName: 'ThreatIntelligenceUrl',
      description: 'Safe links time-of-block and block override events from Microsoft Defender for Office 365.',
    },
    {
      value: 42,
      memberName: 'SecurityComplianceInsights',
      description: 'Events related to insights and reports in the Office 365 security and compliance center.',
    },
    {
      value: 43,
      memberName: 'MIPLabel',
      description: 'Events related to the detection in the Transport pipeline of email messages that have been tagged (manually or automatically) with sensitivity labels.',
    },
    {
      value: 44,
      memberName: 'WorkplaceAnalytics',
      description: 'Workplace Analytics events.',
    },
    {
      value: 45,
      memberName: 'PowerAppsApp',
      description: 'Power Apps events.',
    },
    {
      value: 46,
      memberName: 'PowerAppsPlan',
      description: 'Subscription plan events for Power Apps.',
    },
    {
      value: 47,
      memberName: 'ThreatIntelligenceAtpContent',
      description: 'Phishing and malware events for files in SharePoint, OneDrive for Business, and Microsoft Teams from Microsoft Defender for Office 365.',
    },
    {
      value: 48,
      memberName: 'LabelContentExplorer',
      description: 'Events related to data classification content explorer.',
    },
    {
      value: 49,
      memberName: 'TeamsHealthcare',
      description: 'Events related to the Patients application in Microsoft Teams for Healthcare.',
    },
    {
      value: 50,
      memberName: 'ExchangeItemAggregated',
      description: 'Events related to the MailItemsAccessed mailbox auditing action.',
    },
    {
      value: 51,
      memberName: 'HygieneEvent',
      description: 'Events related to outbound spam protection.',
    },
    {
      value: 52,
      memberName: 'DataInsightsRestApiAudit',
      description: 'Data Insights REST API events.',
    },
    {
      value: 53,
      memberName: 'InformationBarrierPolicyApplication',
      description: 'Events related to the application of information barrier policies.',
    },
    {
      value: 54,
      memberName: 'SharePointListItemOperation',
      description: 'SharePoint list item events.',
    },
    {
      value: 55,
      memberName: 'SharePointContentTypeOperation',
      description: 'SharePoint list content type events.',
    },
    {
      value: 56,
      memberName: 'SharePointFieldOperation',
      description: 'SharePoint list field events.',
    },
    {
      value: 57,
      memberName: 'MicrosoftTeamsAdmin',
      description: 'Teams admin events.',
    },
    {
      value: 58,
      memberName: 'HRSignal',
      description: 'Events related to HR data signals that support the Insider risk management solution.',
    },
    {
      value: 59,
      memberName: 'MicrosoftTeamsDevice',
      description: 'Teams device events.',
    },
    {
      value: 60,
      memberName: 'MicrosoftTeamsAnalytics',
      description: 'Teams analytics events.',
    },
    {
      value: 61,
      memberName: 'InformationWorkerProtection',
      description: 'Events related to compromised user alerts.',
    },
    {
      value: 62,
      memberName: 'Campaign',
      description: 'Email campaign events from Microsoft Defender for Office 365.',
    },
    {
      value: 63,
      memberName: 'DLPEndpoint',
      description: 'Endpoint DLP events.',
    },
    {
      value: 64,
      memberName: 'AirInvestigation',
      description: 'Automated incident response (AIR) events.',
    },
    {
      value: 65,
      memberName: 'Quarantine',
      description: 'Quarantine events.',
    },
    {
      value: 66,
      memberName: 'MicrosoftForms',
      description: 'Microsoft Forms events.',
    },
    {
      value: 67,
      memberName: 'ApplicationAudit',
      description: 'Application audit events.',
    },
    {
      value: 68,
      memberName: 'ComplianceSupervisionExchange',
      description: 'Events tracked by the Communication compliance offensive language model.',
    },
    {
      value: 69,
      memberName: 'CustomerKeyServiceEncryption',
      description: 'Events related to the customer key encryption service.',
    },
    {
      value: 70,
      memberName: 'OfficeNative',
      description: 'Events related to sensitivity labels applied to Office documents.',
    },
    {
      value: 71,
      memberName: 'MipAutoLabelSharePointItem',
      description: 'Auto-labeling events in SharePoint.',
    },
    {
      value: 72,
      memberName: 'MipAutoLabelSharePointPolicyLocation',
      description: 'Auto-labeling policy events in SharePoint.',
    },
    {
      value: 73,
      memberName: 'MicrosoftTeamsShifts',
      description: 'Teams Shifts events.',
    },
    {
      value: 75,
      memberName: 'MipAutoLabelExchangeItem',
      description: 'Auto-labeling events in Exchange.',
    },
    {
      value: 76,
      memberName: 'CortanaBriefing',
      description: 'Briefing email events.',
    },
    {
      value: 78,
      memberName: 'WDATPAlerts',
      description: 'Events related to alerts generated by Windows Defender for Endpoint.',
    },
    {
      value: 82,
      memberName: 'SensitivityLabelPolicyMatch',
      description: 'Events generated when the file labeled with a sensitivity label is opened or renamed.',
    },
    {
      value: 83,
      memberName: 'SensitivityLabelAction',
      description: 'Event generated when sensitivity labels are applied, updated, or removed from a file.',
    },
    {
      value: 84,
      memberName: 'SensitivityLabeledFileAction',
      description: 'Events generated when a file labeled with a sensitivity label is opened or renamed.',
    },
    {
      value: 85,
      memberName: 'AttackSim',
      description: 'Attack simulator events.',
    },
    {
      value: 86,
      memberName: 'AirManualInvestigation',
      description: 'Events related to manual investigations in Automated investigation and response (AIR).',
    },
    {
      value: 87,
      memberName: 'SecurityComplianceRBAC',
      description: 'Security and compliance RBAC events.',
    },
    {
      value: 88,
      memberName: 'UserTraining',
      description: 'Attack simulator training events in Microsoft Defender for Office 365.',
    },
    {
      value: 89,
      memberName: 'AirAdminActionInvestigation',
      description: 'Events related to admin actions in Automated investigation and response (AIR).',
    },
    {
      value: 90,
      memberName: 'MSTIC',
      description: 'Threat intelligence events in Microsoft Defender for Office 365.',
    },
    {
      value: 91,
      memberName: 'PhysicalBadgingSignal',
      description: 'Events related to physical badging signals that support the Insider risk management solution.',
    },
    {
      value: 93,
      memberName: 'AipDiscover',
      description: 'AIP scanner events',
    },
    {
      value: 94,
      memberName: 'AipSensitivityLabelAction',
      description: 'AIP sensitivity label events',
    },
    {
      value: 95,
      memberName: 'AipProtectionAction',
      description: 'AIP protection events',
    },
    {
      value: 96,
      memberName: 'AipFileDeleted',
      description: 'AIP file deletion events',
    },
    {
      value: 97,
      memberName: 'AipHeartBeat',
      description: 'AIP heartbeat events',
    },
    {
      value: 98,
      memberName: 'MCASAlerts',
      description: 'Events corresponding to alerts triggered by Microsoft Cloud App Security.',
    },
    {
      value: 99,
      memberName: 'OnPremisesFileShareScannerDlp',
      description: 'Events related to scanning for sensitive data on file shares.',
    },
    {
      value: 100,
      memberName: 'OnPremisesSharePointScannerDlp',
      description: 'Events related to scanning for sensitive data in SharePoint.',
    },
    {
      value: 101,
      memberName: 'ExchangeSearch',
      description: 'Events related to using Outlook on the web (OWA) to search for mailbox items.',
    },
    {
      value: 102,
      memberName: 'SharePointSearch',
      description: "Events related to searching an organization's SharePoint home site.",
    },
    {
      value: 103,
      memberName: 'PrivacyInsights',
      description: 'Privacy insight events.',
    },
    {
      value: 105,
      memberName: 'MyAnalyticsSettings',
      description: 'MyAnalytics events.',
    },
    {
      value: 106,
      memberName: 'SecurityComplianceUserChange',
      description: 'Events related to modifying or deleting a user.',
    },
    {
      value: 107,
      memberName: 'ComplianceDLPExchangeClassification',
      description: 'Exchange DLP classification events.',
    },
    {
      value: 109,
      memberName: 'MipExactDataMatch',
      description: 'Exact Data Match (EDM) classification events.',
    },
    {
      value: 113,
      memberName: 'MS365DCustomDetection',
      description: 'Events related to custom detection actions in Microsoft 365 Defender.',
    },
    {
      value: 147,
      memberName: 'CoreReportingSettings',
      description: 'Reports settings events.',
    },
    {
      value: 148,
      memberName: 'ComplianceConnector',
      description: 'Events related to importing non-Microsoft data using data connectors in the Microsoft Purview compliance portal.',
    },
    {
      value: 154,
      memberName: 'OMEPortal',
      description: 'Encrypted message portal event logs generated by external recipients.',
    },
    {
      value: 174,
      memberName: 'DataShareOperation',
      description: 'Events related to sharing of data ingested via SystemSync.',
    },
    {
      value: 181,
      memberName: 'EduDataLakeDownloadOperation',
      description: 'Events related to the export of SystemSync ingested data from the lake.',
    },
    {
      value: 183,
      memberName: 'MicrosoftGraphDataConnectOperation',
      description: 'Events related to extractions done by Microsoft Graph Data Connect.',
    },
    {
      value: 186,
      memberName: 'PowerPagesSite',
      description: 'Activities related to Power Pages site.',
    },
    {
      value: 188,
      memberName: 'PlannerPlan',
      description: 'Microsoft Planner plan events.',
    },
    {
      value: 189,
      memberName: 'PlannerCopyPlan',
      description: 'Microsoft Planner copy plan events.',
    },
    {
      value: 190,
      memberName: 'PlannerTask',
      description: 'Microsoft Planner task events.',
    },
    {
      value: 191,
      memberName: 'PlannerRoster',
      description: 'Microsoft Planner roster and roster membership events.',
    },
    {
      value: 192,
      memberName: 'PlannerPlanList',
      description: 'Microsoft Planner plan list events.',
    },
    {
      value: 193,
      memberName: 'PlannerTaskList',
      description: 'Microsoft Planner task list events.',
    },
    {
      value: 194,
      memberName: 'PlannerTenantSettings',
      description: 'Microsoft Planner tenant settings events.',
    },
    {
      value: 195,
      memberName: 'ProjectForTheWebProject',
      description: 'Microsoft Project for the web project events.',
    },
    {
      value: 196,
      memberName: 'ProjectForTheWebTask',
      description: 'Microsoft Project for the web task events.',
    },
    {
      value: 197,
      memberName: 'ProjectForTheWebRoadmap',
      description: 'Microsoft Project for the web roadmap events.',
    },
    {
      value: 198,
      memberName: 'ProjectForTheWebRoadmapItem',
      description: 'Microsoft Project for the web roadmap item events.',
    },
    {
      value: 199,
      memberName: 'ProjectForTheWebProjectSettings',
      description: 'Microsoft Project for the web project tenant settings events.',
    },
    {
      value: 200,
      memberName: 'ProjectForTheWebRoadmapSettings',
      description: 'Microsoft Project for the web roadmap tenant settings events.',
    },
    {
      value: 202,
      memberName: 'MicrosoftTodoAudit',
      description: 'Microsoft To Do events.',
    },
    {
      value: 216,
      memberName: 'Viva Goals',
      description: 'Viva Goals events.',
    },
    {
      value: 217,
      memberName: 'MicrosoftGraphDataConnectConsent',
      description: 'Events for consent actions performed by tenant admins for Microsoft Graph Data Connect applications.',
    },
    {
      value: 230,
      memberName: 'TeamsUpdates',
      description: 'Teams Updates App Events.',
    },
    {
      value: 237,
      memberName: 'DefenderExpertsforXDRAdmin',
      description: 'Microsoft Defender Experts Administrator action events.',
    },
    {
      value: 231,
      memberName: 'PlannerRosterSensitivityLabel',
      description: 'Microsoft Planner roster sensitivity label events.',
    },
    {
      value: 251,
      memberName: 'VfamCreatePolicy',
      description: 'Viva Access Management policy create events.',
    },
    {
      value: 252,
      memberName: 'VfamUpdatePolicy',
      description: 'Viva Access Management policy update events.',
    },
    {
      value: 253,
      memberName: 'VfamDeletePolicy',
      description: 'Viva Access Management policy delete events.',
    },
  ];
  
  export default AUDIT_LOG_RECORD_TYPE;