// React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


// Audit Vault Components
import AuditVaultUserActivityReportList from "../../components/reports/AuditVaultUserActivityReportList";
import AccessDenied from "../../components/common/AccessDenied";
import ProgressBar from "../../components/common/ProgressBar";
import ServiceIsDown from "../../components/common/ServiceIsDown";
import DisplayBreadCrumb from "../../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../../components/common/DisplaySectionTitleAndDescription";

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';

const theSectionTitle = "Report: System User Activity Report";
const theSectionDesc = "This report displays all the user activity logs from Audit Vault.  Filter by Company Id and Duration.";
const isAdminPage = true;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'System User Activity Report', link: '/Admin/system-user-activity' }
];

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function AdminUserActivityReport(props) {

    const { user } = props;


    // Component Constants
    const [logs, setLogs] = useState([]);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [companyId, setCompanyId] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [lastNumOfDays, setLastNumOfDays] = useState(1);



    const handleNumOfDaysChange = (val) => {
        setLastNumOfDays(val);
    };

    // Component Comments
    async function fetchData() {

        if (user) {

            if (user.isSystemAdmin) {
                try {

                    var data = {
                        sysAdminId: user.microsoftGraphId,
                    };
                    var keyString = await encryptKey(JSON.stringify(data));
                    if (keyString) {
                        var response = await axios.get(`${WEB_API_URL}AdminUtilities/GetCompanies`, {
                            params: {
                                key: keyString,
                            }
                        });
                        if (response) {
                            setCompanies(response.data);
                        }
                    }
                } catch (e) {
                    setServiceIsDownError(true);
                    console.log("ERROR: System User Activity Logs")
                    console.log(e);
                }
                finally {
                    setLoading(false);
                }
            }
            else {
                setAccessDenied(true);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function getUserActivityLogs() {
        if (user) {

            if (user.isSystemAdmin) {
                try {
                    setLoading(true);
                    var payload = `{
                        CompanyId: ${companyId},
                        SysAdminGraphId: "${await encryptKey(user.microsoftGraphId)}",
                        LastNumOfDays: "${lastNumOfDays}"}`;

                    if (payload) {
                        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetAllUserActivityLogs`, {
                            params: {
                                key: `${payload}`
                            }
                        });
                        if (result) {
                            setLogs(result.data);
                        }
                    }
                } catch (e) {
                    setAccessDenied(true);
                    console.log("ERROR: getUserActivityLogs")
                    console.log(e);
                }
                finally {
                    setLoading(false);
                }
            }
        }
    }

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<><AccessDenied></AccessDenied></>);
    }

    if (!loading) {
        return (
            <React.Fragment>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

                {logs &&
                    <><Card sx={{ width: '50%', minWidth: 550 }} variant="outlined">
                        <CardContent>
                            <Typography component="div"><b>Search for User Activity Logs:</b></Typography>
                            <Typography component="div"><br></br></Typography>
                            <Table sx={{
                                minWidth: 500, width: '100%', [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                }
                            }} size="small">
                                <TableHead>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell key="search-desc"><b>Company:</b></TableCell>
                                        <TableCell key="search-barcell">
                                            <TextField required
                                                select
                                                name="companyId"
                                                value={companyId}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 300 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={event => setCompanyId(event.target.value)}
                                            >
                                                <MenuItem key={0} value="0">
                                                    ALL
                                                </MenuItem>
                                                {companies.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.companyName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="flterRole"><b>Duration:</b></TableCell>
                                        <TableCell key="filterRole-txt">
                                            <TextField required
                                                select
                                                name="lastNumOfDays"
                                                value={lastNumOfDays}
                                                variant="outlined"
                                                size="small"
                                                style={{ width: 300 }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={event => handleNumOfDaysChange(event.target.value)}
                                            >
                                                <MenuItem key="mnuDiagnostic5" value="1">
                                                    Last 24 Hours
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic5" value="7">
                                                    Last 7 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic4" value="30">
                                                    Last 30 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic3" value="60">
                                                    Last 60 Days
                                                </MenuItem>
                                                <MenuItem key="mnuDiagnostic2" value="90">
                                                    Last 90 Days
                                                </MenuItem>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </CardContent>
                        <CardActions>
                            <Button type="submit" aria-label="search" onClick={() => getUserActivityLogs()}>Search <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
                        </CardActions>
                    </Card>
                    </>
                }

                <Typography component="div"><br></br></Typography>
                {logs &&
                    <AuditVaultUserActivityReportList
                        logs={logs}
                    ></AuditVaultUserActivityReportList>

                }

            </React.Fragment >
        );
    }


}
export default AdminUserActivityReport;