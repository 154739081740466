// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Link from '@mui/material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';

// Audit Vault Components
import ServiceIsDown from "../common/ServiceIsDown";
import ProgressBar from "../common/ProgressBar";

// Audit Vault Utilities
import catchAxiosErr2 from '../../utilities/common-axios-err-catch2';
import { getTenantsRecordedTime, formatDate } from '../../utilities/common-date-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
import { SEARCH_STATUS_LOOKUP, SEARCH_STATUS_COMPLETED } from "../../constants/constants-searchjobstatus";

/*
Displays the saved search results in a list for the Company.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- user: The current logged in user.
- submittedSearchCount: The number of searches recently submitted in the parent form, is just used to force an update to this component once a search is successfully submitted.

Note permissions check to the Tenant data is done by the parent component.

*/
export default function SearchResultsList(props) {

    // Component Constants
    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
    const navigate = useNavigate();
    const { tenantId, user, submittedSearchCount, populateSearch, updateMySearchJobsCount } = props;       
    const [searchJobResults, setSearchJobResults] = useState([]);  // This data structure stores the results. 

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.
    const [expandedRow, setExpandedRow] = useState(null);  // For Accordion control. 

    // React Menu specific items.  Each row in the data table has it's own anchor element (button) hence why we need an array of hooks.
    const [anchorEls, setAnchorEls] = React.useState({});  
    const [menuSelectedRowId, setMenuSelectedRowId] = React.useState(null);

    // For snackbar notification UI element.  Search Job deleted notification.
    const [openSnackbarNotification, setOpenSnackbarNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    // The table to display data source.  815 px to work with.    
    const columns = [
        //{ id: 'id', label: 'Id', minWidth: 30, maxWidth:95, align: 'left' },        
        { id: 'searchName', label: 'Search Name', minWidth: 210, maxWidth: 265, align: 'left' },
        { id: 'jobStatus', label: 'Search Status', minWidth: 50, maxWidth:100, align: 'left' },        
        { id: 'searchCreatedDate', label: 'Search Date', minWidth: 50, maxWidth: 140, align: 'left' },
        { id: 'searchCreatedByUser', label: 'Search Performed By', minWidth: 50, maxWidth: 180, align: 'left' },        
        { id: 'totalResults', label: 'Total Results', minWidth: 40, maxWidth: 120, align: 'left' },        
        { id: 'action', label: 'Action', minWidth: 10, align: 'left' }, 
    ];    



    // Component Functions

    async function fetchSearchJobs() 
    {            
        var axiosUri = "";
        var findUser = user.userEmail;    
        var mySearchJobCount = 0;
        var data = null;
        var keyString = null;

        setLoading(true);
        try {            
            // We attempt to load the Search Jobs.
            if(user.isCompanyAdmin || user.isSystemAdmin) {
                // Load all Search Jobs belonging to the Tenant.
                axiosUri = "Search/GetSearchJobsByTenantId";                
                data = {
                    tenantId: tenantId,  
                };                                                
                keyString = await encryptKey(JSON.stringify(data));
            }
            else {
                // Load all Search Jobs for the UserId only.
                axiosUri = "Search/GetSearchJobsByTenantIdAndUserEmail";
                data = {
                    tenantId: tenantId,  
                    userEmail: user.userEmail
                };                                                
                keyString = await encryptKey(JSON.stringify(data));                
            }
            // console.log(`Check: ${WEB_API_URL}${axiosUri}`);
            var response1 = await axios.get(`${WEB_API_URL}${axiosUri}`, {
                params: {
                    key: `${keyString}`
                }
            });
            if (response1) {                    
                setSearchJobResults(response1.data);  // Store the Search Job.
                // Use filter to find entries where searchCreatedByUser matches findUser.
                let matchingEntries = response1.data.filter((entry) => entry.searchCreatedByUser === findUser);
                // Update the search job count belonging to the current user.
                updateMySearchJobsCount(matchingEntries.length);
            }                                         
        }
        catch (e) {
            catchAxiosErr2(e, "SearchResultsList.fetchSearchJobs", setLoading, setServiceIsDownError);
        }
        setLoading(false);
    }


    // Load Search Result data.
    useEffect(() => {

        fetchSearchJobs();
            
    }, [submittedSearchCount]);


    // Define a function to manually refresh data
    const handleRefresh = () => {
        fetchSearchJobs();
    };

    // Function for Sorting column headers.
    function getComparator(sortConfig) {
        return (a, b) => {
            if (!sortConfig) {
                return 0;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }

    // Function for Sorting column headers.  
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Menu functions - event handler for when a specific menu object is clicked.
    const handleMenuClick = (event, rowId) => {    
        setAnchorEls((prevAnchorEls) => ({
        ...prevAnchorEls,
        [rowId]: event.currentTarget,
        }));
        setMenuSelectedRowId(rowId);
    };

    // Menu functions - when a specific menu is closed.
    const handleMenuClose = (rowId) => {
        setAnchorEls((prevAnchorEls) => ({
        ...prevAnchorEls,
        [rowId]: null,
        }));
    };

    // Accordion row clicked.  Toggles the row open or closed.
    const handleRowClick = (id) => {
        handleMenuClose(id);  // Close the menu nicely first.     
        if (expandedRow === id) {
            setExpandedRow(null);
        }
        else {
            setExpandedRow(id);
        }
    };   
   

    // Handle rehydrate search clicked.
    const handleRehydrateSearch = async (theSearchJob) => {

        handleMenuClose(theSearchJob.id);  // Close the menu nicely first.     

        // After logic is completed, scroll to #searchInput.
        const searchInput = document.getElementById('searchInput');
        if (searchInput) {
            searchInput.scrollIntoView({ behavior: 'smooth' });
        }

        // Repopulate the Seach Inputs.
        await populateSearch(theSearchJob);  // Call function from parent to repopulate the search.

    };   


    // Asynchronous event handler for Mark Delete.  
    const handleMarkDelete = async (rowId) => {          
        handleMenuClose(rowId);  // Close the menu nicely first.                 
        try {
            var result = null;
            // We mark the message as deleted (but we do not purge for now, the deleted search results are kept for now until they are manually purged for auditing purposes - proof of what someone searched for in Audit Vault for M365).            
            //console.log(`${WEB_API_URL}Search/UpdateMarkSearchJobAsDeleted/${rowId}/${user.userEmail}`);            
            var data = {
                searchJobId: rowId,  
                userEmail: await encryptKey(user.userEmail)
            };                                                
            var keyString = JSON.stringify(data);
            if (keyString) 
            {                                                
                result = await axios.put(`${WEB_API_URL}Search/UpdateMarkSearchJobAsDeleted`, null, {
                    params: {
                        key: `${keyString}`
                    }
                });                
                if (result)
                {                   
                    // Set deletion notice.
                    setOpenSnackbarNotification(true);
                    handleRefresh();      
                }                                
            }

        }
        catch(e) {            
            catchAxiosErr2(e, "SearchResultList.handleMarkDelete", setLoading, setServiceIsDownError);  // Call function to parse the Axios Error, if 'Service Is Down' it will set the hook to true.  It also updates the setLoading and setSuccess to false.
        }
    };

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbarNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );





    // Component UI    
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Style.
    const iconStyle = {
        color: 'black',
        textDecoration: 'none',
    };
    


    return (
        <>      
            <h6>Saved Search Results</h6>
            Below are your search results including the corresponding search status and total results. Clicking on a Search Result will show the original search criteria.                                   
            {
                (user.isCompanyAdmin || user.isSystemAdmin) ?
                (                    
                    <><br />Note: As a Company Admin role you can view and manage all Searches submitted by any Company User for this Tenant.  All other Users can only manage their own Searches.</>                           
                )
                :
                (
                    <><br />Note: As a Report Reader role you can only manage your own Searches for this Tenant.</>
                )
            }
            <br /><br />

            <Link onClick={handleRefresh} component="button"><RefreshIcon fontSize="small" />Refresh</Link>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        onClick={() => requestSort(column.id)}
                                    >
                                        {column.label}
                                        {sortConfig && sortConfig.key === column.id && (
                                            <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>                            
                            {                                      
                                stableSort(searchJobResults, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        let searchCompleted = false;     
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];

                                                        switch (column.id) {
                                                            case 'searchCreatedDate':  // Take UTC time and display in current time zone.
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordBreak: 'break-all'}} onClick={() => handleRowClick(row.id)}>
                                                                    { value ? formatDate(value) : value }  
                                                                    </TableCell>
                                                                );  
                                                                break;  
                                                            case 'jobStatus':
                                                                const theSearchStatus = SEARCH_STATUS_LOOKUP.find(item => item.status === value);    
                                                                if(value === SEARCH_STATUS_COMPLETED) {
                                                                    searchCompleted = true;
                                                                }
                                                                return(                                                                    
                                                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                    {
                                                                        searchCompleted == true ?
                                                                        (                                                                            
                                                                            <Link onClick={() => navigate(`/Reports/ViewSearchResults/${row.id}`)} component="button">Completed</Link>
                                                                        )
                                                                        :
                                                                        (
                                                                            theSearchStatus.label
                                                                        )
                                                                    }                                                                    
                                                                    </TableCell>                                                                        
                                                                );                                    
                                                                break;    
                                                            case 'totalResults':
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                    { searchCompleted == true 
                                                                        ? value.toString()
                                                                        : <>N/A</>
                                                                    }
                                                                    </TableCell>
                                                                );
                                                                break;
                                                            case 'action':
                                                                // Display the menu for the row item.                            
                                                                return (
                                                                    <TableCell key={column.id} align="left">                              
                                                                    <Button
                                                                    id={`av365button-${row.id}`}
                                                                    aria-controls={`av365menu-${row.id}`}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={Boolean(anchorEls[row.id])}
                                                                    onClick={(event) => handleMenuClick(event, row.id)}
                                                                    >
                                                                    <MoreVertIcon fontSize="small" style={iconStyle}/>
                                                                    </Button>
                                                                    <Menu
                                                                    id={`av365menu-${row.id}`}
                                                                    anchorEl={anchorEls[row.id]}
                                                                    open={Boolean(anchorEls[row.id])}
                                                                    onClose={() => handleMenuClose(row.id)}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': `av365button-${row.id}`,
                                                                    }}
                                                                    >                                                                         
                                                                        { searchCompleted == true &&
                                                                            <MenuItem onClick={(event) => navigate(`/Reports/ViewSearchResults/${row.id}`)}>View Search Results</MenuItem>
                                                                        }                                                                        
                                                                        <MenuItem onClick={(event) => handleRowClick(row.id)}>View Search Criteria</MenuItem>
                                                                        <MenuItem onClick={(event) => handleRehydrateSearch(row)}>Send to New Search</MenuItem>
                                                                        <MenuItem onClick={(event) => handleMarkDelete(row.id)}>Delete</MenuItem>
                                                                    </Menu>
                                                                </TableCell>
                                                                )                            
                                                                break;         
                                                            default:
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                        {value
                                                                            ? value.toString()
                                                                            : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                                break;
                                                        }
                                                    })}
                                                </TableRow>
                                                {expandedRow === row.id && (
                                                    <TableRow>
                                                        <TableCell colSpan={columns.length}>
                                                            <Accordion>
                                                                {/* We don't include an AccordionSummary. */}
                                                                <AccordionDetails>
                                                                    <span style={{ fontWeight: 'bold' }}>Search Criteria</span><br />
                                                                    <br />
                                                                    System Search ID: {row.id}<br />
                                                                    Start Date (UTC): {row.criteriaStartDateUtc}<br />
                                                                    End Date (UTC): {row.criteriaEndDateUtc}<br />
                                                                    Keyword: {row.criteriaKeyword}<br />
                                                                    Site or Location: {row.criteriaSiteOrLocation}<br />
                                                                    User(s): {row.criteriaForUsers}<br />
                                                                    Workload: {row.criteriaForWorkload}<br />
                                                                    <br />
                                                                    SharePoint Activities<br />                                                                    
                                                                    <li>Operation Names: {row.criteriaActivitiesSharePoint}</li>
                                                                    <li>Friendly Names: {row.criteriaActivitiesFriendlySharePoint}</li>                                                                    
                                                                    Entra ID Activities<br />
                                                                    <li>Operation Names: {row.criteriaActivitiesEntraID}</li>
                                                                    <li>Friendly Names: {row.criteriaActivitiesFriendlyEntraID}</li>
                                                                    Exchange Activities<br />
                                                                    <li>Operation Names: {row.criteriaActivitiesExchange}</li>
                                                                    <li>Friendly Names: {row.criteriaActivitiesFriendlyExchange}</li>
                                                                    Teams Activities<br />
                                                                    <li>Operation Names: {row.criteriaActivitiesTeams}</li>
                                                                    <li>Friendly Names: {row.criteriaActivitiesFriendlyTeams}</li>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="span"
                        count={searchJobResults.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <div>
                <Snackbar
                    open={openSnackbarNotification}
                    autoHideDuration={6000}
                    onClose={handleNotificationClose}
                    message="Your Selected Search was Deleted"
                    action={openNotificationAction}
                />
                </div>            
        </>        
    );    


}