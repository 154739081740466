import React from 'react';
import {Text, View, Image, StyleSheet } from '@react-pdf/renderer';

import logo from '../../../imgs/ecminsights-logo.png';

const styles = StyleSheet.create({
   
    headerContainer:{
        flexDirection: 'row',
        marginTop: 24,
        width: '100%',
    },
    colContainerLeft:{
        width: '100%',
        textAlign: 'left',
    },
    colContainerRight:{
        width: '20%',
        textAlign: 'right',
        verticalAlign: 'top',
    },
    reportTitle:{
        letterSpacing: 1,
        fontSize: 20,
        textAlign: 'left',
        fontWeight: 'bold'
    },
    logo: { 
    }
  });


  const InvoiceTitle = ({title}) => (
    <>
    <View style={styles.headerContainer}>
      <View style={styles.colContainerLeft}>
        <Text style={styles.reportTitle}>{title}</Text>
      </View>
      <View style={styles.colContainerRight}>
        <Image style={styles.logo} src={logo} />
      </View>
    </View>
    </>
  );
  
  export default InvoiceTitle