// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";
import { useState } from "react";

// Audit Vault Components
import AccessDenied from '../components/common/AccessDenied';
import CompanyForm from '../components/company/CompanyForm';


function UpdateCompany(props) {

  const { user } = props;
  const { id } = useParams();
  const [isAdminPage] = useState(false);

  if (id && user) {
    if (id == user.companyId) {
      return (
        <>
          <CompanyForm user={user} companyId={id} isAdminPage={isAdminPage}></CompanyForm>
        </>
      );
    }
    else {
      return (<AccessDenied></AccessDenied>);
    }
  }

}
export default UpdateCompany;