import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export const ProgressBar = (props) => {
    const { message, loading } = props
 
  return (
    <>
    {loading && (
        <Box sx={{ width: '100%' }}>
          <div>{message}</div>
          <div><LinearProgress /></div>
          <div><br></br></div>
        </Box>
      )}
      </>
  );
}

export default ProgressBar;