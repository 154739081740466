import axios from "axios";

// Audit Vault common
import { encryptKey } from "./common-encrypt-util";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function getCompanyProductsInfo(companyId, setCompanyProducts) {
    if (companyId) {
        const response = await axios.get(`${WEB_API_URL}Products/getproductsbycompanyid/${companyId}`);
        if (response) {
            console.log("GetProductsByCompanyId:")
            console.log(response.data)
            setCompanyProducts(response.data);
            var returnMsg = '{"products": [';
            for (var i = 0; i < response.data.length; i++) {
                returnMsg = returnMsg + `{"stripeSubscriptionId":"${response.data[i].stripeSubscriptionId}"},`;
            }
            if (response.data.length > 0) {
                // remove trailing ,
                returnMsg = returnMsg.substring(0, returnMsg.length - 1);
            }
            returnMsg = returnMsg + "]}";
        }

        let productsObjs = JSON.parse(returnMsg);
        console.log("Products Object:");
        console.log(productsObjs);
        return productsObjs;
    }

};

export async function createSubscription(user, company, companySize, product, price, promoCode, stripeCustomerId) {

    var promoCodeId = "";
    if (promoCode && promoCode.couponId != undefined) {
        promoCodeId = promoCode.couponId;
    }

    // create the subscription
    var payload = `{
        "companyId": ${company.id},
        "companySize": ${companySize},
        "description": "${company.companyName}",
        "email": "${company.billingEmail}",
        "phone": "${company.phone}",
        "companyName": "${company.companyName}",
        "city": "${company.city}",
        "country": "${company.country}",
        "addressLine1": "${company.streetAddress}",
        "addressLine2": "${company.streeAddressLine2}",
        "postalZipCode": "${company.postalZip}",
        "provinceState": "${company.provinceState}",
        "productDescription": "${product.name}",
        "productId": "${product.id}",
        "priceId": "${price.priceId}",
        "dbUnitsPerTenant": "${product.dbUnitsPerTenant}",
        "couponId": "${promoCodeId}",
        "stripeCustomerId": "${stripeCustomerId}",
        "currentCompanySetupStageComplete": "${company.setupStageComplete}",
        "userEmail": "${await encryptKey(user.userEmail)}",
        "userMicrosoftGraphId": "${await encryptKey(user.microsoftGraphId)}"
    }`;

    console.log(payload)

    try {
        var response = await axios.post(`${WEB_API_URL}Billing/create-subscription`, null, {
            params: {
                key: payload,
            }
        });

        if (response) {
            console.log(response);
            return response;
        }
    }
    catch (e) {
        console.log("ERROR: processNewCustomerSubscription");
        console.log(e);
    }

}


export async function getStripeCustomer(stripeCustomerId) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-stripe-customer`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getStripeCustomerInvoices(stripeCustomerId, limit) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}", Limit: "${await encryptKey(limit.toString())}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-invoices`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}


export async function getSubscriptionInfo(stripeCustomerId, setSubscription) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-subscription`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            setSubscription(result.data);
            return "true";
        }
    }
};

export async function getSubscriptionInfo2(stripeCustomerId) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-subscription`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
};


export const getBillingStatus = (status) => {
    switch (status) {
        case 'active':
            return "Active";
        case 'past_due':
            return "Past Due";
        case 'unpaid':
            return "Unpaid";
        case 'canceled':
            return "Canceled";
        case 'incomplete':
            return "Incomplete";
        case 'incomplete_expired':
            return "Incomplete Expired";
        case 'trialing':
            return "Trial";
        case 'paused':
            return "Paused";
    }

};

export const getBillingStatusColour = (status) => {
    switch (status) {
        case 'active':
            return "green";
        case 'past_due':
            return "red";
        case 'unpaid':
            return "orange";
        case 'canceled':
            return "orange";
        case 'incomplete':
            return "orange";
        case 'incomplete_expired':
            return "red";
        case 'trialing':
            return "blue";
        case 'paused':
            return "orange";
    }
};

export const getCollectionMethod = (method) => {
    switch (method) {
        case 'charge_automatically':
            return "Charged Automatically";

        default:
            return "N/A";
    }
};

export const getPaymentType = (type) => {
    switch (type) {
        case 'subscription_item':
            return "Subscription";

        default:
            return "N/A";
    }
};

export const getBillingInterval = (interval) => {
    switch (interval) {
        case 'month':
            return "Monthly";

        case 'year':
            return "Yearly";

        default:
            return "N/A";
    }
};