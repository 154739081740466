// React Components
import * as React from 'react';

// Material UI Components
import { Doughnut} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Legend, Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 

// Audit Vault Components

// Audit Vault Utilities



// Note, below is the tree-shakable way to decrease the bundle size.
ChartJS.register(ArcElement, Title, Legend, Tooltip);
ChartJS.register(ChartDataLabels);

export default function AuditsByWorkloadSource(props) {

    // Component Constants
    const { totalSharePointAuditRecords, totalExchangeAuditRecords, totalAzureAdAuditRecords, totalTeamsAuditRecords } = props;    
    var theGraphTitle = "Audits By Workload Source ";                                  
    
    const data = {
        labels: [
        'SharePoint',
        'Exchange',
        'Entra ID',
        'Teams'
        ],
        datasets: [{          
          data: [totalSharePointAuditRecords, totalExchangeAuditRecords, totalAzureAdAuditRecords, totalTeamsAuditRecords],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(18, 211, 74)'
          ],
          hoverOffset: 4
        }]
    };

    const options = {
        // Chart.js options (if needed)
        maintainAspectRatio: false,
        aspectRatio: 1.5,        
        responsive: false,
        plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const dataset = ctx.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, dataPoint) => acc + dataPoint, 0);
                const percentage = ((value * 100) / total).toFixed(1);
                return percentage + '%';
              },
              color: '#FFF', 
              font: {
                weight: 'bold',
              },
              anchor: 'center',
              align: 'center',
            },
            legend: {
                position: 'right',
                labels: {
                fontSize: 12
                },
                display: 'auto'
            },            
          },
    };

    
    // Component Functions

    // Component UI

    return (
        <>
        <h6>{theGraphTitle}</h6>  
        <Doughnut data={data} options={options} />
        </>
    );

}
