export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUDIT_VAULT_APP_ID,
    authority: "https://login.microsoftonline.com/common", // GT: Need to use common here for users from different tenant to get access
    //authority: "https://login.microsoftonline.com/ce33c4b1-1f0c-4032-b956-d6f3ef21477c", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    //cacheLocation: "sessionStorage", // This configures where your cache will be stored
    cacheLocation: "localStorage", // Allows for multiple tabs to open for session. Need for viewing Audit History from SPO Menu item
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

export const organizationRequest = {
  scopes: ["Organization.Read.All"]
};

export const permsRequest = {
  scopes: ["Files.Read"]
};


// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: ["https://graph.microsoft.com/v1.0/me"]
};

export const organizationConfig = {
  graphOrgEndpoint: ["https://graph.microsoft.com/v1.0/organization"]
}