// React Components
import * as React from 'react';
import { useState } from "react";
import axios from "axios";

// Material UI Components
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// Audit Vault Components
import ProgressBar from './common/ProgressBar';

import { encryptKey } from '../utilities/common-encrypt-util';
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function DeleteDiagnosticeLogsConfirmation(props) {
  
  const { onClose, open, snackbarNotification, updateDataFunc, user } = props;
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  async function deleteHandler() {
    
      try
      {
        setLoading(true);
        var rsaKey = `{SysAdminId: "${await encryptKey(user.microsoftGraphId)}"}`;
        if (rsaKey) {

            var result = await axios.delete(`${WEB_API_URL}AdminUtilities/DeleteAllDiagnosticLogs`, {
                params: {
                    key: `${rsaKey}`
                }
            });

          if (result)
          {        
            handleClose();
            snackbarNotification(true);
            updateDataFunc();
          }
        }
      }
      catch (e)
      {
        console.log("ERROR: DeleleDiagnosticeLogsConfirmation.deleteHandler");
        console.log(e);
      }
      finally{
        setLoading(false);
      }
  }
    
  return (
    <Dialog onClose={handleClose} open={open} >
      <DialogTitle id="alert-dialog-title">
          Delete All Diagnostic Logs
        </DialogTitle>
        <DialogContent>
        <ProgressBar message="Deleting ..." loading={loading} />
          <DialogContentText id="alert-dialog-description">
            <div>All Diagnostics Logs will be removed permanently.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={deleteHandler} autoFocus>
            Delete
          </Button>
        </DialogActions>
    </Dialog>
  );
}