// React Components
import * as React from 'react';
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Meterial UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


// Audit Vault Components
import AccessDenied from './common/AccessDenied';
import ProgressBar from './common/ProgressBar';
import DisplayBreadCrumb from './common/DisplayBreadCrumb';
import DisplaySectionTitleAndDescription from './common/DisplaySectionTitleAndDescription';
import SessionTimeout from './common/SessionTimeout';

// Audit Vault Constants 
import { API_ENDPOINTS } from "../constants/constants-apiendpoints";
import { JOB_PROCESS_INTERVALS } from "../constants/constants-jobprocessintervals";
import { LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE, LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE } from '../constants/constants-links';

// MSAL Components
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

// Audit Vault Utilities
import { getUserOrganizationData, verifyUserCanAccessTenantData, verifyUserCanAccessCompanyData } from '../utilities/common-user-utils';
import {getCompanyByAzureTenantId} from '../utilities/common-company';
import { encryptKey } from '../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export default function TenantForm(props) {

    // Component Constants
    const { user, tenant, companyId, tenantId, isAdminPage } = props;
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(true);
    const [loadingMsg, setLoadingMsg] = useState("Loading ...");
    const [accessDenied, setAccessDenied] = React.useState(false);
    const [serviceIsDownError, setServiceIsDownError] = React.useState(false);
    const [loginTimeout, setLoginTimeout] = useState(false);

    const [loadingTestCnn, setLoadingTestCnn] = React.useState(false);
    const [testCnnError, setTestCnnError] = React.useState(false);
    const [testCnnMsg, setTestCnnMsg] = React.useState("");
    const [testCnnSuccess, setTestCnnSuccess] = React.useState(false);
    const [tenantCreateError, setTenantCreateError] = React.useState(false);

    const [enableSelfDatabaseHosting, setEnableSelfDatabaseHosting] = useState(false);

    const tenantUrlRef = useRef("");
    const [tenantUrl, setTenantUrl] = useState("");
    const [errorTenantUrl, setTenantUrlError] = useState(false);

    const tenantApiEndPointRef = useRef("0");
    const [tenantApiEndPoint, setTenantApiEndPoint] = useState(0);

    const [errorAzureTenantIdUrl, setAzureTenantIdError] = useState(false);
    const [errorInvalidTenant, setInvalidTenantError] = useState(false);
    const [errorTeamsNotLogged, setErrorTeamsNotLogged] = useState(false);

    const [tenantSendEmailNotifications, setSendEmailNotifications] = useState(false);
    const [useAdvancedAuditPermsForSpAudit, setUseAdvancedAuditPermsForSpAudit] = useState(false);

    const sharePointJobProcessIntervalRef = useRef("");
    const exchangeJobProcessIntervalRef = useRef("");
    const entraIDJobProcessIntervalRef = useRef("");
    const generalJobProcessIntervalRef = useRef("");

    const [sharePointJobProcessInterval, setSharePointJobProcessInterval] = useState(60);
    const [exchangeJobProcessInterval, setExchangeJobProcessInterval] = useState(60);
    const [entraIDJobProcessInterval, setEntraIDJobProcessInterval] = useState(60);
    const [generalJobProcessInterval, setGeneralJobProcessInterval] = useState(60);
    const [tenantLogSharePoint, setTenantLogSharePoint] = useState(true);  // Set to default enabled.
    const [tenantLogExchange, setTenantLogExchange] = useState(true);
    const [tenantLogEntraID, setTenantLogEntraID] = useState(true);
    const [tenantLogGeneral, setTenantLogGeneral] = useState(true);
    const [tenantLogTeams, setTenantLogTeams] = useState(true);
    const [azureTenantId, setAzureTenantId] = useState("");

    // For Self Hosted database
    const [isDatabaseHostedByCompany, setIsDatabaseHostedByCompany] = useState(false);
    const [dbServerName, setDbServerName] = useState("");
    const [dbUserId, setDbUserId] = useState("");
    const [dbPassword, setDbPassword] = useState("");

    const [dbServerNameError, setDbServerNameError] = useState(false);
    const [dbUserIdError, setDbUserIdError] = useState(false);
    const [dbPasswordError, setDbPasswordError] = useState(false);

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [orgData, setOrgData] = useState(null);
    const [verifiedDomains, setVerifiedDomains] = useState(null);

    // Section titles.
    const updateSectionTitle = "Update Tenant";
    const createSectionTitle = "New Tenant";
    const theSectionDesc = "Configure your Tenant below, select what audit workloads you require and set how often each workload should run.";   

    // Breadcrumb.
    if(isAdminPage == true) {
        // Is from the admin pages.
        if (tenant)
        {
            var updateBreadcrumbPath = [
                { name: 'Home', link: '/' },
                { name: 'Admin', link: '/Admin/AdminHome' },
                { name: 'Company List', link: '/Admin/CompanyListAdmin' },
                { name: 'Tenants', link: `/Admin/tenant-admin/${tenant.companyId}` },
                { name: 'Update Tenant', link: `/Admin/tenant-update/${tenant.Id}`}
            ]; 
        }   
        var createBreadcrumbPath = [
            { name: 'Home', link: '/' },
            { name: 'Admin', link: '/Admin/AdminHome' },
            { name: 'Company List', link: '/Admin/CompanyListAdmin' },
            { name: 'Tenants', link: `/Admin/tenant-admin/${companyId}` },            
            { name: 'Add New Tenant', link: `/Admin/tenant-create/${companyId}`}
        ];    
    }
    else {
        // Is not from the admin pages.
        var updateBreadcrumbPath = [
            { name: 'Home', link: '/' },
            { name: 'Tenant', link: '/Tenant/MyTenant' },
            { name: 'Update Tenant', link: `/tenant-update/${tenantId}`}
        ];
        var createBreadcrumbPath = [
            { name: 'Home', link: '/' },
            { name: 'Tenant', link: '/Tenant/MyTenant' },
            { name: 'Add New Tenant', link: `/tenant-create/${companyId}`}
        ];    
    }    
    
    useEffect(() => {
        async function fetchData()
        {
            try
            {
                // Get the user from the database ... if just created company and tenant was just created, means user has not updated perms
                var permAllowed = false;
                if (loading)
                {
                // the following fails when a new user has never logged in and hasn't created any tenant yet.
                if (user)
                {
                    if (!isAdminPage && !tenant && user.companyId == 0)
                    {
                        setAccessDenied(true);
                        permAllowed = false;
                    }
                    else if(user.isSystemAdmin)
                    {
                        permAllowed = true;
                    }
                    else
                    {
                        var tenantExists = Boolean(tenant);
                        switch (tenantExists)
                        {
                            case false:
                                // creating a new tenant
                                var hasPermsSet = Boolean(user.permissions.length > 0);
                                switch (hasPermsSet)
                                {
                                    case true:
                                        console.log(user);
                                        permAllowed = verifyUserCanAccessCompanyData(user);
                                        break;

                                    case false:                          
                                        if (user.companyId > 0)
                                        {
                                            // means initial set up
                                            permAllowed = true;
                                        }
                                        break;;
                                }
                                break;

                            case true:
                                // Updating an existing tenant.
                                permAllowed = verifyUserCanAccessTenantData(user, tenant.id);
                                break;
                        }
                    }

                
                    if (permAllowed)
                    {
                        try
                        {
                            var response = await getUserOrganizationData(instance, isAuthenticated, accounts, orgData, setOrgData);
                            if (response)
                            {
                                if (!tenant)
                                {
                                    setAzureTenantId(accounts[0].tenantId);
                                    // need to get the company to check if enable self hosting db is enabled or not
                                    var companyResponse = await getCompanyByAzureTenantId(accounts[0].tenantId);
                                    if (companyResponse)
                                    {
                                        setEnableSelfDatabaseHosting(companyResponse.enableSelfHostedDbOption);
                                    }
                                }

                                var list = [];
                                if (!isAdminPage)
                                {
                                    // Just put unique values into tenant drop down list.
                                    for (var x = 0; x < response.value[0].verifiedDomains.length; x++) 
                                    {
                                        var tempName = getTenantHostnameOnly(response.value[0].verifiedDomains[x].name);
                                        var found = false;
                                        for (var y = 0; y < list.length; y++) 
                                        {
                                            if (tempName == list[y])
                                            {
                                                found = true;
                                            }
                                        }
                                        if (!found)
                                        {
                                            if (user.isSystemAdmin)
                                            {
                                                list.push(tenant.tenantUrl);
                                            }
                                            else
                                                list.push(tempName);
                                        }
                                    }
                                }
                                else
                                {
                                    if (tenant)
                                        list.push(tenant.tenantUrl);
                                }

                                setVerifiedDomains(list);
                            }
                        }
                        catch (e)
                        {
                            console.log("ERROR: TenantForm.fetchData");
                            console.log(e);
                            setLoginTimeout(true);
                        }

                        if (tenant)
                        {
                            // Update Existing Tenant
                            setTenantUrl(tenant.tenantUrl);
                            setAzureTenantId(tenant.azureTenantId);
                            setTenantApiEndPoint(tenant.tenantApiEndPointType);
                            setSharePointJobProcessInterval(tenant.sharePointAuditJobRunTimeInterval);
                            setExchangeJobProcessInterval(tenant.exchangeAuditJobRunTimeInterval);
                            setEntraIDJobProcessInterval(tenant.entraIDAuditJobRunTimeInterval);
                            setGeneralJobProcessInterval(tenant.generalAuditJobRunTimeInterval);
                            setSendEmailNotifications(tenant.sendNotificationEmail);
                            setUseAdvancedAuditPermsForSpAudit(tenant.useSaaPermsToAccessSpAudit);
                            setTenantLogSharePoint(tenant.logSharePointAudit);
                            setTenantLogExchange(tenant.logExchangeAudit);
                            setTenantLogEntraID(tenant.logEntraIDAudit);
                            setTenantLogGeneral(tenant.logGeneralAudit);
                            setTenantLogTeams(tenant.logTeamsAudit);
                            setIsDatabaseHostedByCompany(tenant.isDatabaseHostedByCompany);
                            setEnableSelfDatabaseHosting(tenant.isDatabaseHostedByCompany);
                            setDbServerName(tenant.dbServerNm);
                            setDbPassword(tenant.dbPassword);
                            setDbUserId(tenant.dbUserId);

                            console.log(tenant)
                        }
                    }
                }
                else
                {
                    setAccessDenied(true);
                }
            }
            }
            catch(e)
            {
                console.log(e);
                setServiceIsDownError(true);
            }
            finally{
                setLoading(false);
            }
        }
        
        fetchData();
    }, []);

    function getTenantHostnameOnly(tenantUrl) {

        if (!tenantUrl)
          return "N/A";
      
        if (!tenantUrl.startsWith('https://'))
          tenantUrl = 'https://' + tenantUrl;
      
        var hostname = new URL(tenantUrl).hostname;
        var tenantDomain = hostname.split(".")[0];
        return tenantDomain;
      }
    
    const handleGeneralAuditChange = async(val) =>
    {
        setTenantLogGeneral(val);
        if (!val && tenantLogTeams)
        {
            setTenantLogTeams(false);
        }
    }

    const handleTestConnection = async() =>
    {
        setTestCnnError(false);
        setTestCnnMsg("");
        setTestCnnSuccess(false);
        let isError = validateInfoHandler();
        if (!isError)
        {
            try
            {
                setLoadingTestCnn(true);

                var usrEmail = "N/A";
                if (user.userEmail)
                    usrEmail = user.userEmail;

                var payload = {
                    dbUserId: await encryptKey(dbUserId),
                    dbUserPw: await encryptKey(dbPassword),
                    dbServerNm: await encryptKey(dbServerName),
                    userEmail: await encryptKey(usrEmail),
                    userMicrosoftId: await encryptKey(user.microsoftGraphId)
                    };
             
                var response = await axios.get(`${WEB_API_URL}Tenant/TestSelfHostedDbConnection`, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                })
                if (response)
                {
                    setTestCnnSuccess(true);
                    setTestCnnMsg("Connection was successful.")
                }
                
            }
            catch (e)
            {
                setTestCnnMsg("Connection failed. : " + e.message);
                setTestCnnError(true);
                console.log("ERROR: TenantForm.handleInsert");
                console.log(e);
            }
            finally{
                setLoadingTestCnn(false);
            }
        }
    }

    function validateInfoHandler() 
    {
        var isError = false;
        if (tenantUrl === '')
        {
            setTenantUrlError(true);
            isError = true;
        }
        else
        {
           setTenantUrlError(false);
        }
        if (azureTenantId.trim() === '')
        {
            setAzureTenantIdError(true)
            isError = true;
        }
        else
        {
            setAzureTenantIdError(false)
        };

        if (tenantLogGeneral && !tenantLogTeams)
        {
            setErrorTeamsNotLogged(true);
            isError = true;
        }

        if (isDatabaseHostedByCompany)
        {
            if (dbServerName.trim() === '')
            {
                setDbServerNameError(true)
                isError = true;
            }
            else
            {
                setDbServerNameError(false)
            };

            if (dbUserId.trim() === '')
            {
                setDbUserIdError(true)
                isError = true;
            }
            else
            {
                setDbUserIdError(false)
            };

            if (dbPassword.trim() === '')
            {
                setDbPasswordError(true)
                isError = true;
            }
            else
            {
                setDbPasswordError(false)
            };
        }
        return isError;
    }


    const handleInsert = async () => {

        setTenantCreateError(false);
        let isError = validateInfoHandler();
        if (!isError)
        {
            try
            {
                setLoadingMsg("Provisioning Database ...");
                setLoading(true);

                var usrEmail = "N/A";
                if (user.userEmail)
                    usrEmail = user.userEmail;

            var payload = {
                companyId: companyId,
                companyDbServerId: 1,
                tenantUrl: tenantUrl,
                tenantApiEndPointType: tenantApiEndPoint,
                azureTenantId: azureTenantId,
                dbServerPort: 0,
                dbName: "",
                DbUnitsPurchased: 0,
                sendNotificationEmail: tenantSendEmailNotifications,
                sharePointAuditJobRunTimeInterval: sharePointJobProcessInterval,
                exchangeAuditJobRunTimeInterval: exchangeJobProcessInterval,
                entraIDAuditJobRunTimeInterval: entraIDJobProcessInterval,
                generalAuditJobRunTimeInterval: generalJobProcessInterval,
                logSharePointAudit: tenantLogSharePoint,
                logExchangeAudit: tenantLogExchange,
                logEntraIDAudit: tenantLogEntraID,
                logDlpAudit: false,
                logGeneralAudit: tenantLogGeneral,
                logTeamsAudit: tenantLogTeams,
                useSaaPermsToAccessSpAudit: useAdvancedAuditPermsForSpAudit,
                isDatabaseHostedByCompany: false,
                diagnosticLoggingLevel: 4,
                auditQueryStartTimeOffset: 4,
                logToFile: false,
                logFilePath: "",
                logFileName: "",
                logToDatabase: true,
                getXLastNumOfDays: 0,
                customLogoUrl: "",
                customBrandingScheme: "",
                isDatabaseHostedByCompany: isDatabaseHostedByCompany,
                dbUserId: "",
                dbPassword: "",
                dbServerNm: "",
                userEmail: await encryptKey(usrEmail),
                userMicrosoftGraphId: await encryptKey(user.microsoftGraphId)
                };

                if (isDatabaseHostedByCompany)
                {
                    payload = {
                        companyId: companyId,
                        companyDbServerId: 1,
                        tenantUrl: tenantUrl,
                        tenantApiEndPointType: tenantApiEndPoint,
                        azureTenantId: azureTenantId,
                        dbServerPort: 0,
                        dbName: "",
                        DbUnitsPurchased: 0,
                        sendNotificationEmail: tenantSendEmailNotifications,
                        sharePointAuditJobRunTimeInterval: sharePointJobProcessInterval,
                        exchangeAuditJobRunTimeInterval: exchangeJobProcessInterval,
                        entraIDAuditJobRunTimeInterval: entraIDJobProcessInterval,
                        generalAuditJobRunTimeInterval: generalJobProcessInterval,
                        logSharePointAudit: tenantLogSharePoint,
                        logExchangeAudit: tenantLogExchange,
                        logEntraIDAudit: tenantLogEntraID,
                        logDlpAudit: false,
                        logGeneralAudit: tenantLogGeneral,
                        logTeamsAudit: tenantLogTeams,
                        useSaaPermsToAccessSpAudit: useAdvancedAuditPermsForSpAudit,
                        isDatabaseHostedByCompany: false,
                        diagnosticLoggingLevel: 4,
                        auditQueryStartTimeOffset: 4,
                        logToFile: false,
                        logFilePath: "",
                        logFileName: "",
                        logToDatabase: true,
                        getXLastNumOfDays: 0,
                        customLogoUrl: "",
                        customBrandingScheme: "",
                        isDatabaseHostedByCompany: isDatabaseHostedByCompany,
                        dbUserId: await encryptKey(dbUserId),
                        dbPassword: await encryptKey(dbPassword),
                        dbServerNm: await encryptKey(dbServerName),
                        userEmail: await encryptKey(usrEmail),
                        userMicrosoftGraphId: await encryptKey(user.microsoftGraphId)
                        };
                }
             
                var response = await axios.post(`${WEB_API_URL}Tenant/InsertTenant`, null, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                })
                if (response)
                {
                    if (response == -1)
                    {
                        setInvalidTenantError(true);
                    }
                    else
                    {
                        if(isAdminPage == true) {
                            navigate(`/Admin/tenant-admin/${companyId}`); 
                        }
                        else 
                        {
                            navigate("/Tenant/MyTenant");
                        }
                    }
                }
                
            }
            catch (e)
            {
                console.log("ERROR: TenantForm.handleInsert");
                console.log(e);
                setTenantCreateError(true);
            }
            finally{
                setLoading(false);
                setLoadingMsg("Loading ....");
            }
        }
    
    }

    const handleUpdate= async () => {
    {
        
        let isError = validateInfoHandler();
        if (!isError)
        {
            try
            {
                setLoading(true);

                var usrEmail = "N/A";
                if (user.userEmail)
                    usrEmail = user.userEmail;

                var payload = {
                    id: tenant.id,
                    tenantUrl: tenantUrl,
                    tenantApiEndPointType: tenantApiEndPoint,
                    azureTenantId: await encryptKey(azureTenantId),
                    sendNotificationEmail: tenantSendEmailNotifications,
                    sharePointAuditJobRunTimeInterval: sharePointJobProcessInterval,
                    exchangeAuditJobRunTimeInterval: exchangeJobProcessInterval,
                    entraIDAuditJobRunTimeInterval: entraIDJobProcessInterval,
                    generalAuditJobRunTimeInterval: generalJobProcessInterval,
                    logSharePointAudit: tenantLogSharePoint,
                    logExchangeAudit: tenantLogExchange,
                    logEntraIDAudit: tenantLogEntraID,
                    logGeneralAudit: tenantLogGeneral,
                    logTeamsAudit: tenantLogTeams,
                    useSaaPermsToAccessSpAudit: useAdvancedAuditPermsForSpAudit,
                    userEmail: await encryptKey(usrEmail),
                    userMicrosoftGraphId: await encryptKey(user.microsoftGraphId),
                    isDatabaseHostedByCompany: isDatabaseHostedByCompany,
                    dbUserId: await encryptKey(dbUserId),
                    dbPassword: await encryptKey(dbPassword),
                    dbServerNm: await encryptKey(dbServerName),
                    };
        
                var response = await axios.put(`${WEB_API_URL}Tenant/UpdateTenant`, null, {
                    params: {
                        key: JSON.stringify(payload),
                    }
                });
                
                if (response)
                {
                    if(isAdminPage == true) {
                        navigate(`/Admin/tenant-admin/${tenant.companyId}`); 
                    }
                    else {
                        navigate("/Tenant/MyTenant");
                    }            
                    };
                }
                catch (e)
                {
                    console.log("ERROR: TenantForm.handleUpdate");
                    console.log(e);
                }
                finally{
                    setLoading(false);
                }
            }
        }
    }


    if (loginTimeout) {
        return (
            <SessionTimeout></SessionTimeout>
        )
    }

    // Component UI
    if (loading || !orgData) {
        return (<ProgressBar message={loadingMsg} loading={loading} />);
    }

    if (accessDenied)
    {
        return (<AccessDenied></AccessDenied>);
    }

    if (tenantCreateError)
    {
        return (<>
            <Alert severity="error">
            <AlertTitle>Unable to Create Tenant</AlertTitle>
            Please try again.  If the issue persists, please contact support.
            </Alert>
        </>);
    }

    if (errorInvalidTenant) {
        return (<>
            <Alert severity="error">
            <AlertTitle>Invalid Tenant Specified</AlertTitle>
            It appears that the tenant configured is not a valid Microsoft 365 Tenant.
            </Alert>
        </>);
    }

    
if ((!loading && orgData) || tenant)
{
    return(
        <React.Fragment>
        {tenant ?
            <><DisplayBreadCrumb paths={updateBreadcrumbPath} /><DisplaySectionTitleAndDescription sectionTitle={updateSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} /></>
            :
            <><DisplayBreadCrumb paths={createBreadcrumbPath} /><DisplaySectionTitleAndDescription sectionTitle={createSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} /></>
        }

        <Grid
            container
            marginTop={0}
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
        <Grid item xs={12}>
        <Card variant="outlined">
        <CardContent>
            <Typography key='txtTenantUrl' component="div">
                <b>Tenant:</b>
                <br></br>
                <TextField required 
                    select 
                    name="tenantUrlRef" 
                    value={tenantUrl}
                    inputRef={tenantUrlRef}
                    variant="outlined" 
                    size="small"
                    style={{ width: '100%' , maxWidth: 450}}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setTenantUrl(event.target.value)}
                    error={errorTenantUrl}
                    helperText={errorTenantUrl ? 'Tenant cannot be empty.' : ''}
                    >
                    {verifiedDomains && verifiedDomains.map((row) => (
                        <MenuItem key={row} value={row}>
                            {row}
                        </MenuItem>
                    )
                    )}
                </TextField>
            </Typography>
            <Typography key='txtTenantApiEndPointRef' sx={{ marginTop: 2}} component="div">
                <b>Tenant Endpoint:</b>
                <br></br>
                <TextField required 
                    select 
                    name="tenantApiEndPointRef" 
                    inputRef={tenantApiEndPointRef}
                    value={tenantApiEndPoint}
                    variant="outlined" 
                    size="small"
                    style={{ width: '100%', maxWidth: 450 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setTenantApiEndPoint(event.target.value)}
                    >
                {API_ENDPOINTS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
                ))}
                </TextField>
            </Typography>
            <Typography key='txtSendNotifications' sx={{ marginTop: 2}} component="div">
                <Checkbox required
                    checked={tenantSendEmailNotifications} 
                    name="sendNotifications"
                    controlid="sendNotifications" 
                    onChange={event => setSendEmailNotifications(event.target.checked) } 
                    /> Send Email Notifications
            </Typography>
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12}>
        <Card variant="outlined">
        <CardContent>       
            <p><b>Audit History for SharePoint App</b><br></br>
            Easily view the Audit Log History of an Item directly from SharePoint Online.</p>
            <p>The Audit History for SharePoint menu App can be added to your SharePoint tenant via <Link href={`${LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE}`} target="_blank">Microsoft AppSource</Link>.  When installed on your Tenant's Site Collection(s), an "Audit history" menu and command bar button is exposed in SharePoint.  When clicked, the menu will open an Audit Vault for M365 report that displays the audit history for the selected SharePoint Item.</p>
            <Divider sx={{ my: 2, borderColor: 'green' }}/>            
            <Typography key='txtUseAditVaultPerms' component="div">
                <p>Control the permissions of who can access the Audit History for SharePoint menu report below:</p>
                <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={useAdvancedAuditPermsForSpAudit}
                    onChange={event => setUseAdvancedAuditPermsForSpAudit(event.target.value)}
                >
                    <FormControlLabel value="false" control={<Radio slotProps={{ input: { 'aria-describedby': 'use-sp-perms-helper-text' } }} />} label="Use SharePoint Permissions (recommended)" />
                    <FormHelperText sx={{paddingLeft: 2}} id="use-sp-perms-helper-text'">
                        Requires users to have at least "View/Read" access to the Item in SharePoint.
                    </FormHelperText>
                    <br></br>
                    <FormControlLabel value="true" control={<Radio slotProps={{ input: { 'aria-describedby': 'use-av-perms-helper-text' } }}/>} label="Use Audit Vault for M365 Permissions" />
                    <FormHelperText sx={{paddingLeft: 2}} id="use-av-perms-helper-text" >
                        Requires users to have at least "Tenant Viewer" role access in Audit Vault for M365.
                    </FormHelperText>
                </RadioGroup>
                </FormControl>
            </Typography>
            </CardContent>
            <CardActions sx={{width: '100%', textAlign: 'right'}}>
                <Typography component="span" sx={{ textAlign: "left", width: '50%'}}>
                </Typography>
                <Typography component="span" sx={{ textAlign: "right", width: '50%'}}>
                    <Button size="small" href={`${LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE}`} target='_blank'>Learn More</Button>
                </Typography>
            </CardActions>
        </Card>
        </Grid>

        <Grid item xs={12}>
        <Card variant="outlined">
        <CardContent>           
            <p><b>Audit Source Workloads</b></p>
            <p>
            Below you may specify the Audit Source Workloads to be managed by Audit Vault for M365.  SharePoint is enabled by default.  Furthermore if you wish to customize your configuration or if you have specific questions please <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Contact Support</Link>.
            </p>
            <Divider sx={{ my: 2, borderColor: 'green' }}/>            
            <Typography key='txtLogSpAuditHistory' sx={{ marginBottom: 3}} component="div"> 
            <Accordion expanded={tenantLogSharePoint}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Checkbox required
                    name="tenantLogSharePointValue"
                    controlid="tenantLogSharePointValue"
                    checked={tenantLogSharePoint} 
                    onChange={event => setTenantLogSharePoint(event.target.checked) }   
                    disabled
                   /> <Typography sx={{ marginTop: 1}} component="span"> Preserve SharePoint Audit Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                <TextField  
                    select 
                    name="sharePointJobProcessMinsInterval" 
                    inputRef={sharePointJobProcessIntervalRef}
                    value={sharePointJobProcessInterval}
                    label="Process SharePoint Audit Jobs Every X Minutes/Hours:" 
                    variant="outlined" 
                    size="small"
                    style={{ width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setSharePointJobProcessInterval(event.target.value)}
                    >
                    {JOB_PROCESS_INTERVALS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                    </TextField>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            </Typography>
            <Typography key='txtLogExAuditHistory' sx={{ marginBottom: 3}} component="div"> 
            <Accordion expanded={tenantLogExchange}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={event => setTenantLogExchange(!tenantLogExchange) }
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Checkbox required
                  checked={tenantLogExchange} 
                  onChange={event => setTenantLogExchange(event.target.checked) } 
                  name="tenantLogExchangeValue"
                  controlid="tenantLogExchangeValue" 
                   /> <Typography sx={{ marginTop: 1}} component="span">Preserve Exchange Audit Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                   <TextField  
                    select 
                    name="exchangeJobProcessMinsInterval" 
                    inputRef={exchangeJobProcessIntervalRef}
                    value={exchangeJobProcessInterval}
                    label="Process Exchange Audit Jobs Every X Minutes/Hours:" 
                    variant="outlined" 
                    size="small"
                    style={{ width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setExchangeJobProcessInterval(event.target.value)}
                    >
                    {JOB_PROCESS_INTERVALS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                    </TextField>
                   </Typography>
                   </AccordionDetails>
            </Accordion>
            </Typography>
            <Typography key='txtLogAdAuditHistory' sx={{ marginBottom: 3}} component="div"> 
            <Accordion expanded={tenantLogEntraID}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={event => setTenantLogEntraID(!tenantLogEntraID) }
                aria-controls="panel1a-content"
                id="panel1a-header"
                > 
                <Checkbox required
                  checked={tenantLogEntraID} 
                  onChange={event => setTenantLogEntraID(event.target.checked) } 
                  name="tenantLogAzureAdValue"
                  controlid="tenantLogAzureAdValue" 
                   /> <Typography sx={{ marginTop: 1}} component="span">Preserve Entra ID Audit Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                <TextField  
                select 
                name="azureAdJobProcessMinsInterval" 
                inputRef={entraIDJobProcessIntervalRef}
                value={entraIDJobProcessInterval}
                label="Process Entra ID Audit Jobs Every X Minutes/Hours:" 
                variant="outlined" 
                size="small"
                style={{ width: '100%', maxWidth: 350 }}
                InputLabelProps={{ shrink: true }}
                onChange={event => setEntraIDJobProcessInterval(event.target.value)}
                >
                {JOB_PROCESS_INTERVALS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
                ))}
                </TextField>
                </Typography>
                </AccordionDetails>
            </Accordion>
            </Typography>
            <Typography key='txtLogGeneralAuditHistory' sx={{ marginBottom: 3}} component="div"> 
            <Accordion expanded={tenantLogGeneral}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={event => handleGeneralAuditChange(!tenantLogGeneral) }
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Checkbox required
                  checked={tenantLogGeneral} 
                  onChange={event => handleGeneralAuditChange(event.target.checked) } 
                  name="tenantLogGeneralValue"
                  controlid="tenantLogGeneralValue" 
                   /> <Typography sx={{ marginTop: 1}} component="span">Preserve General Audit Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                   <TextField  
                    select 
                    name="generalJobProcessMinsInterval" 
                    inputRef={generalJobProcessIntervalRef}
                    value={generalJobProcessInterval}
                    label="Process General Audit Jobs Every X Minutes/Hours:" 
                    variant="outlined" 
                    size="small"
                    style={{ width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setGeneralJobProcessInterval(event.target.value)}
                    >
                    {JOB_PROCESS_INTERVALS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                    </TextField>
                   </Typography>
                   <Typography sx={{padding : 2, paddingTop: 3}}>Select the types of Audit Logs to Preserve from the General Logs:</Typography>
                   {errorTeamsNotLogged &&
                        <>
                            <Alert severity="error">
                            <AlertTitle>Select At Least One Type of General Audit Logs.</AlertTitle>
                            When enabling the General Audit Logs, you need to specify at least one additional type in order to continue.
                            </Alert>
                        </>
                   }
                   <Typography>
                   <Checkbox required
                    checked={tenantLogTeams} 
                    onChange={event => setTenantLogTeams(event.target.checked) } 
                    name="tenantLogTeamsValue"
                    controlid="tenantLogTeamsValue" 
                    sx={{padding : 2}}
                   /> <Typography component="span">Preserve Teams Audit Logs</Typography>
                   </Typography>
                </AccordionDetails>
            </Accordion>
            </Typography>
        </CardContent>
        </Card>
        </Grid>

        {enableSelfDatabaseHosting && 
        <Grid item xs={12}>
        <Card variant="outlined">
        <CardContent>
        <Accordion expanded={isDatabaseHostedByCompany}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Checkbox required
                    name="isDatabaseHostedByCompany"
                    controlid="isDatabaseHostedByCompany"
                    checked={isDatabaseHostedByCompany} 
                    onChange={event => setIsDatabaseHostedByCompany(event.target.checked) }
                    disabled={tenant}
                   /> <Typography sx={{ marginTop: 1}} component="span"> Host Your Own Audit Vault Database</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>Host your own Audit Vault for M365 SQL Server database.  Fill in the connection information below and test the connection.  Once your connection has been saved, your database will be created.</Typography>
                    <Typography><br></br></Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{minWidth: '340px'}}>
                        <Typography>
                        <b>Server Name:</b>
                        <br></br>
                        <TextField 
                        required='true'
                        name="dbServerName"
                        placeholder="Enter the SQL Server Server Name Here."
                        value={dbServerName}
                        variant="outlined" 
                        size="small"
                        style={{ width: 400}}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setDbServerName(event.target.value) }
                        error={dbServerNameError}
                        helperText={dbServerNameError ? "Server Name is Required" : ""}
                        disabled={tenant && !isAdminPage} 
                        inputProps="100" />
                        </Typography>
                        <Typography><br></br></Typography>
                        <Typography>
                        <b>Login Id:</b>
                        <br></br>
                        <TextField 
                        type={!tenant ? 'text' : 'password'}
                        required='true'
                        name="dbUserId"
                        placeholder="Enter the SQL Server Authentication Login Id Here."
                        value={dbUserId}
                        variant="outlined" 
                        size="small"
                        style={{ width: 400}}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setDbUserId(event.target.value) }
                        error={dbUserIdError}
                        helperText={dbUserIdError ? "Login is Required" : ""}
                        disabled={tenant && !isAdminPage} 
                        inputProps="100" />
                        </Typography>
                        <Typography><br></br></Typography>
                        <Typography>
                        <b>Password:</b>
                        <br></br>
                        <TextField 
                        type={!tenant ? 'text' : 'password'}
                        required='true'
                        name="dbPassword"
                        placeholder="Enter the SQL Server Authentication Password Here."
                        value={dbPassword}
                        variant="outlined" 
                        size="small"
                        style={{ width: 400}}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setDbPassword(event.target.value) }
                        error={dbPasswordError}
                        helperText={dbPasswordError ? "Password is Required" : ""}
                        disabled={tenant && !isAdminPage} 
                        inputProps="100" />
                        </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{minWidth: '340px'}}>
                        <Card variant="outlined">
                            <CardContent>
                            <Typography><b>SQL Server Requirements:</b></Typography>
                            <Typography><br></br></Typography>
                            <Typography>Hosting your own database requires the following settings enabled on your Server:
                                <ul>
                                    <li>Public Access to Selected Networks Enabled.</li>
                                    <li>Firewall settings set to allow the IP addresses of the Audit Vault for M365 servers to access your resource.</li>
                                </ul>
                                
                            </Typography>
                            <Typography>Additionally, you will need the following:
                                <ul>
                                    <li>SQL Server Name.</li>
                                    <li>SQL Server Authenication Login Id and Password is Required.</li>
                                </ul>
                            </Typography>
                            <Typography><br></br></Typography>
                            <Typography>Contact Support for more details.</Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                    </Grid>
                    <ProgressBar message={"Testing connection ..."} loading={loadingTestCnn} />
                    {testCnnSuccess &&
                        <Alert severity='success'><AlertTitle>Successfully connected to your database.  You may proceed.</AlertTitle></Alert>
                    }
                    {testCnnError &&
                        <Alert severity='error'><AlertTitle>Error</AlertTitle>{testCnnMsg}</Alert>
                    }
                    <Typography><br></br></Typography>
                    <Typography><b>Note: Once your database has been created, you cannot update the settings unless you contact Support.</b></Typography>
                    <Typography><br></br></Typography>
                    <Button disabled={loadingTestCnn} className="ml-auto" onClick={() => handleTestConnection()}>Test SQL Server Connection</Button>
                </AccordionDetails>
            </Accordion>
        </CardContent>
        </Card>       
        </Grid>
        }
        <Grid container spacing={2} paddingTop={3} paddingLeft={3}>
        <Grid item xs={6} sx={{ textAlign: "left", width: '50%'}}>
        {!tenant ? <Button variant="contained" className="ml-auto" onClick={() => handleInsert()}> Create Tenant </Button>
                : <Button variant="contained" className="ml-auto" onClick={() => handleUpdate()}> Save Changes </Button>}
        </Grid>
        <Grid item xs={6}  sx={{ textAlign: "right", width: '50%'}}>
            <Button className="ml-auto" onClick={() => navigate(-1)}>Back</Button>
        </Grid>
        </Grid>
        </Grid> 
        </React.Fragment>
    )
    }
}