import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        width: '100%',
        flexDirection: 'row',
        marginTop: 8
    },
    subTitleContainer:{
        width: '100%',
        flexDirection: 'row',
        marginTop: 90
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    subText:{
        fontSize: 10,
        textAlign: 'left',
    }
  });


  const InvoiceThankYouMsg = () => (
    <>s
    <View style={styles.subTitleContainer}>
          <Text style={styles.subText}>Questions about your bill?  Contact us at support@ecminsights.com</Text>
    </View>
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Thank you for your business</Text>
    </View>
    </>
  );
  
  export default InvoiceThankYouMsg