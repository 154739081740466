// Valid periods would be 0: 7 days, 1: 30 days, 2: 90 days, 3: 180 days, 4: 1 year.
const REPORT_ON_PERIOD_1WEEK = 0;
const REPORT_ON_PERIOD_30DAYS = 1;
const REPORT_ON_PERIOD_90DAYS = 2;
const REPORT_ON_PERIOD_180DAYS = 3;
const REPORT_ON_PERIOD_1YEAR = 4;

// The constant below controls the default value used in drop down value lists for reports.
const REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE = REPORT_ON_PERIOD_1WEEK;

const REPORT_PERIOD_LIST = [
    { id: REPORT_ON_PERIOD_1WEEK, label: '7 days' },
    { id: REPORT_ON_PERIOD_30DAYS, label: '30 days' },
    { id: REPORT_ON_PERIOD_90DAYS, label: '90 days' },
    /*
    { id: REPORT_ON_PERIOD_180DAYS, label: '6 months' },
    { id: REPORT_ON_PERIOD_1YEAR, label: '1 year' },
     */
];

export { REPORT_PERIOD_LIST, REPORT_ON_PERIOD_1WEEK, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_180DAYS, REPORT_ON_PERIOD_1YEAR, REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE };