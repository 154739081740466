// React Components
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

// Audit Vault Components
import ProgressBar from '../components/common/ProgressBar';

// Audit Vault Utilities
import { updateTenantSetPowerShellAdminConsent } from '../utilities/common-tenant';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function GrantHistoricalImportConsent(props) {

    const { user } = props;

    const isAuthenticated = useIsAuthenticated();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);

    const adminConsent = queryParams.get("admin_consent");
    const tenantId = queryParams.get("tenant");
    const err = queryParams.get("error");
    const errDesc = queryParams.get("error_description");

    console.log(`Admin Consent: ${adminConsent}`);
    console.log(`Tenant Id: ${tenantId}`);
    console.log(`Error: ${err}`);
    console.log(`Error Description: ${errDesc}`);
    console.log(`User Id: ${user.id}`);
    console.log(`User Company Id: ${user.companyId}`);

    async function getData() {

        if (loading && isAuthenticated && user) {

            try {
                var updateResponse = await updateTenantSetPowerShellAdminConsent(tenantId, user.userEmail, user.microsoftGraphId);
                if (updateResponse != null) {
                    navigate(`/Tenant/MyTenant?tab=4`);
                }
            }
            catch (e) {
                console.log("GrantHistoricalImportConsent: getData")
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    if (adminConsent == 'True' && err == null) {
        useEffect(() => {
            if (loading && user) {
                getData();
            };
        }, []);
    }

    if (err) {
        useEffect(() => {
            setLoading(false);
        }, []);
    }

    return (
        <>
            <ProgressBar message="Granting Admin Consent ..." loading={loading} />
            {err && (
                <Alert severity="error">
                    <AlertTitle>Unable to Obtain Historical Audit Importer Admin Consent.</AlertTitle>
                    <br />
                    <div>Error: <strong>{err}</strong></div>
                    <div>{errDesc}</div>
                    <div>Reason: This App requires access to your M365 Tenant to execute scripts to extract legacy audit information.</div>
                    <div>Resolution: Your Microsoft 365 Global Administrator needs to consent + grant access so this App.</div>
                    <br /><Link onClick={() => navigate(`/Support/HelpCenter`)} component="button">Help Center: See "Learn about Granting Admin Consent" in our getting started guides</Link>
                    <br /><br /><Link onClick={() => navigate(`/`)} component="button">Go back to Home Page and try again</Link>
                </Alert>)}
        </>
    );
}
export default GrantHistoricalImportConsent;