import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


const StyledBadge = styled(Badge)(({ theme, bgcolor, txtcolor }) => ({
  '& .MuiBadge-badge': {
    right: '50%', // Move the badge content to the right by 50%
    transform: 'translate(50%, 0%)', // Center the badge content both horizontally and vertically
    padding: '0 4px',
    minWidth: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: bgcolor,
    color: txtcolor,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const NumberBadge = ({ number, bgcolor, txtcolor }) => {
  return (
    <StyledBadge
      badgeContent={
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {number}
        </Typography>
      }
      bgcolor={bgcolor}
      txtcolor={txtcolor}
      overlap="rectangle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{
        width: 100,
        height: 30,
        borderRadius: '50%',
        backgroundColor: bgcolor,
        color: txtcolor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'bold',
      }}
    />
  );
};