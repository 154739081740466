// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";

// Material UI Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Audit Vault Components
import { formatDate } from '../../utilities/common-date-utils';

export default function TrialPeriodNag(props) {

    // Component Constants
    const { company } = props;
    const navigate = useNavigate();

    function getDaysLeftInTrial()
    {
      const date1 = new Date(company.trialPeriodEndDate);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1); 
      const daysLeft = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
      if (date2 > date1)
        return -1;

      return daysLeft;
    }

    if (!company)
      return "";

    if (company.setupStageComplete === 4)
      return "";
    
    // Component UI
    if (company.setupStageComplete >=3 && company.setupStageComplete < 6)
    {
      var daysLeft = getDaysLeftInTrial();
      if (daysLeft < 5 && daysLeft >= 0)
      {
        return(
          <Grid container spacing={3} sx={{width: '100%' }}>
              <Grid item xs={6} sx={{color: 'orangered'}}>
                  <b>Your Trial Period Expires: {formatDate(company.trialPeriodEndDate)} ({getDaysLeftInTrial() + " days"})</b>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'right'}}><Button variant="contained" size="small" sx={{background: "orangered"}} onClick={() => navigate(`/mybilling`)}>Pay Now</Button></Grid>
          </Grid>
        );
      }

      if (daysLeft < 0)
      {
        return(
          <Grid container spacing={3} sx={{width: '100%' }}>
              <Grid item xs={6} sx={{color: 'red'}}>
                  <b>Your Free Trial Has Expired!</b>&nbsp; Please complete payment to avoid disruptions.
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'right'}}><Button variant="contained" size="small" sx={{background: "red"}} onClick={() => navigate(`/mybilling`)}>Pay Now</Button></Grid>
          </Grid>
        );
      }

      return(
        <Grid container spacing={3} sx={{width: '100%' }}>
            <Grid item xs={6}>
                <b>Your Trial Period Expires: {formatDate(company.trialPeriodEndDate)} ({getDaysLeftInTrial() + " days"})</b>
            </Grid>
            <Grid item xs={6} sx={{textAlign: 'right'}}><Button variant="contained" size="small" onClick={() => navigate(`/mybilling`)}>Pay Now</Button></Grid>
        </Grid>
      );
    }
  }